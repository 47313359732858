<div class="dialog-container import-dialog" ngClass.gt-md="md">
  <!--  <h1 mat-dialog-title>-->
  <!--    {{ data.title | translate }}-->
  <!--  </h1>-->
  <mat-dialog-content>
    <div [formGroup]="form">
      <div fxFlex="" fxLayout="column">
        <div fxFlex>
          <img src="{{ importData?.import_image }}" />
        </div>
        <div fxFlex>
          <button mat-flat-button color="primary" class="download_sample_file" (click)="downloadSampleFile()">
            {{ importData?.download_sample_file | translate }}
          </button>
        </div>
        <div fxFlex class="title">
          {{ importData?.import_types | translate }}
        </div>
        <div fxFlex>
          <div class="fileUpload image-upload field-container" [ngClass]="{ disabled: form.get(fieldName)?.disabled }">
            <div
              fxFlex=""
              fxLayout="row"
              class="input-container d-flex align-items-center justify-content-flex-start position-relative"
            >
              <div class="icon">
                <mat-icon svgIcon="file-document-box-plus-outline" [attr.size]="'14px'"></mat-icon>
              </div>
              <div fxLayout="column" class="labels" *ngIf="!SelectedFile">
                <span class="primary-label">
                  {{ importData?.drop_files | translate }}
                </span>
                <span class="secondary-label">
                  {{ importData?.pick_files | translate }}
                </span>
              </div>

              <div fxLayout="column" class="labels" *ngIf="SelectedFile">
                <span class="primary-label">
                  {{ SelectedFile.name }}
                </span>
                <span class="secondary-label">
                  {{ 'import_dialog.size' | translate }}
                  {{ SelectedFile.size }} Kb |
                  {{ 'import_dialog.type' | translate }}
                  {{ SelectedFile.type }}
                </span>
              </div>

              <div class="action-button">
                <button mat-flat-button color="primary" class="upload_button">
                  {{ importData?.browse | translate }}
                </button>
              </div>
              <input
                [disabled]="form.controls[fieldName]?.disabled"
                class="upload-file-hidden"
                [id]="fieldName"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="uploadFile($event, fieldName, form.controls[fieldName].value)"
              />
              <input type="hidden" [formControlName]="fieldName" />
            </div>
          </div>
        </div>
        <div fxFlex fxLayout="column" *ngIf="submitted && this?.errors" class="mat-error errors-container">
          <div fxFlex class="d-flex" *ngFor="let error of this?.errors">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!--  <mat-dialog-actions>-->
  <!--    <button mat-dialog-close>-->
  <!--      {{ data.cancelText | translate }}-->
  <!--    </button>-->
  <!--    <button-->
  <!--      class="{{ data?.submitCssClass ? data?.submitCssClass : submitCssClass }}"-->
  <!--      (click)="submit()"-->
  <!--      mat-dialog-close-->
  <!--    >-->
  <!--      {{ data.submitText | translate }}-->
  <!--    </button>-->
  <!--  </mat-dialog-actions>-->
</div>
