import { Component, Input, OnInit } from '@angular/core';
import { ItemProps, ListOptions } from '@app/interfaces';
import { RootService } from '@app/core/root.service';

@Component({
  selector: 'app-list-custom-actions',
  templateUrl: './list-custom-actions.component.html',
  styleUrls: ['./list-custom-actions.component.scss'],
})
export class ListCustomActionsComponent implements OnInit {
  @Input() columns: ItemProps[]; // retrieve from any list component includes the columns data
  @Input() service: RootService;
  @Input() options: ListOptions;
  @Input() row: any;

  constructor() {}

  ngOnInit(): void {}
}
