<div [formGroup]="innerFormGroup">
  <div class="checkbox-container">
    <p>
      {{ cid + '.' + field?.name | translate }}
    </p>
    <ng-container *ngFor="let item of selectionsArray">
      <mat-checkbox color="primary" class="bold d-flex clearfix pt-2 pb-2" [attr.testId]="" [formControlName]="item.id">
        {{ item.name }}
      </mat-checkbox>
    </ng-container>
  </div>
</div>
