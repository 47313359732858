<div fxLayout="column">
  <div class="breadcrumb" *ngIf="listOptions?.showBreadcrumb">
    <xng-breadcrumb [preserveQueryParams]="true" [autoGenerate]="false">
      <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">
        <ng-container>
          <mat-icon *ngIf="info">{{ info }}</mat-icon>
          {{ breadcrumb | translate }}
        </ng-container>
      </ng-container>
    </xng-breadcrumb>
  </div>
  <div fxFlex fxLayout="column" class="core-list primary-font" [ngClass]="{ 'full-width': listOptions?.width_percent }">
    <!-- List Heading -->
    <div
      fxFlex
      fxLayout
      class="page_heading list-grid-options"
      [ngClass]="{ headerSmallSize: headerSmallSize }"
      id="tableOptions"
      *ngIf="appListGridOptions"
    >
      <app-list-grid-options
        fxFlex
        [columns]="columns"
        [listOptions]="listOptions"
        [showTitle]="showTitle"
        [service]="service"
        [params]="params"
      ></app-list-grid-options>

      <app-list-multiple-operations
        [service]="service"
        [listOptions]="listOptions"
        [params]="params"
      ></app-list-multiple-operations>
    </div>
    <!-- End List Heading -->

    <!-- List Grid -->
    <div fxFlex fxLayout="row" fxLayoutGap="20px" class="container list-grid-container">
      <app-list-grid [columns]="columns" [options]="listOptions" [service]="service"></app-list-grid>
    </div>
    <!-- End List Grid -->

    <!-- List Pagination -->
    <div
      fxFlex
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="end center"
      class="container list-grid-pagination"
      *ngIf="!listOptions?.infiniteScrolling"
    >
      <app-pagination [service]="service" [options]="listOptions"></app-pagination>
    </div>
    <!--  <div *ngIf="listOptions?.infiniteScrolling">-->
    <!--    <app-pagination-infinite-scroll [service]="service" [options]="listOptions"></app-pagination-infinite-scroll>-->
    <!--  </div>-->
    <!-- List Pagination -->
  </div>
</div>

<div class="dummyDataLoader" id="dummyDataLoader" *ngIf="service.dummyDataLoader">
  <button mat-icon-button (click)="service.loadDummyData()">
    <mat-icon color="warn"> battery_unknown </mat-icon>
  </button>
</div>
<!-- Hint: This div will be removed in future -->
