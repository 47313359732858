import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const messageConfigs = {
      required: { message: 'required' },
      exist: { message: 'exist' },
      invalidEmailAddress: { message: 'invalid_email' },
      invalidNumber: { message: 'invalid_number' },
      invalidPassword: { message: 'invalid_password' },
      passwordSchemaWrong: { message: 'password_schema_msg' },
      must_be_numbers: { message: 'must_be_numbers' },
      minimum_message: { message: 'minimum_validation_message' },
      maximum_message: { message: 'maximum_validation_message' },
      must_start_with_plus: { message: 'must_start_with_plus' },
      minlength: { message: 'minlength', params: { value: `${validatorValue.requiredLength}` } },
      maxlength: { message: 'maxlength', params: { value: `${validatorValue.requiredLength}` } },
      invalid_min_length: {
        message: 'minlength',
        params: { value: `${validatorValue.requiredLength ? validatorValue.requiredLength : ''}` },
      },
      invalid_format: { message: 'invalid_format' },
    };
    const message = messageConfigs[validatorName] ? messageConfigs[validatorName] : { message: validatorName };
    if (!message.params) {
      message.params = { value: '' };
    }
    return message;
  }

  static emailValidator(control: AbstractControl) {
    // RFC 2822 compliant regex
    if (
      !control.value ||
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static numberValidator(control: AbstractControl) {
    const cValue = '' + control.value;
    if (cValue.match(/^\d*\.?\d*$/)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static passwordValidator(control: AbstractControl) {
    if (control.value) {
      //  Password must be between 6 and 12 digits long.
      // include at least one numeric digit.
      if (control.value.match(/^(?=.*\d).{6,12}$/)) {
        return null;
      } else {
        return { passwordSchemaWrong: true };
      }
    } else {
      return null;
    }
  }

  static phoneValidator(control: AbstractControl) {
    const NUMBER_REGEXP = /^\s*(\-|\+)?(\d+|(\d*(\.\d*)))([eE]+[-]?\d+)?\s*$/;
    if (control.value !== '' && !NUMBER_REGEXP.test(control.value)) {
      return { must_be_numbers: true };
    }
    if (control.value !== '' && control.value.length < 13) {
      return { minimum_message: true };
    }
    if (control.value !== '' && control.value.length > 16) {
      return { maximum_message: true };
    }
    // if (control.value.charAt(0) !== '+') {
    //     return {'must_start_with_plus': true};
    // }
    return null;
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    // get password from our confirmPassword form control
    const confirmPassword: string = control.get('confirmPassword').value;
    // compare is the password math
    if ((password === '' && confirmPassword === '') || password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    } else {
      control.get('confirmPassword').setErrors(null);
    }
  }

  static uniqueValidator(error: ValidationErrors, items: FormArray, controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value && control.value !== 0) {
        // if control is empty return no error
        return null;
      }
      let count = 0;
      items.controls.forEach((group: FormGroup) => {
        if (group.controls[controlName].value === control?.value) {
          count++;
        }
      });
      const valid = count < 2;

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static maxValidator(error: ValidationErrors, maxControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value && control.value !== 0) {
        // if control is empty return no error
        return null;
      }
      let count = 0;
      let maxControl = control.parent.get(maxControlName);

      if (maxControl?.value && control.value >= maxControl?.value) {
        count++;
      }
      const valid = count < 1;

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static minValidator(error: ValidationErrors, minControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value && control.value !== 0) {
        // if control is empty return no error
        return null;
      }
      let count = 0;
      let minControl = control.parent.get(minControlName);

      if (minControl?.value && control.value <= minControl?.value) {
        count++;
      }
      const valid = count < 1;

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
}
