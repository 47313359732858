<div class="list-options-container">
  <span class="title" *ngIf="showTitle">
    {{ (service.customTitle ? service.cid + '.' + service.customTitle : service.cid + '.title') | translate }}
    <ng-container *ngIf="listOptions.archivedList"> {{ 'common.archived_list' | translate }}</ng-container>
  </span>
  <div tourAnchor="{{ service.cid + '-list' }}"></div>

  <div class="buttons-container" fxFlex fxLayout>
    <nav fxHide.lt-lg mat-tab-nav-bar *ngIf="service.customFilterActiveLink && service.showCustomLinks">
      <a
        tourAnchor="{{ link?.name + '-customLink' }}"
        mat-tab-link
        *ngFor="let link of service?.customFilterLinks"
        (click)="customFilterLinksAction(link)"
        [active]="service.customFilterActiveLink == link?.name"
      >
        {{ 'common.' + link?.name | translate }}
      </a>
    </nav>

    <!-- custom filter links in responsive -->
    <mat-select
      fxHide.gt-lg
      fxHide.lg
      *ngIf="service.customFilterActiveLink && service.showCustomLinks"
      [value]="service.customFilterActiveLink"
      class="filter-links-responsive"
      [afaqyHeatmap]="{ page: service?.cid, target: 'list-custom-filter-selections-responsive' }"
    >
      <mat-option
        *ngFor="let link of service?.customFilterLinks"
        [value]="link.filter.value"
        (click)="customFilterLinksAction(link)"
      >
        {{ 'common.' + link?.name | translate }}
      </mat-option>
    </mat-select>

    <ng-container *ngIf="!listOptions.archivedList">
      <button
        tourAnchor="{{ service?.cid + '-export' }}"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="menu-trigger-icon"
        *ngIf="listOptions.exportList || listOptions.importList"
      >
        <mat-icon class="mdi-icon" svgIcon="dots-vertical" [attr.size]="'16px'"></mat-icon>
      </button>

      <mat-menu class="export-menu" #menu="matMenu" xPosition="before">
        <div class="d-flex align-items-center justify-content-space-between">
          <span class="export-menu-title">{{ 'common.quick_control' | translate }}</span>
          <button mat-icon-button>
            <mat-icon class="mdi-icon" svgIcon="dots-vertical" [attr.size]="'16px'"></mat-icon>
          </button>
        </div>

        <div *ngFor="let exportAsItem of exportAsList" fxFlex="" fxLayout="">
          <button
            fxFlex=""
            mat-menu-item
            (click)="service.exportMultiple(null, true, exportAsItem.type, params?.queryParams)"
            class="align-items-center in-menu-button"
            [afaqyHeatmap]="{ page: service?.cid, target: 'exportMultiple' }"
          >
            <mat-icon class="mdi-icon in-menu-icon" [svgIcon]="exportAsItem.icon" [attr.size]="'14px'"></mat-icon>
            <span class="in-menu-span">{{ 'common.' + exportAsItem.type + '_file' | translate }}</span>
          </button>
          <button
            *ngIf="listOptions.shareLink"
            fxFlex="40"
            mat-menu-item
            class="share-btn"
            (click)="service.generateShareLink(exportAsItem.type, params?.queryParams)"
          >
            {{ 'common.shareLinkBtn' | translate }}
          </button>
        </div>

        <button
          *ngIf="listOptions.importList"
          mat-menu-item
          (click)="service.openImportDialog(service)"
          class="align-items-center in-menu-button"
          [afaqyHeatmap]="{ page: service?.cid, target: 'import' }"
        >
          <mat-icon class="mdi-icon in-menu-icon" svgIcon="file-download-outline" [attr.size]="'14px'"></mat-icon>
          <span class="in-menu-span">{{ 'common.import_file' | translate }}</span>
        </button>
      </mat-menu>

      <!-- start input search -->

      <ng-container *ngIf="listOptions?.searchable">
        <form [formGroup]="form">
          <div ngClass.gt-lg="gt-lg" ngClass.gt-md="gt-md" ngClass.gt-sm="gt-sm" ngClass.gt-xs="gt-xs" fxFlex>
            <mat-form-field class="example-full-width search-par" floatLabel="never">
              <span matPrefix class="search-icon">
                <mat-icon class="mdi-icon" svgIcon="magnify" [attr.size]="'16px'" matSuffix></mat-icon>
              </span>
              <input
                formControlName="searchKey"
                matInput
                type="search"
                placeholder="{{ 'common.searchBy' | translate }} {{ service?.cid + '.' + 'searchKeyword' | translate }}"
                class="search-input"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + 'searchKey' }"
              />
            </mat-form-field>
          </div>
        </form>
      </ng-container>
      <!-- end input search -->

      <!-- Apply filter button -->
      <ng-container>
        <button
          mat-button
          class="filled-button filter-button"
          (click)="toggleInlineFilter()"
          [@showHide]
          tourAnchor="{{ service?.cid + '-add' }}"
          *ngIf="hasFilters"
        >
          <mat-icon
            fxLayout="row inline"
            fxLayoutAlign="center center"
            class="mdi-icon"
            svgIcon="filter"
            [attr.size]="'16px'"
          ></mat-icon>
          <span class="mx-2">{{ 'common.filter' | translate }} </span>
          <mat-icon
            class="arrow mdi-icon"
            [svgIcon]="'chevron-up'"
            [ngClass]="{ down: !service.showInlineFilters }"
            [attr.size]="'23px'"
          ></mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="listOptions.archivedFilter">
    <div>
      <app-filters-button
        [columns]="columns"
        [service]="service"
        [listOptions]="listOptions"
        [afaqyHeatmap]="{ page: service?.cid, target: 'filters-button' }"
      ></app-filters-button>
    </div>
  </ng-container>
  <!-- end Filter button  -->
  <ng-container *ngIf="service?.permission?.all || service?.permission?.create">
    <button
      mat-button
      class="filled-button add-button"
      *ngIf="listOptions.add"
      (click)="navToCreate()"
      [@showHide]
      tourAnchor="{{ service?.cid + '-add' }}"
    >
      <mat-icon class="mdi-icon" svgIcon="add-new" [attr.size]="'14px'"></mat-icon>
      {{ 'common.add' | translate }}
    </button>
  </ng-container>
</div>
