<h2 mat-dialog-title>{{ isEdit ? (service.cid + '.edit' | translate) : (service.cid + '.add' | translate) }}</h2>

<mat-dialog-content class="mat-typography">
  <div *ngFor="let prop of data?.ngSelectOptions?.saveFormProps">
    <app-core-form-content
      [cid]="service.cid"
      [service]="service"
      [field]="prop"
      [form]="form"
      (showHideChanges)="showHideChanges($event)"
      [isEdit]="isEdit"
      [isClone]="isClone"
      [isSubmitted]="isSubmitted"
      (UpdateFormFieldValueEmitter)="UpdateFormFieldValue($event)"
    >
    </app-core-form-content>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>{{ 'common.cancel' | translate }}</button>
  <button mat-raised-button color="primary" (click)="formSubmission()" [disabled]="loading || form.invalid">
    <mat-spinner *ngIf="loading; else canSave" diameter="20"></mat-spinner>
    <ng-template #canSave>{{ 'common.save' | translate }}</ng-template>
  </button>
</mat-dialog-actions>
