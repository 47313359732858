<mat-form-field style="width: 100%">
  <mat-label fxFlex="row" fxLayout>
    <div fxFlex="95">
      <span>{{ service?.cid + '.' + (field?.form?.name ? field?.form?.name : field?.name) | translate }}</span>
      <span *ngIf="requiredAsterisk" class="mat-error required-asterisk inline"> *</span>
    </div>
  </mat-label>
  <input
    [attr.inputSelector]="field?.form?.inputSelector"
    color="primary"
    matInput
    [ngxTimepicker]="timePicker"
    [formControl]="formControl"
    [readonly]="field?.form?.readonly"
    [min]="min"
    [max]="max"
    [attr.testId]="field.name"
    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + formControl }"
  />
  <!--    [ngModel]="value"-->
  <!--    (timeChanged)="timeChange($event)"-->
  <!--  <input type="hidden" [(ngModel)]="value" />-->
  <mat-icon matSuffix fxFlex="" class="mdi-icon time-picker-icon" svgIcon="clock-start"></mat-icon>
  <ngx-material-timepicker [cancelBtnTmpl]="cancel" [confirmBtnTmpl]="ok" #timePicker></ngx-material-timepicker>
  <mat-error>
    <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
  </mat-error>
</mat-form-field>
<ng-template #cancel>
  <button mat-stroked-button color="warn" class="timepicker-button" [attr.testId]="'time-picker-cancel'">
    {{ 'common.cancel' | translate }}
  </button>
</ng-template>
<ng-template #ok>
  <button mat-stroked-button color="primary" class="timepicker-button" [attr.testId]="'time-picker-ok'">
    {{ 'common.ok' | translate }}
  </button>
</ng-template>
<ng-template #validationErrors>
  <app-input-validation-errors
    [service]="service"
    [form]="form"
    [isEdit]="isEdit"
    [field]="field"
    [isClone]="isClone"
    [name]="field?.name"
    [disabledInput]="(form?.controls)[field.name].disabled"
  ></app-input-validation-errors>
</ng-template>
