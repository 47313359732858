import { Injectable } from '@angular/core';
import { RootService } from '@app/core/root.service';
import { ApiResponse, ItemProps } from '@app/interfaces';
import { Router } from '@angular/router';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { SharedService } from '@app/shared/services/shared.service';
import { CredentialsService } from '@app/core';
import { PermissionsService } from '@app/core/permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends RootService {
  constructor(router: Router, api: ApiRequestService, permissionsService: PermissionsService, shared: SharedService) {
    super(router, api, permissionsService, shared);
    this.permissionsKey = 'dashboard';
  }

  routerPrefix(val: string = '') {
    return val ? val : 'dashboard';
  }

  get featureProps(): ItemProps[] {
    return [];
  }
}
