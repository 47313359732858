import { MenuItem } from '@app/interfaces';

export class MenuItems {
  // @ts-ignore
  items: MenuItem[] = [
    {
      title: 'home',
      url: '/home',
      mdiIcon: true,
      icon: 'speedometer',
      // permissionsKey: 'dashboard',
    },
    {
      title: 'clients',
      url: '/clients',
      icon: 'people',
      // permissionsKey: 'users',
      children: [
        {
          title: 'clients-list',
          url: '/clients',
          exact: true,
          icon: '',
        },
      ],
    },
    {
      title: 'device-settings',
      url: '/device-settings',
      icon: 'people',
      children: [
        {
          title: 'devices',
          url: '/device-settings/devices',
          exact: true,
          icon: '',
        },
        {
          title: 'commands',
          url: '/device-settings/commands',
          exact: true,
          icon: '',
        },
      ],
    },
    {
      title: 'app-settings',
      url: '/settings/cms',
      mdiIcon: true,
      icon: 'settings',
      permissionsKey: 'setting',
      children: [
        {
          title: 'cms',
          url: '/settings/content-management-system',
          exact: true,
          icon: '',
        },
      ],
    },
  ];
}
