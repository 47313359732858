<div fxLayout="column">
  <div ngClass.gt-md="form-spacing" [ngClass]="{ headerSmallSize: headerSmallSize }" class="core-form-component">
    <form fxFlex fxLayout="column" class="core-form-style" [formGroup]="form">
      <div fxFlex fxLayout class="page_heading core-form-header w-100">
        <app-form-footer
          (submit)="formSubmission()"
          (saveAddNew)="formSubmission(true)"
          (cancel)="cancelFormSubmission()"
          [form]="form"
          [isSaveAddNew]="isSaveAddNew"
          [isEdit]="isEdit"
          [isClone]="isClone"
          [isSubmitted]="isSubmitted"
        ></app-form-footer>
      </div>

      <div fxFlex fxLayout class="core-form-component-body">
        <div fxFlex class="form-body-content">
          <div fxFlex fxLayout="column" class="d-flex flex-items-center">
            <div fxFlex="" fxLayout="column" class="default-form">
              <div
                *ngFor="let groups of service.formInputsCategorized | pairs; trackBy: trackByFn"
                fxFlex
                fxLayout="column"
                class="service-form-inputs-wrapper flex-wrap-margin-fixer"
              >
                <!--                [ngStyle]="{ 'margin-right': '-' + gridGapSize }"-->
                <!-- default inputs Group start  aka (no tab group or tabs )-->
                <ng-container *ngIf="groups[0] === 'defaultGroup'">
                  <ng-container *ngFor="let tab of groups[1] | pairs; trackBy: trackByFn">
                    <ng-container *ngFor="let section of tab[1] | pairs; trackBy: trackByFn">
                      <div class="form-section defaultSection" fxFlex="" fxLayout="column">
                        <div class="form-section-title" *ngIf="section[0] !== 'defaultSection'">
                          {{ section[0] | translate }}
                        </div>
                        <div
                          class="form-section-content grid-container"
                          [style.grid-template-columns]="gridColumns"
                          [style.grid-column-gap]="horizontalSpace"
                          [style.grid-row-gap]="verticalSpace"
                        >
                          <ng-template
                            [ngTemplateOutlet]="formTemplateRef"
                            [ngTemplateOutletContext]="{ form: section[1] }"
                          >
                          </ng-template>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <!-- default inputs Group start -->

                <!-- Tabs Groups start -->
                <ng-container *ngIf="groups[0] !== 'defaultGroup'">
                  <div fxFlex="" fxLayout="column">
                    <mat-tab-group
                      fxFlex=""
                      class="core-form-mat-tab-group"
                      [selectedIndex]="selectedIndex"
                      [ngClass]="groups[0]"
                      (selectedTabChange)="onTabChanged($event)"
                    >
                      <mat-tab
                        *ngFor="let tab of groups[1] | pairs; let i = index"
                        label=""
                        [disabled]="groupTabPrevErrors(groups[1], i) > 0"
                      >
                        <ng-template mat-tab-label>
                          <img
                            [hidden]="!tab[1][(tab[1] | pairs)[0][0]][0]?.form?.groupBy?.tabGroup?.icon"
                            class="mx-4 tab-icon"
                            [src]="tab[1][(tab[1] | pairs)[0][0]][0]?.form?.groupBy?.tabGroup?.icon"
                            width="19px"
                            height="15px"
                            alt="tab icon"
                          />
                          {{ tab[0] | translate }}
                          <span class="badge bg-danger" *ngIf="sectionErrorsCount(tab[1]) > 0">
                            {{ sectionErrorsCount(tab[1]) }}
                          </span>
                        </ng-template>
                        <div class="pt-5" fxFlex="" fxLayout="column">
                          <ng-container *ngFor="let section of tab[1] | pairs; trackBy: trackByFn">
                            <div class="form-section" fxFlex="" fxLayout="column">
                              <div class="form-section-title" *ngIf="section[0] !== 'defaultSection'">
                                {{ section[0] | translate }}
                              </div>
                              <!-- 
                                  A Work-around
                                  Set class name by section name (`section[0].split(".").pop()`)
                                  to be able to handle a special custom sections grid like : PlateNumber/Letters grid.
                                -->
                              <div
                                class="form-section-content grid-container"
                                [ngClass]="section[0].split('.').pop()"
                                [style.grid-template-columns]="gridColumns"
                                [style.grid-column-gap]="horizontalSpace"
                                [style.grid-row-gap]="verticalSpace"
                              >
                                <!-- <ng-container *ngFor="let field of section[1]"> -->
                                <!-- Call the form template to init the form -->
                                <ng-template
                                  [ngTemplateOutlet]="formTemplateRef"
                                  [ngTemplateOutletContext]="{ form: section[1], formGroup: form }"
                                >
                                </ng-template>
                                <!-- </ng-container> -->
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </mat-tab>
                    </mat-tab-group>

                    <!--tabs navigator container-->
                    <div class="mat-tab-navigator" *ngIf="(groups[1] | pairs).length - 1">
                      <button class="previous" (click)="previousStep()" [hidden]="selectedIndex === 0" mat-button>
                        {{ 'common.prev' | translate }}
                      </button>
                      <button
                        (click)="nextStep()"
                        [hidden]="selectedIndex === tabGroupsCount"
                        mat-flat-button
                        color="primary"
                        class="btn-primary next"
                      >
                        {{ 'common.next' | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>
                <!-- Tabs Groups end -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="dummyDataLoader" id="dummyDataLoader" *ngIf="service.dummyDataLoader">
  <button mat-icon-button (click)="loadDummyData()">
    <mat-icon color="warn">battery_unknown</mat-icon>
  </button>
</div>
<!-- Start Main Form Template -->

<!-- Define the recursive template. -->
<ng-template #formTemplateRef let-form="form" let-formGroup="formGroup" let-formArrayWrap="formArrayWrap">
  <ng-container *ngFor="let field of form; trackBy: trackByFn">
    <!-- Start not array -->
    <ng-container *ngIf="field?.form && field?.form?.formFieldType != 'formArray' && fieldShowConditions(field)">
      <!-- Initiate the recursive template rendering. -->
      <ng-template
        [ngTemplateOutlet]="fieldTemplateRef"
        [ngTemplateOutletContext]="{ field: field, formGroup: formGroup, formArrayWrap: formArrayWrap }"
      >
      </ng-template>
    </ng-container>
    <!-- End not array -->
    <!-- Start Array -->
    <ng-container *ngIf="field?.form && field?.form?.formFieldType == 'formArray'">
      <div [formGroup]="Form">
        <!-- // Initiate the recursive template rendering.  -->
        <div
          [formArrayName]="field.form.name"
          *ngFor="let item of getArrayFormControls(field.form.name); let i = index"
        >
          <div [formGroupName]="i">
            <ng-template
              [ngTemplateOutlet]="formTemplateRef"
              [ngTemplateOutletContext]="{
                form: field['form']['arrayForm'],
                formGroup: { id: i },
                formArrayWrap: field['form']['name']
              }"
            >
            </ng-template>
            <button
              mat-flat-button
              color="primary"
              class="btn-danger"
              (click)="removeArrayItem(field['form']['name'], i)"
              [disabled]=""
            >
              <!-- <mat-icon class="mat-18">save</mat-icon> -->
              {{ 'common.remove' | translate }}
            </button>
          </div>
        </div>
      </div>
      <button
        mat-flat-button
        color="primary"
        class="btn-primary"
        (click)="addArrayItem(field['form']['arrayForm'], field['form']['name'])"
        [disabled]=""
      >
        <!-- <mat-icon class="mat-18">save</mat-icon> -->
        {{ 'common.add' | translate }}
      </button>
    </ng-container>
    <!-- End Array -->
  </ng-container>
</ng-template>
<!-- End Main Form Template -->

<!-- Start Single Field Template -->
<ng-template #fieldTemplateRef let-field="field" let-formGroup="formGroup" let-formArrayWrap="formArrayWrap">
  <ng-container *ngIf="field?.form && fieldShowConditions(field)">
    <app-core-form-content
      class="app-core-form-content-selector grid-item"
      [cid]="service.cid"
      [service]="service"
      [field]="field"
      [form]="getFormArrayGroup(formArrayWrap, formGroup)"
      (showHideChanges)="showHideChanges($event)"
      [isEdit]="isEdit"
      [isClone]="isClone"
      [isSubmitted]="isSubmitted"
      (UpdateFormFieldValueEmitter)="UpdateFormFieldValue($event)"
      [ngClass]="field?.form?.formContentElementClass"
    >
    </app-core-form-content>
  </ng-container>
</ng-template>
<!-- End Single Field Template -->
