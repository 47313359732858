<div class="bg-container mat-typography">
  <div class="page-content">
    <div>
      <img class="sideContent-img" src="./assets/auth/logo-light.svg" alt="Afaqy" />
      <h2 class="primary-font page-description">
        {{ 'auth.sideContent' | translate }}
      </h2>
    </div>
    <div
      fxLayout="column"
      fxFlexAlign="center"
      class="fields-container-width primary-font"
      ngClass.gt-md="lg"
      ngClass.md="md"
      ngClass.sm="sm"
      ngClass.xs="xs"
    >
      <div class="header">
        <img class="my-5" src="./assets/auth/logo.svg" width="120px" alt="Afaqy" />
        <h2 class="primary-font title">{{ 'auth.change-password.title' | translate }}</h2>
      </div>
      <div class="alert-error-container" [hidden]="!error || isLoading">
        <div class="alert-error-content" fxLayout="row" fxLayoutAlign="space-between center" translate>
          <span>{{ error }}</span>
          <mat-icon (click)="error = null">close</mat-icon>
        </div>
      </div>
      <div fxFlex="100%" class="fields-container" fxLayoutAlign="center">
        <div fxFlex="100%" class="fields-box">
          <!-- Begin Form -->
          <form (ngSubmit)="changePassword()" [formGroup]="changepasswordForm" novalidate>
            <div class="fields-fields" fxLayout="column">
              <label for="mysecretpassword">{{ 'auth.change-password.password.title' | translate }}</label>
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  id="mysecretpassword"
                  type="password"
                  matInput
                  formControlName="password"
                  autocomplete="password"
                  [placeholder]="'auth.change-password.password.title' | translate"
                  required
                  showHideInput
                />
                <mat-error
                  *ngIf="
                    changepasswordForm.controls.password.invalid &&
                    changepasswordForm.controls.password.touched &&
                    changepasswordForm.controls.password?.errors?.required
                  "
                >
                  <span translate>{{ 'auth.change-password.password.required' | translate }}</span>
                </mat-error>
                <i
                  matSuffix
                  class="material-icons cursor-pointer showHidePassword"
                  showHideTrigger="mysecretpassword"
                  [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"
                ></i>
              </mat-form-field>

              <label for="confirmPassword">{{ 'auth.change-password.confirm_password.title' | translate }}</label>
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  id="confirmPassword"
                  type="password"
                  matInput
                  formControlName="confirmPassword"
                  autocomplete="confirmPassword"
                  [placeholder]="'auth.change-password.confirm_password.title' | translate"
                  required
                  showHideInput
                />
                <mat-error
                  *ngIf="
                    changepasswordForm.controls.confirmPassword.invalid &&
                    changepasswordForm.controls.confirmPassword.touched &&
                    changepasswordForm.controls.password.errors?.required
                  "
                >
                  <span translate>{{ 'auth.change-password.confirm_password.required' | translate }}</span>
                </mat-error>
                <i
                  matSuffix
                  class="material-icons cursor-pointer showHidePassword"
                  showHideTrigger="confirmPassword"
                  [showHideStatus]="{ materialIcon: true, id: 'confirmPassword' }"
                ></i>
              </mat-form-field>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-checkbox
                  class="checkBox color-dark primary-font"
                  color="primary"
                  formControlName="closeActiveSessions"
                >
                  {{ 'auth.change-password.signOutOfAllActivityLog' | translate }}
                </mat-checkbox>
              </div>
              <br />
              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="submit-btn"
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="changepasswordForm.invalid || isLoading"
              >
                <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                <span *ngIf="!isLoading">{{ 'auth.change-password.submit' | translate }}</span>
              </button>

              <!-- Begin Errors Summary -->
              <div class="error-summary mt-6">
                <label
                  class="col error-text"
                  [ngClass]="
                    (changepasswordForm.controls['password'].hasError('required') ||
                      changepasswordForm.controls['password'].hasError('minlength')) &&
                    changepasswordForm.controls.password.touched
                      ? 'text-danger'
                      : changepasswordForm.controls.password.touched
                      ? 'text-success'
                      : ''
                  "
                >
                  <i class="material-icons error-icon">
                    {{
                      (changepasswordForm.controls['password'].hasError('required') ||
                        changepasswordForm.controls['password'].hasError('minlength')) &&
                      changepasswordForm.controls.password.touched
                        ? 'clear'
                        : changepasswordForm.controls.password.touched
                        ? 'done'
                        : 'stop'
                    }}
                  </i>
                  {{ 'auth.change-password.validation.eightCharacters' | translate }}
                </label>

                <label
                  class="col error-text"
                  [ngClass]="
                    (changepasswordForm.controls['password'].hasError('required') ||
                      changepasswordForm.controls['password'].hasError('hasSpecialCharacters')) &&
                    changepasswordForm.controls.password.touched
                      ? 'text-danger'
                      : changepasswordForm.controls.password.touched
                      ? 'text-success'
                      : ''
                  "
                >
                  <i class="material-icons error-icon">
                    {{
                      (changepasswordForm.controls['password'].hasError('required') ||
                        changepasswordForm.controls['password'].hasError('hasSpecialCharacters')) &&
                      changepasswordForm.controls.password.touched
                        ? 'clear'
                        : changepasswordForm.controls.password.touched
                        ? 'done'
                        : 'stop'
                    }}
                  </i>
                  {{ 'auth.change-password.validation.specialCharacter' | translate }}
                </label>

                <label
                  class="col error-text"
                  [ngClass]="
                    (changepasswordForm.controls['password'].hasError('required') ||
                      changepasswordForm.controls['password'].hasError('hasNumber') ||
                      changepasswordForm.controls['password'].hasError('hasCapitalCase') ||
                      changepasswordForm.controls['password'].hasError('hasSmallCase')) &&
                    changepasswordForm.controls.password.touched
                      ? 'text-danger'
                      : changepasswordForm.controls.password.touched
                      ? 'text-success'
                      : ''
                  "
                >
                  <i class="material-icons error-icon">
                    {{
                      (changepasswordForm.controls['password'].hasError('required') ||
                        changepasswordForm.controls['password'].hasError('hasNumber') ||
                        changepasswordForm.controls['password'].hasError('hasCapitalCase') ||
                        changepasswordForm.controls['password'].hasError('hasSmallCase')) &&
                      changepasswordForm.controls.password.touched
                        ? 'clear'
                        : changepasswordForm.controls.password.touched
                        ? 'done'
                        : 'stop'
                    }}
                  </i>
                  {{ 'auth.change-password.validation.smallCapitalNumber' | translate }}
                </label>

                <label
                  class="col error-text"
                  [ngClass]="
                    (changepasswordForm.controls['confirmPassword'].hasError('required') ||
                      changepasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')) &&
                    changepasswordForm.controls.confirmPassword.touched
                      ? 'text-danger'
                      : changepasswordForm.controls.confirmPassword.touched
                      ? 'text-success'
                      : ''
                  "
                >
                  <i class="material-icons error-icon">
                    {{
                      (changepasswordForm.controls['confirmPassword'].hasError('required') ||
                        changepasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')) &&
                      changepasswordForm.controls.confirmPassword.touched
                        ? 'clear'
                        : changepasswordForm.controls.confirmPassword.touched
                        ? 'done'
                        : 'stop'
                    }}
                  </i>
                  {{ 'auth.change-password.validation.match' | translate }}
                </label>
              </div>
              <!-- End Errors Summary -->
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </div>
</div>
