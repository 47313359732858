import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { CustomValidators } from '@app/core/classes/custom-validators/custom-validators';
import { SetupAccountBody } from '@app/core/models/change-password-request-body';
import { RootService } from '@app/core/root.service';
import { environment } from '@env/environment';
import { Logger } from '../../../core';

const log = new Logger('setupAccount');
interface Branding {
  name?: string;
  logo?: string;
}

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['../../auth.scss', './setup-account.component.scss'],
})
export class SetupAccountComponent implements OnInit {
  error: string | undefined;
  setupAccountForm!: UntypedFormGroup;
  isLoading: boolean = false;
  showSuccessMgs: boolean;
  token: string;
  email: string;
  alive = true;
  showMsgSuccess: boolean = false;
  branding: Branding = {
    name: '',
    logo: '',
  };
  logoDefault = '/assets/logo-text.svg';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private service: RootService,
    private http: HttpClient
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.token = this.route.snapshot.params.token || null;
  }
  createForm() {
    this.setupAccountForm = this.formBuilder.group({
      password: [
        '',
        Validators.compose([
          // 1. Password Field is Required
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          // 5. check whether the entered password has a special character
          CustomValidators.patternValidator(/[&]|[-]|[_]|[.]|[!]|[@]|[#]|[$]|[%]|[*]|[?]|[+]|[~]/, {
            hasSpecialCharacters: true,
          }),
          // 6. Has a minimum length of 8 characters
          Validators.minLength(8),
        ]),
      ],
      subdomain: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(25),
          CustomValidators.patternValidator(/^[a-z0-9]*$/, {
            doesNotContainSpecialCharacters: true,
          }),
        ]),
      ],
    });
  }

  setupAccount() {
    this.isLoading = true;
    this.error = null;
    const body: SetupAccountBody = {
      token: this.token,
      password: this.setupAccountForm.get('password').value,
      subdomain: this.setupAccountForm.get('subdomain').value,
    };
    this.http.post(`${environment.serverUrl}clients`, body).subscribe(
      (data: any) => {
        if (data) {
          this.showSuccessMgs = data;
        }
        this.showMsgSuccess = true;
        this.isLoading = false;
        log.debug(`${data} successfully logged in`);
      },
      (err: any) => {
        const error = err;
        log.debug(`reseting password error: ${error}`);
        if (error && error.error?.message) {
          this.error = error.error.message;
        } else {
          this.error = 'Reseting password error.';
        }
        this.isLoading = false;
      }
    );
  }
}
