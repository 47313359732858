<div class="bg-container mat-typography">
  <div class="page-content">
    <div>
      <img class="sideContent-img" src="./assets/auth/logo-light.svg" alt="Afaqy" />
      <h2 class="primary-font page-description">
        {{ 'auth.sideContent' | translate }}
      </h2>
    </div>
    <div
      fxLayout="column"
      fxFlexAlign="center"
      class="fields-container-width primary-font"
      ngClass.gt-md="lg"
      ngClass.md="md"
      ngClass.sm="sm"
      ngClass.xs="xs"
    >
      <div class="header">
        <img class="my-5" src="./assets/auth/logo.svg" width="120px" alt="Afaqy" />
        <h2 class="primary-font title">{{ 'auth.login.title' | translate }}</h2>
      </div>
      <div class="alert-error-container" [hidden]="!error || isLoading">
        <div class="alert-error-content" fxLayout="row" fxLayoutAlign="space-between center" translate>
          <span>{{ error }}</span>
          <mat-icon (click)="error = null">close</mat-icon>
        </div>
      </div>

      <div fxFlex="100%" class="fields-container" fxLayoutAlign="center">
        <div fxFlex="100%" class="fields-box">
          <!-- Begin Form -->
          <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
            <div class="fields-fields" fxLayout="column">
              <label for="username">{{ 'auth.login.username.title' | translate }}</label>
              <mat-form-field floatLabel="never" [hideRequiredMarker]="true" appearance="outline">
                <input
                  id="username"
                  type="text"
                  matInput
                  formControlName="username"
                  autocomplete="username"
                  [placeholder]="'auth.login.username.placeholder' | translate"
                  required
                />
                <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                  <span translate>{{ 'auth.login.username.required' | translate }}</span>
                </mat-error>
              </mat-form-field>

              <label for="mysecretpassword">{{ 'auth.login.password.title' | translate }}</label>
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  id="mysecretpassword"
                  type="password"
                  matInput
                  formControlName="password"
                  autocomplete="current-password"
                  [placeholder]="'auth.login.password.placeholder' | translate"
                  required
                  showHideInput
                />
                <i
                  matSuffix
                  class="material-icons cursor-pointer showHidePassword"
                  showHideTrigger="mysecretpassword"
                  [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"
                ></i>
                <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                  <span translate>{{ 'auth.login.password.required' | translate }}</span>
                </mat-error>
              </mat-form-field>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-checkbox class="checkBox color-dark primary-font" color="primary" formControlName="remember">
                  {{ 'auth.login.remember_me' | translate }}
                </mat-checkbox>
                <div class="secondary-font forgot-password primary-font">
                  <a class="underline-none" [routerLink]="['/forgot-password']">{{
                    'auth.login.password.forgot' | translate
                  }}</a>
                </div>
              </div>
              <br />
              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="submit-btn"
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="loginForm.invalid || isLoading"
              >
                <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                <span *ngIf="!isLoading">{{ 'auth.login.submit' | translate }}</span>
              </button>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </div>
</div>
