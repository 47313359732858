import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ItemProps, ListOptions, Pagination, Row } from '@app/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppHelper } from '@app/core/classes/app-helper';
import { environment } from '@env/environment';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { takeWhile } from 'rxjs/operators';
import { RootService } from '@app/core/root.service';
import { SharedService } from '@app/shared/services/shared.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartData, ChartDataset } from 'chart.js';
import { transition, trigger, useAnimation } from '@angular/animations';
import { HideAnimation, showAnimation } from '@app/shared/animations/transform-opacity';
// import {strings as englishStrings} from 'ngx-timeago/language-strings/en';
// import {strings as arabicStrings} from 'ngx-timeago/language-strings/ar';
// import { TimeagoIntl } from 'ngx-timeago';

@Component({
  selector: 'app-list-grid',
  templateUrl: 'list-grid.component.html',
  styleUrls: ['list-grid.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '200ms 200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
    ]),
  ],
})
export class ListGridComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // Inputs
  @Input() columns: ItemProps[]; // retrieve from any list component includes the columns data
  @Input() service: RootService;
  @Input() passedRows: any;
  @Input() page: any;
  @Input() options: ListOptions;
  @Input() itemCount: number;
  @Input() groupId: number;
  @Input() locateOnMap: boolean;

  // Outputs
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() setActivePage: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCountPerPage: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tbodyScroller', {
    read: PerfectScrollbarDirective,
  })
  tbodyScroller: PerfectScrollbarDirective;

  @ViewChild('actionsTh', {
    read: ElementRef,
  })
  actionsTh: ElementRef;

  // Props
  rows: Row;
  environment = environment;
  direction = false;
  alive = true;

  pagination: Pagination = {
    currentPage: 1,
    lastPage: 1,
    totalRecords: 0,
    from: 1,
    to: 10,
  };

  actionsColumnWidth = 0;

  infiniteScrollConfig = {
    // scrollYMarginOffset : 45,
    swipeEasing: true,
  };

  oPassedRows: any = [];

  constructor(
    public dialog: MatDialog,
    private utilitiesService: UtilitiesService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer // private timeagoIntl: TimeagoIntl
  ) {}

  ngOnInit() {
    if (this.passedRows && this.passedRows.length) this.oPassedRows = JSON.parse(JSON.stringify(this.passedRows));
    this.service.resources.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.rows = this.service.resourcesList;
      this.service.filterData && this.service.isEmptyResult(this.service.filterData);
    });

    this.utilitiesService.dialogActionObservable$.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (data) {
        return this.doAction(data.action, data.id);
      }
    });
    // this.languageSubscription();
    this.utilitiesSubscriptions();
    this.setInfiniteScrollerContainerHeight();
    this.scrollTop();
    this.resourceListSubscriptions();
  }

  // languageSubscription() {
  //   this.service.shared.sendLanguage.pipe(takeWhile(() => this.alive)).subscribe((lang: string) => {
  //     lang === 'ar-SA' ? this.timeagoIntl.strings = arabicStrings : englishStrings;
  //     this.timeagoIntl.changes.next();
  //   });
  // }

  utilitiesSubscriptions() {
    // this.utilitiesService.paginateInfiniteObservable$.pipe(takeWhile(() => this.alive)).subscribe(() => {
    // });
    this.utilitiesService.filtersObservable$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.scrollTop();

      this.service.clearSelectedList();
    });
    this.utilitiesService.sortObservable$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.scrollTop();
      this.service.clearSelectedList();
    });
    this.utilitiesService.paginateObservable$.pipe(takeWhile(() => this.alive)).subscribe((res) => {
      if (res.cid === this.service.cid) {
        if (!this.tbodyScroller && !this.options.infiniteScrolling) {
          this.service.clearSelectedList();
        }
      }
    });
    this.service.updateResources.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.scrollTop();
    });
  }

  setInfiniteScrollerContainerHeight() {
    if (this.tbodyScroller && this.options.infiniteScrolling && this.options.infiniteScrollingHeight) {
      this.tbodyScroller.elementRef.nativeElement.style.height = this.options.infiniteScrollingHeight;
      this.tbodyScroller.update(); // for update scroll
    }
  }

  scrollTop() {
    if (this.tbodyScroller && this.options.infiniteScrolling) {
      this.tbodyScroller.update(); // for update scroll
      this.tbodyScroller.scrollToTop(0, 200); // for update scroll
    }
  }

  ngOnChanges(): void {
    if (this.passedRows) {
      this.rows = { data: this.passedRows };
    }
  }

  /**
   * doAction(type) used to make an action [view, edit and archive]
   * @param type => takes the type from the HTML and passes in the event function
   * @param id => item Id
   */
  doAction(type: string, id: any) {
    switch (type) {
      case 'edit':
        this.service.navigateUrl(this.service.navigateUrlOverride(`${this.service.cid}/${type}/${id}`, type, id));
        return;
      case 'view':
        this.service.navigateUrl(`${this.service.cid}/${type}/${id}`);
        return;
      case 'clone':
        this.service.navigateUrl(`${this.service.cid}/${type}/${id}`);
        return;
      case 'delete': {
        this.service.delete(id);
        return;
      }
      case 'archive': {
        this.service.archive(id);
        return;
      }
      case 'archive-multiple': {
        this.service.archiveMultiple(id);
        return;
      }
      case 'select': {
        this.service.selectItem(id);
        return;
      }
      case 'activate': {
        return this.service.toggleActivate(id);
      }
      case 'deactivate': {
        return this.service.toggleActivate(id);
      }
      case 'restore': {
        // return this.service.restore(id);
        return this.service.openDialog(id, type);
      }
      case 'downloadViaLink': {
        return window.open(id);
      }
      case 'closeIssue': {
        return this.service.closeIssue(id);
      }
      case 'reopenIssue': {
        return this.service.reopenIssue(id);
      }
      case 'end': {
        return this.service.end(id);
      }
      // case 'checkSelected': {
      //   // todo review doaction check selected later
      //   return !!this.service.checkSelected(id);
      // }
    }
  }

  // openArchiveDialog(row: any, action: string): void {
  //   this.dialog.open(DialogComponent, {
  //     data: {
  //       id: row.id,
  //       message: 'confirm_message',
  //       actionType: 'archive',
  //       title: 'confirm_archive',
  //       submitText: 'yes',
  //       cancelText: 'no',
  //       action,
  //       name: row.name
  //     }
  //   });
  // }

  // openDeleteDialog(row: any, action: string): void {
  //   this.dialog.open(DialogComponent, {
  //     data: {
  //       id: row.id,
  //       message: 'confirm_message',
  //       actionType: action,
  //       title: 'confirm_delete',
  //       submitText: 'yes',
  //       cancelText: 'no',
  //       action,
  //       name: row.name,
  //       displayName: row.displayName
  //     }
  //   });
  // }

  // openRestoreDialog(row: any, action: string): void {
  //
  //   this.dialog.open(DialogComponent, {
  //     data: {
  //       id: row.id,
  //       message: 'confirm_message',
  //       actionType: 'restore',
  //       title: 'confirm_restore',
  //       submitText: 'yes',
  //       cancelText: 'no',
  //       action,
  //       name: row.name,
  //       displayName: row.displayName
  //     }
  //   });
  // }

  sortList(columnName: string) {
    this.direction = !this.direction;
    this.passedRows && this.passedRows.length
      ? (this.passedRows = this.service.refactorSorting({ colName: columnName, dir: this.direction }, this.passedRows))
      : this.utilitiesService.sortListBy(this.service.refactorSorting({ colName: columnName, dir: this.direction }));
  }

  onSearch(e: any) {
    this.passedRows = e;
    this.rows = { data: this.passedRows };
  }

  /**
   * Pass the value to deep find method using path and original object
   * obj
   * path
   */
  getValue(obj: any, path: string) {
    return AppHelper.deepFind(obj, path);
  }

  hasActions(): boolean {
    return (
      this.options.edit ||
      this.options.archive ||
      this.options.view ||
      this.options.activate ||
      this.options.deactivate ||
      this.options.clone ||
      this.options.delete ||
      this.options.downloadable ||
      this.options.void ||
      !!this.options?.customActions?.length
    );
  }

  /**
   * If a column has at least one filter, return true
   */
  hasFilters(): boolean {
    let count = 0;
    if (this.columns && this.columns.length) {
      this.columns.forEach((c) => {
        if (c.list) {
          if (c.list.searchable) {
            count++;
          }
        }
      });
    }
    return count > 0;
  }

  rowsIds(data: any) {
    const ids: any = [];
    Object.values(data).forEach((value: any) => {
      ids.push(value.id);
    });
    return ids;
  }

  openEmmit() {
    this.sharedService.filterOpen$.next(true);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  showConditionsChecker(row: Row, column: ItemProps) {
    if (column && column.list.showConditions && column.list.showConditions.length) {
      let trueCount = 0;
      column.list.showConditions.forEach((condition) => {
        if (condition.fnRow(row, column)) {
          trueCount++;
        }
      });
      // console.log(row, trueCount);
      return !!trueCount;
    } else {
      return true;
    }
  }

  cssClassConditions(row: Row, column: ItemProps, additionalClasses?: string[]): string {
    let classes = '';
    if (additionalClasses && additionalClasses.length) {
      additionalClasses.forEach((cssClass) => {
        classes = classes + ' ' + cssClass;
      });
    }
    if (column && column.list.cssClassConditions && column.list.cssClassConditions.length) {
      column.list.cssClassConditions.forEach((cssClass) => {
        if (cssClass.fnRow(row, column)) {
          classes = classes + ' ' + cssClass.fnRow(row, column);
        }
      });
    }
    return classes;
  }

  innerCellCssConditions(row: Row, column: ItemProps, additionalClasses?: string[]): string {
    let classes = '';
    if (additionalClasses && additionalClasses.length) {
      additionalClasses.forEach((cssClass) => {
        classes = classes + ' ' + cssClass;
      });
    }
    if (column && column.list.innerCellCssConditions && column.list.innerCellCssConditions.length) {
      column.list.innerCellCssConditions.forEach((cssClass) => {
        if (cssClass.fnRow(row, column)) {
          classes = classes + ' ' + cssClass.fnRow(row, column);
        }
      });
    }
    return classes;
  }

  setPagination(event: any) {
    this.pagination = event;
  }

  infiniteScrollLoadMore() {
    if (this.pagination.lastPage > this.pagination.currentPage) {
      this.loadNextPage();
    } else {
      return;
    }
  }

  loadNextPage() {
    let page: number = this.pagination.currentPage;
    page += 1;
    this.utilitiesService.navigateInfinite(
      page,
      this.service.cid,
      this.service.customCid,
      this.service.customData.serviceUniqueIdentifier
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.actionsTh) {
        this.actionsColumnWidth = this.actionsTh.nativeElement.offsetWidth;
      }
      if (this.options) {
        this.setInfiniteScrollerContainerHeight();
      }
    }, 0);
  }

  getChartData() {
    if (this.options && this.options.chart) {
      let chartObj: ChartData;
      if (this.options.chart.data && this.options.chart.labels) {
        chartObj = {
          datasets: this.options.chart.data,
          labels: this.options.chart.labels,
        };
        this.service.chart.next(chartObj);
      } else if (
        this.options.chart.dataPath &&
        this.options.chart.labelsPath &&
        this.service &&
        this.service.resourcesList
      ) {
        chartObj = {
          datasets: this.getValue(this.service.resourcesList, this.options.chart.dataPath) as ChartDataset[],
          labels: this.getValue(this.service.resourcesList, this.options.chart.labelsPath) as string[],
        };
        this.service.chart.next(chartObj);
      }
    }
  }

  resourceListSubscriptions() {
    this.service.storeResourceListResponse$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.getChartData();
    });
  }
}
