import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CoreListComponent } from '@app/core/components/core-list/core-list.component';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@app/notifications/notifications.service';
import { ItemProps } from '@app/interfaces';
import { takeWhile } from 'rxjs/operators';
import { AppHelper } from '@app/core/classes/app-helper';
import { MessagingService } from '@app/shared/services/messaging/messaging.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['notifications-list.component.scss'],
})
export class NotificationsListComponent extends CoreListComponent implements OnInit, OnDestroy {
  activeLink: string;
  @Input() sideNav: any;
  @Input() isOpened: boolean;
  totalUnread: number;
  constructor(
    service: NotificationsService,
    utilitiesService: UtilitiesService,
    router: Router,
    activatedRoute: ActivatedRoute,
    private messgaing: MessagingService
  ) {
    super(service, utilitiesService, router, activatedRoute);
    this.listOptions = {
      ...this.listOptions,
      archive: false,
      edit: false,
      delete: false,
      selectable: false,
      restore: false,
      exportList: false,
      reopen: false,
      close: false,
      clone: false,
      actionsCssClass: 'action-thead',
      pagination: false,
      infiniteScrolling: true,
      infiniteScrollingHeight: '100vh',
    };

    this.service.sideNavSubject.subscribe((res: any) => {
      this.sideNav.close();
    });
  }

  ngOnInit() {
    // super.ngOnInit();
    AppHelper.calcListHeight();
    AppHelper.pushResize();
    // This is active list
    if (this.service?.resourcesList?.data?.length) {
      console.log(this.service.resourcesList.data.length);
    } else {
      return this.service.startAutoLoad(this.params).then(() => {
        /**
         * Init the columns from each service
         */
        const colsFromService: ItemProps[] = this.service.featureProps;
        if (colsFromService.length) {
          this.columns = colsFromService;
        }
        // overwrite the paginationInit function
        this.service.updateResources.pipe(takeWhile(() => this.alive)).subscribe(() => {
          return this.loadResources();
        });

        this.utilitiesService.paginateInfiniteObservable$
          .pipe(takeWhile(() => this.alive))
          .subscribe((event: { page: number; cid?: string; customCid?: string; serviceUniqueIdentifier?: string }) => {
            if (
              event.page &&
              (event.cid === this.service.cid || (event.customCid && event.customCid === this.service.customCid))
            ) {
              if (event.serviceUniqueIdentifier) {
                if (event.serviceUniqueIdentifier !== this.service.customData.serviceUniqueIdentifier) {
                  return;
                } else {
                  this.params.queryParams.page = event.page;
                  return this.loadInfiniteResources();
                }
              } else {
                this.params.queryParams.page = event.page;
                return this.loadInfiniteResources();
              }
            }
          });
      });
    }
    this.languageSubscription();
    this.service.loadSelectLists('list');
  }
  markAllNotifications(seen: number) {
    let body = {
      seen: seen,
    };
    this.service.markAllNotifications(body);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
