<div [formGroup]="form">
  <!-- start Range -->
  <div class="field-container" *ngIf="field?.form">
    <mat-form-field style="width: 50%" class="p-r-15" *ngIf="startDateKey">
      <mat-label>
        <span>{{ cid + '.' + (field.form.name ? field.form.name : startDateKey) | translate }}</span>
        <span *ngIf="checkRequiredFields(startDateKey)" class="mat-error required-asterisk inline"> *</span>
      </mat-label>
      <input
        color="primary"
        matInput
        [matDatepicker]="picker"
        [max]="startMaxDate"
        [min]="startMinDate"
        [formControlName]="startDateKey"
        autocomplete="off"
        (click)="picker.open()"
        (dateChange)="checkMaxRange($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 50%" class="p-l-15" *ngIf="startTimeKey">
      <mat-label fxFlex="row" fxLayout>
        <div fxFlex="95">
          <span>{{ cid + '.' + (field.form.name ? field.form.name : startTimeKey) | translate }}</span>
          <span *ngIf="checkRequiredFields(startTimeKey)" class="mat-error required-asterisk inline"> *</span>
        </div>
      </mat-label>
      <input
        color="primary"
        matInput
        [ngxTimepicker]="picker"
        [min]=""
        [max]="startMaxTime"
        [formControlName]="startTimeKey"
      />
      <mat-icon matSuffix fxFlex="" class="mdi-icon time-picker-icon" svgIcon="clock-start"></mat-icon>
      <ngx-material-timepicker #picker (timeSet)="checkMaxTime($event)"></ngx-material-timepicker>
    </mat-form-field>
    <!-- validation -->
    <ng-container *ngIf="form?.get(startDateKey)?.errors || form?.get(startTimeKey)?.errors">
      <!--        <ng-container >-->
      <div
        class="validation-errors date-errors"
        *ngIf="
          (form.get(startDateKey)?.touched || isSubmitted) &&
          form.get(startDateKey)?.invalid &&
          form.get(startDateKey)?.errors
        "
      >
        <div class="mat-error" *ngFor="let error of form.get(startDateKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        class="validation-errors time-errors"
        *ngIf="
          (form?.get(startTimeKey)?.touched || isSubmitted) &&
          form?.get(startTimeKey)?.invalid &&
          form?.get(startTimeKey)?.errors
        "
      >
        <div class="mat-error" *ngFor="let error of form.get(startTimeKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!--  -->
  </div>
  <!-- end Range -->
  <div class="field-container" *ngIf="field?.form">
    <mat-form-field style="width: 50%" class="p-r-15" *ngIf="endDateKey">
      <mat-label>
        <span>{{ cid + '.' + (field.form.name ? field.form.name : endDateKey) | translate }}</span>
        <span *ngIf="checkRequiredFields(endDateKey)" class="mat-error required-asterisk inline"> *</span>
      </mat-label>
      <input
        color="primary"
        matInput
        [matDatepicker]="picker"
        [max]="endMaxDate"
        [min]="endMinDate"
        [formControlName]="endDateKey"
        autocomplete="off"
        (click)="picker.open()"
        (dateChange)="checkMinRange($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 50%" class="p-l-15" *ngIf="endTimeKey">
      <mat-label fxFlex="row" fxLayout>
        <div fxFlex="95">
          <span>{{ cid + '.' + (field.form.name ? field.form.name : endTimeKey) | translate }}</span>
          <span *ngIf="checkRequiredFields(endTimeKey)" class="mat-error required-asterisk inline"> *</span>
        </div>
      </mat-label>
      <input
        color="primary"
        matInput
        [ngxTimepicker]="picker"
        [min]="endMinTime"
        [max]=""
        [formControlName]="endTimeKey"
      />
      <mat-icon matSuffix fxFlex="" class="mdi-icon time-picker-icon" svgIcon="clock-start"></mat-icon>
      <ngx-material-timepicker #picker (timeSet)="checkMinTime($event)"></ngx-material-timepicker>
    </mat-form-field>

    <ng-container *ngIf="form?.get(endDateKey)?.errors || form?.get(endTimeKey)?.errors">
      <!--        <ng-container >-->
      <div
        class="validation-errors date-errors"
        *ngIf="
          (form.get(endDateKey)?.touched || isSubmitted) &&
          form.get(endDateKey)?.invalid &&
          form.get(endDateKey)?.errors
        "
      >
        <div class="mat-error" *ngFor="let error of form.get(endDateKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        class="validation-errors time-errors"
        *ngIf="
          (form?.get(endTimeKey)?.touched || isSubmitted) &&
          form?.get(endTimeKey)?.invalid &&
          form?.get(endTimeKey)?.errors
        "
      >
        <div class="mat-error" *ngFor="let error of form.get(endTimeKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
