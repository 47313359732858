import { Component, OnInit } from '@angular/core';

import { ChartDataset, ChartOptions, ChartType, Color } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { untilDestroyed } from '@app/core';
import { startWith } from 'rxjs';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { SharedService } from '@app/shared/services/shared.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-line-chart-clients',
  templateUrl: './line-chart-clients.component.html',
  styleUrls: ['./line-chart-clients.component.scss'],
})
export class LineChartClientsComponent implements OnInit {
  item: {
    datasets: ChartDataset<'line'>[];
    labels: string[];
  };
  alive = true;

  public options: ChartOptions = {};
  public plugins = [
    pluginDataLabels,
    {
      beforeInit: function (chart: any, options: any) {
        chart.legend.afterFit = function () {
          this.height = this.height + 25;
        };
      },
    },
  ];

  constructor(private service: ApiRequestService, private shared: SharedService) {
    this.item = {
      datasets: [{ label: '', data: [] }],
      labels: [''],
    };
  }

  ngOnInit(): void {
    this.shared.sendLanguage
      .pipe(untilDestroyed(this), startWith(environment.defaultLanguage))
      .subscribe((lang: string) => {
        this.setOptions(lang === 'ar-SA' ? 'right' : 'left');
        // this.setDatepickerLang(lang);
        this.loadComponentData();
      });
  }

  loadComponentData() {
    this.service
      .get('admin/dashboard/application-clients')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.item.datasets = res.data.datasets;
        this.item.labels = res.data.labels;

        // TODO : Colors should be sent dynamically
        const colors = ['rgba(196, 91, 91, 1)', 'rgba(72, 162, 125, 1)'];
        this.item.datasets.forEach((item, i) => {
          // For lines colors.
          item.backgroundColor = colors[i];
          item.borderColor = colors[i];
          item.hoverBackgroundColor = colors[i];

          // For legend colors.
          item.pointBackgroundColor = colors[i];
          item.pointBorderColor = colors[i];
          item.pointHoverBackgroundColor = colors[i];
          item.pointHoverBorderColor = colors[i];

          item.tension = 0.5;
        });
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  // helpers
  setOptions(position: string): ChartOptions {
    return (this.options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxis: {
          ticks: {
            beginAtZero: true,
            precision: 0,
            callback: (label: any) => {
              const l = label >= 1000 ? label / 1000 + 'K' : label; // display 1000 as 1K
              return `${l}`;
            },
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            usePointStyle: true,
            padding: 20,
          },
        },
      },
    } as ChartOptions | any);
  }
}
