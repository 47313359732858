<div fxFlex="" fxLayout="column" fxLayoutAlign="space-around center" class="dialog-container">
  <mat-icon
    fxFlex=""
    class="mdi-icon"
    [svgIcon]="data.svgIcon ? data.svgIcon : 'close-circle'"
    [attr.size]="'43px'"
  ></mat-icon>
  <div class="matDialogContent" fxFlex="" fxLayout="column" fxLayoutAlign="center">
    <div class="matDialogMsg" [ngClass]="data.messageClass" fxFlex="">
      {{ data.message | translate : { xx: data.title } }}
      <span *ngIf="data?.subMsg"> {{ data?.subMsg }}</span>
    </div>
    <div class="matDialogAdditionalMsg" fxFlex="">{{ data.additionalMessage | translate }}</div>
  </div>
  <div class="matDialogActions" fxFlex="" fxLayout="" fxLayoutAlign="space-between center">
    <button fxFlex="150px" mat-dialog-close>
      {{ data.cancelText | translate }}
    </button>
    <button
      fxFlex="98px"
      class="{{ data?.submitCssClass ? data?.submitCssClass : submitCssClass }}"
      (click)="submit()"
      mat-dialog-close
    >
      {{ data.submitText | translate }}
    </button>
  </div>
</div>
