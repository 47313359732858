import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Pagination, RowData } from '@app/interfaces';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  // Inputs
  @Input() service: any;
  @Input() options: any;

  // Props
  pages: any[] = [];
  currentPage = 1;
  lang: string;
  itemCount: number;
  pagination: Pagination = {
    currentPage: 1,
    lastPage: 1,
    totalRecords: 0,
    from: 1,
    to: 10,
  };

  @ViewChild('currentPageInput') currentPageInput: ElementRef;
  constructor(private utilitiesService: UtilitiesService) {}

  ngOnInit() {
    // this.authService.userSubject.subscribe(() => {
    //     this.lang = this.authService.lang;
    // });
    this.itemCount = this.options.itemsPerPage[0];
    this.utilitiesService.paginateObservable$.subscribe((res) => {
      if (res.page && res.cid === this.service.cid) {
        this.currentPage = res.page;
        this.navigationInit();
      }
    });
    this.utilitiesService.countObservable$.subscribe((itemCount) => {
      if (itemCount) {
        this.itemCount = itemCount;
        // this.service.updateResources.next();
      }
    });
  }

  /**
   * Retrieve new data to set the pages
   */
  ngOnChanges() {
    this.service.resources.subscribe(() => {
      const paginationInfo: RowData = JSON.parse(JSON.stringify(this.service.resourcesList.meta.pagination)); // clone object
      this.pagination = { ...paginationInfo };
      this.navigationInit();
      this.setPageBounders(this.pagination);
    });
  }
  setPageBounders(pagination: Pagination) {
    if (pagination.currentPage === 1) {
      pagination.from = 1;
    } else {
      pagination.from = pagination.currentPage * pagination.perPage - pagination.perPage + 1;
    }
    pagination.to = pagination.currentPage * pagination.perPage;
    if (pagination.to > pagination.totalRecords) {
      pagination.to = pagination.totalRecords;
    }
    if (pagination.from > pagination.totalRecords) {
      pagination.from = 1;
    }
  }

  /**
   * setPagesCount() looping based on the last page count
   * if the page number is < 3, render the page number
   * if the page number is within +-2 of the current page, render the page number
   * if the page number is > the total number of pages -3 render the page number
   * if the last page number wasn't rendered and dots haven't already been rendered render dots '...' do indicate a gap
   * else do nothing
   * @returns array of available page numbers
   */
  navigationInit() {
    this.pages = [];
    const dynamicPages = 4;
    // Navigation Logic
    // If there's 4 or more than 4 pages contain data or it's only one page contain data..
    if (dynamicPages >= this.pagination.lastPage || this.pagination.lastPage === 1) {
      for (let i = 0; i < this.pagination.lastPage; i++) {
        this.pages.push(i + 1);
      }
    } else {
      if (this.currentPage === 1) {
        // If it was first page
        for (let i = this.currentPage - 1; i < this.currentPage + 4; i++) {
          this.pages.push(i + 1);
        }
      } else if (this.currentPage === 2) {
        // If it was second page
        for (let i = this.currentPage - 2; i < this.currentPage + 3; i++) {
          this.pages.push(i + 1);
        }
      } else if (this.currentPage === this.pagination.lastPage) {
        // If it was the last page
        for (let i = this.currentPage - 5; i < this.currentPage; i++) {
          this.pages.push(i + 1);
        }
        /**
         * If it was the page before 1 of end of the navigation
         */
      } else if (this.currentPage === this.pagination.lastPage - 1) {
        for (let i = this.currentPage - 4; i < this.currentPage + 1; i++) {
          this.pages.push(i + 1);
        }
      } else {
        // All centered results
        for (let i = this.currentPage - 3; i < this.currentPage + 2; i++) {
          this.pages.push(i + 1);
        }
      }
    }
  }

  /**
   * setPage() set user event selected page
   * @returns event which update data from API request
   */
  setPage(page: number) {
    if (page < 1 || page === this.currentPage) {
      this.currentPageInput.nativeElement.value = this.currentPage;
      return;
    }
    this.currentPageInput.nativeElement.value = page;
    this.utilitiesService.navigate(page, this.service.cid);
  }

  /**
   * setPageNav() set user event chooses event [previous, next]
   * @returns event which update data from API request
   */
  setPageNav(type: string) {
    let page: number = this.currentPage;
    switch (type) {
      case 'prev':
        page -= 1;
        break;
      case 'next':
        page += 1;
        break;
      case 'first':
        page = 1;
        break;
      case 'last':
        page = this.pagination.lastPage;
    }
    this.setPage(page);
  }

  changeItemsPerPage() {
    this.utilitiesService.countPerPage(this.itemCount);
    this.setPage(1);
  }
}
