import { Component, Input, OnChanges, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemProps } from '@app/interfaces';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { FiltersInlineService } from '@app/shared/services/filters-inline.service';
import { EMPTY, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-filters-inline',
  templateUrl: './filters-inline.component.html',
  styleUrls: ['./filters-inline.component.scss'],
})
export class FiltersInlineComponent implements OnInit, OnChanges, OnDestroy {
  form: UntypedFormGroup;
  searchTerm$ = new Subject<string>();

  @Input() column: ItemProps;
  @Input() service: RootService;
  @Input() cid: string;

  @Input() passedRows: any;
  oPassedRows: any = [];
  @Output() searchResult: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private us: UtilitiesService,
    private filtersInlineService: FiltersInlineService
  ) {}

  ngOnInit(): void {
    if (this.passedRows && this.passedRows.length) this.oPassedRows = JSON.parse(JSON.stringify(this.passedRows));
    if (this.column) {
      this.createForm();
    }
    this.filter();
  }

  ngOnChanges(): void {}

  /**
   * Identify the Form fields in each form controller
   */
  get formFields(): any {
    let formFields = {};
    const field = this.column;
    if (field.list && field.list.searchableInline) {
      formFields = { ...formFields, [field.name]: [null] };
    }
    return formFields;
  }

  createForm() {
    this.form = this.fb.group(this.formFields);
  }

  /**
   * prevent enter click on searchable inline
   * @param event
   */
  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  filter() {
    this.form.controls[this.column.name].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          if (this.oPassedRows && this.oPassedRows.length) {
            this.form.controls[this.column.name].value && this.form.controls[this.column.name].value.length
              ? this.searchResult.emit(
                  this.passedRows.filter((row: any) =>
                    row[this.column.name]
                      .toLowerCase()
                      .includes(this.form.controls[this.column.name].value.toLowerCase())
                  )
                )
              : this.searchResult.emit(this.oPassedRows);
            return;
          }
          this.filtersInlineService.formData[this.column.name] = this.form.controls[this.column.name].value;
          this.us.setFilters(this.refactorFilters(this.filtersInlineService.formData), true, this.service.cid);
        }),
        switchMap(() => {
          return EMPTY;
        })
      )
      .subscribe();
  }

  refactorFilters(formValue: any) {
    return this.service.refactorFilters(formValue);
  }

  ngSelect_selectAll(control: AbstractControl, listPrefix: string, selectAll: boolean) {
    if (control && this.service.lists[listPrefix]) {
      const selected: number[] = [];
      if (selectAll) {
        Object.values(this.service.lists[listPrefix]).forEach((item: { id: number }) => {
          selected.push(item.id);
        });
      }
      control.setValue(selected);
    }
  }

  checkSelected(control: AbstractControl, id: number): boolean {
    if (control && id && control.value) {
      if (control.value.find((x: number) => x === id)) {
        return true;
      }
    }
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  clearInput(field: string) {
    this.form.controls[field].setValue(null);
  }

  ngOnDestroy(): void {
    this.filtersInlineService.formData = {};
  }
}
