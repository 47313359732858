import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { AuthenticationService, CredentialsService, I18nService } from '@app/core';
import { AppHelper } from '@app/core/classes/app-helper';
import { RootService } from '@app/core/root.service';
import { SharedService } from '@app/shared/services/shared.service';
import { webSocket } from 'rxjs/webSocket';
import { NotificationsService } from '@app/notifications/notifications.service';
import { MessagingService } from '@app/shared/services/messaging/messaging.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { TutorialService } from '@app/shared/services/tutorial/tutorial.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  @Input() menuSizeState: string;
  @Output() menuSizeStateEvent = new EventEmitter<boolean>();
  @Output() isModuleTour: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('notificationsSidenav', { static: false }) notificationSideNav: any;
  messageCount: number = 0;
  logo: any = '/assets/logo.svg';
  totalUnread: number;
  liveData: any[] = [
    {
      id: 1,
      notification: 'You have changed vehicle license expiry',
      seen: false,
      createdAt: '2019-12-09T13:31:27+00:00',
      module: {
        id: 1,
        name: 'vehicle-assignments',
        parent: {
          id: 28,
          name: 'vehicles',
        },
      },
    },
  ];

  constructor(
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private root: RootService,
    private shared: SharedService,
    public notifications: NotificationsService,
    private messaging: MessagingService,
    public tourService: TourService,
    private tutorialService: TutorialService
  ) {
    this.shared.getCompanyLogo().subscribe((res: any) => {
      this.logo = res;
    });
  }

  ngOnInit() {
    this.messaging.messageCount.subscribe((count) => {
      this.messageCount = count;
    });
  }
  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
    this.notifications.resourcesList.data = [];
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
    AppHelper.setDirectionVars(language === 'en-US' ? 'ltr' : 'rtl');
    this.shared.sendLanguage.next(language);
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  sidenavToggle() {
    this.menuSizeStateEvent.emit(true);
  }

  toggleAndClearIncomingCount() {
    this.notificationSideNav.toggle();
    // this.messaging.messageCount.next(0);
    // this.messaging.count = 0;
    // this.messageCount = 0;
    // this.notifications.resourcesList.additionalData.totalUnread = 0;
  }
}
