import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appNumericMinMax]',
})
export class NumericMinMaxDirective {
  @Input() numericInput: boolean;
  @Input() numericControl: AbstractControl;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.checker(event);
  }
  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    this.checker(event);
  }

  checker(event: KeyboardEvent) {
    const e = event as KeyboardEvent;
    const latestInputValue: number = this.el.nativeElement.value ? +this.el.nativeElement.value : 0;
    // console.log('latestInputValue');
    // console.log(latestInputValue);

    const min: number = +this.el.nativeElement.getAttribute('min');
    const max: number = +this.el.nativeElement.getAttribute('max');
    // console.log('min', 'max');
    // console.log(min, max);

    if (this.numericInput) {
      if (min && latestInputValue < min) {
        // console.log('< min');
        e.preventDefault();
        this.numericControl.setValue(min);
        // console.log((this.el.nativeElement as HTMLInputElement).value);
      } else if (max && latestInputValue > max) {
        // console.log('> max');
        e.preventDefault();
        this.numericControl.setValue(max);
        // console.log((this.el.nativeElement as HTMLInputElement).value);
      } else if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        // console.log('keyCode');
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || e.key < '48' || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        // console.log('keyCode other');
        e.preventDefault();
      }
    }
  }
}
