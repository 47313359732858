import { Subject } from 'rxjs';

export class AppHelper {
  static reSize: Subject<any> = new Subject();

  static pushResize() {
    AppHelper.reSize.next({});
  }

  static calcListHeight() {
    setTimeout(() => {
      let deletedSpaces = 0;
      const header = document.getElementById('header');
      const tableOptions = document.getElementById('tableOptions');
      const pagination = document.getElementById('pagination');
      if (header) {
        deletedSpaces += header.offsetHeight;
      }
      if (pagination) {
        deletedSpaces += pagination.offsetHeight;
      }
      if (tableOptions) {
        deletedSpaces += tableOptions.offsetHeight;
      }
      // console.log(deletedSpaces);

      const listHeight = window.innerHeight - deletedSpaces - 30;
      // const mapHeight = window.innerHeight - (header ? header.offsetHeight : 0);
      document.documentElement.style.setProperty('--allHeight', window.innerHeight + 'px');
      document.documentElement.style.setProperty('--listHeight', listHeight + 'px');
      // document.documentElement.style.setProperty('--mapHeight', mapHeight + 'px');
    }, 0);
  }

  /**
   * this method retrieve value from deep object
   * @param obj => whole object
   * @param path => path to value
   */
  static deepFind(obj: {}, path: string) {
    const paths = path.split('.');
    let current = obj;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === null || current[paths[i]] === undefined) {
        return '';
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }

  /* To copy any Text */
  static copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  static calcMainContainerWidth() {
    const windowWidth = window.innerWidth;
    document.documentElement.style.setProperty('--window-width', windowWidth + 'px');

    const menuWidth = document.querySelector('.main-menu').clientWidth;
    document.documentElement.style.setProperty('--main-menu-width', menuWidth + 'px');

    const mainContainerWidth = windowWidth - menuWidth;
    document.documentElement.style.setProperty('--main-container-width', mainContainerWidth + 'px');

    // console.log('calcMainContainerWidth', windowWidth, menuWidth, mainContainerWidth);
  }

  // todo better organize direction variables for the app
  static setDirectionVars(dir: string) {
    const rtlVars = {
      'menu-size-secondary': '244px',
      'menu-size-primary': '0',

      'menu-size-secondary-ipad': '235px',
      'menu-size-primary-ipad': '0',

      'secondary-direction': 'left',
      'primary-direction': 'right',

      'secondary-text-align': 'start',
      'primary-text-align': 'end',

      'primary-flex-justify': 'flex-start',
      'secondary-flex-justify': 'flex-end',

      'primary-flex-direction': 'ltr',
      'secondary-flex-direction': 'rtl',

      'position-absolute-secondary': '0',
      'position-absolute-primary': 'auto',

      'position-absolute-secondary-20': '0',
      'position-absolute-primary-20': '20px',

      'primary-margin': '0',
      'secondary-margin': '16px',

      'primary-position-minus-10': 'auto',
      'secondary-position-minus-10': '-10px',

      'primary-margin-8': '0',
      'secondary-margin-8': '8px',

      'primary-margin-4': '0',
      'secondary-margin-4': '4px',

      'primary-margin-6': '0',
      'secondary-margin-6': '6px',

      'primary-margin-minus-16': '0',
      'secondary-margin-minus-16': '-16px',

      'primary-margin-48': '0',
      'secondary-margin-48': '48px',

      'primary-padding': '0',
      'secondary-padding': '16px',

      'primary-padding-5': '0',
      'secondary-padding-5': '5px',

      'primary-padding-40': '0',
      'secondary-padding-40': '40px',

      'primary-margin-180': '0',
      'secondary-margin-180': '180px',

      'primary-margin-100': '0',
      'secondary-margin-100': '100px',

      'primary-margin-24': '0',
      'secondary-margin-24': '24px',

      'primary-margin-200': '0',
      'secondary-margin-200': '200px',

      'primary-margin-130': '0',
      'secondary-margin-130': '130px',

      'primary-margin-80': '0',
      'secondary-margin-80': '80px',

      'primary-margin-65': '0',
      'secondary-margin-65': '65px',

      'primary-margin-40': '0',
      'secondary-margin-40': '40px',

      'primary-margin-39-percent': '0',
      'secondary-margin-39-percent': '39%',

      'primary-table-spacing': '8px',
      'secondary-table-spacing': '0',
    };
    const ltrVars = {
      'menu-size-secondary': '0',
      'menu-size-primary': '244px',

      'menu-size-secondary-ipad': '0',
      'menu-size-primary-ipad': '235px',

      'position-absolute-secondary': 'auto',
      'position-absolute-primary': '0',

      'position-absolute-secondary-20': '20px',
      'position-absolute-primary-20': '0',

      'secondary-direction': 'right',
      'primary-direction': 'left',

      'secondary-text-align': 'end',
      'primary-text-align': 'start',

      'primary-flex-justify': 'flex-end',
      'secondary-flex-justify': 'flex-start',

      'primary-flex-direction': 'rtl',
      'secondary-flex-direction': 'ltr',

      'primary-margin': '16px',
      'secondary-margin': '0',

      'primary-position-minus-10': '-10px',
      'secondary-position-minus-10': 'auto',

      'primary-margin-8': '8px',
      'secondary-margin-8': '0',

      'primary-margin-4': '4px',
      'secondary-margin-4': '0',

      'primary-margin-6': '6px',
      'secondary-margin-6': '0',

      'primary-margin-minus-16': '-16px',
      'secondary-margin-minus-16': '0',

      'primary-margin-48': '48px',
      'secondary-margin-48': '0',

      'primary-padding': '16px',
      'secondary-padding': '0',

      'primary-padding-5': '5px',
      'secondary-padding-5': '0',

      'primary-padding-40': '40px',
      'secondary-padding-40': '0',

      'primary-margin-180': '180px',
      'secondary-margin-180': '0',

      'primary-margin-100': '100px',
      'secondary-margin-100': '0',

      'primary-margin-24': '24px',
      'secondary-margin-24': '0',

      'primary-margin-200': '200px',
      'secondary-margin-200': '0',

      'primary-margin-130': '130px',
      'secondary-margin-130': '0',

      'primary-margin-80': '80px',
      'secondary-margin-80': '0',

      'primary-margin-65': '65px',
      'secondary-margin-65': '0',

      'primary-margin-40': '40px',
      'secondary-margin-40': '0',

      'primary-margin-39-percent': '39%',
      'secondary-margin-39-percent': '0',

      'primary-table-spacing': '0',
      'secondary-table-spacing': '8px',
    };
    if (dir === 'ltr') {
      Object.keys(ltrVars).forEach((key) => {
        document.documentElement.style.setProperty('--' + key, ltrVars[key]);
      });
    } else {
      Object.keys(rtlVars).forEach((key) => {
        document.documentElement.style.setProperty('--' + key, rtlVars[key]);
      });
    }
  }

  static setThemeVars(Primarycolor: any) {
    const colorVars = {
      'color-primary': Primarycolor,
    };

    Object.keys(colorVars).forEach((key) => {
      document.documentElement.style.setProperty('--' + key, colorVars[key]);
    });
  }

  static checkWindowResize() {
    let resizeId: any;
    window.addEventListener('resize', () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(() => {
        this.doneResizing();
      }, 500);
    });
  }

  static doneResizing() {
    this.calcMainContainerWidth();
  }

  static arrayLast(array: []) {
    return array[array.length - 1];
  }

  constructor() {}
}
