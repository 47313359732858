import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  constructor(private shared: SharedService) {}

  get wholeAppTourSteps(): any {
    return [
      {
        anchorId: 'settings',
        stepId: 'settings',
        content: this.shared.translate.instant('settings.tour.menu'),
        title: this.shared.translate.instant('menu.settings'),
        enableBackdrop: true,
      },
      {
        anchorId: 'users',
        stepId: 'users',
        content: this.shared.translate.instant('users.tour.menu'),
        title: this.shared.translate.instant('users.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'vendors',
        stepId: 'vendors',
        content: this.shared.translate.instant('vendors.tour.menu'),
        title: this.shared.translate.instant('vendors.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'parts',
        stepId: 'parts',
        content: this.shared.translate.instant('parts.tour.menu'),
        title: this.shared.translate.instant('parts.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'vehicles',
        stepId: 'vehicles',
        content: this.shared.translate.instant('vehicles.tour.list'),
        title: this.shared.translate.instant('vehicles.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'vehicle-assignments',
        stepId: 'vehicle-assignments',
        content: this.shared.translate.instant('vehicle-assignments.tour.list'),
        title: this.shared.translate.instant('vehicle-assignments.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'meters',
        stepId: 'meters',
        content: this.shared.translate.instant('meters.tour.list'),
        title: this.shared.translate.instant('meters.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'fuel-history',
        stepId: 'fuel-history',
        content: this.shared.translate.instant('fuel-histories.tour.list'),
        title: this.shared.translate.instant('fuel-histories.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'issues',
        stepId: 'issues',
        content: this.shared.translate.instant('issues.tour.list'),
        title: this.shared.translate.instant('issues.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'service-reminders',
        stepId: 'service-reminders',
        content: this.shared.translate.instant('service-reminders.tour.list'),
        title: this.shared.translate.instant('service-reminders.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'service',
        stepId: 'service',
        content: this.shared.translate.instant('service.tour.menu'),
        title: this.shared.translate.instant('service.tour.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'inspections',
        stepId: 'inspections',
        content: this.shared.translate.instant('inspections.tour.menu'),
        title: this.shared.translate.instant('inspection-categories.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'home',
        stepId: 'home',
        content: this.shared.translate.instant('home.tour.generic_content'),
        title: this.shared.translate.instant('home.dashboard'),
        enableBackdrop: true,
      },
      {
        anchorId: 'reports',
        stepId: 'reports',
        content: this.shared.translate.instant('menu.reports-tour'),
        title: this.shared.translate.instant('menu.reports'),
        enableBackdrop: true,
      },
      {
        anchorId: 'help',
        stepId: 'help',
        content: this.shared.translate.instant('common.help_content'),
        title: this.shared.translate.instant('common.help'),
        enableBackdrop: true,
      },
    ];
  }

  get dashboardSteps(): any {
    return [
      {
        anchorId: 'cost-card',
        stepId: 'cost-card',
        content: this.shared.translate.instant('home.tour.cost-card.content'),
        title: this.shared.translate.instant('home.tour.cost-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'issues-card',
        stepId: 'issues-card',
        content: this.shared.translate.instant('home.tour.issues-card.content'),
        title: this.shared.translate.instant('home.tour.issues-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'reminders-card',
        stepId: 'reminders-card',
        content: this.shared.translate.instant('home.tour.reminders-card.content'),
        title: this.shared.translate.instant('home.tour.reminders-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'statuses-card',
        stepId: 'statuses-card',
        content: this.shared.translate.instant('home.tour.statuses-card.content'),
        title: this.shared.translate.instant('home.tour.statuses-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'activity-stream-card',
        stepId: 'activity-stream-card',
        content: this.shared.translate.instant('home.tour.activity-stream-card.content'),
        title: this.shared.translate.instant('home.tour.activity-stream-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'services-card',
        stepId: 'services-card',
        content: this.shared.translate.instant('home.tour.services-card.content'),
        title: this.shared.translate.instant('home.tour.services-card.title'),
        enableBackdrop: true,
      },
      {
        anchorId: 'services-due-date-card',
        stepId: 'services-due-date-card',
        content: this.shared.translate.instant('home.tour.services-due-date-card.content'),
        title: this.shared.translate.instant('home.tour.services-due-date-card.title'),
        enableBackdrop: true,
      },
    ];
  }
}
