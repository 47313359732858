import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api-service/api.service';
import { APIURL } from '../apis';
import { LoginResponse } from '../models/login-response';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { UserDetailsService } from '@app/shared/services/user-details/user-details.service';
import { MessagingService } from '@app/shared/services/messaging/messaging.service';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface LoginReqBody {
  username: string;
  password: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService,
    private api: ApiRequestService,
    private permissionsService: PermissionsService,
    private userDetailsService: UserDetailsService,
    private messagingService: MessagingService
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    const reqBody: LoginReqBody = {
      username: context.username,
      password: context.password,
    };

    // /**  mocking login **/
    // const mockLogin: Credentials = {
    //   username: 'owner',
    //   token: 'bearer xyz',
    //   userId: '1',
    // };
    // this.credentialsService.setCredentials(mockLogin, context.remember)
    // return of(mockLogin);

    return this.api.post(APIURL.login, reqBody).pipe(
      map((body: LoginResponse) => {
        const data: Credentials = {
          username: context.username,
          token: body.data.accessToken,
          userId: body.data.userId,
          firstLogin: body.data.firstLogin,
        };
        this.credentialsService.setCredentials(data, context.remember);
        data?.firstLogin === 1
          ? (this.credentialsService.isfirstLogin = true)
          : (this.credentialsService.isfirstLogin = false);
        return data;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.messagingService.closeConnection(this.credentialsService.credentials.userId);
    /**
     * clears permissions and userDetails
     */
    this.permissionsService.setPermissions(null);
    this.userDetailsService.setUserDetails();

    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }
}
