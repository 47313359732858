import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ItemProps } from '@app/interfaces';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RootService } from '@app/core/root.service';

@Component({
  selector: 'app-input-validation-errors',
  templateUrl: './input-validation-errors.component.html',
  styleUrls: ['./input-validation-errors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputValidationErrorsComponent),
      multi: true,
    },
  ],
})
export class InputValidationErrorsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  get value() {
    return this.val;
  }

  set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  loading = false;
  alive = true;

  @Input() service: RootService;
  @Input() form: any;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() isSubmitted: boolean;
  @Input() requiredAsterisk: any;
  @Input() field: ItemProps;
  @Input() disabledInput: boolean;
  @Input() validationErrors: any;

  @Input() name: string;
  // eslint-disable-next-line
  @Input('value') val: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() StatusChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onValueChange(field: ItemProps, event: any) {
    this.valueChanged.emit({ field, event });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  preventDefault(event: any) {
    event.preventDefault();
  }
}
