import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreFormComponent } from '@app/core/components/core-form/core-form.component';
import { ApiResponse, NgSelectOptions } from '@app/interfaces';
import { SharedCoreFormService } from '@app/shared/services/shared-core-form.service';
import { GroupByPipe } from 'ngx-pipes';
import { finalize, takeWhile } from 'rxjs';
import { formPopupService } from './form-popout.service';
var moment = require('moment');

@Component({
  selector: 'app-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.scss'],
})
export class FormPopupComponent extends CoreFormComponent implements OnInit {
  loading = false;
  isEdit = this.data?.ngSelectOptions?.isEdit;
  constructor(
    service: formPopupService,
    fb: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    groupByPipe: GroupByPipe,
    sharedCoreFormService: SharedCoreFormService,
    public dialogRef: MatDialogRef<FormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; ngSelectOptions: NgSelectOptions; itemId: string }
  ) {
    super(service, fb, router, activatedRoute, groupByPipe, sharedCoreFormService);

    // if there is a field for `name` , set its default value by the value which user wrote to open the popup.
    const nameProp = data?.ngSelectOptions?.saveFormProps?.find((prop) => prop.name === 'name');
    if (nameProp) {
      nameProp.form.initValue = data?.name || '';
    }

    // set the form-prop service by the passed featureProps.
    service._featureProps = data?.ngSelectOptions?.saveFormProps || [];
    service._moduleName = data?.ngSelectOptions?.saveFormModuleName || '';
    service._apiRouterPrefix = data?.ngSelectOptions?.addedDataUrl || '';
    service.lists = { ...service.lists, ...data?.ngSelectOptions?.saveFormLists } || '';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  formSubmission(): any {
    this.loading = true;
    const url = this.data?.ngSelectOptions?.addedDataUrl;

    // send the date fields on the format `YYYY-MM-DD`
    Object.keys(this.form.value).forEach((key) => {
      if (moment.isMoment(this.form.value[key])) {
        this.form.value[key] = moment(this.form.value[key]).format('YYYY-MM-DD');
      }
    });

    if (this.data.itemId) {
      return this.service
        .updateItem(this.itemId, this.form.value)
        .pipe(
          takeWhile(() => this.alive),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          (res: ApiResponse) => {
            this.service.shared.toastr.success(res.message);
            this.dialogRef.close(true);
          },
          (error) => {
            if (error) {
              /**
               * payload error
               */
              if (error.status === 413) {
                this.service.shared.toastr.error(
                  error.message,
                  this.service.shared.translate.instant('error_codes.413'),
                  { closeButton: true }
                );
              }

              /**
               * timeout error
               */
              if (error && error.name === 'TimeoutError') {
                this.service.shared.toastr.warning(
                  error.message,
                  this.service.shared.translate.instant('error_codes.TimeoutError'),
                  { closeButton: true }
                );
              }
              /**
               * handling server side validation errors
               */
              if (error.error && error.error.errors) {
                this.ServerValidationErrors(error.error.errors);
              }
            }
          }
        );
    }

    this.service
      .createItem(this.toFormData(this.form.value, null, true))
      .pipe(
        takeWhile(() => this.alive),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (res: ApiResponse) => {
          this.service.shared.toastr.success(res.message);
          this.dialogRef.close(true);
        },
        (error) => {
          if (error) {
            /**
             * payload error
             */
            if (error.status === 413) {
              this.service.shared.toastr.error(
                error.message,
                this.service.shared.translate.instant('error_codes.413'),
                { closeButton: true }
              );
            }

            /**
             * timeout error
             */
            if (error && error.name === 'TimeoutError') {
              this.service.shared.toastr.warning(
                error.message,
                this.service.shared.translate.instant('error_codes.TimeoutError'),
                { closeButton: true }
              );
            }
            /**
             * handling server side validation errors
             */
            if (error.error && error.error.errors) {
              this.ServerValidationErrors(error.error.errors);
            }
          }
        }
      );

    //  olde code ////////////
    //  this.service
    //    .apiRequest('POST', url, this.form.value)
    //    .pipe(
    //      takeWhile(() => this.alive),
    //      finalize(() => (this.loading = false))
    //    )
    //    .subscribe(
    //      (res) => {
    //        console.log(res);
    //        this.service.shared.toastr.success(res.message);
    //        this.dialogRef.close(true);
    //      },
    //      (error) => {
    //        if (error) {
    //          /**
    //           * payload error
    //           */
    //          if (error.status === 413) {
    //            this.service.shared.toastr.error(
    //              error.message,
    //              this.service.shared.translate.instant('error_codes.413'),
    //              { closeButton: true }
    //            );
    //          }

    //          /**
    //           * timeout error
    //           */
    //          if (error && error.name === 'TimeoutError') {
    //            this.service.shared.toastr.warning(
    //              error.message,
    //              this.service.shared.translate.instant('error_codes.TimeoutError'),
    //              { closeButton: true }
    //            );
    //          }
    //          /**
    //           * handling server side validation errors
    //           */
    //          if (error.error && error.error.errors) {
    //            this.ServerValidationErrors(error.error.errors);
    //          }
    //        }
    //      }
    //    );
  }

  loadResourcesSubscription() {
    /**
     * Check if path contain ID
     * return values from item id values
     */

    if (this.data.itemId) {
      this.loadResources(this.data.itemId);
    }
  }
}
