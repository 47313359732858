import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared/shared.module';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { FileSaverModule } from 'ngx-filesaver';
import { TimeagoModule } from 'ngx-timeago';

import { registerLocaleData, AsyncPipe } from '@angular/common';
import localeAr from '@angular/common/locales/ar-SA';
registerLocaleData(localeAr);

// import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
//   PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
//
//
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   wheelPropagation: true
// };

import * as Sentry from '@sentry/browser';
// import { NgxHotjarModule } from 'ngx-hotjar';
import { MessagingService } from './shared/services/messaging/messaging.service';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

if (environment.sentryPath) {
  Sentry.init({
    dsn: environment.sentryPath,
    environment: environment.sentryEnvironmentName,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any) {
    const eventId = Sentry.captureException(error.originalError || error);
    if (environment.showSentryDialog) {
      Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    environment.serviceWorker
      ? ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.serviceWorker })
      : [],
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AuthModule,
    MatIconModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-left',
    }),
    FileSaverModule,
    TimeagoModule.forRoot(),
    RouterModule,
    TourMatMenuModule.forRoot(),
    // NgxHotjarModule.forRoot('1753129', 6),
    // PerfectScrollbarModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  // providers: [
  //   {
  //     provide: PERFECT_SCROLLBAR_CONFIG,
  //     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  //   }
  // ],
  providers: [
    environment.sentryPath ? { provide: ErrorHandler, useClass: SentryErrorHandler } : [],
    MessagingService,
    AsyncPipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }, // Globally set form fields appearance
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi.svg'));
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-mat-icons.svg')); // Or whatever path you placed mdi.svg at
  }
}
