import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Resolve } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize, takeWhile } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { RootService } from '@app/core/root.service';
import { SharedService } from '@app/shared/services/shared.service';
import { AppHelper } from '@app/core/classes/app-helper';
import { AuthService } from '@app/auth/services/auth.service';
import { MessagingService } from '@app/shared/services/messaging/messaging.service';

const log = new Logger('Login');

interface Branding {
  name?: string;
  logo?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../auth.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, Resolve<any> {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  form: UntypedFormGroup;
  isLoading = false;
  alive = true;
  branding: Branding = {
    name: '',
    logo: '',
  };
  logoDefault = '/assets/logo-text.svg';
  notExistError: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private service: RootService,
    private shared: SharedService,
    private authService: AuthService,
    private messagingService: MessagingService,
    private credentialsService: CredentialsService
  ) {
    this.createForm();
  }

  ngOnInit() {
    // this.getComapnyBranding();
  }

  resolve() {}
  ngOnDestroy() {}

  login() {
    this.isLoading = true;
    this.error = null;
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials) => {
          log.debug(`${credentials.username} successfully logged in`);
          this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
          this.messagingService.openConnection(this.credentialsService.credentials.userId);
        },
        (err) => {
          const error = err.error;
          log.debug(`Login error: ${error}`);
          if (error && error.errors) {
            if (error.errors.email) {
              this.error = error.errors.email[0];
            } else if (error.errors.username) {
              this.error = error.errors.username[0];
            } else {
              this.error = error.message;
            }
          } else {
            this.error = 'Username/Email or password is incorrect.';
          }
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: false,
    });
  }
}
