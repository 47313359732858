import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { UtilitiesService as BaseUtilitiesService } from '@afaqyit/frontend-core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService extends BaseUtilitiesService {
  dialogActionObservable$: Observable<any>;

  paginateObservable$: Observable<{ page: number; cid: string }>;
  paginateInfiniteObservable$: Observable<{
    page: number;
    cid: string;
    customCid: string;
    serviceUniqueIdentifier?: string;
  }>;

  countObservable$: Observable<number>;
  _itemsCount: number;

  sortObservable$: Observable<object>;
  _sortType = {};

  filtersObservable$: Observable<object>;
  _filtersType = {};

  protected _dialogAction$: Subject<any>;
  protected paginate$: Subject<{ page: number; cid: string }>;
  protected paginateInfinite$: Subject<{
    page: number;
    cid: string;
    customCid: string;
    serviceUniqueIdentifier?: string;
  }>;
  protected count$: BehaviorSubject<number>;

  protected sort$: Subject<object>;
  public filters$: Subject<object>;

  filterData$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor() {
    // Please fill the super() call by the needed parameters
    super();
    this.initSubjects();
  }

  /**
   * TODO: Add JSDoc Here
   */
  dialog(data: any) {
    this._dialogAction$.next(data);
  }

  /**
   * Fire the paginate$ Subject with paginate param
   * to change the current page in view
   * @param page: page
   */
  navigate(page: number, cid?: string) {
    this.paginate$.next({ page, cid });
  }

  /**
   * Fire the paginate$ Subject with paginate param
   * to change the current page in view
   * @param page: page
   * @param cid when using different lists in same page use this cid to differentiate which list will navigate
   * @param customCid alternative for cid when the api cid isn't the same as frontend
   * @param serviceUniqueIdentifier when using different lists from same api method and same cid use this to differentiate which list will navigate
   */
  navigateInfinite(page: number, cid?: string, customCid?: string, serviceUniqueIdentifier?: string) {
    this.paginateInfinite$.next({ page, cid, customCid, serviceUniqueIdentifier });
  }

  /**
   * Fire the sort$ Subject with sort by param
   * @param sortBy: sort List By
   */
  sortListBy(sortBy: object) {
    this.sort$.next(sortBy);
  }

  /**
   * Fire the filters$ Subject with filter by param
   * @param filter: filter item
   */
  setFilters(filter: object, append?: boolean, mod?: string) {
    if (append && this.filterData$.getValue()) {
      if (this.filterData$.getValue().module !== mod) {
        this.filterData$.next(Object.assign({}, { module: mod, ...filter }));
      } else {
        this.filterData$.next(Object.assign(this.filterData$.getValue(), { module: mod, ...filter }));
      }

      const { module, ...withoutModule } = this.filterData$.getValue();
      filter = withoutModule;
    }
    this.filters$.next(filter);
  }

  /**
   * Fire the count$ BehaviorSubject with count param
   * to change the items per page values
   * @param count: count
   */
  countPerPage(count: number) {
    this.count$.next(count);
  }

  initSubjects() {
    this.paginate$ = new Subject();
    this.paginateObservable$ = this.paginate$.asObservable();

    this.paginateInfinite$ = new Subject();
    this.paginateInfiniteObservable$ = this.paginateInfinite$.asObservable();

    this._dialogAction$ = new Subject();
    this.dialogActionObservable$ = this._dialogAction$.asObservable();

    this.sort$ = new BehaviorSubject(this._sortType);
    this.sortObservable$ = this.sort$.asObservable();

    this.filters$ = new BehaviorSubject(this._filtersType);
    this.filtersObservable$ = this.filters$.asObservable();

    this.count$ = new BehaviorSubject(this._itemsCount);
    this.countObservable$ = this.count$.asObservable();
  }
}
