import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ItemProps } from '@app/interfaces';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { SharedService } from '@app/shared/services/shared.service';

import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  loading = false;
  alive = true;
  @Input() service: RootService;
  @Input() form: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() requiredAsterisk: any;
  @Input() field: ItemProps;
  @Input() disabledInput: boolean;
  @Input() validationErrors: any;
  @Input() name: string;
  // eslint-disable-next-line
  @Input('value') val: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() StatusChanged: EventEmitter<any> = new EventEmitter();

  min: string;
  max: string;

  constructor(private sharedService: SharedService) {}

  get value() {
    return this.val;
  }

  set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  get formControl(): UntypedFormControl {
    return this.form.controls[this.field.name] as UntypedFormControl;
  }

  ngOnInit() {}

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onValueChange(field: ItemProps, event: any) {
    this.valueChanged.emit({ field, event });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges', changes);
    if (changes.field && changes.field.currentValue && changes.form && changes.form.currentValue) {
      this.minMaxSetter(this.field, 'min');
      this.minMaxSetter(this.field, 'max');
      // console.log('time minMaxSetter')
    }
  }

  /**
   * field subscription to make a subscribe to value changes of input field and
   * execute a callback function once the value of that field changed
   * @param fieldName: fieldName to subscribe to
   * @param fn: function to execute once the value has changed
   */
  fieldSubscription(fieldName: string, fn: (value: any) => void) {
    this.form.controls[fieldName].valueChanges.pipe(takeWhile(() => this.alive)).subscribe((value) => {
      return fn(value);
    });
  }

  /**
   * helper function to pass input names and return those inputs values
   * @param inputFields: array of input names
   */
  getValuesFromInputs(inputFields: string[]): any[] {
    return inputFields.map((inputField) => {
      return this.form.controls[inputField].value;
    });
  }

  /**
   * to set the max for the Time Picker based on conditions passed in ItemProps
   * @param field: field item props from module service
   * @param setType min | max
   */
  minMaxSetter(field: ItemProps, setType: 'min' | 'max'): void {
    if (field && field.form && field.form.timeOptions && field.form.timeOptions[setType]) {
      if (this.form) {
        if (field.form.timeOptions[setType].prop) {
          this.fieldSubscription(field.form.timeOptions[setType].prop, (value) => {
            this[setType] = value;
            // console.log([setType]', this[setType]);
          });
        } else if (field.form.timeOptions[setType].staticValue) {
          this[setType] = field.form.timeOptions[setType].staticValue;
        } else if (field.form.timeOptions[setType].fn) {
          if (field.form.timeOptions[setType].fn.inputFields && field.form.timeOptions[setType].fn.inputFields.length) {
            field.form.timeOptions[setType].fn.inputFields.forEach((inputField) => {
              this.fieldSubscription(inputField, () => {
                this[setType] = field.form.timeOptions[setType].fn.fnInputFields(
                  this.getValuesFromInputs(field.form.timeOptions[setType].fn.inputFields)
                );
                // console.log('[setType]', this[setType]);
              });
            });
          } else {
            this[setType] = field.form.timeOptions[setType].fn.fnInputFields();
          }
        }
      }
    }
  }

  // toDate(momentDate: moment.Moment): string{
  //   // const test = momentDate.toDate();
  //   const test = momentDate.format('YYYY-MM-DD HH:mm:ss');
  //   console.log('test', test);
  //   return test;
  // }

  timeChange(event: any) {
    if (event && event.value) {
      this.value = event.value;
    }
  }
}
