<div class="bg-container mat-typography">
  <div class="page-content">
    <div>
      <img class="sideContent-img" src="./assets/auth/logo-light.svg" alt="Afaqy" />
      <h2 class="primary-font page-description">
        {{ 'auth.sideContent' | translate }}
      </h2>
    </div>
    <div
      fxLayout="column"
      fxFlexAlign="center"
      class="fields-container-width primary-font"
      ngClass.gt-md="lg"
      ngClass.md="md"
      ngClass.sm="sm"
      ngClass.xs="xs"
    >
      <div class="header">
        <img class="my-5" src="./assets/auth/logo.svg" width="120px" alt="Afaqy" />
        <h2 class="primary-font title" *ngIf="!showSuccessMgs">{{ 'auth.forgot-password.title' | translate }}</h2>
      </div>
      <div class="alert-error-container" [hidden]="!error || isLoading">
        <div class="alert-error-content" fxLayout="row" fxLayoutAlign="space-between center" translate>
          <span>{{ error }}</span>
          <mat-icon (click)="error = null">close</mat-icon>
        </div>
      </div>
      <div fxFlex="100%" class="fields-container" fxLayoutAlign="center">
        <div fxFlex="100%" class="fields-box">
          <!-- Begin Form -->
          <form (ngSubmit)="submit()" [formGroup]="forgotpasswordForm" novalidate *ngIf="!showSuccessMgs">
            <div class="fields" fxLayout="column">
              <label for="email">{{ 'auth.forgot-password.email.title' | translate }}</label>
              <mat-form-field floatLabel="never" [hideRequiredMarker]="true" appearance="outline">
                <input
                  id="email"
                  type="text"
                  matInput
                  formControlName="email"
                  autocomplete="email"
                  [placeholder]="'auth.forgot-password.email.placeholder' | translate"
                  required
                />
                <mat-error
                  *ngIf="forgotpasswordForm.controls.email.invalid && forgotpasswordForm.controls.email.touched"
                >
                  <span translate>{{ 'auth.forgot-password.email.required' | translate }}</span>
                </mat-error>
              </mat-form-field>

              <button
                fxLayout="row"
                fxLayoutAlign="center center"
                class="submit-btn"
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="forgotpasswordForm.invalid || isLoading"
              >
                <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                <span *ngIf="!isLoading">{{ 'auth.forgot-password.submit' | translate }}</span>
              </button>

              <a class="underline-none text-center my-5" [routerLink]="['/login']">{{
                'auth.forgot-password.backLogin' | translate
              }}</a>
            </div>
          </form>

          <div *ngIf="showSuccessMgs" class="text-center">
            <h2 class="title primary-font">
              {{ 'auth.forgot-password.successSentResetMail' | translate }}
              <span class="color-primary">{{ forgotpasswordForm.get('email')?.value }}</span>
            </h2>
            <div class="title title-secondary">{{ 'auth.forgot-password.successSentResetMailHint' | translate }}</div>

            <button *ngIf="!countDownTimer; else showTimer" mat-button class="mt-6" (click)="didnotReceiveMail()">
              <span class="text-center my-5 bold color-primary">{{
                'auth.forgot-password.didnotReceiveMail' | translate
              }}</span>
            </button>

            <ng-template #showTimer> {{ countDownTimer }} : 00 </ng-template>
          </div>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </div>
</div>
