<div [formGroup]="form">
  <label class="bold d-flex clearfix pt-2 pb-2">
    <span>{{ service?.cid + '.' + field.name | translate }}</span>
    <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
  </label>
  <ng-select
    [appearance]="'outline'"
    class="ng-select-style1"
    [items]="service.lists[field.form.listPrefix]"
    [addTag]="addTagName"
    [multiple]="field?.form?.ngSelectOptions?.multiple"
    [hideSelected]="true"
    [virtualScroll]="true"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    bindValue="id"
    dropdownPosition="auto"
    [closeOnSelect]="false"
    [formControlName]="field?.name"
    [loading]="loading"
    appendTo="body"
    [attr.testId]="field.name"
    (change)="onChangeValue(field, $event)"
    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
    [clearable]="field?.form?.ngSelectOptions?.clearable"
    #ng_select_activeEL
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div class="d-inline-flex align-items-center">
        <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
          <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
            <span class="ng_select_prefix" *ngIf="prefix?.prop">
              <ng-container [ngSwitch]="prefix?.displayType">
                <ng-container *ngSwitchCase="null">
                  <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                    {{ item[prefix.prop] }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'color'">
                  <span
                    class="cell-display-type color {{ prefix?.cssClass }}"
                    *ngIf="item[prefix.prop]"
                    [style.background-color]="item[prefix.prop]"
                  ></span>
                </ng-container>
                <ng-container *ngSwitchCase="'img'">
                  <span class="cell-display-type img {{ prefix?.cssClass }}">
                    <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                    <img
                      class="img-placeholder"
                      *ngIf="!item[prefix.prop]"
                      src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                      alt=""
                    />
                  </span>
                </ng-container>
              </ng-container>
            </span>
          </ng-container>
        </ng-container>
        <span class="ng-value-label d-inline-flex">{{ item.name || item.displayName | translate }}</span>
      </div>

      <span class="ng-value-icon right ml-2" (click)="clear(item)">
        <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
      </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
      <div class="d-flex justify-content-space-between full-width">
        <div class="d-flex align-items-center">
          <div class="d-inline-flex">
            <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
              <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                <span class="ng_select_prefix" *ngIf="prefix?.prop">
                  <ng-container [ngSwitch]="prefix?.displayType">
                    <ng-container *ngSwitchCase="null">
                      <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                        {{ item[prefix.prop] }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'color'">
                      <span
                        class="cell-display-type color {{ prefix?.cssClass }}"
                        *ngIf="item[prefix.prop]"
                        [style.background-color]="item[prefix.prop]"
                      ></span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'img'">
                      <span class="cell-display-type img {{ prefix?.cssClass }}">
                        <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                        <img
                          class="img-placeholder"
                          *ngIf="!item[prefix.prop]"
                          src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                          alt=""
                        />
                      </span>
                    </ng-container>
                  </ng-container>
                </span>
              </ng-container>
            </ng-container>
          </div>

          <div class="d-inline-flex" [ngOptionHighlight]="search">
            {{ item.name || item.displayName }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <ng-container *ngIf="field?.form?.ngSelectOptions?.isEdit">
            <span class="ng-value-icon right ml-2" (click)="edit($event, item.id)">
              <mat-icon [svgIcon]="'ng-select-active-edit'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
            </span>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
