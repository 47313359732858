import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, takeWhile } from 'rxjs/operators';
import { untilDestroyed, Logger } from '../../../core';
import { AuthService } from '../../services/auth.service';
import { ChangePasswordBody } from '../../../core/models/change-password-request-body';
import { CustomValidators } from '../../../core/classes/custom-validators/custom-validators';
import { RootService } from '@app/core/root.service';

const log = new Logger('ChangePassword');
interface Branding {
  name?: string;
  logo?: string;
}
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../../auth.scss', './change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  error: string | undefined;
  changepasswordForm!: UntypedFormGroup;
  isLoading = false;
  showSuccessMgs: boolean;
  token: string;
  email: string;
  alive = true;
  branding: Branding = {
    name: '',
    logo: '',
  };
  logoDefault = '/assets/logo-text.svg';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private service: RootService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.token = this.route.snapshot.params.token || null;
    this.email = this.route.snapshot.params.email || null;
  }

  changePassword() {
    this.isLoading = true;
    this.error = null;
    const body: ChangePasswordBody = {
      email: this.email,
      token: this.token,
      password: this.changepasswordForm.get('password').value,
      passwordConfirmation: this.changepasswordForm.get('confirmPassword').value,
      closeActiveSessions: this.changepasswordForm.get('closeActiveSessions').value,
    };
    const change$ = this.authService.changePassword(body);
    change$
      .pipe(
        finalize(() => {
          this.changepasswordForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (data: boolean) => {
          log.debug(`${data} successfully logged in`);
          if (data === true) {
            this.showSuccessMgs = data;
          }
          this.router.navigate(['/login'], { replaceUrl: true });
        },
        (err: any) => {
          const error = err?.error;
          log.debug(`reseting password error: ${error}`);
          if (error && error.errors) {
            if (error.errors.email) {
              this.error = error.errors.email[0];
            } else if (error.errors.password) {
              this.error = error.errors.password[0];
            }
          } else {
            this.error = error?.message || 'Reseting password error.';
          }
        }
      );
  }

  ngOnDestroy() {}

  private createForm() {
    this.changepasswordForm = this.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(/[&]|[-]|[_]|[.]|[!]|[@]|[#]|[$]|[%]|[*]|[?]|[+]|[~]/, {
              hasSpecialCharacters: true,
            }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
          ]),
        ],
        confirmPassword: ['', [Validators.compose([Validators.required])]],
        closeActiveSessions: false,
      },

      {
        validator: CustomValidators.passwordMatchValidator,
      }
    );
  }
}
