import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from '@app/core';
import { ApiService } from '@app/core/api-service/api.service';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { RootService } from '@app/core/root.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  summary: any;
  constructor(private service: ApiRequestService) {}

  ngOnInit(): void {
    this.loadComponentData();
  }

  loadComponentData() {
    this.service
      .get('admin/dashboard/summary')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.summary = res;
      });
  }

  ngOnDestroy() {}
}
