import { Injectable } from '@angular/core';
import { UserDetails } from '@app/interfaces';
import { UserDetailsService as BaseUserDetailsService } from '@afaqyit/frontend-core';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService extends BaseUserDetailsService {
  public userDetails: UserDetails = {};

  constructor() {
    // Please fill the super() call by the needed parameters
    super();
  }

  setUserDetails(userDetails?: UserDetails) {
    if (userDetails) {
      this.userDetails = userDetails;
    } else {
      this.userDetails = undefined;
    }
  }
}
