import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHasChildNodes]',
})
export class HasChildNodesDirective implements AfterViewInit {
  _elementRef: any;
  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
  }

  ngAfterViewInit(): void {
    // console.log(!!!this._elementRef.nativeElement.children.length);
    if (!!this._elementRef.nativeElement.children.length) {
      this._elementRef.nativeElement.classList.add('not-empty');
    }
  }
}
