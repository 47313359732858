<div id="pagination" class="pagination" fxLayout.lt-md="column">
  <div class="itemCount">
    <span class="dark-text">
      <span *ngIf="pagination.from && pagination.to" class="dark-text">
        {{ 'pagination.displaying' | translate }} <strong>{{ pagination.from }}</strong>
        {{ 'pagination.to' | translate }} <strong>{{ pagination.to }}</strong>
      </span>
      <span *ngIf="pagination.totalRecords == 0" class=""> 0 - 0 </span>
      {{ 'pagination.from' | translate }}
      <span class="form-to-page">{{ pagination.totalRecords }}</span>
      {{ 'pagination.' + service.cid | translate }}
    </span>
  </div>
  <div class="d-flex pages-container">
    <div class="currentPage dark-text" fxFlex fxLayout fxLayoutAlign="center center">
      <span>{{ 'pagination.page' | translate }}</span>
      <input
        type="number"
        class="current-page-input"
        [min]="1"
        [max]="pagination.lastPage"
        value="1"
        (change)="setPage($event?.target?.value)"
        matInput
        [attr.testId]="'page'"
        #currentPageInput
      />
      <span>{{ 'pagination.of' | translate }} {{ pagination.lastPage }}</span>
    </div>

    <button mat-icon-button class="paginator-btn" [disabled]="currentPage == 1" (click)="setPageNav('prev')">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-icon-button
      class="paginator-btn"
      [disabled]="currentPage === pagination.lastPage"
      (click)="setPageNav('next')"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
