<mat-toolbar class="navbar" id="header">
  <!-- <div fxLayoutAlign="start center">
      <button class="menu-button" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <span class="title">{{ title }}</span>
    </div> -->

  <button *ngIf="menuSizeState === 'hidden'" mat-icon-button class="menu-button" (click)="sidenavToggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <div *ngIf="menuSizeState === 'small' || menuSizeState === 'hidden'" fxLayout="row " class="pr-5">
    <img [src]="logo" width="120px" alt="Afaqy" />
  </div>
  <!--  <div fxLayout="row" class="search-input" ngClass.gt-md="lg" ngClass.md="md" ngClass.sm="sm" ngClass.xs="xs">-->
  <!--    <mat-form-field floatLabel="never">-->
  <!--      <mat-label>{{ 'common.search' | translate }}</mat-label>-->
  <!--      <input matInput />-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <span class="spacer"></span>
  <!-- <span (click)="initializeTour()">Tour</span> -->
  <div></div>

  <!-- <button
    mat-icon-button
    [routerLink]="['/clients/create']"
    [matTooltip]="'clients.create' | translate"
    class="add-btn"
    aria-label=""
  >
    <mat-icon>add_circle</mat-icon>
    <mat-icon>add</mat-icon>
  </button> -->

  <!-- <hr class="horizontal-line" /> -->

  <!-- Notification icon -->
  <div class="notification-icon-container" (click)="toggleAndClearIncomingCount()">
    <img class="cursor-pointer" src="assets/notifications.svg" alt="" srcset="" />
    <span class="badge cursor-pointer" *ngIf="notifications?.resourcesList?.additionalData?.totalUnread">{{
      notifications.resourcesList.additionalData.totalUnread > 99
        ? '99+'
        : notifications.resourcesList.additionalData.totalUnread
    }}</span>
  </div>

  <hr class="horizontal-line" />

  <!-- Languages icon -->
  <button mat-icon-button [matMenuTriggerFor]="menu" class="color-primary primary-font" aria-label="">
    {{ currentLanguage }}
    <img
      [ngClass]="{ 'mr-2': currentLanguage === 'ar-SA', 'ml-2': currentLanguage !== 'ar-SA' }"
      src="assets/languages.svg"
      alt=""
      srcset=""
    />
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let language of languages">
      <button mat-menu-item (click)="setLanguage(language)" *ngIf="currentLanguage !== language">
        <span>{{ language }}</span>
      </button>
    </ng-container>
  </mat-menu>

  <hr class="horizontal-line" />

  <!-- Logout icon -->
  <mat-icon class="ml-3 logout-icon cursor-pointer" title="Logout" (click)="logout()">exit_to_app</mat-icon>
  <div dir="ltr">
    <ng-progress
      #mainProgressBar
      [direction]="currentLanguage === 'ar-SA' ? 'rtl+' : 'ltr+'"
      id="mainProgressBar"
      [color]="'#324462'"
      fixed="false"
      class="position-relative"
    ></ng-progress>
  </div>
</mat-toolbar>

<mat-sidenav-container class="filterSideNavContainer" [hasBackdrop]="true">
  <mat-sidenav
    #notificationsSidenav
    mode="side"
    opened="false"
    [fixedInViewport]="true"
    position="end"
    class="filterSideNav notificationsSideNav"
  >
    <!-- Un-used: Avoid calling 404 GET /notifications request.  -->
    <!-- <app-notifications-list [sideNav]="notificationsSidenav"></app-notifications-list> -->
  </mat-sidenav>
</mat-sidenav-container>
