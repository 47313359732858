export enum FilterFieldTypeEnum {
  Null = 'null',
  Text = 'text',
  Number = 'number',
  Select = 'select',
  CheckBox = 'checkbox',
  MultiSelect = 'multi_select',
  NgSelectMultiple = 'ng_select_multiple',
  NgSelectApi = 'ng_select_api',
  NgSelect = 'ng_select',
  DatePicker = 'datepicker',
  DateRange = 'date_range',
  RangeSlider = 'range_slider',
}

export enum FormFieldTypeEnum {
  Text = 'text',
  Number = 'number',
  NumberRestrictInputRange = 'number_RestrictInputRange',
  TextArea = 'textarea',
  ColorPicker = 'colorPicker',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  Email = 'email',
  MultiSelect = 'multi_select',
  NgSelectMultiple = 'ng_select_multiple',
  NgAddedSSelectMultiple = 'ng_added_select_multiple',
  NgSelect = 'ng_select',
  NgSelectApi = 'ng_select_api',
  CheckBox = 'checkbox',
  MatSlideToggle = 'mat-slide-toggle',
  DatePicker = 'datepicker',
  TimePicker = 'timepicker',
  DateTimeInput = 'dateTimeInput',
  DateTimeRangeInput = 'dateTimeRangeInput',
  Select = 'select',
  Radio = 'radio',
  RadioTranslated = 'radio_translated',
  FileInput = 'file_input',
  Img = 'img',
  ImgCropper = 'img_cropper',
  Permissions = 'permissions',
  FileUpload = 'file_upload',
  FileDownloadDetails = 'file_download_details',
  FileUploadDetails = 'file_upload_details',
  ImgUploadDetails = 'img_upload_details',
  Hidden = 'hidden',
}
