<div fxFill fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="1.5rem" class="bg-login mat-typography">
  <div
    fxLayout="column"
    fxFlexAlign="center"
    fxFlexAlign.gt-sm="end"
    class="login-container-width"
    ngClass.gt-md="lg"
    ngClass.md="md"
    ngClass.sm="sm"
    ngClass.xs="xs"
  >
    <div fxFlex="100%">
      <img src="../../../../assets/logo-text.svg" width="120px" alt="Afaqy" />
      <h1 class="primary-font app-name">{{ 'common.APP_NAME' | translate }}</h1>
      <h2 class="">
        {{ 'auth.wrong-password.token_expired' | translate }}
        <a href="/login">{{ 'auth.wrong-password.login' | translate }}</a> {{ 'auth.wrong-password.again' | translate }}
      </h2>
    </div>
    <br />
  </div>
</div>
