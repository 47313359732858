<div class="container" fxLayout="column" fxLayoutAlign="center" [hidden]="inputDisabled" [formGroup]="fullLocation">
  <mat-form-field class="form-container" appearance="outline">
    <input
      id="leafletSearchInput"
      matInput
      formControlName="address"
      type="text"
      class="search-input"
      (keydown.enter)="$event.preventDefault()"
      ngx-google-places-autocomplete
      [options]="options"
      #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)"
      [value]="addressValue"
      [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
    />
    <mat-icon
      matSuffix
      [ngClass]="inputDisabled ? 'btnDisabeled' : 'clickable'"
      (click)="invalidateSize(); toggleView()"
      mdePopoverTriggerOn="click"
    >
      location_on
    </mat-icon>
  </mat-form-field>
  <div fxFlex="100" fxLayout="column" @fadeAnimation [hidden]="view">
    <div class="mapContainer" fxFlex fxLayout="row">
      <div
        style="height: 400px; width: 91vh; border-radius: 8px"
        leaflet
        [leafletOptions]="options"
        [leafletLayers]="markers"
        (leafletMapReady)="onMapReady($event)"
        (leafletClick)="onMapClick($event)"
      ></div>
    </div>
  </div>
</div>
