<div>
  <div style="display: block" [style.height.px]="height">
    <canvas
      baseChart
      [datasets]="dataSets"
      [data]="data"
      [labels]="labels"
      [options]="options"
      [plugins]="plugins"
      [legend]="legend"
      [type]="type"
    >
    </canvas>
  </div>
</div>
