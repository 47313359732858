export class DummyData {
  static vehicles: any = {
    message: '',
    data: [
      {
        id: 3,
        name: 'test3',
        photo: null,
        plateNumber: 'TestplateNumber',
        make: 'clk123',
        model: 'mercedes',
        year: null,
        type: 'bus',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test3'],
        odometer: {
          distance: 1520,
          createdAt: '2019-07-22T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 2,
        name: 'test2',
        photo: null,
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-20T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 5,
        name: 'soon',
        photo: null,
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T02:09:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 6,
        name: 'soon',
        photo: null,
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T10:09:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 9,
        name: 'soon',
        photo: null,
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T12:30:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: null,
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 1,
        name: 'Ezz vehicle',
        photo: 'http://dev.api.fleet.afaqy.co/image/vehicles/oEg4Y0Nua0k9sDFtAPUIC6tNyVXLMeET54NVkL6g.jpeg',
        plateNumber: 'TestplateNumber2',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        groups: ['test', 'test2', 'test4', 'hhhhhhhhhh', 'ellllllalllyyyyy', 'test4', 'hhhhhhhhhh', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-08-22T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Samy Basha',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
          {
            id: 3,
            name: 'Samy Basha',
            photo: null,
          },
          {
            id: 4,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f56/fff',
          },
        ],
      },
    ],
    meta: {
      pagination: {
        currentPage: 1,
        firstPage: 1,
        lastPage: 1,
        perPage: 10,
        count: 3,
        totalRecords: 3,
        links: {
          first: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          last: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          previous: null,
          next: null,
        },
      },
    },
  };
  static 'reports-status': any = {
    message: '',
    data: [
      {
        id: 3,
        name: 'test3',
        plateNumber: 'TestplateNumber',
        photo: 'https://dummyimage.com/300.png/09f/fff',
        make: 'clk123',
        model: 'mercedes',
        year: null,
        type: 'bus',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test3'],
        odometer: {
          distance: 1520,
          createdAt: '2019-07-22T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 2,
        name: 'test2',
        photo: 'https://dummyimage.com/300.png/09f/fff',
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-20T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 5,
        name: 'soon',
        photo: 'https://dummyimage.com/300.png/09f/fff',
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T02:09:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 6,
        name: 'soon',
        photo: 'https://dummyimage.com/300.png/09f/fff',
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T10:09:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 9,
        name: 'soon',
        photo: 'https://dummyimage.com/300.png/09f/fff',
        plateNumber: 'TestplateNumber3',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test4', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-09-22T12:30:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Driver Name',
            photo: null,
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
        ],
      },
      {
        id: 1,
        name: 'Ezz vehicle',
        photo: 'http://dev.api.fleet.afaqy.co/image/vehicles/oEg4Y0Nua0k9sDFtAPUIC6tNyVXLMeET54NVkL6g.jpeg',
        plateNumber: 'TestplateNumber2',
        make: '',
        model: '',
        year: null,
        type: '',
        status: { id: 1, name: 'Stopped', color: '#ff0000' },
        startDate: '2019-07-22T10:11:27+00:00',
        endDate: '2019-07-28T10:11:27+00:00',
        groups: ['test', 'test2', 'test4', 'hhhhhhhhhh', 'ellllllalllyyyyy', 'test4', 'hhhhhhhhhh', 'ellllllalllyyyyy'],
        odometer: {
          distance: 1000,
          createdAt: '2019-08-22T10:11:27+00:00',
        },
        drivers: [
          {
            id: 1,
            name: 'Samy Basha',
            photo: 'https://dummyimage.com/300.png/09f/fff',
          },
          {
            id: 2,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f66/fff',
          },
          {
            id: 3,
            name: 'Samy Basha',
            photo: null,
          },
          {
            id: 4,
            name: 'Ahmed Ezzzzz',
            photo: 'https://dummyimage.com/300.png/f56/fff',
          },
        ],
      },
    ],
    meta: {
      pagination: {
        currentPage: 1,
        firstPage: 1,
        lastPage: 1,
        perPage: 10,
        count: 3,
        totalRecords: 3,
        links: {
          first: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          last: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          previous: null,
          next: null,
        },
      },
    },
  };

  static vehicles_form: any = {
    id: 1,
    name: 'Vehicle Name',
    photo: 'https://dummyimage.com/300.png/09f/fff',
    plateNumber: '110 BAT',
    imei: 'this is missing on api',
    vin: 'this is missing on api',
    make: {
      id: 1,
      name: 'BMW',
    },
    model: {
      id: 1,
      name: 'X6',
    },
    year: '2020',
    type: {
      id: 10,
      name: 'Car',
    },
    color: '#1862db',
    license: '12345',
    expiry: '2020-09-19',
    status: {
      startDate: '2019-07-22T10:11:27+00:00',
      endDate: '2019-07-28T10:11:27+00:00',
      id: 2,
      name: 'Active',
      color: '#56d238',
    },
    odometer: {
      distance: 1000,
      createdAt: '2019-08-22T10:11:27+00:00',
    },
    groups: [
      {
        id: 4,
        name: 'KSA',
      },
      {
        id: 5,
        name: 'Technical',
      },
    ],
    specification: {
      width: 213,
      height: 123,
      length: 12312,
      curbWeight: 123,
      grossLength: 123,
    },
    engine: {
      engineSummary: 'engine summary',
      engineBrand: 'engine brand',
      aspiration: 'turbocharger',
      camType: 'sohc',
      compressionRatio: 123,
      cylinders: 123,
      displacement: 123,
      fuelInduction: 'common-rail-direct-injection',
      engineFuelQuality: 'engine fuel quality',
      valves: 132,
      transmissionSummary: 'transmission summary',
      transmissionBrand: 'transmission brand',
      transmissionType: 'automatic',
      transmissionGear: 4,
    },
    wheel: {
      driveType: '4x4',
      brakeSystem: 'air',
      frontTrackWidth: 213,
      rearTrackWidth: 123,
      wheelbase: 123,
      frontWheelDiameter: 213,
      rearWheelDiameter: 123,
      rearAxle: 123,
      frontTireType: 'front_tire_type',
      frontTirePsi: 'front_tire_psi',
      rearTireType: 'rear_tire_type',
      rearTirePsi: 'rear_tire_psi',
    },
    fuel: {
      fuelType: {
        id: 2,
        name: 'Gas',
      },
      fuelQuality: '95',
      fuelTank1Capacity: 50,
      fuelTank2Capacity: 30,
    },
    purchase: {
      vendor: {
        id: 31,
        name: 'testtttt',
      },
      date: '2019-09-19',
      amount: 123,
      odometerOnPurchase: 123,
      comment: 'comment',
    },
    drivers: [
      {
        id: 1,
        name: 'Driver Name',
        photo: 'http://base_url/image/users/photo.png',
      },
      {
        id: 2,
        name: 'Driver Name',
        photo: 'http://base_url/image/users/photo.png',
      },
    ],
  };

  static vehicleProfile: any = {
    id: 1,
    name: 'Ahmed Ezz',
    photo: 'http://dev.api.fleet.afaqy.co/image/vehicles/oEg4Y0Nua0k9sDFtAPUIC6tNyVXLMeET54NVkL6g.jpeg',
    plateNumber: '110 BAT',
    imei: 13579246810,
    vin: 2468,
    make: {
      id: 1,
      name: 'BMW',
    },
    model: {
      id: 1,
      name: 'X6',
    },
    year: 2020,
    type: {
      id: 1,
      name: 'car',
    },
    color: '#6bcaff',
    license: '12345',
    expiry: '2020-09-19',
    status: {
      id: 1,
      name: 'Active',
      color: '#56d238',
    },
    odometer: {
      distance: 1000,
      createdAt: '2019-08-22T10:11:27+00:00',
    },
    groups: [
      {
        id: 1,
        name: 'KSA',
      },
      {
        id: 2,
        name: 'Technical',
      },
    ],
    specification: {
      width: 20,
      height: 8,
      length: 40,
      curbWeight: 123,
      grossLength: 123,
    },
    engine: {
      engineSummary: 'engine summary',
      engineBrand: 'BMW',
      aspiration: 'turbocharger',
      camType: 'sohc',
      compressionRatio: 4,
      cylinders: 1,
      displacement: 123,
      fuelInduction: 'common-rail-direct-injection',
      engineFuelQuality: 'engine fuel quality',
      valves: 132,
      transmissionSummary: 'transmission summary',
      transmissionBrand: 'transmission brand',
      transmissionType: 'automatic',
      transmissionGear: 4,
    },
    wheel: {
      driveType: '4x4',
      brakeSystem: 'air',
      frontTrackWidth: 213,
      rearTrackWidth: 123,
      wheelbase: 123,
      frontWheelDiameter: 213,
      rearWheelDiameter: 123,
      rearAxle: 123,
      frontTireType: 'front_tire_type',
      frontTirePsi: 'front_tire_psi',
      rearTireType: 'rear_tire_type',
      rearTirePsi: 'rear_tire_psi',
    },
    fuel: {
      fuelType: {
        id: 1,
        name: 'Oil',
      },
      fuelQuality: '95',
      fuelTank1Capacity: 50,
      fuelTank2Capacity: 30,
    },
    purchase: {
      vendor: {
        id: 1,
        name: 'Melvin Champlin',
      },
      date: '2019-09-19',
      amount: 123,
      odometerOnPurchase: 123,
      comment: 'comment',
    },
    drivers: [
      {
        id: 1,
        name: 'Samy Ahmed',
        photo: 'http://base_url/image/users/photo.png',
      },
      {
        id: 2,
        name: 'Ahmed Ezz',
        photo: 'http://base_url/image/users/photo.png',
      },
    ],
  };

  static 'activity-log': any = {
    message: '',
    data: [
      {
        id: 1,
        activity: {
          id: 5,
          name: 'test3',
          type: 'issues',
          action: 'changed status of',
          description: 'issue from open to closed',
        },
        user: {
          id: 1,
          name: 'Driver Name',
          photo: 'https://dummyimage.com/300.png/09f/fff',
        },
      },
      {
        id: 2,
        activity: {
          id: 6,
          name: 'test6',
          type: 'vehicles',
          action: 'changed status of',
          description: 'issue from open to closed',
        },
        user: {
          id: 2,
          name: 'Driver Name1',
          photo: 'https://dummyimage.com/300.png/09f/fff',
        },
      },
    ],
    meta: {
      pagination: {
        currentPage: 1,
        firstPage: 1,
        lastPage: 1,
        perPage: 10,
        count: 3,
        totalRecords: 3,
        links: {
          first: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          last: 'http://dev.api.fleet.afaqy.co/api/v1/vehicles?page=1',
          previous: null,
          next: null,
        },
      },
    },
  };

  static 'permissions': any = {
    message: '',
    data: {
      id: 61,
      name: 'custom-all',
      username: 'custom-all',
      email: 'custom-all@custom.com',
      mobile: '01010101010',
      photo: null,
      position: 'employee',
      role: 81,
      interface: 'web',
      manageUsers: false,
      status: true,
      permissions: {
        vendorsvendors: {
          read: true,
          create: true,
          update: true,
          delete: true,
          readAttachments: true,
          manageAttachments: true,
          readComments: true,
          manageComments: true,
          manageArchived: true,
        },
        vehicles: {
          read: true,
          create: true,
          update: true,
          delete: true,
          readComments: true,
          manageComments: true,
          readAttachments: true,
          manageAttachments: true,
          manageArchived: true,
        },
        issues: {
          read: true,
          create: true,
          update: true,
          delete: true,
          readAttachments: true,
          manageAttachments: true,
          readComments: true,
          manageComments: true,
        },
        fuelHistories: { read: true, create: true, update: true, delete: true },
        services: { read: true, create: true, update: true, archive: true },
        meters: { read: true, create: true, update: true, delete: true },
      },
    },
  };

  static clients: any = {
    message: '',
    data: [
      {
        id: 'e3b52255-f22f-4458-9d23-c413c97feda0',
        name: 'Client Name',
        avl: true,
        fleet: false,
        odm: false,
        vehicleInspection: true,
        repairOrder: true,
        createdAt: '2020-02-02',
      },
      {
        id: 'e3b52255-f22f-4458-9d23-c413c97feda0',
        name: 'Maraai',
        avl: true,
        fleet: false,
        odm: false,
        vehicleInspection: true,
        repairOrder: true,
        createdAt: '2020-02-02',
      },
    ],
    meta: {
      pagination: {
        currentPage: 1,
        firstPage: 1,
        lastPage: 2,
        perPage: 10,
        count: 10,
        totalRecords: 20,
        links: {
          first: 'https://base_url/api/v1/users?page=1',
          last: 'https://base_url/api/v1/users?page=2',
          previous: null,
          next: 'https://base_url/api/v1/users?page=2',
        },
      },
    },
  };
}
