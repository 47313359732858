<div class="bg-container mat-typography">
  <div class="page-content">
    <div>
      <img class="sideContent-img" src="./assets/auth/logo-light.svg" alt="Afaqy" />
      <h2 class="primary-font page-description" *ngIf="!showMsgSuccess">
        {{ 'auth.sideContent' | translate }}
      </h2>
      <h2 class="primary-font success-msg" *ngIf="showMsgSuccess">
        {{ 'auth.sideContentAfterSubdomain' | translate }}
      </h2>
    </div>
    <ng-container *ngIf="!showMsgSuccess">
      <div
        fxLayout="column"
        fxFlexAlign="center"
        class="fields-container-width primary-font"
        ngClass.gt-md="lg"
        ngClass.md="md"
        ngClass.sm="sm"
        ngClass.xs="xs"
      >
        <div class="header">
          <img class="my-5" src="./assets/auth/logo.svg" width="120px" alt="Afaqy" />
          <!-- <h2 class="primary-font title">{{ 'auth.change-password.title' | translate }}</h2> -->
        </div>
        <div class="alert-error-container" [hidden]="!error || isLoading">
          <div class="alert-error-content" fxLayout="row" fxLayoutAlign="space-between center" translate>
            <span>{{ error }}</span>
            <mat-icon (click)="error = null">close</mat-icon>
          </div>
        </div>

        <div fxFlex="100%" class="fields-container" fxLayoutAlign="center">
          <div fxFlex="100%" class="fields-box">
            <!-- Begin Form -->

            <form (ngSubmit)="setupAccount()" [formGroup]="setupAccountForm" novalidate>
              <div class="fields-fields" fxLayout="column">
                <label for="mysecretpassword">{{ 'auth.change-password.password.title' | translate }}</label>
                <mat-form-field [hideRequiredMarker]="true">
                  <input
                    id="mysecretpassword"
                    type="password"
                    matInput
                    formControlName="password"
                    autocomplete="password"
                    [placeholder]="'auth.change-password.password.title' | translate"
                    required
                    showHideInput
                  />
                  <mat-error
                    *ngIf="
                      setupAccountForm.controls.password.invalid &&
                      setupAccountForm.controls.password.touched &&
                      setupAccountForm.controls.password?.errors?.required
                    "
                  >
                    <span translate>{{ 'auth.change-password.password.required' | translate }}</span>
                  </mat-error>
                  <i
                    matSuffix
                    class="material-icons cursor-pointer showHidePassword"
                    showHideTrigger="mysecretpassword"
                    [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"
                  ></i>
                </mat-form-field>

                <label for="mydomin">{{ 'auth.change-password.domain.title' | translate }}</label>
                <mat-form-field [hideRequiredMarker]="true">
                  <input
                    id="mydomin"
                    type="domain"
                    matInput
                    formControlName="subdomain"
                    autocomplete="subdomain"
                    [placeholder]="'auth.change-password.domain.title' | translate"
                    required
                    showHideInput
                  />
                  <mat-error
                    *ngIf="
                      setupAccountForm.controls.subdomain.invalid &&
                      setupAccountForm.controls.subdomain.touched &&
                      setupAccountForm.controls.subdomain?.errors?.required
                    "
                  >
                    <span translate>{{ 'auth.change-password.domain.required' | translate }}</span>
                  </mat-error>
                  <mat-error *ngIf="setupAccountForm.controls['subdomain'].hasError('doesNotContainSpecialCharacters')">
                    <span translate>{{
                      'auth.change-password.domain.doesNotContainSpecialCharacters' | translate
                    }}</span>
                  </mat-error>

                  <mat-error *ngIf="setupAccountForm.controls.subdomain?.errors?.maxlength">
                    <span translate>{{ 'validation_errors.maxlength' | translate }}</span>
                  </mat-error>
                </mat-form-field>

                <br />
                <button
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="submit-btn"
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="setupAccountForm.invalid || isLoading"
                >
                  <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
                  <span *ngIf="!isLoading">{{ 'auth.change-password.setupAccountSubmit' | translate }}</span>
                </button>

                <!-- Begin Errors Summary -->
                <div class="error-summary mt-6">
                  <label
                    class="col error-text"
                    [ngClass]="
                      (setupAccountForm.controls['password'].hasError('required') ||
                        setupAccountForm.controls['password'].hasError('minlength')) &&
                      setupAccountForm.controls.password.touched
                        ? 'text-danger'
                        : setupAccountForm.controls.password.touched
                        ? 'text-success'
                        : ''
                    "
                  >
                    <i class="material-icons error-icon">
                      {{
                        (setupAccountForm.controls['password'].hasError('required') ||
                          setupAccountForm.controls['password'].hasError('minlength')) &&
                        setupAccountForm.controls.password.touched
                          ? 'clear'
                          : setupAccountForm.controls.password.touched
                          ? 'done'
                          : 'stop'
                      }}
                    </i>
                    {{ 'auth.change-password.validation.eightCharacters' | translate }}
                  </label>

                  <label
                    class="col error-text"
                    [ngClass]="
                      (setupAccountForm.controls['password'].hasError('required') ||
                        setupAccountForm.controls['password'].hasError('hasSpecialCharacters')) &&
                      setupAccountForm.controls.password.touched
                        ? 'text-danger'
                        : setupAccountForm.controls.password.touched
                        ? 'text-success'
                        : ''
                    "
                  >
                    <i class="material-icons error-icon">
                      {{
                        (setupAccountForm.controls['password'].hasError('required') ||
                          setupAccountForm.controls['password'].hasError('hasSpecialCharacters')) &&
                        setupAccountForm.controls.password.touched
                          ? 'clear'
                          : setupAccountForm.controls.password.touched
                          ? 'done'
                          : 'stop'
                      }}
                    </i>
                    {{ 'auth.change-password.validation.specialCharacter' | translate }}
                  </label>

                  <label
                    class="col error-text"
                    [ngClass]="
                      (setupAccountForm.controls['password'].hasError('required') ||
                        setupAccountForm.controls['password'].hasError('hasNumber') ||
                        setupAccountForm.controls['password'].hasError('hasCapitalCase') ||
                        setupAccountForm.controls['password'].hasError('hasSmallCase')) &&
                      setupAccountForm.controls.password.touched
                        ? 'text-danger'
                        : setupAccountForm.controls.password.touched
                        ? 'text-success'
                        : ''
                    "
                  >
                    <i class="material-icons error-icon">
                      {{
                        (setupAccountForm.controls['password'].hasError('required') ||
                          setupAccountForm.controls['password'].hasError('hasNumber') ||
                          setupAccountForm.controls['password'].hasError('hasCapitalCase') ||
                          setupAccountForm.controls['password'].hasError('hasSmallCase')) &&
                        setupAccountForm.controls.password.touched
                          ? 'clear'
                          : setupAccountForm.controls.password.touched
                          ? 'done'
                          : 'stop'
                      }}
                    </i>
                    {{ 'auth.change-password.validation.smallCapitalNumber' | translate }}
                  </label>
                </div>
                <!-- End Errors Summary -->
              </div>
            </form>

            <!-- End Form -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
