import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Router } from '@angular/router';
// import { SaasService } from '@app/shared/services/saas/saas.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor() {} // private saasService: SaasService

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      if (request.url.split('/')[0] === 'assets') {
        request = request.clone({ url: request.url });
      }
      // else if (request.url.split('/')[0] === 'contact-support') {
      //   // checking for contact support API url
      //   environment.saasAdminUrl
      //     ? (request = request.clone({ url: environment.saasAdminUrl + request.url }))
      //     : (request = request.clone({ url: this.saasService.saasAdminApiDomain + request.url }));
      // }
      else {
        /**
         * checking for serverUrl for when serving the app with fixed api as in local develop environment
         * also checking for apiDomain to use with SAAS service as it's the unique identifier
         * of the SAAS url prefixing method
         */
        if (environment.serverUrl) {
          request = request.clone({ url: environment.serverUrl + request.url });
        }
        // else if (environment.saasEnvironmentApiDomain) {
        //   request = request.clone({ url: this.saasService.apiDomain + request.url });
        // }
      }
    }
    return next.handle(request);
  }
}
