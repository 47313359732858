import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { CredentialsService } from '../../core/authentication/credentials.service';
import { SharedService } from '@app/shared/services/shared.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router, private credentialsService: CredentialsService, private shared: SharedService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * get last keyword in request url and if it is export don't go throw errors
     */
    //
    // const requestArray = request.url.split('/');
    // const requestLast = requestArray[requestArray.length - 1];
    // if (requestLast === 'export') {
    //   console.log('export request');
    //   return next.handle(request);
    // } else {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
    // }
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    /**
     * pass validation errors to next > core form to handle it
     */
    if (response instanceof HttpErrorResponse) {
      // console.log('error handler interceptor');
      // console.log(response);

      if (response.status === 422) {
        // console.log('validation Errors');
        throw response;
      }

      /**
       * logout when unauthenticated
       */
      if (response.status === 401) {
        /**
         * if 401 error code force logout and route to login page
         */
        this.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
        // throw response;
      }
      if (response.status === 403) {
        /**
         * if 403 error code forbidden route him to 401 page
         */
        this.router.navigate(['/401'], { replaceUrl: false, skipLocationChange: true });

        // throw response;
      }

      if (response.status === 413) {
        throw response;
      }
    }

    // if (response && response.name === 'TimeoutError') {
    //   console.log('response TimeoutError');
    //   this.shared.toastr.warning(
    //     response.message,
    //     this.shared.translate.instant('common.success')
    //   );
    //
    //   throw response;
    //
    // }

    /**
     * todo handle other error codes
     * handle errors like 500 and 401 etc
     */
    throw response;
  }
}
