import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { PermissionsGuard } from '@app/core/permissions/permissions.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'settings',
      loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      data: {
        breadcrumb: 'settings.title',
        // permissionsKeys: [{ module: 'users' }],
      },
    },
    {
      path: 'users',
      loadChildren: () => import('./users-feature/users.module').then((m) => m.UsersModule),
      data: {
        breadcrumb: 'users.title',
        permissionsKeys: [{ module: 'users' }],
      },
      canLoad: [PermissionsGuard],
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      data: {
        breadcrumb: 'profile.title',
      },
    },
    {
      path: 'roles',
      loadChildren: () => import('./roles/roles.module').then((m) => m.RolesModule),
      data: {
        breadcrumb: 'roles.title',
        permissionsKeys: [{ module: 'manageUsersRoles' }],
      },
      canLoad: [PermissionsGuard],
    },
    {
      path: '401',
      loadChildren: () => import('./401/unauthorized.module').then((m) => m.UnauthorizedModule),
      data: {
        breadcrumb: 'unauthorized.title',
      },
    },
    {
      path: 'clients',
      loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule),
      data: {
        breadcrumb: 'clients.title',
      },
    },
    {
      path: 'device-settings',
      loadChildren: () => import('./device-settings/device-settings.module').then((m) => m.DeviceSettingsModule),
      data: {
        breadcrumb: 'device-settings.title',
      },
    },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      /**
       * stopped preloading all modules, defaults to lazy loading
       */
      // { preloadingStrategy: PreloadAllModules }
      { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' }
    ),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
