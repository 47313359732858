import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ItemProps } from '@app/interfaces';
import { take, takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss'],
})
export class MultiCheckboxComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() cid: string;
  @Input() field: ItemProps;
  @Input() selectionsArray: { id: string; name: string }[];
  selectedItems: any[] = [];
  innerFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  subscription: Subscription;
  alive = true;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field?.currentValue && changes.form?.currentValue) {
      this.subscription = this.form.controls[this.field.name].valueChanges
        .pipe(takeWhile(() => this.alive))
        .subscribe((value) => {
          if (!value) {
            this.innerFormGroup.reset();
          }
        });
    }
    if (changes.selectionsArray?.currentValue?.length) {
      this.selectionsArray.forEach((x: { id: string; name: string }) => {
        this.innerFormGroup.addControl(x.id, new UntypedFormControl());
      });
    }
  }

  ngOnInit() {
    this.innerFormGroup.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((value) => {
      const valuesArr = Object.keys(value).filter((x) => value[x]);
      this.form.controls[this.field.name].setValue(value ? valuesArr : null);
    });
  }
}
