import { environment } from '../../../../environments/environment';
import { RxStompConfig } from '@stomp/rx-stomp';
import * as SockJS from 'sockjs-client';
import { Injectable } from '@angular/core';

const random = require('sockjs-client/lib/utils/random');

@Injectable({
  providedIn: 'root',
})
export class SensorsStompConfig {
  sURL = environment.sensorStompConfig.url;
  public sessionId = random.string(8);

  generateNewSessionId() {
    this.sessionId = random.string(8);
  }
  config: RxStompConfig = {
    webSocketFactory: () =>
      new SockJS(this.sURL, [], {
        sessionId: () => {
          return this.sessionId;
        },
      }),
    connectHeaders: environment?.sensorStompConfig?.headers,
    heartbeatIncoming: environment?.sensorStompConfig?.heartbeat_in,
    heartbeatOutgoing: environment?.sensorStompConfig?.heartbeat_out,
    reconnectDelay: environment?.sensorStompConfig?.reconnect_delay,
    debug: (msg: string): void => {
      console.log(msg);
    },
  };
}
