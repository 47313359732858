import { Component, OnInit, OnChanges, AfterViewInit, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from '@app/shared/services/shared.service';

import * as moment from 'moment';

import { LocaleConfig } from 'ngx-daterangepicker-material';
import { takeWhile } from 'rxjs/operators';
import { I18nService } from '@app/core/i18n.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() minEntryDate: any; // pass the min value of the minEntryDate
  @Input() maxEntryDate: any; // pass the min value of the maxEntryDate
  @Input() key: string; // pass the formcontrolname key
  @Input() group: UntypedFormGroup; // pass the formgroup
  @Input() appliedFilter: boolean; // pass the formgroup
  @Input() momentObj: boolean;

  checked = false;
  alive = true;

  locale: LocaleConfig = {
    format: 'DD-MM-YYYY',
    // displayFormat: 'MM/DD/YYYY', // default is format value
    direction: 'ltr', // could be rtl
    weekLabel: 'W',
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Okay', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1, // first day is monday
  };

  constructor(private shared: SharedService, private i18nService: I18nService) {}

  ngOnInit() {
    // remove the validation from the main control
    this.group.addControl('joiningDateFrom', new UntypedFormControl(null));
    this.group.addControl('joiningDateTo', new UntypedFormControl(null));
    this.group.controls[this.key].setValidators(null);
    this.checked = true;
    this.setDatepickerLang(this.i18nService.language);
    this.languageSubscription();
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.group.controls['joiningDateFrom'].setValue(this.shared.momentFormat(this.shared.moment(dateRangeStart)));
    this.group.controls['joiningDateTo'].setValue(this.shared.momentFormat(this.shared.moment(dateRangeEnd)));
    this.group.updateValueAndValidity();
  }

  languageSubscription() {
    this.shared.sendLanguage.pipe(takeWhile(() => this.alive)).subscribe((lang: string) => {
      this.setDatepickerLang(lang);
    });
  }

  setDatepickerLang(lang: string) {
    const arLocaleData = moment.localeData('ar');

    if (lang === 'ar-SA') {
      this.locale = {
        format: 'DD-MM-YYYY',
        // displayFormat: 'MM/DD/YYYY', // default is format value
        direction: 'ltr', // could be rtl
        weekLabel: 'الإسبوع',
        separator: ' - ', // default is ' - '
        cancelLabel: 'إلغاء', // detault is 'Cancel'
        applyLabel: 'موافق', // detault is 'Apply'
        clearLabel: 'مسح', // detault is 'Clear'
        customRangeLabel: 'مخصص',
        daysOfWeek: arLocaleData.weekdaysMin(),
        monthNames: arLocaleData.monthsShort(),
        firstDay: 1, // first day is monday
      };
    } else {
      this.locale = {
        format: 'DD-MM-YYYY',
        // displayFormat: 'MM/DD/YYYY', // default is format value
        direction: 'ltr', // could be rtl
        weekLabel: 'W',
        separator: ' - ', // default is ' - '
        cancelLabel: 'Cancel', // detault is 'Cancel'
        applyLabel: 'Okay', // detault is 'Apply'
        clearLabel: 'Clear', // detault is 'Clear'
        customRangeLabel: 'Custom range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1, // first day is monday
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.appliedFilter) {
      this.ngOnInit();
    }
    // if (changes.minEntryDate) {
    //   console.log('changes.minEntryDate', changes.minEntryDate);
    // }
  }
  clearValue() {
    this.group.controls['joiningDateFrom'].reset();
    this.group.controls['joiningDateTo'].reset();
  }
  ngAfterViewInit() {
    // You should see the actual form control properties being passed in
    // should set the min and the max entryDate
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
