import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ItemProps } from '@app/interfaces';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  loading = false;
  alive = true;
  @Input() service: RootService;
  @Input() form: any;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() requiredAsterisk: any;
  @Input() field: ItemProps;
  @Input() disabledInput: boolean;
  @Input() validationErrors: any;
  @Input() name: string;
  // eslint-disable-next-line
  @Input('value') val: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() StatusChanged: EventEmitter<any> = new EventEmitter();

  // @ViewChild('picker', {static: false}) picker: any;
  min: any = null;
  max: any = null;

  constructor() {}

  get value() {
    return this.val;
  }

  set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  get formControl(): UntypedFormControl {
    return this.form ? (this.form.controls[this.field.name] as UntypedFormControl) : null;
  }

  ngOnInit() {}

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onValueChange(field: ItemProps, event: any) {
    this.valueChanged.emit({ field, event });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  clear() {
    this.value = null;
    this.service.updateInputValue.next({ fieldName: this.field.name, fieldValue: null, updateForm: true });
  }

  dateChange(event: any) {
    if (event && event.value) {
      const formattedDate = this.service.shared.momentLocaleRefactor(event.value);
      // console.log(formattedDate);
      this.value = formattedDate;
    }
  }

  /**
   * field subscription to make a subscribe to value changes of input field and
   * execute a callback function once the value of that field changed
   * @param fieldName: fieldName to subscribe to
   * @param fn: function to execute once the value has changed
   */
  fieldSubscription(fieldName: string, fn: (value: any) => void) {
    this.form.controls[fieldName].valueChanges.pipe(takeWhile(() => this.alive)).subscribe((value: any) => {
      return fn(value);
    });
  }

  /**
   * helper function to pass input names and return those inputs values
   * @param inputFields: array of input names
   */
  getValuesFromInputs(inputFields: string[]): any[] {
    return inputFields.map((inputField) => {
      return this.form.controls[inputField].value;
    });
  }

  /**
   * to set the max for the Time Picker based on conditions passed in ItemProps
   * @param field: field item props from module service
   * @param setType min | max
   */
  minMaxSetter(field: ItemProps, setType: 'min' | 'max'): void {
    if (field && field.form && field.form.dateOptions && field.form.dateOptions[setType]) {
      if (this.form) {
        if (field.form.dateOptions[setType].prop) {
          this.fieldSubscription(field.form.dateOptions[setType].prop, (value) => {
            this[setType] = value;
            // console.log([setType]', this[setType]);
          });
        } else if (field.form.dateOptions[setType].staticValue) {
          this[setType] = field.form.dateOptions[setType].staticValue;
        } else if (field.form.dateOptions[setType].fn) {
          if (field.form.dateOptions[setType].fn.inputFields && field.form.dateOptions[setType].fn.inputFields.length) {
            field.form.dateOptions[setType].fn.inputFields.forEach((inputField) => {
              this.fieldSubscription(inputField, () => {
                this[setType] = field.form.dateOptions[setType].fn.fnInputFields(
                  this.getValuesFromInputs(field.form.dateOptions[setType].fn.inputFields)
                );
                // console.log('[setType]', this[setType]);
              });
            });
          } else {
            this[setType] = field.form.dateOptions[setType].fn.fnInputFields();
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field && changes.field.currentValue && changes.form && changes.form.currentValue) {
      // console.log('ngOnChanges', changes);
      this.minMaxSetter(this.field, 'min');
      this.minMaxSetter(this.field, 'max');
      // console.log('date minMaxSetter');
      // console.log('this.field', this.field);
    }
  }
}
