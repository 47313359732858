import { Injectable } from '@angular/core';
import { RootService } from '@app/core/root.service';
import { ApiResponse, ItemProps } from '@app/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { SharedService } from '@app/shared/services/shared.service';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends RootService {
  sideNavSubject = new Subject<any>();

  constructor(
    router: Router,
    api: ApiRequestService,
    permissionsService: PermissionsService,
    shared: SharedService,
    private route: ActivatedRoute
  ) {
    super(router, api, permissionsService, shared);
    this.permissionsKey = 'admin';
  }

  formInjectDefaultValues() {
    // const userId = this.credentialsService.credentials.userId;
    const today = new Date();
    const currentDate = this.shared.moment(today).format('YYYY-MM-DD');
    // this.updateInputValue.next({ fieldName: 'reportedBy', fieldValue: userId, updateForm: true });
    // this.updateInputValue.next({ fieldName: 'reportedDate', fieldValue: currentDate, updateForm: true });
  }

  storeComponentData(componentName: string, data: any, isCreate: boolean = false, filterMethod: boolean = false) {
    this.shared.storeItems({ component: componentName, data, isCreate, filterMethod });
    return data;
  }

  listPrepareOperations(data: any) {
    if (data.data && data.data.length) {
      data.data.forEach((row: any, index: number) => {
        /**
         * only for demonstrating data till backend finish activity log api
         */
        // let sampleOdd: {
        //   id: number;
        //   seen: boolean;
        //   notification: string;
        //   module: {
        //     id: number;
        //     name: string;
        //     parent: null
        //   };
        //   createdAt: string;
        // };
        // const sample = {
        //   id: 1,
        //   notification: 'You have changed vehicle license expiry',
        //   seen: false,
        //   createdAt: '2019-12-09T13:31:27+00:00',
        //   module: {
        //     id: 1,
        //     name: 'service-reminders',
        //     parent: {}
        //   }
        // };
        // sampleOdd = {
        //   id: 1,
        //   notification: 'You have changed vehicle license expiry',
        //   seen: true,
        //   createdAt: '2019-12-09T13:31:27+00:00',
        //   module: {
        //     id: 23,
        //     name: 'issues',
        //     parent: null
        //   }
        // };
        // if (index % 2 === 0) {
        //   row.notification = sample.notification;
        //   row.module = sample.module;
        //   row.seen = sample.seen;
        // } else {
        //   row.notification = sample.notification;
        //   row.module = sample.module;
        //   row.seen = sample.seen;
        // }
        /**
         * only for demonstrating data till backend finish activity log api
         */
      });
    }
    return data;
  }

  refactorFilters(filters: any) {
    super.refactorFilters(filters);

    return filters;
  }

  routerPrefix(val: string = '') {
    return val ? val : '';
  }

  openModuleLink(moduleRoute: string, id: number, parentRoute?: string, parentId?: number, linkSuffix?: string) {
    let link: any;
    if (parentRoute) {
      link = `${parentRoute}/${parentId}/${moduleRoute}`;
    } else {
      if (moduleRoute) {
        id ? (link = `${moduleRoute}/${id}/${linkSuffix}`) : (link = `${moduleRoute}/${linkSuffix}`);
      }
    }
    if (parentRoute || moduleRoute) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([link]));
      // this.router.navigate([link]);
    }
  }

  markNotification(id: any) {
    return this.doMark(this.getFunctionURL(`${id}/status`), id);
  }

  doMark(url: string, id: any) {
    return this.api.put(url).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.marked'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        let msg = this.getNotificationById(id, this.resourcesList.data);
        msg.seen = !msg.seen;
        msg.seen ? this.resourcesList.additionalData.totalUnread-- : this.resourcesList.additionalData.totalUnread++;
        // this.updateResources.next();
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  getNotificationById(id: number, data: any[]) {
    return data.find((msg) => msg.id === id);
  }

  get featureProps(): ItemProps[] {
    return [
      {
        name: 'emptyBlock',
        prop: 'emptyBlock',
        list: {
          displayType: 'emptyBlock',
          // width: 250,
          // height: 64,
          cellCssClass: 'fill-height full-width notification-cell-container',
          additionalContentCssClass: 'd-flex',
          suffixCssClass: 'd-flex flex-column  full-width separator-pl-2',
          innerCellCssConditions: [
            {
              fnRow: (row, column) => {
                const seen = this.getValue(row, 'seen');
                if (seen) {
                  return 'read-notifications';
                } else {
                  return 'unread-notifications';
                }
              },
            },
          ],
          suffix: [
            {
              prop: 'createdAt',
              list: {
                cellCssClass: 'd-inline-flex flex-basis-100 date-style notification-date full-width flex-justify-end',
                displayType: 'date',
                dateOptions: {
                  timeAgo: true,
                  timeAgoCompareTo: 'yesterday',
                  displayDateAs: 'fullDate',
                },
              },
            },
            {
              prop: 'notification',
              list: {
                cellCssClass: 'pt-3 pb-2',
                action: {
                  fnRow: (row, column) => {
                    this.onNotificationClick(row, column);
                  },
                },
              },
            },
            {
              prop: 'seen',
              list: {
                showConditions: [
                  {
                    fnRow: (row, prop) => {
                      const seen: any = this.getValue(row, 'seen');
                      return !seen;
                    },
                  },
                ],
                cellCssClass: 'd-inline-flex flex-basis-100 full-width flex-justify-end eye-icon',
                displayType: 'icon',
                icon: {
                  name: 'eye-outline',
                  type: 'mdi',
                  size: '18px',
                  cssClass: 'eye-icon',
                },
                action: {
                  fnRow: (row, column) => {
                    let notificationId = this.getValue(row, 'id');
                    this.markNotification(notificationId);
                  },
                },
              },
            },
            {
              prop: 'seen',
              list: {
                showConditions: [
                  {
                    fnRow: (row, prop) => {
                      const seen: any = this.getValue(row, 'seen');
                      return !!seen;
                    },
                  },
                ],
                cellCssClass: 'd-inline-flex flex-basis-100 full-width flex-justify-end eye-icon',
                displayType: 'icon',
                icon: {
                  name: 'eye-off-outline',
                  type: 'mdi',
                  size: '18px',
                  cssClass: 'eye-icon',
                },
                action: {
                  fnRow: (row, column) => {
                    let notificationId = this.getValue(row, 'id');
                    this.markNotification(notificationId);
                  },
                },
              },
            },
          ],
        },
      },
    ];
  }

  markAllNotifications(seen: number) {
    return this.doMarkAllNotifications(this.getFunctionURL(`status`), seen);
  }

  doMarkAllNotifications(url: string, seen: any) {
    return this.api.put(url, seen).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.marked'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  onNotificationClick(row: any, column: any) {
    const seen = this.getValue(row, 'seen');
    const id: number = Number(this.getValue(row, 'module.id'));
    const parentId: number = Number(this.getValue(row, 'module.parent.id'));
    const moduleRouteIdentifier = this.getValue(row, 'module.name');
    const parentRouteIdentifier = this.getValue(row, 'module.parent.name');
    const externalLink = this.getValue(row, 'externalLink');
    let moduleRoute: string;
    let parentRoute: string;
    let linkSuffix: string;
    if (parentRouteIdentifier) {
      switch (parentRouteIdentifier) {
        case 'vehicles':
          parentRoute = `vehicles/dashboard`;
          break;
      }
    }
    if (externalLink) {
      const link = String(this.getValue(row, 'link'));
      window.open(link, '_blank');
    }
    switch (moduleRouteIdentifier) {
      case 'vehicles':
        moduleRoute = `vehicles/dashboard`;
        linkSuffix = 'vehicle-overview';
        break;
      case 'vehicle-assignments':
        moduleRoute = `one-vehicle-assignment`;
        linkSuffix = '';
        break;
      case 'issues':
        moduleRoute = `issues/dashboard`;
        linkSuffix = '';
        break;
      case 'service-reminders':
        moduleRoute = `service-reminders/view`;
        linkSuffix = '';
        break;
      case 'work-order':
        moduleRoute = 'work-order/dashboard';
        linkSuffix = '';
        break;
      case 'service-entry':
        moduleRoute = 'service-entry/dashboard';
        linkSuffix = '';
        break;
      case 'insurance-payment':
        moduleRoute = 'insurance-payments';
        linkSuffix = '';
        break;
      case 'purchase-installment':
        moduleRoute = 'vehicle-installments';
        linkSuffix = '';
        break;
      case 'vendor':
        moduleRoute = 'vendors/dashboard';
        linkSuffix = '';
        break;
      case 'parts':
        moduleRoute = 'parts/dashboard';
        linkSuffix = '';
        break;
      case 'users':
        moduleRoute = 'users/view';
        linkSuffix = '';
        break;
      case 'expense':
        moduleRoute = 'expenses/dashboard';
        linkSuffix = '';
        break;
      case 'report':
        moduleRoute = null;
        linkSuffix = '';
        parentRoute = null;

        break;
    }
    seen ? '' : this.markNotification(this.getValue(row, 'id'));
    this.sideNavSubject.next(true);
    this.openModuleLink(moduleRoute, id, parentRoute, parentId, linkSuffix);
    return;
  }
}
