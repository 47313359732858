<div class="filter-container search-card" [ngClass]="listOptions?.filterContainerCssClass" fxFlex fxLayout>
  <mat-card class="main-card" [perfectScrollbar]>
    <mat-card-header class="filter-card-header">
      <mat-icon class="filter-icon">tune</mat-icon>
      {{ 'common.filter' | translate }}
    </mat-card-header>
    <mat-card-content class="card-content" *ngIf="sectionedItemProps">
      <form [formGroup]="form" (ngSubmit)="filter()" *ngIf="form">
        <ng-container *ngFor="let section of sectionedItemProps | pairs; trackBy: trackByFn">
          <div class="form-section">
            <div class="form-section-title font-weight-bold" *ngIf="section[0] !== 'defaultSection'">
              {{ section[0] | translate }}
            </div>
            <div class="form-section-content flex-wrap" fxFlex="column wrap" [fxLayoutGap]="gridGapSize">
              <ng-container *ngFor="let column of section[1]">
                <ng-container *ngIf="column?.list && column?.list?.searchable && column?.list?.filterFieldType">
                  <div
                    [ngClass]="column?.list?.filterOptions?.cellCssClass"
                    [hidden]="!fieldShowConditions(column)"
                    [style.width.px]="column?.list?.width"
                    [fxFlex.gt-lg]="getInputGridSize(column?.list?.filterOptions?.grid?.gt_lg, gridGapSize)"
                    [fxFlex.lt-xl]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_xl, gridGapSize)"
                    [fxFlex.lt-lg]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_lg, gridGapSize)"
                    [fxFlex.lt-md]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_md, gridGapSize)"
                    [fxFlex.lt-sm]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_sm, gridGapSize)"
                  >
                    <app-core-form-content
                      [cid]="service.cid"
                      [customCid]="service.customCid"
                      [service]="service"
                      [field]="refactorField(column)"
                      [form]="form"
                      [isEdit]="isEdit"
                      [isClone]="isClone"
                      [isSubmitted]="isSubmitted"
                      [appliedFilter]="appliedFilter"
                      [manualRefresh]="manualRefresh"
                    >
                    </app-core-form-content>
                    <!-- <ng-container [ngSwitch]="column?.list?.filterFieldType"> -->
                    <!-- <ng-container *ngSwitchCase="null">
                        <mat-form-field>
                          <input
                            matInput
                            [formControlName]="column?.name"
                            [placeholder]="service.cid + '.' + column?.name | translate"
                          />
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.Text">
                        <mat-form-field>
                          <input
                            matInput
                            [formControlName]="column?.name"
                            [placeholder]="service.cid + '.' + column?.name | translate"
                          />
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.Number">
                        <mat-form-field>
                          <input
                            type="number"
                            matInput
                            [formControlName]="column?.name"
                            [placeholder]="service.cid + '.' + column?.name | translate"
                          />
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.Select">
                        <mat-form-field>
                          <mat-select
                            [formControlName]="column?.name"
                            [placeholder]="service.cid + '.' + column?.name | translate"
                          >
                            <mat-option
                              *ngFor="let item of service.lists[column?.list?.listPrefix]"
                              [value]="item?.id"
                              >{{ item?.name || item?.displayName }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.CheckBox">
                        <mat-checkbox
                          color="primary"
                          class="d-flex clearfix pt-2 pb-2"
                          [formControlName]="column?.name"
                          (change)="service.onChangeValue_checker(column, $event)"
                          >{{ service.cid + '.' + column?.name | translate }}
                        </mat-checkbox>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.MultiSelect">
                        <mat-form-field>
                          <mat-select
                            [formControlName]="column?.name"
                            [placeholder]="service.cid + '.' + column?.name | translate"
                            multiple
                          >
                            <mat-option *ngFor="let item of service.lists[column?.list?.listPrefix]" [value]="item?.id">
                              {{ item?.name || item?.displayName | translate }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.NgSelectMultiple">
                        <ng-select
                          class="ng-select-style1"
                          [items]="service.lists[column?.list?.listPrefix]"
                          [multiple]="true"
                          bindLabel="name"
                          bindValue="id"
                          dropdownPosition="bottom"
                          [closeOnSelect]="false"
                          [formControlName]="column?.name"
                          (change)="service.onChangeValue_checker(column, $event)"
                          placeholder="{{
                            column.list.name
                              ? (column.list.name | translate)
                              : (service.cid + '.' + column.name | translate)
                          }}"
                          appendTo="body"
                        >
                          <ng-template ng-header-tmp>
                            <div class="d-flex py-3">
                              <button
                                class="mr-2"
                                (click)="ngSelect_selectAll(form.controls[column?.name], column?.list.listPrefix, true)"
                                mat-stroked-button
                                color="primary"
                              >
                                {{ 'common.selectAll' | translate }}
                              </button>
                              <button
                                (click)="
                                  ngSelect_selectAll(form.controls[column?.name], column?.list?.listPrefix, false)
                                "
                                mat-stroked-button
                                color="warn"
                              >
                                {{ 'common.unselectAll' | translate }}
                              </button>
                            </div>
                          </ng-template>

                          <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label">{{ item?.name || item?.displayName }}</span>
                            <span class="ng-value-icon right ml-2" (click)="clear(item)">
                              <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
                            </span>
                          </ng-template>

                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                            let-search="searchTerm"
                          >
                            <div class="d-flex align-items-center multiselect-option-container">
                              <mat-checkbox
                                class="d-inline-flex dropdown-checkbox"
                                id="{{ item }}-option-{{ index }}"
                                color="primary"
                                [checked]="checkSelected(form.controls[column?.name], item?.id)"
                                (click)="preventDefault($event)"
                              >
                              </mat-checkbox>

                              <div class="d-inline-flex">
                                <ng-container *ngIf="column?.list?.filterOptions?.prefix">
                                  <ng-container *ngFor="let prefix of column?.list?.filterOptions?.prefix">
                                    <span class="ng_select_prefix" *ngIf="prefix.prop">
                                      <ng-container [ngSwitch]="prefix.displayType">
                                        <ng-container *ngSwitchCase="null">
                                          <span
                                            class="cell-display-type text{{ prefix?.cssClass }}"
                                            *ngIf="item[prefix.prop]"
                                          >
                                            {{ item[prefix.prop] }}
                                          </span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'color'">
                                          <span
                                            class="cell-display-type color {{ prefix?.cssClass }} dropdown-color"
                                            *ngIf="item[prefix.prop]"
                                            [style.background-color]="item[prefix.prop]"
                                          ></span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'img'">
                                          <span class="cell-display-type img {{ prefix?.cssClass }}">
                                            <img
                                              class="img-data"
                                              *ngIf="item[prefix.prop]"
                                              [src]="item[prefix.prop]"
                                              alt=""
                                            />
                                            <img
                                              class="img-placeholder"
                                              *ngIf="!item[prefix.prop]"
                                              src="{{
                                                prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png'
                                              }}"
                                              alt=""
                                            />
                                          </span>
                                        </ng-container>
                                      </ng-container>
                                    </span>
                                  </ng-container>
                                </ng-container>
                              </div>
                              <div class="d-inline-flex dropdown-span">
                                <span [ngOptionHighlight]="search">{{
                                  item?.name || item?.displayName | translate
                                }}</span>
                              </div>
                            </div>
                          </ng-template>
                        </ng-select>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.NgSelectApi">
                        <app-ng-select-api
                          [item]="form?.get(column?.name)?.value"
                          [service]="service"
                          [form]="form"
                          [field]="column"
                          [formControlName]="column?.name"
                          [name]="column?.name"
                          (valueChanged)="service.onChangeValue_checker(column, $event)"
                        >
                        </app-ng-select-api>
                      </ng-container>

                      <ng-container *ngSwitchCase="filterFieldTypes.NgSelect">
                        <ng-select
                          class="ng-select-style1"
                          [items]="service.lists[column?.list?.listPrefix]"
                          [multiple]="false"
                          bindLabel="name"
                          bindValue="id"
                          dropdownPosition="bottom"
                          [closeOnSelect]="true"
                          [formControlName]="column.name"
                          (change)="service.onChangeValue_checker(column, $event)"
                          placeholder="{{
                            column.list.name
                              ? (column.list.name | translate)
                              : (service.cid + '.' + column.name | translate)
                          }}"
                          appendTo="body"
                        >
                          <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label" *ngIf="!column?.list?.ngSelectOptions?.translate">{{
                              item?.name || item?.displayName
                            }}</span>
                            <span class="ng-value-label" *ngIf="column?.list?.ngSelectOptions?.translate">{{
                              item?.name || item?.displayName | translate
                            }}</span>
                            <span class="ng-value-icon right ml-2" (click)="clear(item)">
                              <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
                            </span>
                          </ng-template>

                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                            let-search="searchTerm"
                          >
                            <div class="d-flex align-items-center">
                              <div class="d-inline-flex" [ngOptionHighlight]="search">
                                {{ item?.name || item?.displayName | translate }}
                              </div>
                            </div>
                          </ng-template>
                        </ng-select>
                      </ng-container> -->

                    <!-- // todo : refactor repeated components into one single core-form-content -->
                    <!-- <ng-container *ngSwitchCase="'datepicker'">
                        <div class="field-container">
                          <mat-form-field style="width: 100%">
                            <mat-label>
                              <span>
                                {{
                                  service.cid + '.' + (column?.form?.name ? column?.form?.name : column?.name)
                                    | translate
                                }}
                              </span>
                            </mat-label>
                            <input
                              color="primary"
                              matInput
                              [matDatepicker]="picker"
                              [formControlName]="column?.name"
                              autocomplete="off"
                              (click)="picker.open()"
                              (dateChange)="dateChange($event, (form?.controls)[column?.name])"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </ng-container> -->

                    <!-- <ng-container *ngSwitchCase="'date_range'">
                        <div *ngIf="column?.list?.filterOptions">
                          <p class="date-filter-field-label">{{ service.cid + '.' + column?.name | translate }}</p>
                          <app-date-range
                            [group]="form"
                            [key]="column?.name"
                            [appliedFilter]="appliedFilter"
                            [minEntryDate]="
                              service?.resourcesList?.additionalData && column?.list?.filterOptions?.minRange?.prop
                                ? service?.getValue(
                                    service?.resourcesList?.additionalData,
                                    column?.list?.filterOptions?.minRange?.prop
                                  )
                                : column?.list?.filterOptions?.minRange?.initial
                                ? column?.list?.filterOptions?.minRange?.initial
                                : service?.shared?.moment('today')
                            "
                            [maxEntryDate]="
                              service?.resourcesList?.additionalData && column?.list?.filterOptions?.maxRange?.prop
                                ? service?.getValue(
                                    service?.resourcesList?.additionalData,
                                    column?.list?.filterOptions?.maxRange?.prop
                                  )
                                : column?.list?.filterOptions?.maxRange?.initial
                                ? column?.list?.filterOptions?.maxRange?.initial
                                : service?.shared?.moment('today')
                            "
                            [momentObj]="column?.list?.filterOptions?.momentObj"
                          >
                          </app-date-range>
                        </div>
                      </ng-container> -->

                    <!-- <ng-container *ngSwitchCase="'range_slider'">
                        <div *ngIf="column?.list?.filterOptions && service?.resourcesList?.additionalData">
                          <p>{{ service.cid + '.' + column?.name | translate }}</p>
                          <app-slider-range
                            [floor]="
                              service?.getValue(
                                service?.resourcesList?.additionalData,
                                column?.list?.filterOptions?.minRange?.prop
                              )
                            "
                            [ceil]="
                              service?.getValue(
                                service?.resourcesList?.additionalData,
                                column?.list?.filterOptions?.maxRange?.prop
                              )
                            "
                            [appliedFilter]="appliedFilter"
                            [step]="column?.list?.filterOptions?.stepRange"
                            [prefixUnit]="
                              'common.unit_types.' + (column?.list?.filterOptions?.prefixUnit | lowercase) | translate
                            "
                            [form]="form"
                            [key]="column?.name"
                            [manualRefresh]="manualRefresh"
                          >
                          </app-slider-range>
                        </div>
                      </ng-container> -->
                    <!-- </ng-container> -->
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <button *ngIf="columns" [disabled]="!form.valid" type="submit" class="submit_button apply" mat-button>
          {{ 'common.apply' | translate }}
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
