import { Component, Input, OnChanges, OnDestroy, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ItemProps, ListOptions, Row } from '@app/interfaces';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { GroupByPipe } from 'ngx-pipes';
import { FormControl } from '@angular/forms';
import { SharedService } from '@app/shared/services/shared.service';
import { I18nService } from '@app/core/i18n.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@app/shared/components/core-form-content/core-form-content.component';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'app-filters-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FiltersButtonComponent implements OnChanges, OnDestroy, OnInit {
  form: UntypedFormGroup;
  alive = true;
  ItemProp: ItemProps;
  sectionedItemProps: any = {};
  @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;
  @ViewChild(MdePopoverTrigger, { static: true }) triggerClose: MdePopoverTrigger;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  appliedChanges = {
    prop: '',
    flag: false,
  };
  appliedFilter = false;
  openFilter = false;

  /**
   * inputsVisibility determines visibility of inputs in each form and will be updated
   * based on each condition from fieldShowConditions function that is executed based on
   * featureProps showConditions
   */
  inputsVisibility: {
    key: string;
    visible?: boolean;
  }[] = [];

  @Input() columns: ItemProps[];
  @Input() service: RootService;
  @Input() listOptions: ListOptions;

  constructor(
    private fb: UntypedFormBuilder,
    private us: UtilitiesService,
    public groupByPipe: GroupByPipe,
    private sharedService: SharedService,
    private i18nService: I18nService,
    private _adapter: DateAdapter<any>
  ) {}

  ngOnChanges(): void {
    if (this.columns) {
      this.createForm();
    }
  }

  /**
   * Identify the Form fields in each form controller
   */
  get formFields(): any {
    let formFields = {};
    for (const field of this.columns) {
      if (field.list && field.list.searchable) {
        // check if range_slider and assign in rangeOptions

        formFields = {
          ...formFields,
          [field.name]: [
            field.list.filterOptions && field.list.filterOptions.initValue ? field.list.filterOptions.initValue : null,
          ],
        };
      }
      this.inputsVisibility.push({
        key: field.name,
        visible: true,
      });
    }

    // console.log(formFields);
    return formFields;
  }

  createForm() {
    this.form = this.fb.group(this.formFields);
    this.groupItemsByGroup();
  }

  // manual render for the range slider
  refreshRangeSlider() {
    this.sharedService.filterOpen$.subscribe(() => {
      setTimeout(() => {
        this.manualRefresh.emit();
      }, 300);
    });
  }

  // this.rotationForm.get('comments').value
  changeMinRange(key: string, value: number, limit: number) {
    const maxValue = this.form.get(key).value[1];

    if (maxValue >= value && value >= limit) {
      this.form.controls[key].setValue([value, maxValue]);
    }
  }

  changeMaxRange(key: string, value: number, limit: number) {
    const minValue = this.form.get(key).value[0];

    if (value >= minValue && value <= limit) {
      this.form.controls[key].setValue([minValue, value]);
    }
  }

  filter() {
    this.trigger.togglePopover();
    this.appliedFilter = false;
    this.service.filterData = this.form.value;
    this.us.setFilters(this.refactorFilters(this.form.value));
    this.hidePopup();
  }
  resetFilter() {
    const initialValues = {};
    this.service.featureProps
      .filter((x) => x?.list?.filterOptions?.initValue)
      .forEach((x) => {
        initialValues[x?.name] = x?.list?.filterOptions?.initValue;
      });
    // this.trigger.togglePopover();
    this.form.reset(initialValues);
  }
  refactorFilters(formValue: any) {
    return this.service.refactorFilters(formValue);
  }
  getInputGridSize(inputSize: string, gap: string) {
    if (inputSize) {
      if (gap) {
        return `0 1 calc(${inputSize} - ${gap})`;
      } else {
        return `0 1 ${inputSize}`;
      }
    } else {
      if (gap) {
        return `0 1 calc(100% - ${gap})`;
      } else {
        return `0 1 100%`;
      }
    }
  }

  refactorField(field: ItemProps): ItemProps {
    return {
      name: field.name,
      prop: field.prop,
      form: {
        formFieldType: field.list.filterFieldType,
        listPrefix: field.list.listPrefix,
        dataUrl: field.list.dataUrl,
        ngSelectOptions: {
          prefix: field?.list?.filterOptions?.prefix ? field.list.filterOptions.prefix : [],
        },
      },
      list: {
        filterOptions: field.list.filterOptions,
        ngSelectOptions: field.list.ngSelectOptions,
      },
    };
  }

  dateChange(event: any, control: AbstractControl) {
    if (event && event.value) {
      const formattedDate = this.service.shared.momentLocaleRefactor(event.value);
      // console.log(formattedDate);
      control.setValue(formattedDate);
      control.updateValueAndValidity();
    }
  }

  ngSelect_selectAll(control: AbstractControl, listPrefix: string, selectAll: boolean) {
    if (control && this.service.lists[listPrefix]) {
      const selected: number[] = [];
      if (selectAll) {
        Object.values(this.service.lists[listPrefix]).forEach((item: { id: number }) => {
          selected.push(item.id);
        });
      }
      control.setValue(selected);
    }
  }

  checkSelected(control: AbstractControl, id: number): boolean {
    if (control && id && control.value) {
      if (control.value.find((x: number) => x === id)) {
        return true;
      }
    }
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  clearInputSubscription() {
    this.service.clearInput.pipe(takeWhile(() => this.alive)).subscribe((input: any) => {
      this.form.controls[input].setValue(null);
    });
    this.appliedFilter = false;
  }

  groupItemsByGroup() {
    let tabContent: any = [];
    if (this.columns) {
      tabContent = this.groupByPipe.transform(this.columns, 'list.groupBy.section');
    } else {
      tabContent = this.groupByPipe.transform(this.service.featureProps, 'list.groupBy.section');
    }
    Object.entries(tabContent).forEach((section) => {
      let sectionName = section[0];
      if (sectionName === 'undefined') {
        sectionName = 'defaultSection';
      }
      this.sectionedItemProps[sectionName] = section[1];
    });
  }

  ngOnDestroy(): void {
    this.appliedFilter = false;
    this.alive = false;
  }

  ngOnInit(): void {
    this.clearInputSubscription();
    this.refreshRangeSlider();
    this.updateFormFieldSubscription();
    this.setDatepickerLang(this.i18nService.language);
    this.languageSubscription();
  }

  languageSubscription() {
    this.sharedService.sendLanguage.pipe(takeWhile(() => this.alive)).subscribe((lang: string) => {
      this.setDatepickerLang(lang);
    });
  }

  setDatepickerLang(dateLang: string) {
    dateLang = this.i18nService.language;
    this._adapter.setLocale(dateLang);
  }

  // ngAfterViewInit() {

  // }

  trackByFn() {
    return;
  }

  /**
   * alternative to normal show and hide to takes function that's passed from ItemProps
   * and parameters abstract control input to show and hide inputs based on a boolean
   * return from this function and also remove it's validations when hiding and add it back when showing the input
   * @param field : ItemProps form field passed
   */
  fieldShowConditions(field: ItemProps) {
    if (field.list.filterOptions && field.list.filterOptions.showConditions) {
      let trueCount = 0;
      if (field.list.filterOptions.showConditions && field.list.filterOptions.showConditions.length) {
        field.list.filterOptions.showConditions.forEach((condition: any) => {
          if (condition.fnProp) {
            if (condition.fnProp.fn) {
              if (condition.fnProp.fn(condition.fnProp.prop ? this.form.controls[condition.fnProp.prop].value : null)) {
                trueCount++;
              }
            }
          }
        });
      }

      if (trueCount === 0) {
        if (this.inputsVisibility.find((x) => x.key === field.name).visible) {
          this.inputsVisibility.find((x) => x.key === field.name).visible = false;
        }
        return false;
      } else if (trueCount === 1) {
        if (!this.inputsVisibility.find((x) => x.key === field.name).visible) {
          this.inputsVisibility.find((x) => x.key === field.name).visible = true;
        }
        return true;
      }
    } else {
      return true;
    }
  }

  UpdateFormFieldValue(event: { fieldProps?: ItemProps; fieldName?: string; fieldValue?: any; updateForm?: boolean }) {
    // console.log(event);
    const key =
      event && event.fieldProps && event.fieldProps.name
        ? event.fieldProps.name
        : event.fieldName
        ? event.fieldName
        : null;
    const value = event.fieldValue;
    if (key) {
      this.form.get(key).patchValue(value);
      if (event.updateForm) {
        this.form.markAsDirty();
      }
    }
  }
  showPopup() {
    this.openFilter = true;
  }
  hidePopup() {
    this.openFilter = false;
  }
  updateFormFieldSubscription() {
    this.service.updateInputValue
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { fieldProps?: ItemProps; fieldName?: string; fieldValue?: any; updateForm?: boolean }) => {
        this.UpdateFormFieldValue(event);
      });
  }
}
