<!-- Active/In-active clients/Assets -->
<div class="full-width">
  <app-summary></app-summary>

  <!-- Active clients/Assets Bar Charts -->
  <!-- <div class="bars-chart-container">
    <app-bar-chart-clients></app-bar-chart-clients>
    <app-bar-chart-assets></app-bar-chart-assets>
  </div> -->

  <!-- clients Mini-list -->
  <!-- <app-clients-list-mini></app-clients-list-mini> -->

  <!-- Active clients/Assets Line Chart -->
  <app-line-chart-clients></app-line-chart-clients>
</div>
