import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, pairwise, startWith, take, takeWhile } from 'rxjs/operators';
import { AppHelper } from '@app/core/classes/app-helper';
import { AppConfig } from '@app/core/classes/app-config';
import { RootService } from '@app/core/root.service';
import { environment } from '@env/environment';
import { transition, trigger, useAnimation } from '@angular/animations';
import { HideAnimation, showAnimation } from '@app/shared/animations/transform-opacity';
import { ApiResponse, AppendedItemObj, FormTourStep, ItemProps } from '@app/interfaces';
import { GroupByPipe, PairsPipe } from 'ngx-pipes';
import { SharedCoreFormService } from '@app/shared/services/shared-core-form.service';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// eslint-disable-next-line no-duplicate-imports
import * as _moment from 'moment';
import { defaultFormat as _rollupMoment } from 'moment';
import { TourService } from 'ngx-ui-tour-md-menu';
import { CoreFormComponent as BaseCoreFormComponent } from '@afaqyit/frontend-core';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-core-form',
  templateUrl: './core-form.component.html',
  styleUrls: ['./core-form.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '200ms 200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
    ]),
  ],
  providers: [GroupByPipe],
})
export class CoreFormComponent extends BaseCoreFormComponent implements OnInit, OnDestroy, AfterViewInit {
  showBreadcrumb = true;
  headerSmallSize = false;
  gridGapSize = '30px';
  cid: string;
  form: UntypedFormGroup;
  isEdit = false;
  itemId: number;
  item: any;
  config = AppConfig;
  helper = AppHelper;
  isSubmitted = false;
  isClone = false;
  isSaveAddNew = false;
  environment = environment;
  imageFieldName: string;
  previewImage: string;
  fileFields: string[] = [];
  alive = true;
  formTouched: boolean;
  /**
   * inputsVisibility determines visibility of inputs in each form and will be updated
   * based on each condition from fieldShowConditions function that is executed based on
   * featureProps showConditions
   */
  inputsVisibility: {
    key: string;
    visible?: boolean;
  }[] = [];
  formInjectValues$: Subject<any> = new Subject();
  asyncAfterViewInit: boolean;
  tourSteps: FormTourStep[] = [];
  fields = {};
  selectedIndex = 0;
  tabGroupsCount: number;

  // Form Grid Configuration
  gridColumns: string = ''; // must be followed by 'fr' unit,'fr' is similar to '%' but not adding space gap for the last item. Ex : '1fr 1fr' ,
  horizontalSpace: string = ''; // between fields.
  verticalSpace: string = '';

  constructor(
    public service: RootService,
    protected fb: UntypedFormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    public groupByPipe: GroupByPipe,
    public sharedCoreFormService: SharedCoreFormService, // public changeDetectorRef: ChangeDetectorRef
    protected tourService?: TourService
  ) {
    // Please fill the super() call by the needed parameters
    super(service, fb, router, activatedRoute, groupByPipe);
    this.cid = service.cid;
  }

  /**
   * Identify the Form fields in each form controller
   */
  get formFields(): any {
    return this.mapFields(this.service.featureProps);
  }

  get Form() {
    return this.form as UntypedFormGroup;
  }

  ngOnInit(): void {
    // this.fillLists();
    this.service.loadSelectLists('form');

    this.checkFormType();
    this.groupItemsByGroup();
    this.createForm();

    this.loadResourcesSubscription();
    this.updateFormFieldSubscription();
    /**
     * check for route data flag
     */
    this.activatedRoute.data.pipe(takeWhile(() => this.alive)).subscribe((routeData) => {
      if (routeData.isClone) {
        this.isClone = true;
      } else if (routeData.isEdit) {
        this.isEdit = true;
        // this.previewImage = this.form.controls.photo ? this.form.controls.photo.value : null;
        // this.form.removeControl('photo');
      }
    });

    this.checkPasswords();
    this.clearInputSubscription();
    // this.service.setPermissionsModuleKey();
    this.permissionsUpdateSubscription();
    this.overrideTourServiceShowStepFunc();
    this.formTutorialSubscription();
  }

  loadResourcesSubscription() {
    /**
     * Check if path contain ID
     * return values from item id values
     */
    this.activatedRoute.params.pipe(takeWhile(() => this.alive)).subscribe((routeInfo: any) => {
      if (routeInfo.id) {
        this.loadResources(routeInfo.id);
      }
    });
  }

  createArrayItem(formArray: ItemProps[], name: string) {
    const fields = this.mapFields(formArray);
    // console.log(fields);
    return this.fb.group(fields);
  }

  addArrayItem(formArray: ItemProps[], name: string): void {
    const items = this.form.get(name) as UntypedFormArray;
    // console.log(name);
    items.push(this.createArrayItem(formArray, name));
  }

  removeArrayItem(name: string, index: number) {
    const items = this.form.get(name) as UntypedFormArray;
    // console.log(name);
    items.removeAt(index);
  }

  /**
   * Pick color
   * @param field: field name
   * @param color: HEX color
   */
  setColor(field: string, color: string) {
    this.form.controls[field].setValue(color);
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  getArrayForm(name: string): UntypedFormArray {
    const field = this.form.get(name) as UntypedFormArray;
    return field;
  }

  getArrayFormControls(name: string) {
    const field = this.form.get(name) as UntypedFormArray;
    return field.controls;
  }

  getFormArrayGroup(name: string, group: any) {
    if (!name || !group) {
      return this.form;
    }
    return this.getArrayFormControls(name)[group.id];
  }

  uploadPhoto(event: any, field: any, image?: any) {
    if (this.isEdit) {
      this.uploadNewPhoto(field, image);
      // const photoControl = this.fb.control(null, []);
      // this.form.addControl('photo', photoControl);
    }
    if (field.form.inputValueType === 'base64_img') {
      this.imageFieldName = field.name;
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = this.convertImage.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (field.form.inputValueType === 'formData') {
      const file = event.target.files[0];
      this.readURL(file);
      this.form.controls[field.name].setValue(file);
    }
  }

  uploadNewPhoto(field: any, image: any) {
    const formV = {};
    formV[field.name] = image;
    this.service
      .doCreate(`${this.cid}/${this.itemId}/${field.name}`, this.toFormData(formV))
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {});

    //   .then(() => {
    //     this.service.updateResources.next();
    //   });
  }

  checkFormType() {
    this.activatedRoute.data.pipe(takeWhile(() => this.alive)).subscribe((routeData) => {
      if (routeData.isClone) {
        this.isClone = true;
      } else if (routeData.isEdit) {
        this.isEdit = true;
      }
    });
  }

  enableControl(control: any) {
    control.enable();
  }

  /**
   * Preview Image
   * @param input: Input
   */
  readURL(input: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewImage = e.target.result;
    };
    reader.readAsDataURL(input);
  }

  convertImage(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.form.controls[this.imageFieldName].setValue(btoa(binaryString));
  }

  /**
   * fetch single item data from service
   * and fill form with it in Edit forms
   */
  loadResources(id: any): void {
    this.itemId = id;
    this.service
      .showItem(id)
      // .pipe(takeWhile(() => this.alive))
      .pipe(
        map((item) => {
          // this.item = item;
          return this.refactorItem(item);
        })
      )
      .subscribe(
        (response) => {
          const obj = JSON.parse(JSON.stringify(response)); // clone response object
          this.formInjectValues(obj);
          this.formInjectValues$.next(obj);
          // this.removeFileInputs();
        },
        (err) => {
          this.service.errorHandle(err);
        }
      );
  }

  /**
   * Do operation on response before subscription
   * @param item: Response
   */
  refactorItem(item: any): any {
    if (this.isClone && item.name) {
      item.name += ' - copy';
    }
    this.item = item;
    return item;
  }

  trackByFn() {
    return;
  }

  formInjectValues(obj: any) {
    this.form.patchValue(obj);
    this.form.markAsPristine();
  }

  /**
   * Create the form controls
   * param => formFields fills from each inheritance component
   */
  createForm(): void {
    this.form = this.fb.group(this.formFields);
  }

  // /**
  //  * Load necessary lists to fill specific fields
  //  */
  // getLists(field: any): Subscription {
  //   return this.service.getLists(field);
  // }

  // removeFileInputs(): void {
  //   if (this.fileFields.length) {
  //     this.fileFields.forEach(x => {
  //       this.form.removeControl(x);
  //     });
  //   }
  // }

  // /**
  //  * get data and fill & cache it in service
  //  */
  // fillLists(): void {
  //   for (const item of this.lists) {
  //     for (const key of Object.keys(item)) {
  //       this.getLists(key);
  //     }
  //   }
  // }

  /**
   * submit form data to createItem method in root service
   */
  prepareFormAfterSubmit(): void {
    this.refactorColorPicker(); // generic sending the hex value instead of the full color object.
    return;
  }

  refactorFormValue() {
    for (const field of Object.keys(this.form.value)) {
      const xFields = this.service.featureProps.filter((x) => x.name === field);
      xFields.forEach((xField) => {
        if (xField.form && xField.form.formFieldType) {
          const fieldType = xField.form.formFieldType;

          if (fieldType === 'datepicker') {
            console.log(xField.name, this.form.value[xField.name]);
            if (this.form.value[xField.name]) {
              this.form.value[xField.name] = this.convertDate(this.form.value[xField.name]);
            }
          }
          /**
           * clears value of input image from form submitted value if user clicks
           * open select then cancels image selection while there is already an image
           * previously selected and patched into form values
           */
          if (fieldType === 'img') {
            if (typeof this.form.value[xField.name] === 'string') {
              delete this.form.value[xField.name];
            }
          }

          if (
            this.form.value[xField.name] === '' ||
            this.form.value[xField.name] === null ||
            this.form.value[xField.name] === undefined ||
            this.form.value[xField.name] === [] ||
            this.form.value[xField.name] === [''] ||
            JSON.stringify(this.form.value[xField.name]) === '[]' ||
            this.form.value[xField.name] === 'Invalid date' ||
            JSON.stringify(this.form.value[xField.name]) === 'Invalid date'
          ) {
            if (
              this.isEdit &&
              (fieldType === 'img' || fieldType === 'file_upload_details' || fieldType === 'img_upload_details')
            ) {
              this.form.value[xField.name] = '';
            } else if (this.isEdit) {
              if (fieldType === 'checkbox') {
                delete this.form.value[xField.name];
              } else {
                this.form.value[xField.name] = null;
              }
            } else {
              // console.log(this.form.value[field]);
              if (!this.isEdit) {
                delete this.form.value[xField.name];
              }
            }
          }
        }
      });
    }
    this.service.refactorFormBeforeSubmit(this.form.value);
  }

  convertDate(date: any) {
    const convertedDate = this.service.shared.momentFormat(date);
    return convertedDate;
  }

  /**
   * Post form data to API
   */
  formSubmission(saveAddNew?: boolean) {
    this.isSubmitted = true;
    this.prepareFormAfterSubmit();

    let updateAsPost = false;
    let jsonForm = true;

    for (const field of this.service.featureProps) {
      if (
        field.form &&
        (field.form.formFieldType === 'img' ||
          field.form.formFieldType === 'img_cropper' ||
          field.form.formFieldType === 'file' ||
          field.form.formFieldType === 'file_upload_details' ||
          field.form.formFieldType === 'img_upload_details')
      ) {
        updateAsPost = true;
      }
      if (
        field.form &&
        (field.form.formFieldType === 'img' ||
          field.form.formFieldType === 'img_cropper' ||
          field.form.formFieldType === 'file' ||
          field.form.formFieldType === 'file_upload_details' ||
          field.form.formFieldType === 'img_upload_details')
      ) {
        jsonForm = false;
      }
    }

    this.refactorFormValue();

    if (this.isEdit) {
      if (updateAsPost) {
        return this.service
          .updateItemAsPost(this.itemId, this.toFormData(this.form.value, 'PUT'))
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (resp: ApiResponse) => {
              this.service.updateResources.next(true);
              this.service.shared.toastr.success(
                // this.service.shared.translate.instant(this.cid + '.updated'),
                resp.message,
                this.service.shared.translate.instant('common.success'),
                { closeButton: true }
              );
              if (saveAddNew) {
                this.form.reset();
                this.isSubmitted = false;
              } else {
                return this.navigateToList();
              }
            },
            (error: any) => {
              if (error.status === 413) {
                this.service.shared.toastr.error(
                  error.message,
                  this.service.shared.translate.instant('error_codes.413'),
                  { closeButton: true }
                );
              }
              /**
               * handling server side validation errors
               */
              if (error && error.error && error.error.errors) {
                this.ServerValidationErrors(error.error.errors);
              }
            }
          );
      } else {
        return this.service
          .updateItem(this.itemId, this.form.value)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (resp: ApiResponse) => {
              this.service.updateResources.next(true);
              this.service.shared.toastr.success(
                // this.service.shared.translate.instant(this.cid + '.updated'),
                resp.message,
                this.service.shared.translate.instant('common.success'),
                { closeButton: true }
              );
              if (saveAddNew) {
                this.form.reset();
                this.isSubmitted = false;
              } else {
                return this.navigateToList();
              }
            },
            (error: any) => {
              if (error.status === 413) {
                this.service.shared.toastr.error(
                  error.message,
                  this.service.shared.translate.instant('error_codes.413'),
                  { closeButton: true }
                );
              }
              /**
               * handling server side validation errors
               */
              if (error && error.error && error.error.errors) {
                this.ServerValidationErrors(error.error.errors);
              }
            }
          );
      }
    } else {
      this.service
        .createItem(this.toFormData(this.form.value, null, jsonForm))
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (resp: ApiResponse) => {
            if (resp.data && resp.data.id) {
              this.actionOnCreatedItem(resp.data.id);
            }
            if (this.service.updateOnCreate) {
              this.service.updateResources.next(true);
            }
            if (this.service.appendOnCreate) {
              this.service.appendResources$.next(true);
            }
            this.service.shared.toastr.success(
              // this.service.shared.translate.instant(this.cid + '.created'),
              resp.message,
              this.service.shared.translate.instant('common.success'),
              { closeButton: true }
            );
            if (saveAddNew) {
              this.form.reset();
              this.isSubmitted = false;
            } else {
              return this.navigateToList();
            }
          },
          (error) => {
            if (error) {
              /**
               * payload error
               */
              if (error.status === 413) {
                this.service.shared.toastr.error(
                  error.message,
                  this.service.shared.translate.instant('error_codes.413'),
                  { closeButton: true }
                );
              }

              /**
               * timeout error
               */
              if (error && error.name === 'TimeoutError') {
                this.service.shared.toastr.warning(
                  error.message,
                  this.service.shared.translate.instant('error_codes.TimeoutError'),
                  { closeButton: true }
                );
              }
              /**
               * handling server side validation errors
               */
              if (error.error && error.error.errors) {
                this.ServerValidationErrors(error.error.errors);
              }
            }
          }
        );
    }
  }

  /**
   * add server side validation errors into the errors array in each form control inside key serverError
   * @param errors => taken from fail response 422
   */
  ServerValidationErrors(errors: any) {
    if (!errors) {
      return;
    }
    Object.keys(errors).forEach((prop) => {
      const formControl = this.form.get(prop);
      if (formControl) {
        // activate the error message
        formControl.setErrors({
          serverError: errors[prop],
        });
        formControl.markAsTouched();
      }
    });
  }

  toFormData<T>(formValue: T, _method?: string, jsonForm?: boolean) {
    // console.log(formValue);
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      /**
       * fix for boolean values in formData method
       */
      if (value === true) {
        formData.append(key, '1');
      } else if (value === false) {
        formData.append(key, '0');
      } else {
        formData.append(key, value);
      }
      // console.log(formData);

      const xFields = this.service.featureProps.filter((x) => x.name === key);
      xFields.forEach((xField) => {
        if (xField.form && xField.form.formFieldType) {
          const fieldType = xField.form.formFieldType;

          if (fieldType === 'ng_select_multiple') {
            if (value && value.length) {
              const valueArray = value;
              formData.delete(key);
              valueArray.forEach((id: any) => {
                formData.append(xField.name + '[]', id);
              });
            } else {
              if (this.isEdit) {
                formData.delete(key);
                formData.append(xField.name, '');
              } else {
                formData.append(xField.name + '[]', '');
                formData.delete(key);
              }
            }
          }

          if (formValue[key] === null) {
            formData.delete(key);
            formData.append(key, '');
          }
        }
      });
    }

    if (_method) {
      formData.append('_method', _method);
    }
    if (jsonForm) {
      return formValue;
    } else {
      return formData;
    }
  }

  /**
   * alternative to normal show and hide to takes function that's passed from ItemProps
   * and parameters abstract control input to show and hide inputs based on a boolean
   * return from this function and also remove it's validations when hiding and add it back when showing the input
   * @param field : ItemProps form field passed
   */
  fieldShowConditions(field: ItemProps) {
    if (field.form && field.form.showConditions) {
      const validators = field.form.Validators;
      let trueCount = 0;
      if (field.form.showConditions && field.form.showConditions.length) {
        field.form.showConditions.forEach((condition: any) => {
          if (condition.fnProp) {
            if (condition.fnProp.fn) {
              if (
                condition.fnProp.fn(condition.fnProp.prop ? this.form.controls[condition.fnProp.prop]?.value : null)
              ) {
                trueCount++;
              }
            }
          }
        });
      }

      if (trueCount === 0) {
        if (this.inputsVisibility.find((x) => x.key === field.name).visible) {
          this.inputsVisibility.find((x) => x.key === field.name).visible = false;
          this.form.get(field.name).clearValidators();
          this.form.get(field.name).updateValueAndValidity();
        }
        return false;
      } else if (trueCount === 1) {
        if (!this.inputsVisibility.find((x) => x.key === field.name).visible) {
          this.inputsVisibility.find((x) => x.key === field.name).visible = true;

          // @ts-ignore ignore type of validators set
          this.form.get(field.name).setValidators(validators);
          this.form.get(field.name).updateValueAndValidity();
        }
        return true;
      }
    } else {
      return true;
    }
  }

  // validationErrors(errors: any) {
  //   Object.keys(errors).forEach(prop => {
  //     const formControl = this.form.get(prop);
  //     if (formControl) {
  //       // activate the error message
  //       formControl.setErrors({
  //         serverError: errors[prop]
  //       });
  //     }
  //   });
  // }

  checkPasswords() {
    // here we have the 'passwords' group
    if (this.form.controls.password && this.form.controls.password_confirmation) {
      this.form.controls.password_confirmation.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        this.comparePasswords();
      });
      this.form.controls.password.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        this.comparePasswords();
      });
    }
  }

  comparePasswords() {
    const pass = this.form.controls.password.value;
    const password_confirmation = this.form.controls.password_confirmation.value;
    if (pass === password_confirmation) {
      this.form.controls.password_confirmation.setErrors(null);
    } else {
      this.form.controls.password_confirmation.setErrors({ passwordsDontMatch: true });
    }
  }

  checkValidations(key: any) {
    for (const field of this.service.featureProps) {
      if (field.form) {
        if (field.form.Validators) {
          // console.log(field.form.Validators);
          if (field.form.Validators.find((x) => x === key)) {
            return true;
          }
        }
      }
    }
  }

  checkImgSrc(field: any) {
    if (!!this.form.controls[field.name].valueChanges) {
      if (!this.previewImage) {
        if (!this.form.controls[field.name].value) {
          return field.form.placeHolder;
        } else {
          return this.form.controls[field.name].value;
        }
      } else {
        return this.previewImage;
      }
    }
  }

  ngOnDestroy(): void {
    this.service.clearActiveTabs();
    this.alive = false;
  }

  checkRequiredFields(controlName: string) {
    if (!this.isEdit) {
      const abstractControl = this.form.controls[controlName];
      if (!abstractControl) {
        return false;
      }
      if (abstractControl.validator) {
        const validator = abstractControl.validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      }
      return false;
    }
  }

  focusInputById(id: any) {
    document.getElementById(id).focus();
  }

  cancelFormSubmission() {
    if (this.form.dirty) {
      this.service.openActionDialog({
        service: this.service,
        action: 'cancel',
        title: 'common.confirmCancelForm.title',
        message: 'common.confirmCancelForm.message',
        additionalMessage: 'common.confirmCancelForm.additionalMessage',
        submitText: 'common.confirmCancelForm.submitText',
        cancelText: 'common.confirmCancelForm.cancelText',
        data: null,
        submitCssClass: 'bg-confirm',
      });
    } else {
      // this.service.navigateToList();
      this.service.goBack();
    }
  }

  groupItemsByGroup() {
    // only Group form props. (this.service.featureProps.filter(p => p.form))
    const tabGroups = this.groupByPipe.transform(
      this.service.featureProps.filter((p) => p.form),
      'form.groupBy.tabGroup.tabGroupName'
    );

    Object.entries(tabGroups).forEach((group) => {
      let tabGroupName = group[0];
      if (tabGroupName === 'undefined') {
        tabGroupName = 'defaultGroup';
      }
      this.service.formInputsCategorized[tabGroupName] = {};

      const tabs = this.groupByPipe.transform(group[1], 'form.groupBy.tabGroup.tabName');
      Object.entries(tabs).forEach((tab) => {
        let tabName = tab[0];
        if (tabName === 'undefined') {
          tabName = 'defaultTab';
        }
        this.service.formInputsCategorized[tabGroupName][tabName] = {};

        const tabContent = this.groupByPipe.transform(tab[1], 'form.groupBy.section');
        Object.entries(tabContent).forEach((section) => {
          let sectionName = section[0];
          if (sectionName === 'undefined') {
            sectionName = 'defaultSection';
          }
          this.service.formInputsCategorized[tabGroupName][tabName][sectionName] = section[1];
        });
      });
    });

    this.countTabs();
  }

  showHideChanges(data: any) {
    if (data.field && data.validators) {
      // console.log(data.validators);

      this.form.get(data.field).setValidators(data.validators);

      // this.form.get(data.field).updateValueAndValidity();
      this.form.updateValueAndValidity();
      // this.changeDetectorRef.detectChanges();

      // console.log(this.form);
      // this.changeDetectorRef.detectChanges();
    }
  }

  clearInputSubscription() {
    this.service.clearInput.pipe(takeWhile(() => this.alive)).subscribe((input: any) => {
      this.form.controls[input].setValue(null);
    });
  }

  loadDummyData() {
    this.form.patchValue(this.refactorItem(this.service.returnDummyData(this.cid + '_form')));
  }

  UpdateFormFieldValue(event: { fieldProps?: ItemProps; fieldName?: string; fieldValue?: any; updateForm?: boolean }) {
    // console.log(event);
    const key =
      event && event.fieldProps && event.fieldProps.name
        ? event.fieldProps.name
        : event.fieldName
        ? event.fieldName
        : null;
    const value = event.fieldValue;
    if (key) {
      if (event.updateForm) {
        this.form.get(key).patchValue(value);
        setTimeout(() => {
          this.form.markAsDirty();
        }, 1000);
      } else {
        const prevFormPristineState = this.form.pristine;
        if (prevFormPristineState) {
          // console.log(this.form.pristine);
          this.form.get(key).patchValue(value, { emitEvent: false, onlySelf: true });
          /**
           * work around when not using timeout the form doesn't get the expected behaviour of resetting into pristine
           * if it was pristine before patching the value of that key
           */
          setTimeout(() => {
            this.form.markAsPristine();
          }, 1000);
        } else {
          this.form.get(key).patchValue(value);
        }
      }
    }
  }

  triggerInputChangeDetection(event: { fieldName?: string; fieldValue?: any }): void {
    if (event) {
      console.log('triggerInputChangeDetection', event);
    }
  }

  updateFormFieldSubscription() {
    this.service.updateInputValue
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { fieldProps?: ItemProps; fieldName?: string; fieldValue?: any; updateForm?: boolean }) => {
        this.UpdateFormFieldValue(event);
      });
  }

  inputChangeDetectionSubscription() {
    this.service.inputChangeDetection
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { fieldName?: string; fieldValue?: any }) => {
        this.triggerInputChangeDetection(event);
      });
  }

  actionOnCreatedItem(id: number) {
    return this.service.actionOnCreatedItem(id);
  }

  navigateToList() {
    this.service.navigateToList();
  }

  getInputGridSize(inputSize: string, gap: string) {
    if (inputSize) {
      if (gap) {
        return `0 1 calc(${inputSize} - ${gap})`;
      } else {
        return `0 1 ${inputSize}`;
      }
    } else {
      if (gap) {
        return `0 1 calc(100% - ${gap})`;
      } else {
        return `0 1 100%`;
      }
    }
  }

  sectionErrorsCount(tab: any) {
    let errorsCount = 0;
    Object.values(tab).forEach((section) => {
      Object.values(section).forEach((field: ItemProps) => {
        if (
          this.form.controls[field.name]?.errors &&
          (this.form.dirty || this.form.invalid) &&
          (this.form.touched || this.isSubmitted)
        ) {
          errorsCount++;
        }
      });
    });

    return errorsCount;
  }

  groupTabPrevErrors(group: any, tabIndex: number): number {
    let errorsCount = 0;
    let enableSteps: boolean;
    Object.values(group).forEach((tab, index) => {
      // if (index < tabIndex) {
      Object.values(tab).forEach((section) => {
        if (
          Object.values(section).some((field: ItemProps) => {
            if (field.form.groupBy.tabGroup.steps) {
              return true;
            }
          })
        ) {
          enableSteps = true;
          // if (!this.formTouched) {
          //   this.formTouched = true;
          //   console.log(this.form.controls);
          //   Object.keys(this.form.controls).forEach(key => {
          //     this.form.controls[key].markAsTouched();
          //   });
          //   // this.form.markAsDirty();
          //   // console.log(this.form)
          // }
        }
      });
      // }
    });
    if (enableSteps) {
      Object.values(group).forEach((tab, index) => {
        if (index < tabIndex) {
          Object.values(tab).forEach((section) => {
            Object.values(section).forEach((field: ItemProps) => {
              if (
                this.form.controls[field.name].errors
                // &&
                // (this.form.dirty || this.form.invalid) &&
                // (this.form.touched || this.isSubmitted)
              ) {
                errorsCount++;
              }
            });
          });
        }
      });
    }
    return errorsCount;
  }

  getGroup(name: string) {
    return this.form.get(name);
  }

  getNativeElement(inputSelector: string): HTMLInputElement {
    if (inputSelector && document.querySelector(`[inputSelector = ${inputSelector}]`)) {
      return document.querySelector(`[inputSelector = ${inputSelector}]`) as HTMLInputElement;
    }
  }

  focusFieldByInputSelector(inputSelector: string) {
    if (inputSelector) {
      // console.log(this.getNativeElement(inputSelector));
      this.getNativeElement(inputSelector).focus();
    }
  }

  clickFieldByInputSelector(inputSelector: string) {
    if (inputSelector) {
      // console.log(this.getNativeElement(inputSelector));
      if (this.getNativeElement(inputSelector)) {
        this.getNativeElement(inputSelector).click();
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.asyncAfterViewInit) {
      setTimeout(() => {
        this.asyncAfterViewInit = true;
      });
    }
  }

  permissionsUpdateSubscription() {
    this.service.permissionsService.permissionsUpdated.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.service.setModulePermissions();
      this.unAuthRedirect();
    });
  }

  unAuthRedirect() {
    const permissionsKeys = this.activatedRoute.snapshot.data.permissionsKeys;
    if (permissionsKeys && permissionsKeys.length) {
      if (
        !permissionsKeys.every((permissionKey: { module?: string; key?: string }) => {
          return this.service.permissionsService.getPermissions(permissionKey.module, permissionKey.key);
        })
      ) {
        this.router.navigateByUrl('/401', { skipLocationChange: true });
      }
    }
  }

  getInputValueChangesPair(controlName: string): Observable<any> {
    let subscription: Observable<any>;
    if (this.isEdit) {
      subscription = this.form.controls[controlName].valueChanges.pipe(
        takeWhile(() => this.alive),
        pairwise()
      );
    } else {
      subscription = this.form.controls[controlName].valueChanges.pipe(
        takeWhile(() => this.alive),
        /**
         * startWith used in create and not edit as first emitted value doesn't have a prev and is ignored in pairwise
         * null as string => fix for deprecation warning when using
         * startWith(null) => https://github.com/ReactiveX/rxjs/issues/4772
         */
        startWith(null as string),
        pairwise()
      );
    }
    return subscription;
  }

  /**
   *
   * helper function for joining date and time controls in another combined control,
   * to force user to pick time if he picked the date and clears the time if he clears the date
   * must use inputSelector on the timePicker for clicking the timepicker to open it after entering values in datepicker
   * @param dateControlName formControlName for date input
   * @param timeControlName formControlName for time input
   * @param combinedControlName formControlName for dateTime input
   * @param clickTimeInput if you want to click the time picker input after picking date
   * @param dateControlCustomSubscription //todo: update later
   */

  dateTimeValidator(
    dateControlName: string,
    timeControlName: string,
    combinedControlName: string,
    clickTimeInput?: boolean,
    dateControlCustomSubscription?: Observable<any>
  ): void | Subscription {
    const dateControl = this.form.controls[dateControlName];
    const timeControl = this.form.controls[timeControlName];
    if (dateControl && timeControl) {
      if (dateControl.value) {
        timeControl.enable();
        timeControl.setValidators([Validators.required]);
        timeControl.updateValueAndValidity();
      }
      let dateControlSubscription: Observable<any>;
      if (dateControlCustomSubscription) {
        dateControlSubscription = dateControlCustomSubscription;
      } else {
        dateControlSubscription = this.getInputValueChangesPair(dateControlName);
      }

      const dateControlRunningSubscription = dateControlSubscription.subscribe(([prev, next]: [any, any]) => {
        if (next && !dateControl.errors) {
          const prevMoment = prev ? this.service.shared.moment(new Date(prev)) : null;
          const nextMoment = next ? this.service.shared.moment(new Date(next)) : null;

          if (nextMoment.isValid() && (!prev || (prev && prevMoment.isValid() && !prevMoment.isSame(nextMoment)))) {
            // console.log(dateControlName);
            // console.log('prev', prev);
            // console.log('prevMoment', prevMoment);
            // console.log('next', next);
            // console.log('nextFormatted', this.service.shared.momentFormat(next));
            // console.log('nextMoment', nextMoment);
            // console.log('moment valid');
            timeControl.enable();
            this.service.clearInput.next(timeControlName);
            timeControl.setValidators([Validators.required]);
            timeControl.markAsTouched();
            timeControl.updateValueAndValidity();

            if (clickTimeInput) {
              setTimeout(() => {
                // console.log('click', this.service.getFieldByName(timeControlName).form.inputSelector);
                this.clickFieldByInputSelector(this.service.getFieldByName(timeControlName).form.inputSelector);
              });
            }
          }
        } else {
          timeControl.disable();
          timeControl.clearValidators();
          timeControl.updateValueAndValidity();
          this.service.clearInput.next(timeControlName);
          this.service.clearInput.next(combinedControlName);
        }
      });
      return dateControlRunningSubscription;
    }
  }

  compareDateTime(
    startDateControlName: string,
    startTimeControlName: string,
    combinedStartControlName: string,
    endDateControlName: string,
    endTimeControlName: string,
    combinedEndControlName: string
  ) {
    const startDateControl = this.form.controls[startDateControlName];
    const startTimeControl = this.form.controls[startTimeControlName];
    const endDateControl = this.form.controls[endDateControlName];
    const endTimeControl = this.form.controls[endTimeControlName];

    let startDateTime: any;
    let endDateTime: any;

    if (startDateControl && startTimeControl) {
      startDateControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        startDateTime = this.getDateTime(startDateControl, startTimeControl);
        this.form.controls[combinedStartControlName].setValue(startDateTime);
      });
      startTimeControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        startDateTime = this.getDateTime(startDateControl, startTimeControl);
        this.form.controls[combinedStartControlName].setValue(startDateTime);
      });
    }
    if (endDateControl && endTimeControl) {
      endDateControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        endDateTime = this.getDateTime(endDateControl, endTimeControl);
        this.form.controls[combinedEndControlName].setValue(endDateTime);
      });
      endTimeControl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
        endDateTime = this.getDateTime(endDateControl, endTimeControl);
        this.form.controls[combinedEndControlName].setValue(endDateTime);
      });
    }
  }

  getDateTime(DateControl: AbstractControl, TimeControl: AbstractControl) {
    if (DateControl && TimeControl) {
      if (DateControl.value && !DateControl.errors && TimeControl.value && !TimeControl.errors) {
        return this.service.shared.moment(
          new Date(this.service.shared.momentFormat(DateControl.value) + ' ' + TimeControl.value)
        );
      }
    }
  }

  /**
   * helper function for modules with ng_select_api select dropdown which load data paginated
   * this function is used on edit item and it does 2 things
   * 1. it wait for 2 responses the edit response and list of the dropdown to return
   * 2. it will inject the dropdown selected item in the input as the selected item could be not listed
   *    in the paginated results, another function inside the ng_select_api component filters results
   *    for when scrolling the dropdown and getting new results it will filter duplicates that's already
   *    added before by this function
   * 3. it will select this item from the selections
   * @param itemProp the itemProp of the dropdown using ng_select_api with paginated results
   */
  appendEditSelectItem(itemProp: ItemProps) {
    const listResponse = this.service.lists$.pipe(
      takeWhile(() => this.alive),
      take(1),
      map((listPrefix: string) => {
        if (itemProp.form.listPrefix && listPrefix === itemProp.form.listPrefix) {
          return listPrefix;
        }
      })
    );

    const dataRetrieved = this.formInjectValues$.pipe(take(1));

    combineLatest([listResponse, dataRetrieved])
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        const appendedItemObj: AppendedItemObj =
          itemProp.form.ngSelectOptions.appendedProp && this.item
            ? (this.service.getValue(this.item, itemProp.form.ngSelectOptions.appendedProp) as AppendedItemObj)
            : null;

        if (appendedItemObj) {
          this.service.listsAdditions$.next({
            listPrefix: itemProp.form.listPrefix,
            items: [appendedItemObj],
          });
          this.service.updateInputValue.next({
            fieldName: itemProp.name,
            fieldValue: appendedItemObj.id,
            updateForm: false,
          });
          this.form.markAsPristine();
        }
      });
  }

  formInjectDefaultValues() {}

  mapFields(fieldsList: ItemProps[]) {
    let formFields = {};
    for (const field of fieldsList) {
      if (field.form) {
        if (field.form.formFieldType == 'formArray') {
          formFields = {
            ...formFields,
            [field.name]: this.fb.array([this.fb.group(this.mapFields(field.form.arrayForm))]),
          };
        } else {
          formFields = {
            ...formFields,
            [field.name]: [
              {
                value: field.form.initValue || field.form.initValue === 0 ? field.form.initValue : null,
                disabled: (field.form.editDisabled && this.isEdit) || field.form.disabled,
              },
              field.form.Validators,
            ],
          };
        }
        this.inputsVisibility.push({
          key: field.name,
          visible: true,
        });
      }
    }
    // console.log(formFields);

    this.fields = formFields;
    return formFields;
  }

  formTutorialSubscription() {
    this.service.shared.isModuleTour.pipe(takeWhile(() => this.alive)).subscribe((res) => {
      this.tourService.initialize(this.tourSteps, {
        enableBackdrop: false,
      });
      this.tourService.start();
    });
  }

  overrideTourServiceShowStepFunc() {
    if (this.tourService) {
      this.tourService['showStep'] = (step: any) => {
        /** @type {?} */
        const anchor = this.tourService.anchors[step && step.anchorId];
        if (!anchor) {
          this.tourService.next(); // if step is not found, skip and next
          return;
        }
        anchor.showTourStep(step);
        this.tourService.stepShow$.next(step);
      };
    }
  }

  // tabs navigator
  nextStep() {
    if (this.selectedIndex < this.tabGroupsCount) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  previousStep() {
    if (this.selectedIndex != 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  onTabChanged(event: any) {
    this.selectedIndex = event?.index;
  }

  refactorColorPicker() {
    if (this.form.value[this.service.colorPicker?.fieldName]) {
      this.form.value[this.service.colorPicker?.fieldName] = this.service.colorPicker?.fieldValue?.hex
        ? '#' + this.service.colorPicker?.fieldValue?.hex
        : this.service.colorPicker?.fieldValue;
    }
  }

  countTabs() {
    // todo: refactor/isolate code : calculate the tabs count.
    const pairs = new PairsPipe();
    const formInputs = pairs.transform(this.service.formInputsCategorized);
    formInputs.forEach((groups: any[]) => {
      if (groups[0] !== 'defaultGroup') {
        this.tabGroupsCount = Object.keys(groups[1]).length - 1;
      }
    });
  }
}
