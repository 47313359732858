<div class="buttons-container" fxFlex fxLayout>
  <div class="container w-100" fxFlex="" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="breadcrumb">
      <xng-breadcrumb [preserveQueryParams]="true" [autoGenerate]="false" *ngIf="showBreadcrumb == 'true'">
        <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">
          <ng-container>
            <mat-icon *ngIf="info">{{ info }}</mat-icon>
            {{ breadcrumb | translate }}
          </ng-container>
        </ng-container>
      </xng-breadcrumb>
    </div>
    <div>
      <button mat-button class="btn-secondary btn-cancel" (click)="cancel.emit()" [attr.testId]="'formCancel'">
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="btn-primary"
        (click)="submit.emit()"
        [disabled]="!form.valid || form.pristine"
        [attr.testId]="'formSave'"
      >
        <mat-icon class="mat-18">save</mat-icon>
        {{ 'common.save' | translate }}
      </button>

      <button
        mat-stroked-button
        *ngIf="!isEdit && !isClone && isSaveAddNew"
        mat-flat-button
        color="primary"
        class="btn-primary"
        (click)="saveAddNew.emit()"
        [disabled]="!form.valid || form.pristine"
      >
        {{ 'common.save_add_new' | translate }}
      </button>
    </div>
  </div>
</div>
