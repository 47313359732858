<ng-container *ngIf="form?.get(field?.name)?.errors">
  <div
    class="validation-errors"
    *ngIf="
      (form?.get(field?.name)?.touched || isSubmitted) &&
      form?.get(field?.name)?.invalid &&
      form?.get(field?.name)?.errors
    "
  >
    <div class="mat-error" *ngFor="let error of form?.get(field?.name)?.errors | keyvalue">
      <!-- Server Side Errors -->
      <ng-container *ngIf="error?.key === 'serverError'">
        <ng-container *ngFor="let err of error?.value">
          {{ err }}
        </ng-container>
      </ng-container>

      <!-- Client Side Errors-->
      <ng-container *ngIf="error?.key !== 'serverError'">
        <ng-container *ngIf="!field?.hints?.password_helper">
          <ng-container [ngSwitch]="error?.key">
            <ng-container *ngSwitchCase="'minlength'">
              {{ 'validation_errors.' + error?.key | translate : { xx: error?.value?.requiredLength } }}
            </ng-container>
            <ng-container *ngSwitchCase="'maxlength'">
              {{ 'validation_errors.' + error?.key | translate : { xx: error?.value?.requiredLength } }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ 'validation_errors.' + error?.key | translate }}
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
