<div [formGroup]="fullLocation">
  <mat-form-field class="form-container" appearance="outline">
    <input
      matInput
      formControlName="address"
      type="text"
      class="search-input"
      (keydown.enter)="$event.preventDefault()"
      [value]="addressValue"
    />
    <mat-icon
      matSuffix
      [ngClass]="inputDisabled ? 'btnDisabeled' : 'clickable'"
      (click)="invalidateSize(); toggleView()"
    >
      location_on
    </mat-icon>
  </mat-form-field>
  <div
    @fadeAnimation
    [hidden]="view"
    leaflet
    style="height: 400px"
    leafletDraw
    [leafletOptions]="options"
    [leafletDrawOptions]="drawOptions"
    [leafletDrawLocal]="drawLocal"
    (leafletDrawCreated)="onDrawCreated($event)"
    (leafletDrawStart)="onDrawStart($event)"
  >
    <div [leafletLayer]="drawnItems"></div>
  </div>
</div>
