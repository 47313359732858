<div [formGroup]="form">
  <mat-form-field (click)="picker.open()" [floatLabel]="false" class="full-width">
    <button
      class="color-square"
      tabindex="-1"
      [style.background]="form.get(field.name).value ? form.get(field.name).value : '#fff'"
      matPrefix
      (click)="picker.open()"
    ></button>
    <input
      matInput
      [ngxMatColorPicker]="picker"
      [formControlName]="field.name"
      [id]="field.name"
      autocomplete="off"
      [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
      [attr.testId]="field.name"
      (keydown)="picker.open()"
      [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
    />
    <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker></ngx-mat-color-picker>

    <mat-error>
      <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
    </mat-error>
  </mat-form-field>
</div>
<ng-template #validationErrors>
  <app-input-validation-errors
    [service]="service"
    [form]="form"
    [isEdit]="isEdit"
    [field]="field"
    [isClone]="isClone"
    [name]="field?.name"
    [disabledInput]="(form?.controls)[field.name].disabled"
  ></app-input-validation-errors>
</ng-template>
