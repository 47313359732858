import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RootService } from '../../../core/root.service';
import { ListOptions } from '../../../interfaces';
import { takeWhile } from 'rxjs/operators';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { from, Observable, of } from 'rxjs';

@Component({
  selector: 'app-list-multiple-operations',
  templateUrl: './list-multiple-operations.component.html',
  styleUrls: ['./list-multiple-operations.component.scss'],
})
export class ListMultipleOperationsComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() listOptions: ListOptions;
  @Input() service: RootService;
  alive = true;
  // Local Props
  operations: Operation[];

  @Input() params: any;

  constructor(private utilitiesService: UtilitiesService) {}

  /**
   * Implement on init interface because filled data receives from input
   */
  ngOnInit(): void {
    /**
     * Fill array with available operations
     */
    this.operations = [
      {
        type: 'archiveMultiple',
        icon: 'inbox-arrow-down',
        name: 'archive',
        permissionKey: 'manageArchived',
        condition:
          (this.listOptions.archive && !this.listOptions.archivedList) ||
          (this.listOptions.archivedFilter && this.service.customFilterActiveLink === 'active'),
      },
      {
        type: 'deleteMultiple',
        icon: 'delete-outline',
        name: 'delete',
        permissionKey: 'delete',
        condition: this.listOptions.deleteMultiple,
      },
      {
        type: 'restoreMultiple',
        icon: 'restore',
        name: 'restore',
        permissionKey: 'manageArchived',
        condition:
          (this.listOptions.restore && this.listOptions.archivedList) ||
          (this.listOptions.archivedFilter && this.service.customFilterActiveLink === 'archived'),
      },
      {
        type: 'voidMultiple',
        icon: 'diameter-variant',
        name: 'void',
        permissionKey: 'void',
        condition: this.listOptions.void,
      },
    ];
    this.filterSubscription();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  filterSubscription() {
    this.utilitiesService.filtersObservable$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      // console.log('filter active');
      this.operations.find((x) => x.type === 'archiveMultiple').condition =
        (this.listOptions.archive && !this.listOptions.archivedList) ||
        (this.listOptions.archivedFilter && this.service.customFilterActiveLink === 'active');
      this.operations.find((x) => x.type === 'restoreMultiple').condition =
        (this.listOptions.restore && this.listOptions.archivedList) ||
        (this.listOptions.archivedFilter && this.service.customFilterActiveLink === 'archived');
      this.operations = [...this.operations];
    });
  }
}

/**
 * Local interface for prop: operations
 */
interface Operation {
  type: string;
  icon: string;
  condition?: any;
  name?: string;
  permissionKey?: string;
}
