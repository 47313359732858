import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '@app/shared/services/shared.service';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit {
  @Input() minEntryDate?: string; // pass the min value of the minEntryDate
  @Input() maxEntryDate?: string;
  @Input() key: string; // pass the formcontrolname key
  @Input() cid: string; // pass the formcontrolname key
  @Input() service: any; // pass the formcontrolname key
  @Input() field: any; // pass the formcontrolname key
  @Input() isEdit: boolean; // pass the formcontrolname key
  @Input() form: UntypedFormGroup; // pass the formgroup

  dateKey: string;
  timeKey: string;
  startKey: string;
  endKey: string;
  minDate: any;
  maxDate: any;
  constructor() {}

  ngOnInit() {
    // console.log(this.key);
    // console.log(this.cid);
    // console.log(this.form.controls);

    // add to controls wiht date and time suffix
    this.dateKey = this.key + 'Date';
    this.timeKey = this.key + 'Time';
    this.form.addControl(this.dateKey, new UntypedFormControl('', Validators.required));
    this.form.addControl(this.timeKey, new UntypedFormControl('', Validators.required));

    // remove the validation from the main control
    this.form.controls[this.key].validator = null;

    // min prop or max prop validation

    if (this.field.form.maxValue && this.field.form.maxValue.value) {
      this.maxDate = new Date(this.field.form.maxValue.value);
    }
    if (this.field.form.minValue && this.field.form.minValue.value) {
      this.minDate = new Date(this.field.form.minValue.value);
    }
    // console.log(this.field);
  }
  /**
   * checks if the control name is required or not from the current validators on it
   * why > because template checking doesn't update as you remove
   * the validations and re insert them when live removing inputs
   * also > to be able to see which inputs are required before they are
   *  touched and have error.required in validation errors
   * @param controlName the control name in the form
   */
  checkRequiredFields(controlName: string) {
    // if (!this.isEdit) {
    const abstractControl = this.form.controls[controlName];
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
    // }
  }

  checkStartEndDate() {
    // check if the Date of the min and max is not set yet
    if (
      !this.form.controls[this.startKey + 'Date'].value ||
      !this.form.controls[this.endKey + 'Date'].value ||
      !this.form.controls[this.startKey + 'Time'].value ||
      !this.form.controls[this.endKey + 'Time'].value
    ) {
      return false;
    }

    // get the min and max Dates to compare
    const startDate = new Date(
      this.form.controls[this.startKey + 'Date'].value.format('YYYY-MM-DD') +
        ' ' +
        this.form.controls[this.startKey + 'Time'].value
    ).toISOString();

    const endDate = new Date(
      this.form.controls[this.endKey + 'Date'].value.format('YYYY-MM-DD') +
        ' ' +
        this.form.controls[this.endKey + 'Time'].value
    ).toISOString();

    if (startDate > endDate) {
      return true;
    }

    return false;
  }
  addEvent(key: string, event: MatDatepickerInputEvent<Date>) {
    if (this.field.form.minValue && this.field.form.minValue.prop) {
      this.minDate = this.form.controls[this.field.form.minValue.prop].value;
    }
    // console.log(event.value._d);
  }
}
