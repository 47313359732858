import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Location } from '@angular/common';
// const mime = require('mime');
import * as mime from 'mime';
import * as moment from 'moment';
import { TimeAgoPipe } from 'ngx-pipes';
import { I18nService } from '@app/core/i18n.service';
import { SystemPermissions } from '@app/interfaces';
import { takeWhile, retry } from 'rxjs/operators';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { SharedService as BaseSharedService } from '@afaqyit/frontend-core';
// import { PermissionsService } from '@app/core/permissions/permissions.service';

// import * as mime from 'mime-types';

@Injectable({
  providedIn: 'root',
})
export class SharedService extends BaseSharedService {
  data$: Subject<any> = new Subject();
  deletedItem$: Subject<any> = new Subject();
  dashboardErrors$: Subject<any> = new Subject();
  filterOpen$: Subject<any> = new Subject();
  // This subject is to send language to chart component
  sendLanguage: Subject<any> = new Subject();
  compnayLogo$: ReplaySubject<any> = new ReplaySubject();
  defaultFormat = 'YYYY-MM-DD';
  isModuleTour: Subject<any> = new Subject();
  constructor(
    public toastr: ToastrService,
    public dialog: MatDialog,
    public translate: TranslateService,
    public location: Location,
    public timeAgoPipe: TimeAgoPipe, // private permissionsService: PermissionsService,
    private i18nService: I18nService,
    public utilitiesService: UtilitiesService,
    private translateService: TranslateService
  ) {
    // Please fill the super() call by the needed parameters
    super(toastr, dialog, translate, location, timeAgoPipe, utilitiesService);
  }

  public moment(date: 'today' | any = ''): moment.Moment {
    if (date === 'today') {
      date = new Date();
    }
    return moment(date).locale('en-US');
  }

  public utcToLocal(date: any) {
    const utc = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
    const utcDate = moment.utc(utc).toDate();
    const localDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss');

    return localDate;
  }

  public momentFormat(date: any, format?: string) {
    const convertedDate = this.moment(date).format(format ? format : this.defaultFormat);
    return convertedDate;
  }

  public momentLocaleRefactor(date: moment.Moment | string): moment.Moment {
    if (moment.isMoment(date)) {
      return date.locale('en-US');
    } else {
      return moment(date).locale('en-US');
    }
  }

  storeItems(data: any) {
    this.data$.next(data);
  }

  handleItemById(data: any) {
    this.deletedItem$.next(data);
  }

  getMimeTypes(extensions: string[]) {
    const extensionsArray: string[] = [];
    extensions.forEach((extension) => {
      extensionsArray.push(this.getMimeType(extension));
    });
    return extensionsArray;
  }

  getMimeType(extension: string) {
    return mime.getType(extension);
  }

  getTimeAgoFromDate(date: any, daysCompareTo?: number, dateFormat?: string) {
    const momentDate = moment(date);
    let timeAgo: any = '';
    if (daysCompareTo) {
      const now = moment(new Date());
      const compareTo = now.subtract(daysCompareTo, 'days');

      if (momentDate > compareTo) {
        timeAgo = this.timeAgoPipe.transform(momentDate);
      } else {
        if (dateFormat) {
          timeAgo = momentDate
            .local()
            .locale(this.translate.currentLang === 'ar-SA' ? 'ar' : 'en')
            .format(dateFormat);
        } else {
          timeAgo = momentDate.local().toDate();
        }
      }
    } else {
      timeAgo = this.timeAgoPipe.transform(momentDate);
    }
    return timeAgo;
  }

  getCompanyLogo() {
    return this.compnayLogo$.asObservable();
  }

  setCompanyLogo(data: any) {
    this.compnayLogo$.next(data);
  }

  // General Translator for the object name - Object must include `name` key.
  transalteName(item: any) {
    const currentLang = this.translate.currentLang;

    switch (currentLang) {
      case 'en-US':
        item.name = item['nameEn'] || item['name']; // some responses doesn't have nameEn :((
        break;
      case 'ar-SA':
        item.name = item['nameAr'] || item['name'];
        break;
      default:
        item.name = item['name'] || item['displayName'] || item['nameEn'];
        break;
    }

    return item['name'];
  }
}
