import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss'],
})
export class FormFooterComponent {
  @Input() form: UntypedFormGroup;
  @Input() isSubmitted: boolean;
  @Input() isClone: boolean;
  @Input() isEdit: boolean;
  @Input() isSaveAddNew: boolean;
  @Input() showBreadcrumb: string = 'true';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<any>;
  @Output() saveAddNew: EventEmitter<any>;
  @Output() cancel: EventEmitter<any>;

  constructor() {
    this.submit = new EventEmitter<any>();
    this.saveAddNew = new EventEmitter<any>();
    this.cancel = new EventEmitter<any>();
  }
}
