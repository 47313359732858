import { Injectable } from '@angular/core';
import { ApiRequestService } from '@app/core/http/api-request.service';
import {
  ApiResponse,
  ItemProps,
  CustomFilterLink,
  SystemPermission,
  SystemPermissions,
  FilterObject,
  ActionDialogParams,
} from '@app/interfaces';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { AppHelper } from '@app/core/classes/app-helper';
import { DynamicDialogComponent } from '@app/shared/components/dynamic-dialog/dynamic-dialog.component';
import { SharedService } from '@app/shared/services/shared.service';
import { ImageItem } from '@ngx-gallery/core';
import { DummyData } from '@app/shared/services/dummy-data';
import * as moment from 'moment';
import { customEnvironment } from '@env/environment.custom';
import { ImportDialogComponent } from '@app/shared/components/import-dialog/import-dialog.component';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { FilterFieldTypeEnum } from '@app/enums';
import { RootService as BaseRootService } from '@afaqyit/frontend-core';

@Injectable({
  providedIn: 'root',
})
export abstract class RootService extends BaseRootService {
  [x: string]: any;

  // List Object which store the data from the server once it retrieve
  public resourcesList: any;
  customFilterActiveLink: string;
  resources: Subject<any> = new Subject();
  chart: ReplaySubject<any> = new ReplaySubject();
  updateResources: Subject<any> = new Subject();
  appendResources$: Subject<any> = new Subject();
  storeResourceListResponse$: Subject<any> = new Subject();
  updateOnCreate = true;
  appendOnCreate = false;
  customData: any = {};
  formInputsCategorized: any = {};
  itemID: number;
  customFilterLinks: CustomFilterLink[];
  lists: any = {
    // positions: { data: [] },
    // roles: { data: [] },
    // 'roles-create': { data: [] }
    // replaced with key : [] that holds data
  };

  lists$: Subject<any> = new Subject();
  listsAdditions$: Subject<{ listPrefix: string; items: any[] }> = new Subject();

  dummyDataLoader = false;

  customCid: string;
  customTitle: string;
  notFoundResult: boolean;

  public selectedItems: number[] = []; // for selecting multiple items for delete actions
  public selectedItems$: Subject<any> = new Subject();
  searchQuery: string;
  filterData: any;
  importData: any;
  clearInput: Subject<any> = new Subject();

  tabGroupActiveTab: {
    [key: string]: number;
  } = {};

  updateInputValue: Subject<{
    fieldProps?: ItemProps;
    fieldName?: string;
    fieldValue?: any;
    updateForm?: boolean;
  }> = new Subject();

  formInjectDefaultValues$: Subject<any> = new Subject();

  inputChangeDetection: Subject<{
    fieldName?: string;
    fieldValue?: any;
  }> = new Subject();

  selectedAdditionalData: {
    key: string;
    unit: string;
    color?: string;
  }[];

  permissionsKey: string;
  permission: SystemPermission;

  showCustomLinks = true;
  showInlineFilters = false;

  colorPicker: { fieldName?: string; fieldValue?: any } = {};
  protected constructor(
    protected router: Router,
    protected api: ApiRequestService,
    public permissionsService: PermissionsService,
    public shared: SharedService
  ) {
    // Initialize the resourceList as empty array.
    // Please fill the super() call by the needed parameters
    super(router, api, permissionsService, shared);
    this.resourcesList = {};
    this.customEnvironment();
  }

  /**
   * @returns the controller id that predefined in child service
   */
  get cid() {
    return this.routerPrefix();
  }

  /**
   * define the grid list items (columns)
   */
  abstract get featureProps(): ItemProps[];

  /**
   * Main method to get resource lists
   * @param params: params
   * @param archivedList: Flag to check if list is archived list
   * @param infinite: if you want to update list and add new items not replace old items
   * @param updateLists if you want to update existing list with new items and not load a new list over the existing
   */
  startAutoLoad(params: {} = {}, archivedList: boolean = false, infinite?: boolean, updateLists?: boolean) {
    return this.autoLoadResources(params, archivedList, infinite, updateLists);
  }

  /**
   * Get list of data
   * page
   * params
   */
  public autoLoadResources(params: {}, archivedList: boolean, infinite?: boolean, updateLists?: boolean) {
    if (this.routerPrefix() === '') {
      return;
    }
    return this.doGetLists(this.getFunctionURL(null, archivedList ? '/archived' : ''), params, infinite, updateLists);
  }

  async doGetLists(url: string, params: {} = {}, infinite?: boolean, updateLists?: boolean) {
    return this.api
      .get(url, params)
      .pipe(
        map((response) => {
          return this.listPrepareOperations(response);
        })
      )
      .subscribe(async (resp: ApiResponse) => {
        if (updateLists) {
          this.storeResourceListResponseNewItems(resp, params, infinite);
          this.storeResourceListResponse$.next(true);
        } else {
          this.storeResourceListResponse(resp, params, infinite);
          this.storeResourceListResponse$.next(true);
        }
      });
  }

  /**
   * set response list to resourceList variable
   */
  storeResourceListResponse(resp: any, params?: any, infinite?: boolean) {
    if (infinite) {
      const newData = [...resp.data];
      const oldData = [...this.resourcesList.data];
      // this.resourcesList.data = [...oldData, ...newData];
      let appendedData: any[] = [];

      newData.forEach((newItem) => {
        if (
          oldData.some((oldItem) => {
            return oldItem.id === newItem.id;
          })
        ) {
          // return;
        } else {
          appendedData = [...appendedData, ...[newItem]];
        }
      });
      if (appendedData.length) {
        this.resourcesList.data = [...oldData, ...appendedData];
      }
      this.resourcesList.meta = resp.meta;
    } else {
      this.resourcesList = resp;
    }
    this.resources.next(true);
  }

  /**
   * set response list to resourceList variable
   */
  storeResourceListResponseNewItems(resp: any, params?: any, infinite?: boolean) {
    if (infinite) {
      const newData = [...resp.data];
      const oldData = [...this.resourcesList.data];
      const appendedData: any[] = [];
      newData.forEach((newItem) => {
        if (
          oldData.some((oldItem) => {
            return oldItem.id === newItem.id;
          })
        ) {
          // return;
        } else {
          appendedData.push(newItem);
        }
      });
      if (appendedData.length) {
        this.resourcesList.data = [...appendedData, ...oldData];
      }
      this.resourcesList.meta = resp.meta;
    } else {
      this.resourcesList = resp;
    }
    this.resources.next(true);
  }

  /**
   * Helper method to refactor the code before it's returned
   * @param data: Response
   */
  listPrepareOperations(data: {}) {
    return data;
  }

  /**
   * Helper Method, It'll overwrite from child services
   */
  routerPrefix(val: string = ''): string {
    return val ? val : '';
  }

  /**
   * Helper Method for different api route than frontend router prefix and component cid,
   * It'll overwrite from child services
   */
  apiRouterPrefix(val: string = null): string {
    return val;
  }

  getFunctionURL(action: any, suffix: string = '', prefix: string = '') {
    return action
      ? prefix + (this.apiRouterPrefix() ? this.apiRouterPrefix() : this.routerPrefix()) + '/' + action + suffix
      : prefix + (this.apiRouterPrefix() ? this.apiRouterPrefix() : this.routerPrefix()) + suffix;
  }

  /**
   * Archive Item
   */
  archive(id: number) {
    return this.doArchive(this.getFunctionURL('archive'), id);
  }

  /**
   * Archive Item Request
   * url
   * id
   */
  doArchive(url: string, id: number) {
    return this.api.put(url, { ids: [id] }).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.archived'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Archive Items
   */
  archiveMultiple(ids: {}) {
    return this.doArchiveMultiple(this.getFunctionURL('archive'), { ids });
  }

  /**
   * Archive Items Request
   * url
   * id
   */
  doArchiveMultiple(url: string, body: any) {
    return this.api.put(url, body).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.archived'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );

        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Archive Items
   */
  deleteMultiple(ids: {}) {
    return this.doDeleteMultiple(this.getFunctionURL(''), { ids });
  }

  /**
   * Archive Items Request
   * url
   * id
   */
  doDeleteMultiple(url: string, body: any) {
    return this.api.request('delete', url, body).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          res.message ? res.message : this.shared.translate.instant(this.cid + '.deleted'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );

        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Generic Restore
   * id: Item Id
   */
  restoreMultiple(ids: any) {
    return this.doRestoreMultiple(this.getFunctionURL(''), { ids });
  }

  /**
   * Restore Item Request
   * url
   * id
   */
  doRestoreMultiple(url: string, body: any) {
    return this.api.put(url + '/restore', body).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.restored'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );

        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Delete Item
   */
  delete(id: number) {
    return this.doDelete(this.getFunctionURL(id));
  }

  /**
   * Archive Item Request
   * url
   * id
   */
  doDelete(url: string) {
    return this.api.delete(url).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          res.message ? res.message : this.shared.translate.instant(this.cid + '.deleted'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * selects items by ids as numbers or can use array of items to select them based on passed type: ex [5,2,3] or 5
   * @param id: ID
   */
  selectItem(id: number | number[]) {
    if (Array.isArray(id)) {
      if (this.selectedItems.length) {
        if (this.selectedItems.length === id.length) {
          id.forEach((y: number) => {
            if (this.selectedItems.find((x) => x === y)) {
              const i = this.selectedItems.findIndex((x) => x === y);
              this.selectedItems.splice(i, 1);
            } else {
              this.selectedItems.push(y);
            }
          });
        } else {
          id.forEach((y: number) => {
            if (this.selectedItems.find((x) => x === y)) {
              // const i = this.selectedItems.findIndex(x => x === y);
              // this.selectedItems.splice(i, 1);
            } else {
              this.selectedItems.push(y);
            }
          });
        }
      } else {
        id.forEach((y: number) => {
          this.selectedItems.push(y);
        });
      }
      this.selectedItems$.next(true);
    } else if (typeof id === 'number') {
      if (this.selectedItems.length) {
        if (this.selectedItems.find((x) => x === id)) {
          const i = this.selectedItems.findIndex((x) => x === id);
          this.selectedItems.splice(i, 1);
        } else {
          this.selectedItems.push(id);
        }
      } else {
        this.selectedItems.push(id);
      }
      this.selectedItems$.next(true);
    }
    // console.log('selectItem');
    // console.log(this.selectedItems);
  }

  clearSelectedList() {
    this.selectedItems = [];
    this.selectedItems$.next(true);
  }

  /**
   * Generic Restore
   * id: Item Id
   */
  restore(id: number) {
    console.log('restore this shit', id);
    return this.doRestore(this.getFunctionURL('restore'), id);
  }

  /**
   * Restore Item Request
   * url
   * id
   */
  doRestore(url: string, data: any) {
    return this.api.put(url, { ids: [data] }).subscribe(
      (res: ApiResponse) => {
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.restored'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );

        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Generic Update Status
   * id: Item Id
   * data: New Item Data
   */
  toggleActivate(id: any) {
    return this.doToggleActivateItem(this.getFunctionURL(`${id}/status`), {});
  }

  /**
   * Generic Update Status
   */
  async doToggleActivateItem(url: string, data: {}) {
    return this.api.put(url, data).subscribe(
      async (resp: ApiResponse) => {
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.status_changed'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );

        this.afterSuccessListAction();
        return this.updateResources.next(true);
      },
      async (err) => {
        this.errorHandle(err);
      }
    );
  }

  checkSelected(id: number) {
    return !!this.selectedItems.find((x) => x === id);
  }

  /**
   * Generic Show Action Request
   * @param id: Item Identifier
   */
  showItem(id: number) {
    return this.doShow(this.getFunctionURL(`${id}`));
  }

  doShow(url: string) {
    return this.api.get(url).pipe(
      map((response: ApiResponse) => {
        return this.refactorItem(response.data);
      })
    );
  }

  /**
   * Generic Create Action Request
   * @param data: New Item Data
   */
  createItem(data: {}) {
    return this.doCreate(this.getFunctionURL(''), data);
  }

  /**
   * Create Item Request
   * url
   * id
   */
  doCreate(url: string, data: {}) {
    return this.api.post(url, data);
  }

  /**
   * Generic Create Action Request
   * id: Item Id
   * data: New Item Data
   */
  updateItem(id: any, data: {}) {
    return this.doUpdateItem(this.getFunctionURL(id), data);
  }

  /**
   * Generic Update Action Request
   */
  doUpdateItem(url: string, data: {}) {
    return this.api.put(url, data);
  }

  /**
   * Generic update Action Request
   * id: Item Id
   * data: New Item Data
   */
  updateItemAsPost(id: any, data: {}) {
    return this.doUpdateItemAsPost(this.getFunctionURL(id), data);
  }

  /**
   * Generic Update Action Request
   */
  doUpdateItemAsPost(url: string, data: {}) {
    return this.api.post(url, data);
  }

  errorHandle(err: {}) {
    for (const error of Object.keys(err)) {
      // this.presentToast(err[error], 'danger');
    }
  }

  navigateToList() {
    return this.router.navigate([this.cid]);
  }

  refactorItem(item: {}) {
    return item;
  }

  // /**
  //  * Load lists Request
  //  */
  // getLists(field: string, page: number = 1) {
  //   return this.api
  //     .get(field)
  //     .pipe(
  //       map(response => {
  //         return this.refactorListsData(field, response);
  //       })
  //     )
  //     .subscribe(
  //       (resp: ApiResponse) => {
  //         this.storeListsResponse(field, resp, page);
  //       },
  //       err => this.errorHandle(err)
  //     );
  // }

  // refactorListsData(field: string, response: {}) {
  //   return response;
  // }

  /**
   * set field response list to lists variable
   */
  storeListsResponse(field: string, resp: ApiResponse, page: number) {
    if (page === 1) {
      // this.lists[field].data.length = 0;
      const fieldName = field.replace('/', '-');
      this.lists[fieldName] = resp.data;

      // this.lists[field].data = resp.data;
    } else {
      // this.lists[field].data = this.lists[field].data.concat(resp.response.data);
    }
  }

  /**
   * this is just generic function for easier upgrading all routes on one base location,
   * or adding extra logic to custom modules in their service
   * @param url: url
   * @param extras: navigate extras
   */
  navigateUrl(url: any, extras?: NavigationExtras) {
    return this.router.navigate([url], extras);
  }

  afterSuccessListAction() {
    this.clearSelectedList();
  }

  refactorFormBeforeSubmit(formValue: any) {
    return formValue;
  }

  /**
   * Pass the value to deep find method using path and original object
   * obj
   * path
   */
  getValue(obj: any, path: string) {
    return AppHelper.deepFind(obj, path);
  }

  /**
   * custom actions used in lists
   * @param action to be done ex open dashboard of vendors
   * @param row > table row
   * @param column > table column
   * @param staticValue to do action on it instead of the row and column and prop method of doing action on a column value
   * @param fnRow
   * @param fn
   */
  listActions(
    action: string,
    row: any,
    column: any,
    fnRow?: (row: any, column: any) => void,
    fn?: (column: any) => void
  ) {
    if (!action) {
      if (fnRow) {
        return fnRow(row, column);
      } else if (fn) {
        return fn(column);
      } else {
        return;
      }
    }
    let value: any = '';
    if (column.list.action.prop) {
      value = this.getValue(row, column.list.action.prop);
    } else {
      value = this.getValue(row, column.prop);
    }
    switch (action) {
      case 'mailto': {
        window.open(action + ':' + value);
        // return this.router.navigateByUrl(action + ':' + value);
        break;
      }
      case 'copyField': {
        this.copyToClipboard(value);
        break;
      }
      case 'openItemDashboard': {
        console.log('openItemDashboard', `${this.cid}/dashboard/${value}`);
        this.navigateUrl(`${this.cid}/dashboard/${value}`);
        break;
      }
      case 'openItemDashboardNewTab': {
        // this.navigateUrl(`${this.cid}/dashboard/${value}`);
        window.open(`${this.cid}/dashboard/${value}`, '_blank');
        break;
      }
      case 'goToUrl': {
        window.open(value, '_blank');
        break;
      }
      case null: {
        break;
      }
    }
  }

  copyToClipboard(value: any, message?: string) {
    const text: string = typeof value !== 'string' ? JSON.stringify(value) : value;
    AppHelper.copyToClipboard(text);
    this.shared.toastr.info(
      message ? message : `${text} ` + this.shared.translate.instant('common.copied_to_clipboard'),
      this.shared.translate.instant('common.success'),
      { closeButton: true }
    );
  }

  /**
   * Action dialog for actions that need dialog confirmation before doing the action
   * @param actionDialogObj of type ActionDialogParams
   */
  openActionDialog(actionDialogObj: ActionDialogParams): void {
    this.shared.dialog.open(DynamicDialogComponent, {
      data: {
        service: actionDialogObj.service,
        action: actionDialogObj.action,
        title: actionDialogObj.title,
        svgIcon: actionDialogObj.svgIcon,
        additionalMessage: actionDialogObj.additionalMessage,
        message: actionDialogObj.message,
        subMsg: `${actionDialogObj.subMsg ? actionDialogObj.subMsg : ''}`,
        messageClass: actionDialogObj.messageClass,
        submitText: actionDialogObj.submitText,
        cancelText: actionDialogObj.cancelText,
        data: actionDialogObj.data,
        submitCssClass: actionDialogObj.submitCssClass,
      },
    });
  }

  /**
   * function for all actions that's passed to and from the dialog
   * @param action string for action name passed before open dialog
   * @param data object to be used in each function passed from the function that opened the dialog
   */
  dialogAction(action: string, data?: any) {
    switch (action) {
      case 'cancel': {
        this.goBack();
        break;
      }
      case 'restoreMultiple': {
        this.restoreMultiple(data);
        break;
      }
      case 'restore': {
        this.restore(data);
        break;
      }
      case 'deleteMultiple': {
        this.deleteMultiple(data);
        break;
      }
      case 'delete': {
        this.delete(data);
        break;
      }
      case 'archiveMultiple': {
        this.archiveMultiple(data);
        break;
      }
      case 'archive': {
        this.archive(data);
        break;
      }
      case 'edit': {
        this.updateItem(this.id, data);
        break;
      }
      case 'end': {
        this.end(data);
        break;
      }
      case 'void': {
        this.void(data);
        break;
      }
      case 'voidMultiple': {
        this.voidMultiple(data);
      }
    }
  }

  /**
   * Extend from openActionDialog() method
   * @param data: id or [ids] of selected items
   * @param action: action type [restore, archive, delete]
   */
  openDialog(data: any, action: string) {
    this.openActionDialog({
      service: this,
      action: action,
      title: `common.confirm_${action}.title`,
      message: `common.confirm_${action}.message`,
      submitText: `common.confirm_${action}.submitText`,
      cancelText: `common.confirm_${action}.cancelText`,
      data: data,
      submitCssClass: action === 'restore' ? 'bg-success' : 'bg-danger',
    });
  }

  /**
   * Export Items
   */
  exportMultiple(ids?: number[], all: boolean = false, value?: any, params?: any) {
    let exportBody: any;
    exportBody = {
      fileType: 'csv',
    };
    if (value) {
      exportBody.fileType = value;
    }
    if (ids && ids.length > 0 && !all) {
      exportBody.ids = ids;
      // console.log('ids' + ids);
    }
    // console.log('params', params);
    if (params && params.page) {
      delete params.page;
    }
    return this.doExportMultiple(this.getFunctionURL('export'), exportBody, params);
  }

  /**
   * Export Items Request
   * url
   * id
   */
  doExportMultiple(url: string, body: any, params?: any) {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const dateTime = date + '_' + time;

    const fileName = `${this.cid}_${dateTime}.${body.fileType}`;

    return this.apiRequest('POST', url, body, {}, params, 'blob').subscribe(
      (res) => {
        this.api.fileSaverService.save(res, fileName, body.fileType);
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.exported'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * Get data form server
   * @param componentName: Component Name
   * @param itemID: Resource id
   * @param isCreate: flag
   * @param headers: Request headers
   * @param filterMethod: is this method filtering the results
   */
  getComponentData(
    componentName: string,
    itemID: number,
    headers: {} = {},
    isCreate: boolean = false,
    filterMethod: boolean = false
  ) {
    this.api
      .get(this.getFunctionURL(`${itemID}/${componentName}`), headers)
      .pipe(
        map((data) => {
          return data;
        })
      )
      .subscribe((obj) => {
        return this.storeComponentData(componentName, obj, isCreate, filterMethod);
      });
  }

  /**
   * post data to server
   * @param componentName: Component Name
   * @param itemID: Resource id
   * @param data: form data
   * @param isCreate: Check if post type is Create or update
   */
  postComponentData(componentName: string, itemID: number, data: any, isCreate: boolean = false) {
    this.api
      .post(this.getFunctionURL(`${itemID}/${componentName}`), data)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .subscribe(
        (obj) => {
          this.shared.toastr.success(obj.message, '', { closeButton: true });
          return this.getComponentData(componentName, itemID, null, isCreate);
        },
        (error) => {
          if (error && error.status === 422) {
            const errorObj = { componentName: componentName, error: error.error };
            this.shared.dashboardErrors$.next(errorObj);
          }
        }
      );
  }

  /**
   * Delete Item from resource nested resources
   * @param componentName: Component Name
   * @param id: deleting item id
   * @param itemID: Resource id
   */
  deleteComponentData(componentName: string, id: number, itemID: number) {
    this.api
      .delete(this.getFunctionURL(`${componentName}/${id}`))
      .pipe(
        map((data: any) => {
          return data;
        })
      )
      .subscribe((obj) => {
        this.shared.toastr.success(obj.message, '', { closeButton: true });
        this.getComponentData(componentName, itemID);
        return this.shared.handleItemById({ componentName, id });
      });
  }

  /**
   * store response data in subject subscription
   * It override in each service
   * @param componentName: Component Name
   * @param data: response data
   * @param isCreate: isCreate
   * @param filterMethod: is this method filtering the data?
   */
  storeComponentData(componentName: string, data: any, isCreate: boolean = false, filterMethod: boolean = false) {
    return { component: componentName, data, isCreate, filterMethod };
  }

  apiRequest(
    method: string,
    url: string,
    body: {} = {},
    headers?: any,
    params?: any,
    responseType?: any
  ): Observable<any> {
    return this.api.request(method, url, body, headers, params, responseType);
  }

  /**
   * generic method to be overridden in specific services to refactor filters in each module service as needed
   * @param filters passed filters object
   */
  refactorFilters(filters: any) {
    this.featureProps.forEach((featureProp) => {
      if (featureProp.list && featureProp.list.filterFieldType === FilterFieldTypeEnum.DatePicker) {
        const dateValue = filters[featureProp.name];
        if (dateValue) {
          const convertedDate = this.shared.momentFormat(dateValue);
          filters[featureProp.name] = convertedDate;
        }
      }
    });
    this.searchQuery && (filters.keyword = this.searchQuery);
    return filters;
  }

  /**
   * generic method to be overridden in specific services to refactor filters before converting
   * them into query parameters in each module service as needed
   * @param filters passed filters object
   */
  refactorFiltersBeforeQueryParams(filters: any) {
    return filters;
  }

  /**
   * generic method to be overridden in specific services to refactor sorting in each module service as needed
   * @param sorting passed sorting object
   */
  refactorSorting(sorting: any, passedRows?: any) {
    return sorting;
  }

  customEnvironment() {
    if (customEnvironment.dummyDataLoader) {
      this.dummyDataLoader = true;
    }
  }

  loadDummyData(cid: string = this.cid) {
    this.storeResourceListResponse(this.listPrepareOperations(DummyData[cid]));
  }

  returnDummyData(dummyVar: string) {
    return DummyData[dummyVar];
  }

  /**
   * compare fn to compare current date to another date by a compare keyword to be overridden as needed in each separate module service
   * @param inputDate to compare
   * @param compareKeyword compare string of switch case
   */
  dateAgoChecker(inputDate: any, compareKeyword?: string): boolean {
    const now = moment(new Date());
    let compareTo = now;
    if (compareKeyword === 'yesterday') {
      compareTo = now.subtract(1, 'days');
    }
    const date = moment(inputDate);
    return date > compareTo;
  }

  /**
   * helper fn to convert any type of data into date with moment library
   * @param date string
   */
  dateConverter(date: any) {
    return moment(date);
    // return moment(new Date());
  }

  openImportDialog(service: RootService) {
    this.shared.dialog.open(ImportDialogComponent, {
      data: {
        service,
      },
      panelClass: 'import-dialog',
    });
  }

  onChangeValue_checker(column: ItemProps, event: any) {
    if ((column.list && column.list.onChange) || (column.form && column.form.onChange)) {
      this.onChangedValue(column, event);
    } else {
      return;
    }
  }

  onChangedValue(column: ItemProps, event: any) {}

  loadSelectList(fieldName: string, url?: string, type?: string) {
    const field =
      this.featureProps.find((prop) => prop[type] && prop.name === fieldName) ||
      this.featureProps.find((prop) => prop.name === fieldName);

    if (url && type) {
      this.resourceGet(url, this.getFieldByName(fieldName)[type].listPaginate).subscribe((result: any) => {
        this.lists[field[type].listPrefix] = this.refactorSelectListsResponse(
          field[type].listPrefix,
          result,
          field[type].listPaginate
        );
        this.lists$.next(field[type].listPrefix);
      });
    } else {
      if (field.list.dataUrl) {
        this.resourceGet(field.list.dataUrl, this.getFieldByName(fieldName)[type].listPaginate).subscribe(
          (result: any) => {
            this.lists[field.list.listPrefix] = this.refactorSelectListsResponse(
              field.list.listPrefix,
              result,
              field.list.listPaginate
            );
            this.lists$.next(field.list.listPrefix);
          }
        );
      } else if (field.form.dataUrl) {
        this.resourceGet(field.form.dataUrl, this.getFieldByName(fieldName)[type].listPaginate).subscribe(
          (result: any) => {
            this.lists[field.form.listPrefix] = this.refactorSelectListsResponse(
              field.form.listPrefix,
              result,
              field.form.listPaginate
            );
            this.lists$.next(field.form.listPrefix);
          }
        );
      } else {
        return;
      }
    }
  }

  clearSelectList(fieldName?: string, listPrefix?: string) {
    let _listprefix: string;
    if (fieldName) {
      const field: ItemProps = this.getFieldByName(fieldName);
      if (field.list && field.list.listPrefix) {
        _listprefix = field.list.listPrefix;
      } else if (field.form && field.form.listPrefix) {
        _listprefix = field.form.listPrefix;
      }
    } else if (listPrefix) {
      _listprefix = listPrefix;
    }
    if (this.lists[_listprefix]) {
      this.lists[_listprefix] = [];
    }
  }

  getFieldByName(fieldName: string) {
    if (fieldName) {
      return this.featureProps.find((x) => x.form && x.name === fieldName);
    }
  }

  loadSelectLists(type?: string) {
    this.featureProps.forEach((field) => {
      if (
        (field.list &&
          (field.list.filterFieldType || field.list.filterFieldTypeInline) &&
          field.list.dataUrl &&
          field.list.listPrefix &&
          (field.list.searchable || field.list.searchableInline)) ||
        (field.form && field.form.formFieldType && field.form.dataUrl && field.form.listPrefix)
      ) {
        if (type && type === 'list') {
          if (field && field.list && field.list.dataUrl && field.list.listPrefix) {
            this.resourceGet(field.list.dataUrl, field.list.listPaginate).subscribe((result: any) => {
              this.lists[field.list.listPrefix] = this.refactorSelectListsResponse(
                field.list.listPrefix,
                result,
                field.list.listPaginate
              );
              this.lists$.next(field.list.listPrefix);
            });
          }
        } else if (type && type === 'form') {
          if (field && field.form && field.form.dataUrl && field.form.listPrefix) {
            this.resourceGet(field.form.dataUrl, field.form.listPaginate).subscribe((result: any) => {
              this.lists[field.form.listPrefix] = this.refactorSelectListsResponse(
                field.form.listPrefix,
                result,
                field.form.listPaginate
              );
              this.lists$.next(field.form.listPrefix);
            });
          }
        } else {
          if (field && field.list && field.list.dataUrl && field.list.listPrefix) {
            this.resourceGet(field.list.dataUrl, field.list.listPaginate).subscribe((result: any) => {
              this.lists[field.list.listPrefix] = this.refactorSelectListsResponse(
                field.form.listPrefix,
                result,
                field.form.listPaginate
              );
              this.lists$.next(field.list.listPrefix);
            });
          } else if (field && field.form && field.form.dataUrl && field.form.listPrefix) {
            this.resourceGet(field.form.dataUrl, field.form.listPaginate).subscribe((result: any) => {
              this.lists[field.form.listPrefix] = this.refactorSelectListsResponse(
                field.form.listPrefix,
                result,
                field.form.listPaginate
              );
              this.lists$.next(field.form.listPrefix);
            });
          }
        }
      }
    });
  }

  refactorSelectListsResponse(listPrefix: string, response: any, listPaginate: boolean = false) {
    if (response.data) {
      if (listPaginate) {
        return response;
      } else {
        return response.data;
      }
    }
  }

  /**
   * returns observable from component url into a data observable for async loading resources in template
   * @param apiPath path of api resource request
   * @param pagination request paginated or not
   * @param params parameters to the request
   */
  resourceGet(apiPath: string, pagination: boolean = false, params: any = {}) {
    if (!pagination) {
      params.page = 0;
    }
    return this.apiRequest('GET', apiPath, {}, {}, params);
  }

  downloadSampleFile(url: string, fileType: string) {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const dateTime = date + '_' + time;

    const fileName = `${this.cid}_${dateTime}.${fileType}`;

    return this.api.request('GET', url, null, {}, {}, 'blob').subscribe(
      (res) => {
        this.api.fileSaverService.save(res, fileName, fileType);
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.sampleFileGenerated'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  toFormData<T>(formValue: T, _method?: string) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      /**
       * fix for boolean values in formData method
       */
      if (value === true) {
        formData.append(key, '1');
      } else if (value === false) {
        formData.append(key, '0');
      } else {
        formData.append(key, value);
      }
    }
    if (_method) {
      formData.append('_method', _method);
    }
    return formData;
  }

  navigateUrlOverride(url: string, type?: string, id?: number) {
    return url;
  }

  closeIssue(id: number) {}

  reopenIssue(id: number) {}

  goBack() {
    this.shared.location.back();
  }

  setActiveTab(tabGroup: string, tabName: number) {
    this.tabGroupActiveTab[tabGroup] = tabName;
  }

  clearActiveTabs() {
    Object.keys(this.tabGroupActiveTab).forEach((key) => {
      this.tabGroupActiveTab[key] = 0;
    });
  }

  getActiveTab(tabGroup: string): number {
    return this.tabGroupActiveTab[tabGroup] ? this.tabGroupActiveTab[tabGroup] : 0;
  }

  /**
   * helper method to patch some values in form
   * to be overridden on specific services
   */
  formInjectDefaultValues() {
    // const userName = this.credentialsService.credentials.userId;
    // const currentDate = this.shared.moment().format('YYYY-MM-DD');
    // this.updateInputValue.next({fieldName: 'name', fieldValue: userName, updateForm: true})
    // this.updateInputValue.next({fieldName: 'contractStart', fieldValue: currentDate, updateForm: true})
    this.formInjectDefaultValues$.next(true);
  }

  actionOnCreatedItem(id: number) {}

  /**
   * @param formValue : form value
   * @param controls : control names to be removed from form value
   */
  removeFormControlValue(formValue: any, controls: string[]) {
    if (formValue && controls && controls.length) {
      controls.forEach((control) => {
        if (formValue.hasOwnProperty(control)) {
          delete formValue[control];
        }
      });
    }
  }

  setModulePermissions() {
    this.permission = this.permissionsService.getPermissionsHelper(this.permissionsKey);
    // console.log('this.permission', this.permission);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/401', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  generateShareLink(fileType: string, params: any) {
    let body: any;
    body = {
      fileType: 'csv',
    };
    if (fileType) {
      body.fileType = fileType;
    }
    return this.doGenerateShareLink(this.getFunctionURL('share'), body, params);
  }

  /**
   * Share link Items Request
   * url
   * id
   */
  doGenerateShareLink(url: string, body: any, params?: any) {
    return this.apiRequest('POST', url, body, {}, params).subscribe(
      (res) => {
        if (res.data && res.data.url) {
          this.copyToClipboard(res.data.url, this.shared.translate.instant('common.shareLink'));
        }
        // this.shared.toastr.success(
        //   this.shared.translate.instant(this.cid + '.exported'),
        //   this.shared.translate.instant('common.success')
        // );
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  /**
   * End Item
   */
  end(id: number) {
    return this.doEnd(this.getFunctionURL(`end/${id}`), id);
  }

  void(id: number) {
    return this.doVoid(this.getFunctionURL(``), id);
  }

  /**
   * End Item Request
   * url
   * id
   */
  doEnd(url: string, id: number) {
    return this.api.put(url, { ids: [id] }).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          this.shared.translate.instant(this.cid + '.ended'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  doVoid(url: string, id: number) {
    return this.api.request('DELETE', url, { ids: [id] }).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          res.message ? res.message : this.shared.translate.instant(this.cid + '.voided'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  voidMultiple(ids: {}) {
    return this.doVoidMultiple(this.getFunctionURL(``), ids);
  }

  doVoidMultiple(url: string, body: any) {
    return this.api.request('DELETE', url, { ids: body }).subscribe(
      (res: ApiResponse) => {
        this.afterSuccessListAction();
        this.shared.toastr.success(
          res.message ? res.message : this.shared.translate.instant(this.cid + '.voided'),
          this.shared.translate.instant('common.success'),
          { closeButton: true }
        );
        this.afterSuccessListAction();
        this.updateResources.next(true);
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  // pay(id: number) {
  //   return this.doPay(this.getFunctionURL(`paid/${id}`), id);
  // }

  // doPay(url: string, id: number) {
  //   return this.api.put(url, { ID: id }).subscribe(
  //     (res: ApiResponse) => {
  //       this.afterSuccessListAction();
  //       this.shared.toastr.success(
  //         this.shared.translate.instant(this.cid + '.paid'),
  //         this.shared.translate.instant('common.success')
  //       );
  //       this.afterSuccessListAction();
  //       this.updateResources.next();
  //     },
  //     err => {
  //       this.errorHandle(err);
  //     }
  //   );
  // }
  /**
   * to filter the list
   * @param filterBy key value pair
   */
  filter(filterBy: FilterObject) {
    this.customFilterActiveLink = filterBy.value;
    this.shared.utilitiesService.setFilters(this.refactorFilters(filterBy));
  }

  /**
   * to do custom action when using the tabs above the list,
   * usually used to filter the list but could be used to do anything in every separate module
   * @param link object containing name and key value pair
   */
  customFilterLinksAction(link: CustomFilterLink) {
    this.filter(link.filter);
  }

  openDeleteMeterDialog(data: any, action: string, cssClass: string, service: any) {
    this.openActionDialog({
      service,
      action,
      title: this.cid + `.confirm_${action}.title`,
      svgIcon: 'exclamation-triangle',
      additionalMessage: this.cid + `.confirm_${action}.additionalMessage`,
      messageClass: 'color-danger',
      message: this.cid + `.confirm_${action}.message`,
      submitText: this.cid + `.confirm_${action}.submitText`,
      cancelText: this.cid + `.confirm_${action}.cancelText`,
      data,
      submitCssClass: cssClass,
    });
  }

  isEmptyResult(obj: any) {
    for (let key in obj) {
      if (obj[key]) {
        return (this.notFoundResult = true);
      }
    }
    return (this.notFoundResult = false);
  }
}
