import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import {
  ChangePasswordComponent,
  ForgotPasswordComponent,
  LoginComponent,
  WrongPasswordComponent,
} from '@app/auth/components';
import { LoginGuard } from '@app/auth/login.guard';
import { SetupAccountComponent } from './components/setup-account/setup-account.component';

const routes: Routes = [
  {
    path: 'setup-account/:token/:email',
    component: SetupAccountComponent,
    data: { title: extract('setupAccount') },
    // canActivate: [LoginGuard],
  },
  {
    path: 'change-password/:token/:email',
    component: ChangePasswordComponent,
    data: { title: extract('ChangePassword') },
  },

  {
    path: 'wrong-password',
    component: WrongPasswordComponent,
    data: { title: extract('ChangePassword') },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: extract('Login') },
    canActivate: [LoginGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: extract('forgotPassword') },
    canActivate: [LoginGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {
  static components = [
    LoginComponent,
    SetupAccountComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    WrongPasswordComponent,
  ];
}
