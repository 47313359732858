import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrong-password',
  templateUrl: './wrong-password.component.html',
  styleUrls: ['./wrong-password.component.scss'],
})
export class WrongPasswordComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
