import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, last, take, takeWhile } from 'rxjs/operators';
import { CredentialsService, I18nService, untilDestroyed } from '@app/core';
import { MenuItems } from '@app/menuItems';
import { Router } from '@angular/router';

import { AppHelper } from '@app/core/classes/app-helper';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { UserDetailsService } from '@app/shared/services/user-details/user-details.service';
import { SharedService } from '@app/shared/services/shared.service';
import { RootService } from '@app/core/root.service';
import { EngineOdometerI18nHelperService } from '@app/shared/services/engine-odometer-i18n/engine-odometer-i18n-helper.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { HomeService } from '@app/home/home.service';
import { TutorialService } from '@app/shared/services/tutorial/tutorial.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy, AfterViewInit {
  alive = true;
  menuItems = new MenuItems();
  logo: any = '/assets/logo.svg';
  @ViewChild('sidenav') sidenav!: MatSidenav;
  menuSizeState = 'large';

  // menuSizePrevState = 'small';

  constructor(
    private media: MediaObserver,
    private i18nService: I18nService,
    private router: Router,
    private permissionsService: PermissionsService,
    public userDetailsService: UserDetailsService,
    private shared: SharedService,
    private service: RootService,
    private engineOdometeri18nService: EngineOdometerI18nHelperService,
    public tourService: TourService,
    private tutorialService: TutorialService,
    private credentialService: CredentialsService
  ) {
    // this.permissionsService.requestPermissions();

    this.shared.getCompanyLogo().subscribe((res: any) => {
      this.logo = res;
    });
  }

  get currentLang(): string {
    return this.i18nService.language;
  }

  ngOnInit() {
    // Automatically close side menu on screens > sm breakpoint
    this.media
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) =>
          changes.some((change) => change.mqAlias === 'xs' || change.mqAlias === 'sm')
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        // this.hideMenu()
        this.toggleMenuSize('small');
      });

    /**
     * call the permissions request once on init,
     * if already called before on routing just use the loaded and don't call api again
     * useful when navigating to routes that doesn't have permissions to load permissions
     * and validate other modules before navigating to them
     */
    this.permissionsService.permissionsRequest().pipe(take(1)).subscribe();
    this.permissionsSubscription();

    // Unneeded code: Avoid calling 404 http requests..
    // this.getSettings();
    // if (this.credentialService.isfirstLogin) {
    //   this.firstTimeAppTour();
    // }
  }

  /**
   * check for triggering refresh subject on permissions service for refreshing permissions from api
   * to use it just use permissionsService.refresh.next()
   */
  permissionsSubscription() {
    this.permissionsService.refresh.pipe(takeWhile(() => this.alive)).subscribe(() => {
      // console.log('refreshed');
      this.permissionsService
        .permissionsRequest({ refresh: true })
        .pipe(take(1))
        .subscribe(() => () => {
          // console.log('Refreshed Permissions');
        });
    });
  }

  ngOnDestroy() {
    // Needed for automatic unsubscribe with untilDestroyed
    this.alive = false;
  }

  ngAfterViewInit() {
    AppHelper.calcMainContainerWidth();
    AppHelper.checkWindowResize();
  }

  toggleMenuSize(size?: string) {
    if (this.sidenav.opened) {
      if (size) {
        this.menuSizeState = size;
      } else {
        if (this.menuSizeState === 'large') {
          // this.menuSizePrevState = 'large';
          this.menuSizeState = 'small';
        } else {
          // this.menuSizePrevState = 'small';
          this.menuSizeState = 'large';
        }
      }

      setTimeout(() => {
        AppHelper.calcMainContainerWidth();
      }, 300);
    }
  }

  /**
   * get's child customFilterLinks from menu item and push them into array into the next function to process them
   * @param menuItem > single menu item
   */
  getChildrenRoutes(menuItem: any) {
    const childLinks: any = [];
    if (menuItem.children) {
      menuItem.children.forEach((child: any) => {
        if (child.url) {
          childLinks.push(child.url);
        }
        if (child.children) {
          child.children.forEach((subChild: any) => {
            if (subChild.url) {
              childLinks.push(subChild.url);
            }
          });
        }
      });
    }
    return childLinks;
  }

  /**
   * helper method for highlighting active parent menu item
   * get's the children customFilterLinks array and compare to current url to find
   * if some of the customFilterLinks in array match the current router and return boolean for template
   * @param menuItem > single menu item
   */
  checkCurrentRoute(menuItem: any): boolean {
    const currentUrl = this.router.url;
    return this.getChildrenRoutes(menuItem).some((url: string) => currentUrl === url);
  }

  checkPermission(module?: string, key?: string) {
    if (!module) {
      return true;
    } else {
      return !!this.permissionsService.getPermissions(module, key);
    }
  }

  refreshPermissions() {
    this.permissionsService.refresh.next(true);
  }

  getSettings() {
    this.service
      .apiRequest('GET', `settings`)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        const obj = JSON.parse(JSON.stringify(data.data));
        obj.logo ? this.shared.setCompanyLogo(obj.logo) : this.shared.setCompanyLogo('/assets/logo.svg');
        obj.primaryColor ? AppHelper.setThemeVars(obj.primaryColor) : AppHelper.setThemeVars('#3c4d6c');
        // obj.usageParameter ? this.engineOdometeri18nService.translateUsageParameter(obj.usageParameter) : '';
      });
  }

  firstTimeAppTour() {
    this.tourService.initialize(this.tutorialService.wholeAppTourSteps);
    this.tourService.start();
  }

  ModuleTour() {
    this.shared.isModuleTour.next(true);
  }

  getStepIndex(item: any) {
    return this.tutorialService.wholeAppTourSteps.findIndex((step: any) => step.anchorId === item.title);
  }
}
