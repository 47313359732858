import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RootService } from '@app/core/root.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss'],
})
export class ImportDialogComponent {
  service: RootService;
  fieldName = 'file';
  SelectedFile: any;

  importData: any;

  form: UntypedFormGroup = new UntypedFormGroup({
    file: new UntypedFormControl(''),
  });

  submitted = false;
  errors: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialogRef: MatDialogRef<ImportDialogComponent>) {
    this.service = this.data.service;
    this.importData = this.service.importData;
  }

  uploadFile(event: any, fieldName: any, value: any) {
    const file = event.target.files[0];
    this.form.controls[fieldName].setValue(file);
    this.SelectedFile = file;
    this.submit();
  }

  submit() {
    return this.service
      .apiRequest('POST', `${this.service.cid}/import`, this.service.toFormData(this.form.value))
      .subscribe(
        (res: any) => {
          let msg = this.service.shared.translate.instant('common.imported');
          if (res && res.message) {
            msg = res.message;
          }
          this.service.shared.toastr.success(msg, this.service.shared.translate.instant('common.success'), {
            closeButton: true,
          });
          this.submitted = true;
          this.errors = [];
          this.service.updateResources.next(true);
          this.matDialogRef.close();
        },
        (error: any) => {
          if (error.error && error.error.message) {
            this.service.shared.toastr.error(
              error.error.message,
              this.service.shared.translate.instant('validation_errors.import_failed'),
              { closeButton: true }
            );
            this.submitted = true;
            if (error.error.errors) {
              this.errors = error.error.errors;
            }
          }
        }
      );
  }

  downloadSampleFile() {
    return this.service.downloadSampleFile(this.service.cid + '/sample', 'csv');
  }
}
