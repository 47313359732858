<mde-popover #appPopover="mdePopover">
  <div class="mdePopover" [ngClass]="listOptions?.filterContainerCssClass" fxFlex fxLayout>
    <form
      [formGroup]="form"
      (ngSubmit)="filter()"
      *ngIf="form"
      fxLayout="column"
      fxFlex
      Flex
      fxLayoutAlign="space-around"
    >
      <mat-card class="filters-div">
        <div class="title">
          <p>{{ showTitle ? 'common.filters' : ('' | translate) }}</p>
        </div>
        <div class="form-content">
          <ng-container *ngIf="sectionedItemProps">
            <ng-container *ngFor="let column of sectionedItemProps.defaultSection | pairs; trackBy: trackByFn">
              <ng-container *ngIf="column?.list && column?.list?.searchable && column?.list?.filterFieldType">
                <div
                  [ngClass]="column?.list?.filterOptions?.cellCssClass"
                  [hidden]="!fieldShowConditions(column)"
                  [style.width.px]="column?.list?.width"
                  [fxFlex.gt-lg]="getInputGridSize(column?.list?.filterOptions?.grid?.gt_lg, gridGapSize)"
                  [fxFlex.lt-xl]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_xl, gridGapSize)"
                  [fxFlex.lt-lg]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_lg, gridGapSize)"
                  [fxFlex.lt-md]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_md, gridGapSize)"
                  [fxFlex.lt-sm]="getInputGridSize(column?.list?.filterOptions?.grid?.lt_sm, gridGapSize)"
                >
                  <app-core-form-content
                    [cid]="service.cid"
                    [customCid]="service.customCid"
                    [service]="service"
                    [field]="refactorField(column)"
                    [form]="form"
                    [isEdit]="isEdit"
                    [isClone]="isClone"
                    [isSubmitted]="isSubmitted"
                    [appliedFilter]="appliedFilter"
                    [manualRefresh]="manualRefresh"
                  >
                  </app-core-form-content>
                </div>
                <span class="line"></span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="bottom-card">
          <button class="submit_button clear" mat-button (click)="resetFilter()">
            {{ 'common.reset' | translate }}
          </button>
          <button *ngIf="columns" class="submit_button apply" [disabled]="!form.valid" type="submit" mat-button>
            {{ 'common.applyFilter' | translate }}
          </button>
        </div>
      </mat-card>
    </form>
  </div>
</mde-popover>
<div>
  <button
    mat-button
    [mdePopoverOverlapTrigger]="false"
    mdePopoverArrowOffsetX="100"
    [mdePopoverTriggerFor]="appPopover"
    [mdePopoverCloseOnClick]="false"
    mdePopoverTriggerOn="click"
    #popoverTrigger="mdePopoverTrigger"
    class="filter-menu-button"
    (opened)="showPopup()"
    (closed)="hidePopup()"
  >
    <mat-icon
      fxLayout="row inline"
      fxLayoutAlign="center center"
      class="mdi-icon"
      svgIcon="filter"
      [attr.size]="'16px'"
    ></mat-icon>
    <span class="mx-2">{{ 'common.filter' | translate }} </span>
  </button>
</div>
