import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '@app/core/api-service/api.service';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { NotificationsService } from '@app/notifications/notifications.service';
import Pusher from 'pusher-js';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/core';
import { SaasService } from '../saas/saas.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  messageCount = new BehaviorSubject(0);
  count = 0;
  public notification: Subject<any> = new Subject<any>();
  private pusherClient: Pusher;

  constructor(
    public shared: SharedService,
    private api: ApiRequestService,
    private notificationsService: NotificationsService // private saasService: SaasService
  ) {}

  openConnection(userId: string) {
    if (environment?.pusher?.key) {
      if (userId) {
        // console.log(environment.pusher.options.cluster);
        //
        this.pusherClient = new Pusher(environment.pusher.key, environment.pusher.options);

        const channel = this.pusherClient.subscribe(`channel--${userId}`);

        // console.log(channel);

        channel.bind(`event--${userId}`, (data: any) => {
          // console.log(data);
          this.notification.next({
            data,
            eventName: `event--${userId}`,
          });

          this.notificationsService.resourcesList.additionalData.totalUnread++;
          this.notificationsService.resourcesList.data.unshift(data.data); //add the incoming notifications to the history
        });
      }
    }
  }

  closeConnection(userId: string) {
    if (environment?.pusher?.key) {
      if (userId) {
        return this.pusherClient.unsubscribe(`channel--${userId}`);
      }
    }
  }
}
