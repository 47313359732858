import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ItemProps } from '@app/interfaces';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { debounceTime, map, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-ng-select-api',
  templateUrl: './ng-select-api.component.html',
  styleUrls: ['./ng-select-api.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgSelectApiComponent),
      multi: true,
    },
  ],
})
export class NgSelectApiComponent implements OnInit, ControlValueAccessor, OnDestroy {
  response: any = {};
  items: any = [];
  itemsBuffer: any = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  alive = true;
  currentPage = 1;
  lastPage = 1;
  totalRecords = 0;
  search$ = new Subject<string>();
  params = {
    page: 1,
  };
  listsAdditions: any[] = [];
  @Input() item: any;
  @Input() service: RootService;
  @Input() form: any;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() requiredAsterisk: any;
  @Input() field: ItemProps;
  @Input() disabledInput: boolean;
  @Input() name: string;
  // eslint-disable-next-line
  @Input('value') val: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() StatusChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  get value() {
    return this.val;
  }

  set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit() {
    this.triggerLoadSelectListOnFirstInitComponent();
    this.listsSubscription();
    this.listsAdditionsSubscription();
    this.SearchSubscription();
  }

  /**
   * workaround for when component loaded after request is already filled in service.
   * re trigger subscription then if service has data for this specific select input
   */
  triggerLoadSelectListOnFirstInitComponent() {
    const listPrefix =
      this.field.form && this.field.form.listPrefix
        ? this.field.form.listPrefix
        : this.field.list && this.field.list.listPrefix
        ? this.field.list.listPrefix
        : null;
    if (listPrefix) {
      if (this.service.lists[listPrefix]) {
        this.service.lists$.next(listPrefix);
      }
    }
  }

  listsSubscription() {
    this.service.lists$.pipe(takeWhile(() => this.alive)).subscribe((listPrefix: string) => {
      if (
        (this.field.form && listPrefix === this.field.form.listPrefix) ||
        (this.field.list && listPrefix === this.field.list.listPrefix)
      ) {
        // console.log('initial');
        // console.log(this.service.lists[this.field.list.listPrefix]);
        this.response = this.service.lists[listPrefix];
        if (!!this.response && !!this.response.data && !!this.response.meta.pagination) {
          this.items = this.response.data && this.response.data.length ? [...this.response.data] : [];
          // console.log(this.items);
          this.currentPage = this.response.meta.pagination.currentPage;
          this.lastPage = this.response.meta.pagination.totalPages;
          this.totalRecords = this.response.meta.pagination.total;
        }
      }
    });
  }

  listsAdditionsSubscription() {
    this.service.listsAdditions$
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { listPrefix: string; items: any[] }) => {
        // console.log('listsAdditionsSubscription', event);
        if (
          (this.field.form && event.listPrefix === this.field.form.listPrefix) ||
          (this.field.list && event.listPrefix === this.field.list.listPrefix)
        ) {
          this.listsAdditions = [...this.listsAdditions, ...event.items];

          this.response = this.service.lists[event.listPrefix];
          let responseData: any[] = [];
          if (!!this.response && !!this.response.data && !!this.response.meta.pagination) {
            of(this.response.data)
              .pipe(
                map((items) =>
                  items.filter((item: any) => {
                    const isValid = this.listsAdditions.some((listAddition: any) => {
                      if (item.id !== listAddition.id) {
                        return true;
                      }
                    });
                    if (isValid) {
                      return item;
                    }
                  })
                )
              )
              .subscribe((items) => {
                responseData = items;
                this.response.data = [...responseData];
                this.listsAdditions.forEach((listAddition: any) => {
                  this.response.data.unshift(listAddition);
                });
              });

            this.items = this.response.data && this.response.data.length ? [...this.response.data] : [];
            this.currentPage = this.response.meta.pagination.currentPage;
            this.lastPage = this.response.meta.pagination.totalPages;
            this.totalRecords = this.response.meta.pagination.total;
          }
        }
      });
  }

  removeDuplicates(item: any, compareItem: any) {
    return item.id !== compareItem.id;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onValueChange(field: ItemProps, event: any) {
    this.valueChanged.emit({ field, event });
  }

  SearchSubscription() {
    this.search$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.loading = true;
        })
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((term: any) => {
        this.searchRequest(term)
          .pipe(takeWhile(() => this.alive))
          .subscribe((response: any) => {
            if (!!response && !!response.data && !!response.meta.pagination) {
              this.loading = false;
              this.items = response.data && response.data.length ? [...response.data] : [];
              this.currentPage = response.meta.pagination.currentPage;
              this.lastPage = response.meta.pagination.totalPages;
              this.totalRecords = response.meta.pagination.total;
            }
          });
      });
  }

  searchRequest(term: string, key: string = 'name') {
    if (term) {
      this.params[key] = term;
    } else {
      delete this.params[key];
    }
    this.params.page = 1;
    return this.service.resourceGet(
      this.field.form && this.field.form.dataUrl
        ? this.field.form.dataUrl
        : this.field.list && this.field.list.dataUrl
        ? this.field.list.dataUrl
        : null,
      true,
      this.params
    );
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  // onScroll({ end }: any) {
  //   console.log('end');
  //   console.log(end);
  //   if (this.loading || (this.lastPage === this.currentPage)) {
  //     return;
  //   }
  //
  //   if (end && (this.lastPage > this.currentPage)) {
  //     this.fetchMore();
  //   }
  // }

  ngOnDestroy(): void {
    this.alive = false;
  }

  checkSelected(control: AbstractControl, id: number): boolean {
    if (control && id && control.value) {
      if (control.value.find((x: number) => x === id)) {
        return true;
      }
    }
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  private fetchMore() {
    if (this.lastPage > this.currentPage) {
      this.loading = true;
      const nextPage = this.currentPage + 1;
      this.service
        .resourceGet(
          this.field.form && this.field.form.dataUrl
            ? this.field.form.dataUrl
            : this.field.list && this.field.list.dataUrl
            ? this.field.list.dataUrl
            : null,
          true,
          { ...this.params, ...{ page: nextPage } }
        )
        .subscribe((response: any) => {
          if (
            response &&
            response.meta.pagination &&
            response.meta.pagination.currentPage &&
            response.meta.pagination.totalPages &&
            response.meta.pagination.total
          ) {
            let responseData: any[] = [];
            of(response.data)
              .pipe(
                map((items) =>
                  items.filter((item: any) => {
                    const isValid =
                      this.listsAdditions && this.listsAdditions.length
                        ? this.listsAdditions.some((listAddition: any) => {
                            return item.id !== listAddition.id;
                          })
                        : true;
                    if (isValid) {
                      return item;
                    }
                  })
                )
              )
              .subscribe((items) => {
                responseData = items;
              });

            this.items = response.data && response.data.length ? [...this.items, ...responseData] : [];

            this.currentPage = response.meta.pagination.currentPage;
            this.lastPage = response.meta.pagination.totalPages;
            this.totalRecords = response.meta.pagination.total;
            this.loading = false;

            // console.log(this.items);
          }
        });
    } else {
      return;
    }
  }
}
