import { ListGridOptionsComponent as baseListGridOptionsComponent } from '@afaqyit/frontend-core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomFilterLink, FilterObject, ItemProps } from '@app/interfaces';
import { HideAnimation, showAnimation } from '@app/shared/animations/transform-opacity';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RootService } from '../../../core/root.service';

@Component({
  selector: 'app-list-grid-options',
  templateUrl: './list-grid-options.component.html',
  styleUrls: ['./list-grid-options.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '200ms ease-in-out',
            transform: 'translateX(50%)',
            opacity: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '200ms ease-in-out',
            transform: 'translateX(50%)',
            opacity: '0',
          },
        }),
      ]),
    ]),
  ],
})
export class ListGridOptionsComponent extends baseListGridOptionsComponent {
  form: UntypedFormGroup;
  @Input() groupView: any;
  @Input() listOptions: any;
  @Input() controller: any;
  @Input() item: any;
  @Input() title: any;
  @Input() showTitle: boolean;
  @Input() service: RootService;
  @Input() columns: ItemProps[];
  @Input() params: any;
  @Input() hasFilters: boolean;
  searchKey: any;

  exportAsList: ExportAsItem[] = [
    {
      type: 'csv',
      icon: 'file-download-outline',
    },
    {
      type: 'xls',
      icon: 'file-download-outline',
    },
    {
      type: 'xlsx',
      icon: 'file-download-outline',
    },
    {
      type: 'pdf',
      icon: 'file-download-outline',
    },
  ];

  constructor(
    protected router: Router,
    protected us: UtilitiesService,
    protected tourService: TourService,
    protected fb: UntypedFormBuilder
  ) {
    super(router, us, tourService);
  }

  // doAction(actionType: string, options?: {}) {
  //   this.doActionEvent.emit({ actionType, options });
  // }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.buildSearchInputFormGroup();
    this.search();
    super.ngOnInit();
  }

  buildSearchInputFormGroup() {
    this.form = this.fb.group({
      searchKey: '',
    });
  }

  navToCreate() {
    return this.router.navigate([`${this.router.url}/create`]);
  }

  filter(filterBy: FilterObject) {
    this.service.customFilterActiveLink = filterBy.value;
    this.us.setFilters(this.service.refactorFilters({}));
  }

  customFilterLinksAction(link: CustomFilterLink) {
    this.service.customFilterActiveLink = link.name;
    this.service.customFilterLinksAction(link);
  }

  toggleInlineFilter() {
    this.service.showInlineFilters = !this.service.showInlineFilters;
  }

  search() {
    this.form.controls['searchKey'].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((value: any) => {
          if (value === ' ') {
            this.service.filter({ ...this.service.filterData });
          } else {
            this.service.searchQuery = value;
            this.service.filter({ keyword: value, ...this.service.filterData });
          }
        })
      )
      .subscribe();
  }
}

/**
 * Local Interface for prop: exportAsList
 */
interface ExportAsItem {
  type: string;
  icon: string;
}
