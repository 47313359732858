import { Injectable } from '@angular/core';
import { RootService } from '@app/core/root.service';
import { ItemProps } from '@app/interfaces';
import { Router } from '@angular/router';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { Validators } from '@angular/forms';
import { SharedService } from '@app/shared/services/shared.service';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { CredentialsService } from '@app/core';
import { CustomValidators } from '@app/core/classes/custom-validators/custom-validators';
import { numberValidator } from '@app/core/classes/selectiveCustomValidators';

@Injectable({
  providedIn: 'root',
})
export class formPopupService extends RootService {
  _featureProps: ItemProps[] = [];
  _moduleName: string = '';
  _apiRouterPrefix: string = '';

  constructor(
    router: Router,
    api: ApiRequestService,
    permissionsService: PermissionsService,
    shared: SharedService,
    private credentialsService: CredentialsService
  ) {
    super(router, api, permissionsService, shared);
  }

  routerPrefix(val: string = '') {
    return val ? val : this._moduleName;
  }

  apiRouterPrefix(val?: string): string {
    return val ? val : this._apiRouterPrefix;
  }

  refactorFormBeforeSubmit(formValue: any) {
    return formValue;
  }

  refactorSelectListsResponse(listPrefix: string, response: any, listPaginate: boolean = false): any {
    response.data.forEach((item: any) => {
      if (item.nameEn) {
        item.name = item.nameEn;
      }
    });
    return super.refactorSelectListsResponse(listPrefix, response, listPaginate);
  }

  get featureProps(): ItemProps[] {
    return this._featureProps;
  }
}
