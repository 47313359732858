import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { findIndex, take, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() item: any;
  @Input() service: any;
  @Input() form: any;
  @Input() isEdit: any;
  @Input() isClone: any;

  @Output() UpdateFormFieldValue: EventEmitter<any> = new EventEmitter();

  formValue: any = {};

  selectedAllArray: {} = {};
  permissions: {
    val?: string;
    custom?: any;
  } = {};

  templateLoaded = false;
  alive = true;
  requestCount = 0;

  customDefaultPermissions: {
    [key: string]: string[];
  } = {};

  private templateLoaded$: Subject<any> = new Subject<any>();
  private dataLoaded$: Subject<any> = new Subject<any>();

  constructor() {}

  handleAll(module: string, emit: boolean = true, updateForm: boolean = true): void {
    this.selectedAllArray[module] = { all: true, custom: [] };

    this.permissions[module].custom = {};
    this.formValue[module] = this.selectedAllArray[module];
    this.emitValues(emit, updateForm);
  }

  handleCustom(module: string, emit: boolean = true, updateForm: boolean = true): void {
    this.selectedAllArray[module] = { all: false, custom: [] };

    if (this.customDefaultPermissions[module] && this.customDefaultPermissions[module].length) {
      this.customDefaultPermissions[module].forEach((id) => {
        this.togglePermission(module, id, false, false);
        this.permissions[module].custom[id] = true;
      });
    }

    this.formValue[module] = this.selectedAllArray[module];
    this.emitValues(emit, updateForm);
  }

  clearAll(module: string, emit: boolean = true, updateForm: boolean = true): void {
    this.selectedAllArray[module] = { all: false, custom: [] };

    this.permissions[module].custom = {};
    this.formValue[module] = this.selectedAllArray[module];
    this.emitValues(emit, updateForm);
  }

  togglePermission(moduleName: string, permissionId: string, emit: boolean = true, updateForm: boolean = true): void {
    if (!this.selectedAllArray[moduleName]) {
      this.selectedAllArray[moduleName] = {};
    }
    const customArray = this.selectedAllArray[moduleName].custom;
    if (!customArray) {
      this.selectedAllArray[moduleName].custom = [];
    }
    if (customArray && customArray.length) {
      const item = customArray.find((x: string) => x === permissionId);
      if (item) {
        const itemIndex = customArray.indexOf(item);
        customArray.splice(itemIndex, 1);
      } else {
        this.selectedAllArray[moduleName].custom.push(permissionId);
      }
    } else {
      this.selectedAllArray[moduleName].custom.push(permissionId);
    }
    this.formValue[moduleName] = this.selectedAllArray[moduleName];
    this.emitValues(emit, updateForm);
  }

  emitValues(emit: boolean = true, updateForm: boolean = true) {
    if (emit) {
      this.UpdateFormFieldValue.emit({ fieldValue: this.formValue, updateForm });
    }
  }

  ngOnInit(): void {
    this.createTemplate();

    this.templateLoaded$
      .pipe(
        takeWhile(() => this.alive),
        take(1)
      )
      .subscribe(() => {
        // console.log('templateLoaded$');

        this.dataLoaded$
          .pipe(
            takeWhile(() => this.alive),
            take(1)
          )
          .subscribe(() => {
            // console.log('dataLoaded$');
            this.loadData();
          });
      });
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes');
    // console.log(changes);
    if (this.item) {
      this.requestCount++;
      this.dataLoaded$.next(true);
      // console.log(this.requestCount);
    }
  }

  createTemplate() {
    this.service.lists$.pipe(takeWhile(() => this.alive)).subscribe((listPrefix: any) => {
      if (listPrefix === 'roles-create') {
        const permissionsTemplate = this.service.lists['roles-create'].permissions;
        if (permissionsTemplate) {
          for (const permissionsKey of Object.keys(permissionsTemplate)) {
            this.permissions[permissionsKey] = {};
            this.permissions[permissionsKey].custom = {};
            this.selectedAllArray[permissionsKey] = {};
            this.selectedAllArray[permissionsKey].custom = {};
            this.selectedAllArray[permissionsKey].all = false;
            permissionsTemplate[permissionsKey].forEach((x: any) => {
              this.permissions[permissionsKey].custom[x.id] = false;
              if (x.isDefault) {
                if (!this.customDefaultPermissions[permissionsKey]) {
                  this.customDefaultPermissions[permissionsKey] = [];
                }
                this.customDefaultPermissions[permissionsKey].push(x.id);
                // this.togglePermission(permissionsKey, x.id);
              }
            });
          }
          this.initData();
          this.templateLoaded = true;
          this.templateLoaded$.next(true);
        }
      }
    });
  }

  initData() {
    const permissionsTemplate = this.service.lists['roles-create'].permissions;
    for (const permissionsKey of Object.keys(permissionsTemplate)) {
      // console.log(permissionsKey);
      // console.log(this.item);
      this.permissions[permissionsKey].val = 'none-' + permissionsKey;
      if (this.isEdit || this.isClone) {
        this.clearAll(permissionsKey, false);
      } else {
        this.clearAll(permissionsKey);
      }
    }
  }

  loadData() {
    // console.log('this.item');
    // console.log(this.item);
    if (!this.item) {
      return;
    }

    if (this.item) {
      // console.log(this.item);
      for (const permissionsKey of Object.keys(this.item)) {
        if (!this.item[permissionsKey].all) {
          if (this.item[permissionsKey].custom.length) {
            this.handleCustom(permissionsKey, false);
            this.permissions[permissionsKey].val = 'custom-' + permissionsKey;
            // const selectedItems: string[] = [];
            // console.log(this.selectedAllArray);
            this.selectedAllArray[permissionsKey].custom = [...this.item[permissionsKey].custom];
            this.item[permissionsKey].custom.forEach((x: any) => {
              this.permissions[permissionsKey].custom[x] = true;
            });
            // console.log(this.selectedAllArray);
            // this.item[permissionsKey].custom = selectedItems;
          } else {
            // this.clearAll(permissionsKey, false);
            this.permissions[permissionsKey].val = 'none-' + permissionsKey;
          }
        } else {
          this.handleAll(permissionsKey, false);
          this.permissions[permissionsKey].val = 'all-' + permissionsKey;
        }
        this.formValue = this.selectedAllArray;
        if (this.isClone) {
          this.emitValues(true, true);
        } else {
          this.emitValues(true, false);
        }
      }
    }
  }

  customDefaultPermissionsChecker(key: string, id: string) {
    return this.customDefaultPermissions[key]
      ? !!this.customDefaultPermissions[key].find((x: string) => x === id)
      : false;
  }
}
