<mat-form-field class="full-width">
  <mat-date-range-input [rangePicker]="picker" [formGroup]="group">
    <input matStartDate placeholder="Start Date" formControlName="joiningDateFrom" />
    <input
      matEndDate
      placeholder="End Date"
      (dateChange)="dateRangeChange(group.value.joiningDateFrom, group.value.joiningDateTo)"
      formControlName="joiningDateTo"
    />
  </mat-date-range-input>
  <div matSuffix class="input-icon">
    <mat-icon *ngIf="group.value.joiningDateFrom" (click)="clearValue()">close</mat-icon>
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
  </div>

  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
