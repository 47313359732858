<!--<div [formGroup]="form">-->
<mat-label class="bold d-flex clearfix pt-2 pb-2">
  <span>
    {{
      field?.form?.apiName
        ? field?.form?.name
        : (service?.cid + '.' + (field?.form?.name ? field?.form?.name : field?.name) | translate)
    }}</span
  >
  <span *ngIf="requiredAsterisk" class="mat-error required-asterisk inline"> *</span>
</mat-label>
<mat-form-field style="width: 100%">
  <input
    [attr.inputSelector]="field?.form?.inputSelector"
    color="primary"
    matInput
    [matDatepicker]="picker"
    [ngModel]="value"
    autocomplete="off"
    [min]="min"
    [max]="max"
    [value]="field?.form.initValue"
    (click)="picker.open()"
    [readonly]="field?.form?.readonly"
    [attr.testId]="field.name"
    [formControl]="formControl"
    (dateChange)="onValueChange(field, $event)"
    [placeholder]="
      field?.form?.apiName
        ? field?.form?.name
        : (service?.cid + '.' + (field?.form?.name ? field?.form?.name : field?.name) | translate)
    "
    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + formControl }"
  />
  <!--    (dateChange)="dateChange($event)"-->
  <!--  <input type="hidden" [(ngModel)]="value" />-->
  <mat-datepicker-toggle
    matPrefix
    [for]="picker"
    [style.color]="(form?.controls)[field?.name].value ? 'rgba(53, 82, 173, 1)' : 'rgba(53, 82, 173, 0.54)'"
  >
  </mat-datepicker-toggle>
  <button
    *ngIf="(form?.controls)[field?.name].value && !(form?.controls)[field?.name].disabled"
    mat-icon-button
    (click)="clear()"
    matSuffix
  >
    <mat-icon class="mat-18">clear</mat-icon>
  </button>
  <mat-icon matSuffix class="mdi-icon dropDownArrow" [svgIcon]="'menu-down'" [attr.size]="'10px'"></mat-icon>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint align="start" *ngIf="field?.form?.hint">
    <ng-container [ngSwitch]="field?.form?.hint?.list?.displayType">
      <ng-container *ngSwitchCase="'text'">
        <strong>
          {{ field?.form?.hint?.list?.fn() }}
        </strong>
      </ng-container>
    </ng-container>
  </mat-hint>
  <mat-error>
    <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
  </mat-error>
</mat-form-field>
<!--</div>-->
<ng-template #validationErrors>
  <app-input-validation-errors
    [service]="service"
    [form]="form"
    [isEdit]="isEdit"
    [field]="field"
    [isClone]="isClone"
    [name]="field?.name"
    [disabledInput]="(form?.controls)[field.name].disabled"
  ></app-input-validation-errors>
</ng-template>
