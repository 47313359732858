import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ItemProps } from '@app/interfaces';
import { RootService } from '@app/core/root.service';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormPopupComponent } from './form-popup/form-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-ng-select-active',
  templateUrl: './ng-select-active.component.html',
  styleUrls: ['./ng-select-active.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgSelectActiveComponent),
      multi: true,
    },
  ],
})
export class NgSelectActiveComponent implements OnInit, OnDestroy {
  response: any = {};
  items: any = [];
  loading = false;
  alive = true;
  params = {
    page: 1,
  };
  listsAdditions: any[] = [];
  addedValue: any;
  @Input() item: any;
  @Input() service: RootService;
  @Input() form: any;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() requiredAsterisk: any;
  @Input() field: ItemProps;
  @Input() disabledInput: boolean;
  @Input() name: string;
  // eslint-disable-next-line
  @Input('value') val: string;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() StatusChanged: EventEmitter<any> = new EventEmitter();
  wholeList: any[] = [];
  @ViewChild('ng_select_activeEL', { static: false }) ng_select_activeEL: NgSelectComponent;
  constructor(public dialog: MatDialog) {}
  addTagName = (name: any, type: any) => {
    switch (this.field?.form?.ngSelectOptions?.saveFormType) {
      case 'popup':
        this.ng_select_activeEL.close();
        this.openPopupForm(name);
        break;

      default:
        this.saveAddedName(name, type);
        return { name, tag: true };
    }
  };

  ngOnInit() {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  saveAddedName(name: string, type: string) {
    this.loading = true;
    if (this.field.form.ngSelectOptions.active) {
      const tagType = this.field.form.ngSelectOptions.type;
      const extras = this.field.form.ngSelectOptions?.extras && this.field.form.ngSelectOptions?.extras();
      const url = this.field.form.ngSelectOptions.addedDataUrl;
      this.service
        .apiRequest('POST', url, { name, type: tagType, ...extras })
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (res) => {
            this.loading = false;

            if (this.field.form.ngSelectOptions?.afterSuccess) {
              this.field.form.ngSelectOptions?.afterSuccess({ res, name });
            } else {
              this.service.loadSelectList(this.field.name, url + `?type=${tagType}`, 'form');
              // this.ng_select_activeEL.clear.Model();
              this.autoSelectAddedName(name);
            }
          },
          (error) => {
            this.loading = false;

            if (error.error && error.error.errors) {
              this.ServerValidationErrors(error.error.errors);
            }
          }
        );
    }
  }

  autoSelectAddedName(name: string) {
    this.service.lists$.pipe(takeWhile(() => this.alive)).subscribe((res) => {
      const item = this.service.lists[this.field.form.listPrefix].find((item: any) => item.name === name);

      /*
       - for multi-select field: the whole value will be replaced (auto-selected)
         the value should be a unique array of ( current added values + newely added value ).
      */

      const oldMultiValue = this.form.get(this.field?.name)?.value?.length
        ? this.form.get(this.field?.name)?.value?.filter((c: any) => !!c)
        : [];

      const fieldValue = this.field?.form?.ngSelectOptions?.multiple
        ? [...new Set([...oldMultiValue, item.id])]
        : item?.id;

      this.service.updateInputValue.next({ fieldName: this.field.name, fieldValue: fieldValue, updateForm: true });
    });
  }

  openPopupForm(name: string, itemId?: string) {
    const dialogRef = this.dialog.open(FormPopupComponent, {
      width: '406px',
      data: { name, ngSelectOptions: this.field?.form?.ngSelectOptions, itemId },
      panelClass: 'form-popup-custom-dialog',
      autoFocus: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // To do : handle success closing
        const tagType = this.field.form.ngSelectOptions.type;
        const url = this.field.form.ngSelectOptions.addedDataUrl;

        this.service.loadSelectList(this.field.name, url + `?type=${tagType}`, 'form');
      }
    });
  }

  onChangeValue(column: ItemProps, event: any) {
    this.service.onChangeValue_checker(column, event);
  }

  ServerValidationErrors(errors: any) {
    if (!errors) {
      return;
    }
    // get form control of that name.
    const formControl = this.form.get(this.field.name);

    if (formControl) {
      // loop throught the server error and attach all of them to the exact form control.
      Object.keys(errors).forEach((error) => {
        formControl.setErrors({
          serverError: errors[error],
        });
        formControl.markAsTouched();
      });
    }
  }

  checkRequiredFields(controlName: string) {
    // if (!this.isEdit) {
    const abstractControl = this.form.controls[controlName];
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
  edit(event: any, itemId: string) {
    event.stopPropagation();
    this.ng_select_activeEL.close();
    this.openPopupForm(undefined, itemId);
  }
}
