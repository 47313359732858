import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DrawEvents, featureGroup, FeatureGroup, icon, latLng, Layer, marker, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import { Location } from '@app/interfaces';
import { animate, style, transition, trigger } from '@angular/animations';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as LCG from 'leaflet-control-geocoder';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
  animations: [fadeAnimation],
})
export class LeafletMapComponent implements OnInit {
  gridGapSize = '30px';

  @Input() cid?: any; // pass the
  @Input() field?: any; // pass the
  @Input() form?: any; // pass
  @Input() key?: string; // pass the formcontrolname key
  @Input() service?: any; // pass the
  @Input() isEdit?: any; // pass the
  @Input() inputDisabled?: boolean; // Disable field
  drawnItems: FeatureGroup = featureGroup();
  location: Location = {};
  view: boolean = true;
  closePopover: boolean = false;
  map: any;
  lat: number = 0;
  lng: number = 0;

  fullLocation = new UntypedFormGroup({
    address: new UntypedFormControl(''),
    long: new UntypedFormControl(''),
    lat: new UntypedFormControl(''),
  });

  markers: Layer[] = [];
  addressValue: string;
  address: GooglePlaceDirective;

  constructor() {}

  options = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '' })],
    zoom: 5,
    center: latLng({ lat: 23.8859, lng: 45.0792 }),
  };

  ngOnInit(): void {}

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  onMapReady(map: L.Map) {
    this.map = map;
  }

  invalidateSize() {
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 100);
    }
  }

  toggleView() {
    if (!this.inputDisabled) {
      return (this.view = !this.view);
    }
  }

  handleAddressChange(address: Address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    const latlnObject = { lat, lng };
    this.addressValue = address.formatted_address;
    this.addMarker(latlnObject);
  }

  onMapClick(event?: any) {
    const latlng = event.latlng;
    this.addMarker(latlng);
    this.form.controls.longitude.setValue(event.latlng.lng);
    this.form.controls.latitude.setValue(event.latlng.lat);
  }

  addMarker(latlng: any) {
    if (this.markers.length > 0) {
      this.markers = [];
    }
    const marker = L.marker(latlng, {
      title: 'Add Point',
      icon: L.icon({
        iconSize: [30, 30],
        iconUrl: '../../../assets/map_point.svg',
      }),
    }).addTo(this.map);
    this.markers.push(marker);
    this.map.setView(latlng);
    this.getAddress(latlng.lat, latlng.lng);
    this.closePopover = true;
  }

  getAddress(longitude: any, latitude: any) {
    const geocoder = new google.maps.Geocoder();
    const longitudeLatitude = new google.maps.LatLng(longitude, latitude);
    const latlng = { lat: longitudeLatitude.lat(), lng: longitudeLatitude.lng() };
    let locationName = this.fullLocation.controls.address;

    return geocoder.geocode({ location: latlng }, function (results, status) {
      locationName.setValue(results[0].formatted_address);
    });
  }

  getInputGridSize(inputSize: string, gap: string) {
    if (inputSize) {
      if (gap) {
        return `0 1 calc(${inputSize} - ${gap})`;
      } else {
        return `0 1 ${inputSize}`;
      }
    } else {
      if (gap) {
        return `0 1 calc(100% - ${gap})`;
      } else {
        return `0 1 100%`;
      }
    }
  }
}
