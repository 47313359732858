import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HomeService } from '@app/home/home.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isLoading = false;
  alive = true;

  constructor(public service: HomeService, protected activatedRoute: ActivatedRoute, protected router: Router) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.alive = false;
  }
}
