import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ItemProps, ListOptions } from '@app/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppHelper } from '@app/core/classes/app-helper';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { TimeAgoPipe } from 'ngx-pipes';
import { RootService } from '@app/core/root.service';

@Component({
  selector: 'app-list-grid-content',
  templateUrl: 'list-grid-content.component.html',
  styleUrls: ['list-grid-content.component.scss'],
  providers: [TimeAgoPipe],
})
export class ListGridContentComponent implements OnInit, OnDestroy, AfterViewInit {
  // Inputs
  @Input() column: ItemProps; // retrieve from any list component includes the columns data
  @Input() columnIndex: number; // column index
  @Input() controller: any;
  @Input() row: any;
  @Input() options: ListOptions;
  @Input() service: RootService;

  AfterViewInit = false;

  alive = true;

  constructor(
    public dialog: MatDialog,
    private utilitiesService: UtilitiesService,
    private translate?: TranslateService,
    private router?: Router
  ) {}

  ngOnInit() {
    // console.log('columns: ', this.column);
  }

  /**
   * Pass the value to deep find method using path and original object
   * obj
   * path
   */
  getValue(obj: any, path: string) {
    return AppHelper.deepFind(obj, path);
  }

  tooltipConditions(row: any, tooltip: any, list: any) {
    // const tooltip = list.icon.tooltip;
    if (!tooltip) {
      return null;
    } else {
      if (tooltip.prop) {
        if (list.displayType === 'boolean') {
          return this.translate.instant(`${this.controller}.${tooltip.prop}.${this.getValue(row, tooltip.prop)}`);
        } else {
          return this.getValue(row, tooltip.prop);
        }
      } else if (tooltip.text) {
        if (tooltip.translated) {
          return this.translate.instant(tooltip.text);
        } else {
          return tooltip.text;
        }
      } else {
        return null;
      }
    }
  }

  tooltipTitleConditions(row: any, tooltip: any, column: any) {
    if (!tooltip) {
      return null;
    } else {
      if (tooltip.title.text) {
        if (tooltip.title.translated) {
          return this.translate.instant(tooltip.title.text);
        } else {
          return tooltip.title.text;
        }
      } else if (tooltip.title.prop) {
        if (tooltip.title.translated) {
          return this.translate.instant(`${this.getValue(row, tooltip.title.prop)}`);
        } else {
          return this.getValue(row, tooltip.title.prop);
        }
      } else {
        if (tooltip.title.translated) {
          return this.translate.instant(`${this.controller}.${column.prop}`);
        } else {
          return column.prop;
        }
      }
    }
  }

  arrayItemsPropFn(item: any, arrayItemsProp?: string) {
    if (typeof item === 'object' && arrayItemsProp) {
      return this.getValue(item, arrayItemsProp);
    } else if (typeof item === 'string') {
      return item;
    } else {
      return;
    }
  }

  getColumnCssClass(column: any) {
    if (column && column.list && column.list.cellInnerCssClass) {
      return column.list.cellInnerCssClass;
    } else if (column && column.list && column.list.cellCssClass) {
      return column.list.cellCssClass;
    } else if (column && column.list && column.list.innerTextCssClass) {
      return column.list.innerTextCssClass;
    } else {
      return;
    }
  }

  numberChecker(text: any) {
    const number = Number(text);
    if (number > 0) {
      return 'number-dark';
    } else if (number === 0) {
      return 'number-light';
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    this.AfterViewInit = true;
  }
}
