import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { ItemProps } from '@app/interfaces';

@Component({
  selector: 'app-material-color-picker',
  templateUrl: './material-color-picker.component.html',
  styleUrls: ['./material-color-picker.component.scss'],
})
export class MaterialColorPickerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() field: ItemProps;
  @Input() validationErrors: any;
  @Input() service: RootService;
  @Input() cid: string;

  @ViewChild('picker', { static: true }) colorPicker: NgxMatColorPickerComponent;
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.colorPicker._selectedChanged.subscribe((val: any) => {
      this.service.colorPicker = { fieldName: this.field.name, fieldValue: val };
    });
  }
}
