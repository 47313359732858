import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '@app/shared/services/shared.service';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-range',
  templateUrl: './date-time-range.component.html',
  styleUrls: ['./date-time-range.component.scss'],
})
export class DateTimeRangeComponent implements OnInit {
  @Input() key: string; // pass the formcontrolname key
  @Input() cid: string; // pass the formcontrolname key
  @Input() service: any; // pass the formcontrolname key
  @Input() field: any; // pass the formcontrolname key
  @Input() isEdit: boolean; // pass the formcontrolname key
  @Input() form: UntypedFormGroup; // pass the formgroup

  startDateKey: string;
  startTimeKey: string;
  endDateKey: string;
  endTimeKey: string;

  startMaxDate: any;
  endMinDate: any;
  startMaxTime: any;
  endMinTime: any;

  constructor() {}

  ngOnInit() {
    // add to controls wiht date and time suffix
    this.startDateKey = this.field.form.minRange.prop + 'Date';
    this.startTimeKey = this.field.form.minRange.prop + 'Time';
    this.endDateKey = this.field.form.maxRange.prop + 'Date';
    this.endTimeKey = this.field.form.maxRange.prop + 'Time';

    this.form.addControl(this.startDateKey, new UntypedFormControl(''));
    this.form.addControl(this.startTimeKey, new UntypedFormControl(''));
    this.form.addControl(this.endDateKey, new UntypedFormControl(''));
    this.form.addControl(this.endTimeKey, new UntypedFormControl(''));

    // remove the validation from the main control
    this.form.controls[this.key].validator = null;
    //detect changes of the four inputs
    this.onChanges();
  }

  onChanges(): void {
    //check on start date to make the start time required
    this.form.get(this.startDateKey).valueChanges.subscribe((val) => {
      if (val && !this.checkRequiredFields(this.startTimeKey)) {
        this.form.controls[this.startTimeKey].setValidators([Validators.required]);
        this.form.controls[this.startTimeKey].updateValueAndValidity();
      } else if (!val && this.checkRequiredFields(this.startTimeKey)) {
        this.form.controls[this.startTimeKey].clearValidators();
        this.form.controls[this.startTimeKey].updateValueAndValidity();
      }
      if (!moment(val, 'LL', true).isValid() && val) {
        this.form.controls[this.startDateKey].setValue(null);
      }
    });

    //check on start time to make the start date required
    this.form.get(this.startTimeKey).valueChanges.subscribe((val) => {
      if (val && !this.checkRequiredFields(this.startDateKey)) {
        this.form.controls[this.startDateKey].setValidators([Validators.required]);
        this.form.controls[this.startDateKey].updateValueAndValidity();
      } else if (!val && this.checkRequiredFields(this.startDateKey)) {
        this.form.controls[this.startDateKey].clearValidators();
        this.form.controls[this.startDateKey].updateValueAndValidity();
      }
      if (!moment(val, 'H:mm A', true).isValid() && val) {
        this.form.controls[this.startTimeKey].setValue(null);
      }
    });

    //check on end date to make the end time required
    this.form.get(this.endDateKey).valueChanges.subscribe((val) => {
      if (val && !this.checkRequiredFields(this.endTimeKey)) {
        this.form.controls[this.endTimeKey].setValidators([Validators.required]);
        this.form.controls[this.endTimeKey].updateValueAndValidity();
      } else if (!val && this.checkRequiredFields(this.endTimeKey)) {
        this.form.controls[this.endTimeKey].clearValidators();
        this.form.controls[this.endTimeKey].updateValueAndValidity();
      }
      if (!moment(val, 'LL', true).isValid() && val) {
        this.form.controls[this.endDateKey].setValue(null);
      }
    });

    //check on end time to make the end date required
    this.form.get(this.endTimeKey).valueChanges.subscribe((val) => {
      if (val && !this.checkRequiredFields(this.endDateKey)) {
        this.form.controls[this.endDateKey].setValidators([Validators.required]);
        this.form.controls[this.endDateKey].updateValueAndValidity();
      } else if (!val && this.checkRequiredFields(this.endDateKey)) {
        this.form.controls[this.endDateKey].clearValidators();
        this.form.controls[this.endDateKey].updateValueAndValidity();
      }
      if (!moment(val, 'H:mm A', true).isValid() && val) {
        this.form.controls[this.endTimeKey].setValue(null);
      }
    });
  }

  checkRequiredFields(controlName: string) {
    // if (!this.isEdit) {
    const abstractControl = this.form.controls[controlName];
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
    // }
  }

  compareStartEndDates() {
    if (
      this.form.controls[this.startDateKey].value &&
      this.form.controls[this.endDateKey].value &&
      this.form.controls[this.startDateKey].value.format('YYYY-MM-DD') ===
        this.form.controls[this.endDateKey].value.format('YYYY-MM-DD')
    ) {
      return true;
    }

    return false;
  }

  checkMinRange(event: MatDatepickerInputEvent<Date>) {
    if (!this.form.controls[this.startDateKey].value) {
      this.startMaxDate = event.value;
    }
  }

  checkMaxRange(event: MatDatepickerInputEvent<Date>) {
    if (!this.form.controls[this.endDateKey].value) {
      this.endMinDate = event.value;
    }
  }

  checkMinTime(event: any) {
    // checkMinTime
    if (!this.form.controls[this.startTimeKey].value && this.compareStartEndDates()) {
      this.startMaxTime = event;
    }
  }

  checkMaxTime(event: any) {
    if (!this.form.controls[this.endTimeKey].value && this.compareStartEndDates()) {
      this.endMinTime = event;
    }
  }
}
