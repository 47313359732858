import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(private credService: CredentialsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.credService.isAuthenticated()) {
      // determine if the under is logged in from this method.
      return true;
    }
    this.router.navigate([route.queryParams.redirect || '/'], { replaceUrl: true });
    return false;
  }
}
