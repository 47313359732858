import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsListComponent } from './components';

@NgModule({
  imports: [CommonModule, SharedModule, NotificationsRoutingModule],
  exports: [NotificationsListComponent],
  declarations: [...NotificationsRoutingModule.components],
})
export class NotificationsModule {}
