import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/material.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { SummaryComponent } from './components/summary/summary.component';
import { BarChartClientsComponent } from './components/bar-chart-clients/bar-chart-clients.component';
import { BarChartAssetsComponent } from './components/bar-chart-assets/bar-chart-assets.component';
import { ClientsListMiniComponent } from './components/clients-list-mini/clients-list-mini.component';
import { LineChartClientsComponent } from './components/line-chart-clients/line-chart-clients.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    HomeRoutingModule,
    TourMatMenuModule,
  ],
  declarations: [
    HomeComponent,
    SummaryComponent,
    BarChartClientsComponent,
    BarChartAssetsComponent,
    ClientsListMiniComponent,
    LineChartClientsComponent,
  ],
})
export class HomeModule {}
