<!-- ngSwitch start -->
<ng-container [ngSwitch]="column?.list?.displayType">
  <ng-container *ngSwitchCase="'color'">
    <span
      class="cell-display-type color"
      *ngIf="getValue(row, column.prop)"
      [style.background-color]="getValue(row, column.prop)"
    ></span>
  </ng-container>
  <ng-container *ngSwitchCase="'image'">
    <div class="img-container">
      <img class="img-data" *ngIf="getValue(row, column.prop)" [src]="getValue(row, column.prop)" alt="" />
      <img
        class="img-placeholder"
        *ngIf="!getValue(row, column.prop)"
        src="{{ column.list.placeHolder ? column.list.placeHolder : 'assets/image-placeholder.png' }}"
        alt=""
      />
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <span class="cell-display-type icon">
      <ng-container *ngIf="column.list.icon">
        <ng-container [ngSwitch]="column.list.icon.type">
          <ng-container *ngSwitchCase="'mdi'">
            <mat-icon
              class="mdi-icon"
              [matTooltip]="tooltipConditions(row, column.list.icon.tooltip, column.list)"
              [matTooltipDisabled]="!column.list.icon.tooltip"
              [attr.size]="column?.list?.icon?.size ? column?.list?.icon?.size : '12px'"
              svgIcon="{{ column.list.icon.name }}"
            ></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="null">
            <mat-icon
              [matTooltip]="tooltipConditions(row, column.list.icon.tooltip, column.list)"
              [matTooltipDisabled]="!column.list.icon.tooltip"
              >{{ column.list.icon.name }}</mat-icon
            >
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'boolean'">
    <span class="boolean-icon" [ngClass]="{ true: getValue(row, column.prop) }" *ngIf="column.list.icon">
      <ng-container [ngSwitch]="column.list.icon.type">
        <ng-container *ngIf="(getValue(row, column.prop) && column.list.icon.trueIcon) || column.list.icon.name">
          <ng-container *ngSwitchCase="'mdi'">
            <mat-icon
              class="md-18 mdi-icon boolean"
              [matTooltip]="tooltipConditions(row, column.list.icon.tooltip, column.list)"
              [matTooltipDisabled]="!column.list.icon.tooltip"
              [attr.size]="column?.list?.icon?.size ? column?.list?.icon?.size : '12px'"
              [svgIcon]="
                getValue(row, column.prop) && column.list.icon.trueIcon
                  ? column.list.icon.trueIcon
                  : column.list.icon.name
              "
            ></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="null">
            <mat-icon
              class="md-18 boolean"
              [style.font-size]="column.list.icon.size ? column.list.icon.size : null"
              [class.true]="getValue(row, column.prop)"
              >{{
                getValue(row, column.prop) && column.list.icon.trueIcon
                  ? column.list.icon.trueIcon
                  : column.list.icon.name
              }}</mat-icon
            >
          </ng-container>
        </ng-container>
      </ng-container>
    </span>

    <span
      class="custom-value"
      *ngIf="
        (column?.list?.trueValue && getValue(row, column.prop)) ||
        (column?.list?.falseValue && !getValue(row, column.prop))
      "
    >
      <ng-container *ngIf="column?.list?.translate">
        <span class="true-value" *ngIf="getValue(row, column.prop)">{{ column?.list?.trueValue | translate }}</span>
        <span class="false-value" *ngIf="!getValue(row, column.prop) && column?.list?.falseValue">{{
          column?.list?.falseValue | translate
        }}</span>
      </ng-container>
      <ng-container *ngIf="!column?.list?.translate">
        <span *ngIf="getValue(row, column.prop)">
          {{ column?.list?.trueValue }}
        </span>
        <span *ngIf="!getValue(row, column.prop) && column?.list?.falseValue">
          {{ column?.list?.falseValue }}
        </span>
      </ng-container>
    </span>

    <span class="default-value" *ngIf="!column?.list?.trueValue && !column?.list?.icon">
      <ng-container>
        {{ getValue(row, column.prop) }}
      </ng-container>
    </span>

    <!--    <span *ngIf="!column.list.icon">-->
    <!--      <mat-icon class="md-18 indicator green-indicator" *ngIf="getValue(row, column.prop)">brightness_1</mat-icon>-->
    <!--      <mat-icon class="md-18 indicator red-indicator" *ngIf="!getValue(row, column.prop)">brightness_1</mat-icon>-->
    <!--    </span>-->
  </ng-container>

  <ng-container *ngSwitchCase="'numberChecker'">
    <div class="number-checker" [ngClass]="getColumnCssClass(column)">
      <div class="text-div" [ngClass]="numberChecker(getValue(row, column.prop))">
        <span class="link-text">{{
          column.list.translate
            ? getValue(row, column.prop) === ''
              ? ''
              : (controller + '.' + getValue(row, column.prop).toLowerCase() | translate)
            : getValue(row, column.prop).length > 30
            ? getValue(row, column.prop).substring(0, 30) + '...'
            : getValue(row, column.prop)
        }}</span>
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() === 1">
          {{ column?.list?.customValue | translate }}</span
        >
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() !== 1">
          {{ column?.list?.customValue + 's' | translate }}</span
        >
      </div>
    </div>
    <div *ngIf="column.additionalProp" class="additional-data">
      {{
        column.list.translate
          ? getValue(row, column.additionalProp) === ''
            ? ''
            : (controller + '.' + getValue(row, column.additionalProp).toLowerCase() | translate)
          : getValue(row, column.additionalProp).length > 30
          ? getValue(row, column.additionalProp).substring(0, 30) + '...'
          : getValue(row, column.additionalProp)
      }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'array'">
    <!--         Tooltip material for this cell          -->
    <!--         [matTooltip]="tooltipConditions(row, column.list.tooltip, column.list)"-->
    <!--         [matTooltipDisabled]="!column.list.tooltip"-->
    <!--         [matTooltipClass]="column.list.tooltip.cssClass"-->

    <!--         Tippy Tooltip template for this cell start         -->
    <!--    // todo make this tooltip generic component and use it accross all field types with a option to swtich material tooltip or tippy tooltip  -->
    <div
      [id]="'array-tooltip-template-' + row.id + '-' + columnIndex"
      class="tooltip-template d-none"
      *ngIf="column.list.tooltip"
    >
      <div fxFlex fxLayout="column" class="tooltip-template-inner">
        <div class="d-flex title" fxFlex *ngIf="column?.list?.tooltip?.title">
          {{ tooltipTitleConditions(row, column.list.tooltip, column) }}
        </div>

        <div fxFlex class="d-flex w-fixed">
          <span class="array-item" *ngFor="let item of getValue(row, column.prop); let i = index">
            <ng-container [ngSwitch]="column?.list?.arrayList?.arrayOfType">
              <ng-container *ngSwitchCase="'object'">
                <ng-container [ngSwitch]="column.list.arrayList.arrayItemDisplayType">
                  <ng-container *ngSwitchCase="'image'">
                    <img
                      class="table-image-thumb"
                      *ngIf="arrayItemsPropFn(item, column.list.arrayList.prop)"
                      [src]="arrayItemsPropFn(item, column.list.arrayList.prop)"
                      alt=""
                    />
                    <img
                      class="table-image-thumb img-placeholder"
                      *ngIf="!arrayItemsPropFn(item, column.list.arrayList.prop)"
                      src="{{
                        column?.list?.arrayList?.placeHolder
                          ? column?.list?.arrayList?.placeHolder
                          : 'assets/image-placeholder.png'
                      }}"
                      alt=""
                    />
                  </ng-container>
                  <ng-container *ngSwitchCase="'string'">
                    {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
                  </ng-container>
                  <ng-container *ngSwitchCase="null">
                    {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'string'">
                {{ arrayItemsPropFn(item) }}
              </ng-container>
              <ng-container *ngSwitchCase="null">
                {{ arrayItemsPropFn(item) }}
              </ng-container>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <!--         Tippy Tooltip template for this cell end         -->

    <!-- with tooltip -->
    <div
      class="cell-display-type array cursor-pointer"
      [ngClass]="getColumnCssClass(column)"
      *ngIf="
        AfterViewInit &&
        !!getValue(row, column.prop) &&
        !!getValue(row, column.prop).length &&
        column.list.tooltip &&
        column.list.tooltip.tippy
      "
      appTippy
      [tippyOptions]="{
        arrow: false,
        name: 'tippy',
        html: '#array-tooltip-template-' + row.id + '-' + columnIndex,
        createPopperInstanceOnInit: true,
        theme: column.list.tooltip.cssClass ? column.list.tooltip.cssClass : ''
      }"
    >
      <ng-container *ngIf="!column.list.staticText">
        <span
          class="array-item"
          *ngFor="let item of getValue(row, column.prop) | slice : 0 : column.list.arrayList.arrayLimit"
        >
          <ng-container [ngSwitch]="column?.list?.arrayList?.arrayOfType">
            <ng-container *ngSwitchCase="'object'">
              <ng-container [ngSwitch]="column.list.arrayList.arrayItemDisplayType">
                <ng-container *ngSwitchCase="'image'">
                  <img
                    class="table-image-thumb"
                    *ngIf="arrayItemsPropFn(item, column.list.arrayList.prop)"
                    [src]="arrayItemsPropFn(item, column.list.arrayList.prop)"
                    alt=""
                  />
                  <img
                    class="table-image-thumb img-placeholder"
                    *ngIf="!arrayItemsPropFn(item, column.list.arrayList.prop)"
                    src="{{
                      column?.list?.arrayList?.placeHolder
                        ? column?.list?.arrayList?.placeHolder
                        : 'assets/image-placeholder.png'
                    }}"
                    alt=""
                  />
                </ng-container>
                <ng-container *ngSwitchCase="'string'">
                  {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
                </ng-container>
                <ng-container *ngSwitchCase="null">
                  {{ arrayItemsPropFn(item, column.list.arrayList.prop) | json }}
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'string'">
              <span>
                {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="null">
              <span>
                {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
              </span>
            </ng-container>
          </ng-container>
        </span>
      </ng-container>

      <ng-container *ngIf="column.list.staticText">
        <span
          class="array-item"
          *ngIf="
            column?.list?.arrayList?.arrayLimit && getValue(row, column.prop).length > column.list.arrayList.arrayLimit
          "
        >
          {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
        </span>
        <span *ngIf="!column?.list?.arrayList?.arrayLimit">
          {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
        </span>
      </ng-container>
    </div>

    <!-- repeat code without tooltip till refactored into better structure -->
    <!-- //todo refactor into component for displaying array of items -->

    <!-- no tooltip -->
    <div
      class="cell-display-type array"
      [ngClass]="getColumnCssClass(column)"
      *ngIf="!!getValue(row, column.prop) && !!getValue(row, column.prop).length && !column.list.tooltip"
    >
      <ng-container *ngIf="!column.list.staticText">
        <span
          class="array-item"
          *ngFor="let item of getValue(row, column.prop) | slice : 0 : column.list.arrayList.arrayLimit; index as i"
        >
          <ng-container [ngSwitch]="column?.list?.arrayList?.arrayOfType">
            <ng-container *ngSwitchCase="'object'">
              <ng-container [ngSwitch]="!!column.list.arrayList.tooltip">
                <ng-container *ngSwitchCase="true">
                  <div
                    [id]="'array-tooltip-template-' + row.id + '-' + columnIndex + '-' + i"
                    class="tooltip-template d-none"
                  >
                    {{ arrayItemsPropFn(item, column.list.arrayList.tooltip.prop) }}
                  </div>
                  <ng-container *ngIf="AfterViewInit">
                    <span
                      appTippy
                      [tippyOptions]="{
                        arrow: true,
                        name: 'arraytooltip',
                        html: '#array-tooltip-template-' + row.id + '-' + columnIndex + '-' + i,
                        createPopperInstanceOnInit: true,
                        theme: column.list.arrayList.tooltip.cssClass ? column.list.arrayList.tooltip.cssClass : ''
                      }"
                    >
                      <ng-container [ngSwitch]="column.list.arrayList.arrayItemDisplayType">
                        <ng-container *ngSwitchCase="'image'">
                          <img
                            class="table-image-thumb"
                            *ngIf="arrayItemsPropFn(item, column.list.arrayList.prop)"
                            [src]="arrayItemsPropFn(item, column.list.arrayList.prop)"
                            alt=""
                          />
                          <img
                            class="table-image-thumb img-placeholder"
                            *ngIf="!arrayItemsPropFn(item, column.list.arrayList.prop)"
                            src="{{
                              column?.list?.arrayList?.placeHolder
                                ? column?.list?.arrayList?.placeHolder
                                : 'assets/image-placeholder.png'
                            }}"
                            alt=""
                          />
                        </ng-container>
                        <ng-container *ngSwitchCase="'string'">
                          {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="null">
                          {{ arrayItemsPropFn(item, column.list.arrayList.prop) | json }}
                        </ng-container>
                      </ng-container>
                    </span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  {{ 'no tooltip' }}
                  <ng-container [ngSwitch]="column.list.arrayList.arrayItemDisplayType">
                    <ng-container *ngSwitchCase="'image'">
                      <img
                        class="table-image-thumb"
                        *ngIf="arrayItemsPropFn(item, column.list.arrayList.prop)"
                        [src]="arrayItemsPropFn(item, column.list.arrayList.prop)"
                        alt=""
                      />
                      <img
                        class="table-image-thumb img-placeholder"
                        *ngIf="!arrayItemsPropFn(item, column.list.arrayList.prop)"
                        src="{{
                          column?.list?.arrayList?.placeHolder
                            ? column?.list?.arrayList?.placeHolder
                            : 'assets/image-placeholder.png'
                        }}"
                        alt=""
                      />
                    </ng-container>
                    <ng-container *ngSwitchCase="'string'">
                      {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
                    </ng-container>
                    <ng-container *ngSwitchCase="null">
                      {{ arrayItemsPropFn(item, column.list.arrayList.prop) | json }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'string'">
              <span>
                {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="null">
              <span>
                {{ arrayItemsPropFn(item, column.list.arrayList.prop) }}
              </span>
            </ng-container>
          </ng-container>
        </span>

        <!-- trimBy start -->
        <div class="array-trimBy" *ngIf="column?.list?.arrayList?.trimBy">
          <!-- // todo warning code repeat till refactored into custom component  -->
          <ng-container>
            <!--         Tooltip material for this cell          -->
            <!--         [matTooltip]="tooltipConditions(row, column.list.tooltip, column.list)"-->
            <!--         [matTooltipDisabled]="!column.list.tooltip"-->
            <!--         [matTooltipClass]="column.list.tooltip.cssClass"-->

            <!--         Tippy Tooltip template for this cell start         -->
            <!--    // todo make this tooltip generic component and use it accross all field types with a option to swtich material tooltip or tippy tooltip  -->
            <div
              [id]="'array-tooltip-template-' + row.id + '-' + columnIndex"
              class="tooltip-template d-none"
              *ngIf="column?.list?.arrayList?.trimBy?.list?.tooltip"
            >
              <div fxFlex fxLayout="column" class="tooltip-template-inner">
                <div class="d-flex title" fxFlex *ngIf="column?.list?.arrayList?.trimBy?.list?.tooltip?.title">
                  {{
                    tooltipTitleConditions(
                      row,
                      column?.list?.arrayList?.trimBy.list.tooltip,
                      column?.list?.arrayList?.trimBy
                    )
                  }}
                </div>

                <div fxFlex class="d-flex w-fixed">
                  <span
                    class="array-item"
                    *ngFor="let item of getValue(row, column?.list?.arrayList?.trimBy?.prop); let i = index"
                  >
                    <ng-container [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayOfType">
                      <ng-container *ngSwitchCase="'object'">
                        <ng-container
                          [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayItemDisplayType"
                        >
                          <ng-container *ngSwitchCase="'image'">
                            <img
                              class="table-image-thumb"
                              *ngIf="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                              [src]="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                              alt=""
                            />
                            <img
                              class="table-image-thumb img-placeholder"
                              *ngIf="!arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                              src="{{
                                column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                  ? column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                  : 'assets/image-placeholder.png'
                              }}"
                              alt=""
                            />
                          </ng-container>
                          <ng-container *ngSwitchCase="'string'">
                            {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                          </ng-container>
                          <ng-container *ngSwitchCase="null">
                            {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngSwitchCase="'string'">
                        {{ arrayItemsPropFn(item) }}
                      </ng-container>
                      <ng-container *ngSwitchCase="null">
                        {{ arrayItemsPropFn(item) }}
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>
            <!--         Tippy Tooltip template for this cell end         -->

            <!-- with tooltip -->
            <div
              class="cell-display-type array cursor-pointer"
              [ngClass]="getColumnCssClass(column?.list?.arrayList?.trimBy)"
              *ngIf="
                AfterViewInit &&
                !!getValue(row, column?.list?.arrayList?.trimBy?.prop) &&
                !!getValue(row, column?.list?.arrayList?.trimBy?.prop).length &&
                column?.list?.arrayList?.trimBy?.list?.tooltip &&
                column?.list?.arrayList?.trimBy?.list?.tooltip?.tippy
              "
              appTippy
              [tippyOptions]="{
                arrow: false,
                name: 'tippy',
                html: '#array-tooltip-template-' + row.id + '-' + columnIndex,
                createPopperInstanceOnInit: true,
                theme: column?.list?.arrayList?.trimBy?.list?.tooltip?.cssClass
                  ? column?.list?.arrayList?.trimBy?.list?.tooltip?.cssClass
                  : ''
              }"
            >
              <ng-container *ngIf="!column?.list?.arrayList?.trimBy?.list?.staticText">
                <span
                  class="array-item"
                  *ngFor="
                    let item of getValue(row, column?.list?.arrayList?.trimBy.prop)
                      | slice : 0 : column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit
                  "
                >
                  <ng-container [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayOfType">
                    <ng-container *ngSwitchCase="'object'">
                      <ng-container [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayItemDisplayType">
                        <ng-container *ngSwitchCase="'image'">
                          <img
                            class="table-image-thumb"
                            *ngIf="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                            [src]="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                            alt=""
                          />
                          <img
                            class="table-image-thumb img-placeholder"
                            *ngIf="!arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                            src="{{
                              column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                ? column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                : 'assets/image-placeholder.png'
                            }}"
                            alt=""
                          />
                        </ng-container>
                        <ng-container *ngSwitchCase="'string'">
                          {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="null">
                          {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) | json }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'string'">
                      <span>
                        {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="null">
                      <span>
                        {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                      </span>
                    </ng-container>
                  </ng-container>
                </span>
              </ng-container>

              <ng-container *ngIf="column?.list?.arrayList?.trimBy?.list?.staticText">
                <span
                  class="array-item"
                  *ngIf="
                    column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit &&
                    getValue(row, column?.list?.arrayList?.trimBy?.prop).length >
                      column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit
                  "
                >
                  {{
                    column?.list?.arrayList?.trimBy?.list?.translate
                      ? (column?.list?.arrayList?.trimBy?.list?.staticText | translate)
                      : column?.list?.arrayList?.trimBy?.list?.staticText
                  }}
                </span>
                <span *ngIf="!column?.list?.arrayList?.arrayLimit">
                  {{
                    column?.list?.arrayList?.trimBy?.list?.translate
                      ? (column?.list?.arrayList?.trimBy?.list?.staticText | translate)
                      : column?.list?.arrayList?.trimBy?.list?.staticText
                  }}
                </span>
              </ng-container>
            </div>

            <!-- repeat code without tooltip till refactored into better structure -->
            <!-- //todo refactor into component for displaying array of items -->

            <!-- no tooltip -->
            <div
              class="cell-display-type array"
              [ngClass]="getColumnCssClass(column?.list?.arrayList?.trimBy)"
              *ngIf="
                !!getValue(row, column?.list?.arrayList?.trimBy.prop) &&
                !!getValue(row, column?.list?.arrayList?.trimBy.prop).length &&
                !column?.list?.arrayList?.trimBy?.list?.tooltip
              "
            >
              <ng-container *ngIf="!column?.list?.arrayList?.trimBy?.list?.staticText">
                <span
                  class="array-item"
                  *ngFor="
                    let item of getValue(row, column?.list?.arrayList?.trimBy?.prop)
                      | slice : 0 : column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit;
                    index as i
                  "
                >
                  <ng-container [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayOfType">
                    <ng-container *ngSwitchCase="'object'">
                      <ng-container [ngSwitch]="!!column?.list?.arrayList?.trimBy.list.arrayList.tooltip">
                        <ng-container *ngSwitchCase="true">
                          <div
                            [id]="'array-tooltip-template-' + row.id + '-' + columnIndex + '-' + i"
                            class="tooltip-template d-none"
                          >
                            {{
                              arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.tooltip?.prop)
                            }}
                          </div>
                          <ng-container *ngIf="AfterViewInit">
                            <span
                              appTippy
                              [tippyOptions]="{
                                arrow: true,
                                name: 'arraytooltip',
                                html: '#array-tooltip-template-' + row.id + '-' + columnIndex + '-' + i,
                                createPopperInstanceOnInit: true,
                                theme: column?.list?.arrayList?.trimBy?.list?.arrayList?.tooltip?.cssClass
                                  ? column?.list?.arrayList?.trimBy?.list?.arrayList?.tooltip?.cssClass
                                  : ''
                              }"
                            >
                              <ng-container
                                [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayItemDisplayType"
                              >
                                <ng-container *ngSwitchCase="'image'">
                                  <img
                                    class="table-image-thumb"
                                    *ngIf="
                                      arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)
                                    "
                                    [src]="
                                      arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)
                                    "
                                    alt=""
                                  />
                                  <img
                                    class="table-image-thumb img-placeholder"
                                    *ngIf="
                                      !arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)
                                    "
                                    src="{{
                                      column?.list?.arrayList?.placeHolder
                                        ? column?.list?.arrayList?.placeHolder
                                        : 'assets/image-placeholder.png'
                                    }}"
                                    alt=""
                                  />
                                </ng-container>
                                <ng-container *ngSwitchCase="'string'">
                                  {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                                </ng-container>
                                <ng-container *ngSwitchCase="null">
                                  {{
                                    arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)
                                      | json
                                  }}
                                </ng-container>
                              </ng-container>
                            </span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          {{ 'no tooltip' }}
                          <ng-container
                            [ngSwitch]="column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayItemDisplayType"
                          >
                            <ng-container *ngSwitchCase="'image'">
                              <img
                                class="table-image-thumb"
                                *ngIf="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                                [src]="arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                                alt=""
                              />
                              <img
                                class="table-image-thumb img-placeholder"
                                *ngIf="!arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop)"
                                src="{{
                                  column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                    ? column?.list?.arrayList?.trimBy?.list?.arrayList?.placeHolder
                                    : 'assets/image-placeholder.png'
                                }}"
                                alt=""
                              />
                            </ng-container>
                            <ng-container *ngSwitchCase="'string'">
                              {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                            </ng-container>
                            <ng-container *ngSwitchCase="null">
                              {{
                                arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) | json
                              }}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'string'">
                      <span>
                        {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="null">
                      <span>
                        {{ arrayItemsPropFn(item, column?.list?.arrayList?.trimBy?.list?.arrayList?.prop) }}
                      </span>
                    </ng-container>
                  </ng-container>
                </span>
              </ng-container>

              <ng-container *ngIf="column?.list?.arrayList?.trimBy?.list?.staticText">
                <span
                  class="array-item"
                  *ngIf="
                    column?.list?.arrayList?.arrayLimit &&
                    getValue(row, column?.list?.arrayList?.trimBy?.prop).length >
                      column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit
                  "
                >
                  {{
                    column?.list?.arrayList?.trimBy?.list?.translate
                      ? (column?.list?.arrayList?.trimBy?.list?.staticText | translate)
                      : column?.list?.arrayList?.trimBy?.list?.staticText
                  }}
                </span>
                <span *ngIf="!column?.list?.arrayList?.trimBy?.list?.arrayList?.arrayLimit">
                  {{
                    column?.list?.arrayList?.trimBy?.list?.translate
                      ? (column?.list?.arrayList?.trimBy?.list?.staticText | translate)
                      : column?.list?.arrayList?.trimBy?.list?.staticText
                  }}
                </span>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- trimBy end -->
      </ng-container>

      <ng-container *ngIf="column.list.staticText">
        <span
          class="array-item"
          *ngIf="
            column?.list?.arrayList?.arrayLimit && getValue(row, column.prop).length > column.list.arrayList.arrayLimit
          "
        >
          {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
        </span>
        <span *ngIf="!column?.list?.arrayList?.arrayLimit">
          {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
        </span>
      </ng-container>
    </div>
  </ng-container>

  <!-- Date display type , Date format 5 min ago , or 7 hours ago, 22 sept 2019 -->
  <ng-container *ngSwitchCase="'date'">
    <div [ngClass]="getColumnCssClass(column)">
      <div class="text-div">
        <span class="link-text">
          {{
            column?.list?.dateOptions?.timeAgo &&
            service.dateAgoChecker(getValue(row, column.prop), column?.list?.dateOptions?.timeAgoCompareTo)
              ? (service.dateConverter(getValue(row, column.prop)) | timeAgo)
              : column?.list?.dateOptions?.displayDateAs
              ? (getValue(row, column.prop) | localizedDate : column?.list?.dateOptions?.displayDateAs)
              : (getValue(row, column.prop) | localizedDate : 'longDate')
          }}
        </span>
      </div>
    </div>
  </ng-container>

  <!-- no display type > display as it's -->
  <ng-container *ngSwitchCase="null">
    <div
      *ngIf="!options?.hoverData?.status && column.prop && getValue(row, column.prop)"
      [ngClass]="getColumnCssClass(column)"
    >
      <div class="text-div">
        <span class="link-text">{{
          column.list.translate
            ? getValue(row, column.prop) === ''
              ? ''
              : (controller + '.' + getValue(row, column.prop).toLowerCase() | translate)
            : getValue(row, column.prop)
        }}</span>
        <!--        : getValue(row, column.prop).length > 30-->
        <!--        ? getValue(row, column.prop).substring(0, 30) + '...'-->
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() === 1">
          {{ column?.list?.customValue | translate }}</span
        >
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() !== 1">
          {{ column?.list?.customValue + 's' | translate }}</span
        >
      </div>
    </div>
    <div *ngIf="column.additionalProp" class="additional-data">
      {{
        column.list.translate
          ? getValue(row, column.additionalProp) === ''
            ? ''
            : (controller + '.' + getValue(row, column.additionalProp).toLowerCase() | translate)
          : getValue(row, column.additionalProp).length > 30
          ? getValue(row, column.additionalProp).substring(0, 30) + '...'
          : getValue(row, column.additionalProp)
      }}
    </div>
    <span *ngIf="column.list.staticText" [ngClass]="getColumnCssClass(column)">
      {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
    </span>
  </ng-container>

  <!-- emptyBlock > display as empty container -->
  <ng-container *ngSwitchCase="'emptyBlock'">
    <div [ngClass]="getColumnCssClass(column)">
      <span *ngIf="column.list.staticText">
        {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'not_splitted_text'">
    <div
      *ngIf="!options?.hoverData?.status && column.prop && getValue(row, column.prop)"
      [ngClass]="getColumnCssClass(column)"
    >
      <div class="text-div">
        <span class="link-text">{{
          column.list.translate
            ? getValue(row, column.prop) === ''
              ? ''
              : (controller + '.' + getValue(row, column.prop).toLowerCase() | translate)
            : getValue(row, column.prop)
        }}</span>
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() === 1">
          {{ column?.list?.customValue | translate }}</span
        >
        <span *ngIf="column?.list?.customValue && getValue(row, column.prop).valueOf() !== 1">
          {{ column?.list?.customValue + 's' | translate }}</span
        >
      </div>
    </div>
    <div *ngIf="column.additionalProp" class="additional-data">
      {{
        column.list.translate
          ? getValue(row, column.additionalProp) === ''
            ? ''
            : (controller + '.' + getValue(row, column.additionalProp).toLowerCase() | translate)
          : getValue(row, column.additionalProp).length > 30
          ? getValue(row, column.additionalProp).substring(0, 30) + '...'
          : getValue(row, column.additionalProp)
      }}
    </div>
    <span *ngIf="column.list.staticText">
      {{ column.list.translate ? (column.list.staticText | translate) : column.list.staticText }}
    </span>
  </ng-container>

  <!-- ngSwitch end -->
</ng-container>
