import { Injectable } from '@angular/core';
import { SystemPermission, SystemPermissions } from '@app/interfaces';
import { ApiRequestService } from '@app/core/http/api-request.service';
import { UserDetailsService } from '@app/shared/services/user-details/user-details.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { PermissionsService as BasePermissionsService } from '@afaqyit/frontend-core';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends BasePermissionsService {
  public refresh: Subject<any> = new Subject();
  public permissionsUpdated: Subject<any> = new Subject();
  protected permissions: SystemPermissions | boolean;

  constructor(protected apiRequestService: ApiRequestService, protected userDetailsService: UserDetailsService) {
    // Please fill the super() call by the needed parameters
    super(apiRequestService, userDetailsService);
  }

  /**
   * helper function for easier mapping in template for better performance on the dom
   * and not using getPermissions directly in templating
   * @param module: module permissions key
   */
  public getPermissionsHelper(module?: string): SystemPermission {
    const helperPermissions: SystemPermission = {};
    helperPermissions.all = this.booleanPermissionsChecker(module);
    if (this.getPermissions(module)) {
      if (typeof this.permissions[module] === 'object') {
        if (Object.keys(this.permissions[module]).length) {
          Object.keys(this.permissions[module]).forEach((key) => {
            helperPermissions[key] = this.booleanPermissionsChecker(module, key);
          });
        }
      }
    }
    return helperPermissions;
  }

  /**
   * helper function to return permissions as boolean only
   * @param module: module permission key
   * @param key: sub module permissions key
   */
  public booleanPermissionsChecker(module?: string, key?: string): boolean {
    if (module) {
      if (key) {
        return !!this.getPermissions(module, key);
      } else {
        if (typeof this.getPermissions(module) === 'boolean') {
          return !!this.getPermissions(module);
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  /**
   * for any section in system to check for specific permission with module and key both optional,
   * or to return the full permissions of system
   * @param module: module permission key
   * @param key: sub module permissions key
   */
  public getPermissions(module?: string, key?: string): boolean | SystemPermissions {
    if (this.permissions) {
      if (typeof this.permissions === 'boolean') {
        return this.permissions;
      } else {
        if (module) {
          if (this.permissions[module]) {
            if (typeof this.permissions[module] === 'boolean') {
              return this.permissions[module];
            } else if (typeof this.permissions[module] === 'object') {
              if (key) {
                return !!this.permissions[module][key];
              } else {
                return this.permissions[module];
              }
            }
          } else if (this.permissions['admin']) {
            if (module === 'manageUsersRoles') {
              return this.permissions['manageUsersRoles'];
            } else {
              return true;
            }
          }
        } else {
          return this.permissions;
        }
      }
    } else {
      return false;
    }
  }

  /**
   * Permissions Request Api function checks if there isn't loaded permissions then request the api and then
   * call a callback function provided with the request as optional
   * @param options : {
   *        getPermissions: optional callback function to be executed on successful api response or if permissions
   *        already exist on service, should be the same getPermissions function with module and key
   *        if they are provided. unless another logic is needed,
   *        refresh: if needed to force refresh permissions with an API request
   * }
   */
  public permissionsRequest(options?: {
    getPermissions?: (module?: string, key?: string) => boolean | SystemPermissions;
    refresh?: boolean;
  }): Observable<boolean> {
    if (this.permissions === undefined || this.permissions === null || (options && options.refresh)) {
      // return this.apiRequestService.request('GET', 'users/authorized').pipe(
      return of({
        message: '',
        data: {
          id: 1,
          name: 'afaqy',
          arabicName: '',
          username: 'owner',
          email: 'omar.mokhtar@afaqy.com',
          mobile: '',
          nationalId: '',
          idExpiryDate: '',
          address: '',
          ibutton: '',
          rfid: '',
          nationality: null,
          issuer: null,
          photo: null,
          position: 'owner',
          branchAccess: 'full',
          role: { id: 1, name: 'Owner' },
          licenseType: null,
          licenseExpiry: null,
          licenseNumber: '',
          licenseIssuer: null,
          interface: 'web',
          manageUsers: false,
          status: true,
          hourlyRate: null,
          department: null,
          userId: '',
          jobTitle: null,
          group: null,
          location: null,
          employmentStatus: null,
          region: null,
          grade: null,
          joiningDate: null,
          birthDate: null,
          lineManager: null,
          comment: '',
          branchIds: [],
          permissions: true,
        },
      }).pipe(
        map((res) => {
          this.userDetailsService.setUserDetails(this.refactorUserData(res.data));
          this.setPermissions(this.refactorPermissions(res.data));
          this.permissionsUpdated.next(true);
          return options.getPermissions ? !!options.getPermissions() : true;
        }),
        catchError((err) => {
          // this.setPermissions(false);
          return of(false).pipe(take(1));
        })
      );
    } else {
      // this.setPermissions(false);
      return of(options && options.getPermissions ? !!options.getPermissions() : false).pipe(take(1));
    }
  }

  /**
   * public method to update Private Permissions object with in the service
   * @param permissions : permissions data to be set,
   * if not provided then the function clears the permissions and set it to undefined
   */
  public setPermissions(permissions?: SystemPermissions | boolean) {
    this.permissions = permissions;
  }

  /**
   * refactor logic for permissions response
   * @param data : response data to parse
   */
  refactorPermissions(data: any): SystemPermissions | boolean {
    let permissions: SystemPermissions | boolean = false;
    // if (data) {
    // if (data.permissions) {
    // permissions = { ...data.permissions };
    // if (data.position === 'administrator') {
    //   permissions['admin'] = true;
    //   if (data.manageUsers) {
    //     permissions['manageUsersRoles'] = true;
    //   }
    // } else if (data.position === 'owner') {
    permissions = true;
    // }
    // }
    // }
    return permissions;
  }

  /**
   * refactor logic for user data response
   * @param data : response data to parse
   */
  refactorUserData(data: any) {
    if (data) {
      const userDetails = { ...data };
      delete userDetails.permissions;
      return userDetails;
    }
  }
}
