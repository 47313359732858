import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { PermissionsService } from '@app/core/permissions/permissions.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private permissionsService: PermissionsService, private router: Router) {}

  /**
   * determine if the user can Activate the children of this module
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermission(childRoute);
  }

  /**
   * determine if the user can Activate this module
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkPermission(route);
  }

  /**
   * determine if the user can Activate this module
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkPermission(route);
  }

  /**
   * generic check Permissions function for a permissions key
   * @param route: route of module to parse from routing and get the permissionsKeys from data key
   */
  checkPermission(route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    if (route.data.permissionsKeys && route.data.permissionsKeys.length) {
      // console.log('guard checkPermission');
      // console.log(route);
      return this.permissionsService
        .permissionsRequest({
          getPermissions: () => {
            return route.data.permissionsKeys.every((permissionKey: { module?: string; key?: string }) => {
              // console.log(permissionKey.module, permissionKey.key);
              // console.log(this.permissionsService.getPermissions(permissionKey.module, permissionKey.key));
              return this.permissionsService.getPermissions(permissionKey.module, permissionKey.key);
            });
          },
        })
        .pipe(
          take(1),
          map((havePermission) => {
            // console.log('havePermission', havePermission);
            if (!havePermission) {
              if (route.data && route.data.adminDashboard) {
                this.router.navigate(['/welcome'], { replaceUrl: false });
              } else {
                this.router.navigate(['/401'], { replaceUrl: false, skipLocationChange: true });
              }
              return havePermission;
            }
            return havePermission;
          })
        );
    }
    this.router.navigate(['/401'], { replaceUrl: false, skipLocationChange: true });
    return of(false).pipe(take(1));
  }
}
