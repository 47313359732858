import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataset, ChartConfiguration } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit {
  @Input() height: number;
  @Input() data: ChartDataset[] | any;
  @Input() dataSets: ChartDataset[] | any;
  @Input() labels: string[] | any;
  @Input() options: ChartConfiguration;
  @Input() plugins: {};
  @Input() legend: boolean;
  @Input() type: ChartType;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxis: {}, yAxis: {} },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  // public labels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  // public type: ChartType = 'bar';
  // public legend = true;
  // public plugins = [pluginDataLabels];
  // public colors: Color[] = [
  //   { backgroundColor: '#E40303' },
  //   { backgroundColor: '#FFB24A' },
  //   { backgroundColor: '#777677' },
  //   { backgroundColor: '#6A9AE9' }
  // ];
  //
  // public data: ChartDataSets[] = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
  //   { data: [16, 85, 94, 35, 62, 20, 57], label: 'Series C' },
  //   { data: [91, 54, 66, 87, 78, 49, 36], label: 'Series D' }
  // ];

  constructor() {}

  ngOnInit() {}

  public chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }
}
