import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root',
})
export class EngineOdometerI18nHelperService {
  constructor(private translateService: TranslateService, private shared: SharedService) {}

  translateUsageParameter(usageParam: string) {
    const engineHoursVarEn = {
      vehicles: {
        odometer: 'Engine Hours',
        odometerOnPurchase: 'Engine Hours on Purchase',
        meters: 'Engine Hours',
      },
      profile: {
        odometerOnPurchase: 'Engine Hours on Purchase',
      },
      common: {
        unit_types: {
          km: 'Hours',
        },
      },
      meters: {
        distance: 'Engine Hours',
        avoid: 'Void Engine Hours',
        title: 'Engine Hours',
      },
      menu: {
        meters: 'Engine Hours',
        'meters-cost': 'Engine Hours Cost',
      },
      issues: {
        odometer: 'Engine Hours',
        avoidOdometer: 'Void Engine Hours',
      },
      'fuel-histories': {
        odometer: 'Engine Hours',
        avoidOdometer: 'Void Engine Hours',
      },
      services: {
        odometer: 'Engine Hours',
        avoidOdometer: 'Void Engine Hours',
        workOrderView: {
          odometer: 'Engine Hours',
        },
      },
      'meters-cost': {
        totalMeters: 'Total Engine Hours',
        costPerMeter: 'Cost/Second',
      },
      'parts-vehicle-summary': {
        distance: 'Engine Hours',
      },
    };
    const odometerVarEn = {
      vehicles: {
        odometer: 'Odometer',
        odometerOnPurchase: 'Odometer on Purchase',
        meters: 'Meters',
      },
      profile: {
        odometerOnPurchase: 'Odometer on Purchase',
      },
      common: {
        unit_types: {
          km: 'KM',
        },
      },
      meters: {
        distance: 'Odometer',
        avoid: 'Void Odometer',
        title: 'Meters History',
      },
      menu: {
        meters: 'Meters History',
        'meters-cost': 'Meters Cost',
      },
      issues: {
        odometer: 'Odometer',
        avoidOdometer: 'Void Odometer',
      },
      'fuel-histories': {
        odometer: 'Odometer',
        avoidOdometer: 'Void Odometer',
      },
      services: {
        odometer: 'Odometer',
        avoidOdometer: 'Void Odometer',
        workOrderView: {
          odometer: 'Odometer',
        },
      },
      'meters-cost': {
        totalMeters: 'Total Meters',
        costPerMeter: 'Cost/Meter',
      },
      'parts-vehicle-summary': {
        distance: 'Odometer',
      },
    };
    if (this.shared.translate.currentLang === 'en-US') {
      if (usageParam === 'engine-hours') {
        this.translateService.setTranslation('en-US', engineHoursVarEn, true);
      } else {
        this.translateService.setTranslation('en-US', odometerVarEn, true);
      }
    }
  }
}
