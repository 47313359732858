import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DrawEvents, featureGroup, FeatureGroup, latLng, tileLayer } from 'leaflet';
import * as L from 'leaflet';

@Component({
  selector: 'app-leaflet-draw',
  templateUrl: './leaflet-draw.component.html',
  styleUrls: ['./leaflet-draw.component.scss'],
})
export class LeafletDrawComponent implements OnInit {
  @Input() cid?: any; // pass the
  @Input() field?: any; // pass the
  @Input() form?: any; // pass
  @Input() key?: string; // pass the formcontrolname key
  @Input() service?: any; // pass the
  @Input() isEdit?: any; // pass the
  @Input() inputDisabled?: boolean; // Disable field
  addressValue: string;
  zone = new UntypedFormControl();
  drawnItems: FeatureGroup = featureGroup();
  view: boolean = true;
  map: any;

  fullLocation = new UntypedFormGroup({
    address: new UntypedFormControl(''),
    coordinates: new UntypedFormControl(),
  });

  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'Open Street Map' }),
    ],
    zoom: 5,
    center: latLng({ lat: 23.8859, lng: 45.0792 }),
  };

  drawLocal: any = {
    draw: {
      toolbar: {
        buttons: {
          polygon: 'Draw an awesome polygon!',
        },
      },
    },
  };

  drawOptions = {
    position: 'topright',
    draw: {
      marker: {
        icon: L.icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: '2b3e1faf89f94a4835397e7a43b4f77d.png',
          iconRetinaUrl: '680f69f3c2e6b90c1812a813edf67fd7.png',
          shadowUrl: 'a0c6cc1401c107b501efee6477816891.png',
        }),
      },
      polyline: false,
      circle: {
        shapeOptions: {
          color: '#d4af37',
        },
      },
      rectangle: {
        shapeOptions: {
          color: '#85bb65',
        },
      },
    },
    edit: {
      featureGroup: this.drawnItems,
    },
  };

  constructor() {}

  ngOnInit(): void {}

  invalidateSize() {
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 100);
    }
  }

  toggleView() {
    if (!this.inputDisabled) {
      return (this.view = !this.view);
    }
  }
  public onDrawStart(e: any) {}

  public onDrawCreated(e: any) {
    const lat = e.layer.editing.latlngs[0][0][0].lat;
    const lng = e.layer.editing.latlngs[0][0][0].lng;
    const layer = (e as DrawEvents.Created).layer;
    this.drawnItems.addLayer(layer);

    if (e.layerType === 'polygon' || e.layerType === 'rectangle') {
      this.zone = e.layer._latlngs;
    } else if (e.layerType === 'circle') {
      const radius = e.layer._mRadius;
      this.fullLocation.controls['coordinates'].setValue(e.layer._latlng);
    }
    this.getAddress(lat, lng);
  }

  getAddress(longitude: any, latitude: any) {
    const geocoder = new google.maps.Geocoder();
    const longitudeLatitude = new google.maps.LatLng(longitude, latitude);
    const latlng = { lat: longitudeLatitude.lat(), lng: longitudeLatitude.lng() };
    let locationName = this.fullLocation.controls.address;

    return geocoder.geocode({ location: latlng }, function (results, status) {
      locationName.setValue(results[0].formatted_address);
    });
  }
}
