<div class="applications">
  <mat-card class="main app-card primary-font">
    <div class="card-title">{{ 'home.summary.afaqy' | translate }}</div>
    <div class="card-body">
      <div class="clients">
        <img src="/assets/company-data-tab.svg" alt="" />
        <div class="statistics">
          <div class="count">{{ summary?.additionalData?.totalClients }}</div>
          <div class="text">{{ 'home.summary.clients' | translate }}</div>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="assets">
        <img src="/assets/Icon awesome-truck.svg" alt="" />
        <div class="statistics">
          <div class="count">{{ summary?.additionalData?.totalAssets }}</div>
          <div class="text">{{ 'home.summary.assets' | translate }}</div>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card class="app-card primary-font" *ngFor="let app of summary?.data">
    <div class="card-title">{{ app?.name }}</div>
    <div class="card-body">
      <div class="clients">
        <img src="/assets/company-data-tab.svg" alt="" />
        <div class="statistics">
          <div class="count">{{ app?.totalClients }}</div>
          <div class="text">{{ 'home.summary.clients' | translate }}</div>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="assets">
        <img src="/assets/Icon awesome-truck.svg" alt="" />
        <div class="statistics">
          <div class="count">{{ app?.totalAssets }}</div>
          <div class="text">{{ 'home.summary.assets' | translate }}</div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
