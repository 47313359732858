<div class="field-container file_upload_details" [ngClass]="{ disabled: form.get(field?.name)?.disabled }">
  <div
    fxFlex=""
    fxLayout="row"
    class="input-container d-flex align-items-center justify-content-flex-start position-relative"
  >
    <div class="icon">
      <mat-icon svgIcon="file-document-box-plus-outline" [attr.size]="'14px'"></mat-icon>
    </div>
    <div fxLayout="column" class="labels" *ngIf="!selectedImages[field?.name]">
      <span class="primary-label">
        {{ 'common.drop_photos' | translate }}
      </span>
      <span class="secondary-label">
        {{ 'common.pick_photos' | translate }}
      </span>
    </div>

    <div fxLayout="column" class="labels" *ngIf="selectedImages[field?.name]">
      <span class="primary-label">
        {{ selectedImages[field?.name]?.name }}
      </span>
      <span class="secondary-label">
        {{ 'common.size' | translate }}
        {{ selectedImages[field?.name]?.size }} Kb
      </span>
    </div>

    <div class="action-button">
      <button mat-flat-button color="primary" class="upload_button">
        {{ 'common.browse' | translate }}
      </button>
    </div>
    <input
      [disabled]="(form?.controls)[field?.name]?.disabled"
      class="upload-file-hidden"
      [id]="field.name"
      type="file"
      accept=".png, .jpg, .jpeg"
      (change)="uploadPhoto($event, field, form.controls[field.name].value)"
    />
    <div
      *ngIf="isEdit && (form?.controls)[field?.name]?.disabled"
      class="upload-file-hidden edit"
      (click)="openFileSelectFromInput(form.controls[field.name], field.name)"
    ></div>
    <input type="hidden" [formControlName]="field?.name" />
  </div>
</div>
