import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RootService } from '@app/core/root.service';
import { ItemProps } from '@app/interfaces';

@Component({
  selector: 'app-img-upload-details',
  templateUrl: './img-upload-details.component.html',
  styleUrls: ['./img-upload-details.component.scss'],
})
export class ImgUploadDetailsComponent implements OnInit {
  @Input() field: ItemProps;
  @Input() form: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() service: RootService;

  imageFieldName: string;
  alive = true;
  selectedImages: any = {};
  previewImage: string;
  constructor() {}

  ngOnInit(): void {}

  uploadPhoto(event: any, field: any, image?: any) {
    const fileExtensions = ['jpeg', 'jpg', 'png', 'tiff', 'gif', 'bmp', 'webp'];
    const mimeTypes = this.service.shared.getMimeTypes(fileExtensions);
    const file = event.target.files[0];
    if (file && file.type) {
      const MimeMatched = !!mimeTypes.some((MimeType: any) => {
        if (file.type === MimeType) {
          return true;
        }
      });
      if (!MimeMatched) {
        this.service.shared.toastr.warning(
          this.service.shared.translate.instant('error_codes.photos_file_type'),
          this.service.shared.translate.instant('error_codes.upload_failed'),
          { closeButton: true }
        );
      } else {
        if (field.form.inputValueType === 'base64_img') {
          this.imageFieldName = field.name;
          if (file) {
            const reader = new FileReader();
            reader.onload = this.convertImage.bind(this);
            reader.readAsBinaryString(file);
          }
        } else if (field.form.inputValueType === 'formData') {
          if (file && file.size > 5000000) {
            this.service.shared.toastr.warning(
              this.service.shared.translate.instant('error_codes.photos_file_size_limit'),
              this.service.shared.translate.instant('error_codes.upload_failed'),
              { closeButton: true }
            );
          } else {
            this.readURL(file);
            this.form.controls[field.name].setValue(file);
            this.selectedImages[field.name] = file;
            this.form.controls[field.name].markAsDirty();
          }
        }
      }
    }
  }

  convertImage(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.form.controls[this.imageFieldName].setValue(btoa(binaryString));
  }

  readURL(input: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewImage = e.target.result;
    };
    reader.readAsDataURL(input);
  }
}
