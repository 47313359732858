<mat-grid-list cols="2" rowHeight="40px">
  <mat-grid-tile>
    <span class="column-title">{{ 'roles.module_perms' | translate }}</span>
  </mat-grid-tile>
  <mat-grid-tile>
    <span class="column-title">{{ 'roles.access' | translate }}</span>
  </mat-grid-tile>
</mat-grid-list>

<ng-container *ngIf="templateLoaded">
  <div *ngFor="let item of (service?.lists)['roles-create']?.permissions | keyvalue">
    <!--          <mat-radio-group [(ngModel)]="permissions[item.key]" [ngModelOptions]="{ standalone: true }">-->
    <!--            <mat-radio-button [id]="'all-' + item.key" [value]="'all-' + item.key" (click)="handleAll(item.key)">{{-->
    <!--              'all ' + item.key-->
    <!--              }}</mat-radio-button>-->
    <!--            <mat-radio-button [id]="'custom-' + item.key" [value]="'custom-' + item.key">Custom</mat-radio-button>-->
    <!--          </mat-radio-group>-->

    <mat-radio-group [(ngModel)]="permissions[item.key].val" [ngModelOptions]="{ standalone: true }">
      <mat-grid-list cols="2" rowHeight="40px">
        <mat-grid-tile>
          {{ 'roles.' + item.key | translate }}
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-radio-button
            [id]="'all-' + item.key"
            [value]="'all-' + item.key"
            (change)="handleAll(item.key)"
            color="primary"
          >
            <span class="title">{{ 'roles.full' | translate }}</span>
          </mat-radio-button>
          <mat-radio-button
            [id]="'custom-' + item.key"
            [value]="'custom-' + item.key"
            (change)="handleCustom(item.key)"
            color="primary"
            ><span class="title">{{ 'roles.custom' | translate }}</span>
          </mat-radio-button>
          <mat-radio-button
            [id]="'none-' + item.key"
            [value]="'none-' + item.key"
            (change)="clearAll(item.key)"
            color="primary"
          >
            <span class="title">{{ 'roles.none' | translate }}</span>
          </mat-radio-button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-radio-group>

    <div class="left-mock-div"></div>
    <div *ngIf="permissions[item.key].val === 'custom-' + item.key" class="right-mock-div">
      <ng-container *ngFor="let permission of item?.value">
        <!--        {{customDefaultPermissionsChecker(item.key, permission.id) | json }}-->
        <mat-checkbox
          class="checkBox"
          [(ngModel)]="permissions[item.key].custom[permission.id]"
          (change)="togglePermission(item.key, permission.id)"
          [disabled]="customDefaultPermissionsChecker(item.key, permission.id)"
          color="primary"
        >
          <span class="title">{{ permission.displayName }}</span>
          <span class="desc">{{ permission.description }}</span>
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
</ng-container>
