<div [formGroup]="form">
  <div class="field-container" *ngIf="field?.form">
    <mat-form-field style="width: 70%" class="p-r-30" *ngIf="dateKey">
      <mat-label>
        <span>{{ cid + '.' + (field.form.name ? field.form.name : dateKey) | translate }}</span>
        <span *ngIf="checkRequiredFields(dateKey)" class="mat-error required-asterisk inline"> *</span>
      </mat-label>
      <input
        color="primary"
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        [min]="minDate"
        [formControlName]="dateKey"
        autocomplete="off"
        (click)="picker.open()"
        [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + dateKey }"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 30%" *ngIf="timeKey">
      <mat-label fxFlex="row" fxLayout>
        <div fxFlex="95">
          <span>{{ cid + '.' + (field.form.name ? field.form.name : timeKey) | translate }}</span>
          <span *ngIf="checkRequiredFields(timeKey)" class="mat-error required-asterisk inline"> *</span>
        </div>
      </mat-label>
      <input color="primary" matInput [ngxTimepicker]="picker" [formControlName]="timeKey" />
      <mat-icon matSuffix fxFlex="" class="mdi-icon time-picker-icon" svgIcon="clock-start"></mat-icon>
      <ngx-material-timepicker #picker></ngx-material-timepicker>
    </mat-form-field>
    <ng-container *ngIf="form?.get(dateKey)?.errors || form?.get(timeKey)?.errors">
      <!--        <ng-container >-->
      <div
        class="validation-errors date-errors"
        *ngIf="(form.get(dateKey)?.touched || isSubmitted) && form.get(dateKey)?.invalid && form.get(dateKey)?.errors"
      >
        <div class="mat-error" *ngFor="let error of form.get(dateKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        class="validation-errors time-errors"
        *ngIf="
          (form?.get(timeKey)?.touched || isSubmitted) && form?.get(timeKey)?.invalid && form?.get(timeKey)?.errors
        "
      >
        <div class="mat-error" *ngFor="let error of form.get(timeKey)?.errors | keyvalue">
          <!-- Server Side Errors -->
          <ng-container *ngIf="error?.key === 'serverError'">
            <ng-container *ngFor="let err of error.value">
              {{ err }}
            </ng-container>
          </ng-container>

          <!-- Client Side Errors-->
          <ng-container *ngIf="error?.key !== 'serverError'">
            <ng-container *ngIf="!field?.hints?.password_helper">
              <ng-container [ngSwitch]="error?.key">
                <ng-container *ngSwitchCase="'minlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchCase="'maxlength'">
                  {{ 'validation_errors.' + error.key | translate : { xx: error?.value?.requiredLength } }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ 'validation_errors.' + error.key | translate }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
