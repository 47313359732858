import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap, mergeMapTo } from 'rxjs/operators';
import { AppHelper } from '@app/core/classes/app-helper';

import { environment } from '@env/environment';
import { Logger, I18nService, untilDestroyed, AuthenticationService, CredentialsService } from '@app/core';
import { MessagingService } from './shared/services/messaging/messaging.service';
import { TourService } from 'ngx-ui-tour-md-menu';
const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    public tourService: TourService,
    private messagingService: MessagingService,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    // this.changeTitlePage();
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    // todo my play
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let baseParentUrl = 'menu.' + event.url.split('/')[1];
      if (event.url.split('/')[1] === '') {
        baseParentUrl = 'menu.home';
      }
      this.titleService.setTitle('Afaqy Products | ' + this.translateService.instant(baseParentUrl));
    });

    this.setDirectionVars();
    if (this.credentialsService.isAuthenticated()) {
      this.messagingService.openConnection(this.credentialsService.credentials.userId);
    }
  }

  setDirectionVars() {
    const language = this.i18nService.language;
    AppHelper.setDirectionVars(language === 'en-US' ? 'ltr' : 'rtl');
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
