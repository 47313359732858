import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RootService } from '@app/core/root.service';
import { ItemProps } from '@app/interfaces';
import { AbstractControl, ControlContainer, FormControl, UntypedFormGroup } from '@angular/forms';
import { transition, trigger, useAnimation } from '@angular/animations';
import { HideAnimation, showAnimation } from '@app/shared/animations/transform-opacity';
import { takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// eslint-disable-next-line no-duplicate-imports
import { defaultFormat as _rollupMoment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AppHelper } from '@app/core/classes/app-helper';
import { I18nService } from '@app/core/i18n.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { ImgCropperDialogComponent } from '../img-cropper-dialog/img-cropper-dialog.component';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  // parse: {
  //   //   // dateInput: 'LL',
  //   dateInput: 'YYYY-MM-DD'
  // },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-core-form-content',
  templateUrl: './core-form-content.component.html',
  styleUrls: ['./core-form-content.component.scss'],
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: {
            timings: '200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: {
            timings: '200ms 200ms ease-in-out',
            transform: 'translateY(-10%)',
            opacity: '0',
          },
        }),
      ]),
    ]),
  ],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreFormContentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, AfterContentInit {
  @Input() cid: string;
  @Input() customCid: string;
  @Input() service: RootService;
  @Input() field: ItemProps;
  @Input() forcedFieldType: string;

  @Input() form: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Input() isClone: boolean;
  @Input() isSubmitted: boolean;
  @Input() controller: any;
  @Input() fieldArrayName: any;
  @Input() appliedFilter: boolean;
  @Input() manualRefresh: any;

  @Output() showHideChanges: EventEmitter<any> = new EventEmitter();
  @Output() UpdateFormFieldValueEmitter: EventEmitter<any> = new EventEmitter();

  // form: FormGroup = new FormGroup({});

  previewImage: string;
  imageFieldName: string;
  alive = true;
  itemId: number;

  selectedFiles: any = {};
  selectedImages: any = {};
  asyncAfterViewInit: boolean;
  asyncAfterContentInit: boolean;
  isCropped = false;
  croppedImage: any;
  constructor(
    // private changeDetectorRef: ChangeDetectorRef, // private controlContainer: ControlContainer,
    private dialog: MatDialog,
    private _adapter: DateAdapter<any>,
    private i18nService: I18nService,
    private translate?: TranslateService
  ) {}

  ngOnInit() {
    this.setDatepickerLang(this.i18nService.language);
    this.languageSubscription();
  }

  languageSubscription() {
    this.service.shared.sendLanguage.pipe(takeWhile(() => this.alive)).subscribe((lang: string) => {
      this.setDatepickerLang(lang);
    });
  }

  setDatepickerLang(dateLang: string) {
    dateLang = this.i18nService.language;
    this._adapter.setLocale(dateLang);
  }

  getValue(obj: any, path: string) {
    return AppHelper.deepFind(obj, path);
  }

  tooltipConditions(tooltip?: any, list?: any) {
    // const tooltip = list.icon.tooltip;
    if (!tooltip) {
      return null;
    } else {
      if (tooltip.text) {
        if (tooltip.translated) {
          return this.translate.instant(tooltip.text);
        } else {
          return tooltip.text;
        }
      } else {
        return null;
      }
    }
  }

  /**
   * checks if the control name is required or not from the current validators on it
   * why > because template checking doesn't update as you remove
   * the validations and re insert them when live removing inputs
   * also > to be able to see which inputs are required before they are
   *  touched and have error.required in validation errors
   * @param controlName the control name in the form
   */
  checkRequiredFields(controlName: string) {
    // if (!this.isEdit) {
    const abstractControl = this.form.controls[controlName];
    if (!abstractControl) {
      return false;
    }
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
    // }
  }

  checkImgSrc(field: any) {
    if (!!this.form.controls[field.name].valueChanges) {
      if (!this.previewImage) {
        if (!this.form.controls[field.name].value) {
          return field.form.placeHolder;
        } else {
          return this.form.controls[field.name].value;
        }
      } else {
        return this.previewImage;
      }
    }
  }

  checkCroppedImgSrc(field: any) {
    if (!!this.form.controls[field.name].valueChanges) {
      if (!this.previewImage) {
        if (!this.form.controls[field.name].value) {
          return field.form.placeHolder;
        } else {
          return this.form.controls[field.name].value;
        }
      } else {
        if (this.isCropped) {
          this.previewImage = this.croppedImage;
          return this.previewImage;
        } else {
          if (this.form.controls[field.name].value) {
            return this.form.controls[field.name].value;
          } else {
            this.previewImage = null;
            return this.previewImage;
          }
        }
      }
    }
  }

  /**
   * Pick color
   * @param field: field name
   * @param color: HEX color
   */
  setColor(field: string, color: string) {
    this.form.controls[field].setValue(color);
  }

  uploadPhoto(event: any, field: any, image?: any) {
    const fileExtensions = ['jpeg', 'jpg', 'png', 'tiff', 'gif', 'bmp', 'webp'];
    const mimeTypes = this.service.shared.getMimeTypes(fileExtensions);
    const file = event.target.files[0];
    if (file && file.type) {
      const MimeMatched = !!mimeTypes.some((MimeType: any) => {
        if (file.type === MimeType) {
          return true;
        }
      });
      if (!MimeMatched) {
        this.service.shared.toastr.warning(
          this.service.shared.translate.instant('error_codes.photos_file_type'),
          this.service.shared.translate.instant('error_codes.upload_failed'),
          { closeButton: true }
        );
      } else {
        if (field.form.inputValueType === 'base64_img') {
          this.imageFieldName = field.name;
          if (file) {
            const reader = new FileReader();
            reader.onload = this.convertImage.bind(this);
            reader.readAsBinaryString(file);
          }
        } else if (field.form.inputValueType === 'formData') {
          if (file && file.size > 5000000) {
            this.service.shared.toastr.warning(
              this.service.shared.translate.instant('error_codes.photos_file_size_limit'),
              this.service.shared.translate.instant('error_codes.upload_failed'),
              { closeButton: true }
            );
          } else {
            this.readURL(file);
            this.form.controls[field.name].setValue(file);
            this.selectedImages[field.name] = file;
            this.form.controls[field.name].markAsDirty();
          }
        }
      }
    }
  }

  uploadFile(event: any, field: any) {
    if (field.form.inputValueType === 'formData') {
      const file = event.target.files[0];
      if (file && file.size > 10000000) {
        this.service.shared.toastr.warning(
          this.service.shared.translate.instant('error_codes.document_file_size_limit'),
          this.service.shared.translate.instant('error_codes.upload_failed'),
          { closeButton: true }
        );
      } else {
        this.form.controls[field.name].setValue(file);
        this.form.controls[field.name].markAsDirty();
        this.selectedFiles[field.name] = file;
      }
    }
  }

  markAsDirty(control: AbstractControl) {
    this.form.controls[this.field.name].markAsDirty();
  }

  /**
   * Preview Image
   * @param input: Input
   */
  readURL(input: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewImage = e.target.result;
    };
    reader.readAsDataURL(input);
  }

  convertImage(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.form.controls[this.imageFieldName].setValue(btoa(binaryString));
  }

  uploadNewPhoto(field: any, image: any) {
    const formV = {};
    formV[field.name] = image;
    this.service
      .doCreate(`${this.cid}/${this.itemId}/${field.name}`, this.toFormData(formV))
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {});

    //   .then(() => {
    //     this.service.updateResources.next();
    //   });
  }

  toFormData<T>(formValue: T) {
    // console.log(formValue);
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      /**
       * fix for boolean values in formData method
       */
      if (value === true) {
        formData.append(key, '1');
      } else if (value === false) {
        formData.append(key, '0');
      } else {
        formData.append(key, value);
      }
    }
    // console.log(formData);

    return formData;
  }

  focusInputById(id: any) {
    document.getElementById(id).focus();
  }

  clickElementBySelector(selector: any) {
    return document.querySelector(selector)[0].click();
  }

  clearValue(control: AbstractControl) {
    control.setValue(null);
  }

  async enableControl(control: AbstractControl) {
    if (control.disabled) {
      control.enable();
    }
    return;
  }

  async clearImage(control: AbstractControl, id?: string) {
    await this.enableControl(control);

    control.setValue(null);
    control.markAsDirty();
    this.previewImage = null;
  }

  async openFileSelectFromInput(control: AbstractControl, id: string) {
    await this.enableControl(control);

    setTimeout(() => {
      document.getElementById(id).click();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    if (!this.asyncAfterViewInit) {
      setTimeout(() => {
        this.asyncAfterViewInit = true;
      });
    }

    // this.changeDetectorRef.detectChanges();
    // this.form.updateValueAndValidity();
    // console.log(this.form.controls[this.field.name].value)
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    // console.log(this.form);
  }

  ngSelect_selectAll(control: AbstractControl, listPrefix: string, selectAll: boolean) {
    if (control && this.service.lists[listPrefix]) {
      const selected: number[] = [];
      if (selectAll) {
        Object.values(this.service.lists[listPrefix]).forEach((item: { id: number }) => {
          selected.push(item.id);
        });
      }
      control.setValue(selected);
      this.markAsDirty(control);
    }
  }

  ngSelect_selectAllLabels(control: AbstractControl, listPrefix: string, selectAll: boolean) {
    if (control && this.service.lists[listPrefix]) {
      const selected: string[] = [];
      if (selectAll) {
        Object.values(this.service.lists[listPrefix]).forEach((item: { name: string }) => {
          selected.push(item.name);
        });
      }
      control.setValue(selected);
    }
  }

  // getControlName(name:string) {
  //   if(this.field.form.formFieldType == 'formArray') {
  //     let group = this.form.get(this.field.form.name) as FormGroup
  //     let control = group.controls[name]

  //   }
  //   return control;
  // }

  checkSelected(control: AbstractControl, id: number): boolean {
    if (control && id && control.value) {
      if (control.value.find((x: number) => x === id)) {
        return true;
      }
    }
  }

  checkLabelSelected(control: AbstractControl, name: string): boolean {
    if (control && name && control.value) {
      if (control.value.find((x: string) => x === name)) {
        return true;
      }
    }
  }

  preventDefault(event: any) {
    event.preventDefault();
  }

  onChangeValue(column: ItemProps, event: any) {
    this.service.onChangeValue_checker(column, event);
  }

  UpdateFormFieldValue(event: any, field?: ItemProps) {
    this.UpdateFormFieldValueEmitter.emit({
      fieldProps: field ? field : this.field,
      fieldValue: event.fieldValue,
      updateForm: event.updateForm,
    });
  }

  addTagNameFn(name: any) {
    // console.log(name);

    name = name.replace(/ /g, '-');

    if (name.length > 20) {
      name = name.substring(0, 20);
    }

    return { name, tag: true };
  }

  trackByFn(item: any) {
    return item.id;
  }

  ngAfterContentInit(): void {
    if (!this.asyncAfterContentInit) {
      setTimeout(() => {
        this.asyncAfterContentInit = true;
      });
    }
  }

  handle_multiCheckboxValues(field: any, selectedOption: any, event: any) {
    this.form.get(field.name).markAsTouched();

    const checked = event?.checked;
    const mainCustomFieldVal: string[] = this.form.get(field.name).value || [];

    if (checked) {
      mainCustomFieldVal.push(selectedOption.id);
    } else {
      mainCustomFieldVal.splice(mainCustomFieldVal.indexOf(selectedOption.id), 1);
    }

    this.form.get(field.name).setValue(mainCustomFieldVal);
  }

  uploadPhotoAndCrop(event: any, field: any, image?: any) {
    const fileExtensions = ['jpeg', 'jpg', 'png', 'gif'];
    const mimeTypes = this.service.shared.getMimeTypes(fileExtensions);
    const file = event.target.files[0];
    if (file && file.type) {
      const MimeMatched = !!mimeTypes.some((MimeType: any) => {
        if (file.type === MimeType) {
          return true;
        }
      });
      if (!MimeMatched) {
        this.service.shared.toastr.warning(
          this.service.shared.translate.instant('error_codes.photos_file_type'),
          this.service.shared.translate.instant('error_codes.upload_failed'),
          { closeButton: true }
        );
      } else {
        if (field.form.inputValueType === 'base64_img') {
          this.imageFieldName = field.name;
          if (file) {
            const reader = new FileReader();
            reader.onload = this.convertImage.bind(this);
            reader.readAsBinaryString(file);
          }
        } else if (field.form.inputValueType === 'formData') {
          if (file && file.size > 5000000) {
            this.service.shared.toastr.warning(
              this.service.shared.translate.instant('error_codes.photos_file_size_limit'),
              this.service.shared.translate.instant('error_codes.upload_failed'),
              { closeButton: true }
            );
          } else {
            this.readURL(file);
            const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
              data: {
                image: event,
              },
            });
            dialogRef.componentInstance.onSave.subscribe((res: any) => {
              if (res) {
                this.isCropped = true;
                this.croppedImage = res.base64;
                this.previewImage = this.croppedImage;
                this.form.controls[field.name].setValue(res.file);
                this.form.controls[field.name].markAsDirty();
              } else {
                this.isCropped = false;
              }
            });
          }
        }
      }
    }
  }
}
