<!--{{ value | json }}-->
<div class="form-input-size field-container">
  <label class="bold d-flex clearfix pt-2 pb-2">
    <span>{{ this.service.cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
  </label>
  <ng-select
    [appearance]="'outline'"
    class="ng-select-style1"
    [ngClass]="{ 'required-asterisk-inline-before': requiredAsterisk }"
    [items]="items"
    [loading]="loading"
    [multiple]="field?.form?.ngSelectOptions?.multiple || field?.list?.ngSelectOptions?.multiple"
    bindLabel="name"
    bindValue="id"
    dropdownPosition="auto"
    [closeOnSelect]="
      !(field?.form?.ngSelectOptions?.keepOpenAfterSelect || field?.list?.ngSelectOptions?.keepOpenAfterSelect)
    "
    [virtualScroll]="true"
    appendTo="body"
    (scrollToEnd)="onScrollToEnd()"
    [typeahead]="search$"
    (change)="onValueChange(field, $event)"
    [name]="field?.name"
    [(ngModel)]="value"
    [disabled]="disabledInput"
    (open)="onTouched()"
    [attr.testId]="field.name"
    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
  >
    <!--  (scroll)="onScroll($event)"-->
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div class="d-inline-flex">
        <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
          <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
            <span class="ng_select_prefix" *ngIf="prefix?.prop">
              <ng-container [ngSwitch]="prefix?.displayType">
                <ng-container *ngSwitchCase="null">
                  <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                    {{ item[prefix.prop] }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'color'">
                  <span
                    class="cell-display-type color {{ prefix?.cssClass }}"
                    *ngIf="item[prefix.prop]"
                    [style.background-color]="item[prefix.prop]"
                  ></span>
                </ng-container>
                <ng-container *ngSwitchCase="'img'">
                  <span class="cell-display-type img {{ prefix?.cssClass }}">
                    <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                    <img
                      class="img-placeholder"
                      *ngIf="!item[prefix.prop]"
                      src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                      alt=""
                    />
                  </span>
                </ng-container>
              </ng-container>
            </span>
          </ng-container>
        </ng-container>
      </div>

      <span class="ng-value-label d-inline-flex">
        {{ item.name || item.displayName }}
      </span>

      <span class="ng-value-icon right ml-2" (click)="clear(item)">
        <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
      </span>
    </ng-template>

    <ng-template ng-header-tmp>
      <small class="form-text text-muted">
        {{ ('common.loaded' | translate) + ' ' + items.length + ' ' + ('common.of' | translate) + ' ' + totalRecords }}
      </small>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
      <div class="d-flex align-items-center">
        <mat-checkbox
          *ngIf="field?.form?.ngSelectOptions?.multiple || field?.list?.ngSelectOptions?.multiple"
          class="d-inline-flex mr-3"
          id="{{ item }}-option-{{ index }}"
          color="primary"
          [checked]="checkSelected(form.controls[field.name], item.id)"
          (click)="preventDefault($event)"
        ></mat-checkbox>
        <div class="d-flex align-items-center">
          <div class="d-inline-flex">
            <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
              <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                <span class="ng_select_prefix" *ngIf="prefix?.prop">
                  <ng-container [ngSwitch]="prefix?.displayType">
                    <ng-container *ngSwitchCase="null">
                      <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                        {{ item[prefix.prop] }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'color'">
                      <span
                        class="cell-display-type color {{ prefix?.cssClass }}"
                        *ngIf="item[prefix.prop]"
                        [style.background-color]="item[prefix.prop]"
                      ></span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'img'">
                      <span class="cell-display-type img {{ prefix?.cssClass }}">
                        <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                        <img
                          class="img-placeholder"
                          *ngIf="!item[prefix.prop]"
                          src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                          alt=""
                        />
                      </span>
                    </ng-container>
                  </ng-container>
                </span>
              </ng-container>
            </ng-container>
          </div>

          <div class="d-inline-flex" [ngOptionHighlight]="search">
            {{ item?.name || item?.displayName }}
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
