<mat-card class="main-card" fxFlex="" fxLayout="column">
  <div fxFlex class="header-container">
    <span fxFlex="" class="card-title">{{ 'notifications.title' | translate }}</span>
    <mat-icon class="mdi-icon cursor-pointer" svgIcon="close" (click)="sideNav.close()"></mat-icon>
  </div>

  <div fxFlex fxLayout="row" class="mark-as-container">
    <div fxFlex="50" fxLayout="row center" class="cursor-pointer" (click)="markAllNotifications(1)">
      <mat-icon class="eye-icon cursor-pointer" svgIcon="eye-outline"></mat-icon>
      <span class="mark-title">{{ 'notifications.mark_read' | translate }}</span>
    </div>
    <div fxFlex="" fxLayout="row center" class="cursor-pointer" (click)="markAllNotifications(0)">
      <mat-icon class="eye-icon cursor-pointer" svgIcon="eye-off-outline"></mat-icon>
      <span class="mark-title">{{ 'notifications.mark_unread' | translate }}</span>
    </div>
  </div>

  <div fxFlex fxLayout="column" class="core-list px-2">
    <ng-container *ngIf="columns">
      <app-list-grid [columns]="columns" [options]="listOptions" [service]="service"></app-list-grid>
    </ng-container>

    <div
      fxFlex
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="end center"
      class="container list-grid-pagination"
      *ngIf="!listOptions?.infiniteScrolling"
    >
      <app-pagination [service]="service" [options]="listOptions"></app-pagination>
    </div>
  </div>
</mat-card>
