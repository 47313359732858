<!--<ngx-spinner bdColor="rgba(255,255,255,0.4)" size="small" color="none" type="none">-->
<!--  &lt;!&ndash;  <p style="font-size: 20px; color: white">{{ 'common.loading' | translate }}</p>&ndash;&gt;-->
<!--  <mat-spinner [mode]="'indeterminate'"></mat-spinner>-->
<!--</ngx-spinner>-->

<router-outlet></router-outlet>
<tour-step-template>
  <ng-template #defaultTemplate let-step="step">
    <mat-card (click)="$event.stopPropagation()">
      <mat-card-title>
        {{ step?.title }}
      </mat-card-title>
      <mat-card-content class="content-wrap">
        {{ step?.content }}
      </mat-card-content>
      <mat-card-actions>
        <button mat-icon-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          mat-icon-button
          [disabled]="!tourService.hasNext(step) || (step?.nextStepCondition && step?.nextStepCondition())"
          (click)="tourService.next()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-button (click)="tourService.end()">{{ step?.endBtnTitle }}</button>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
