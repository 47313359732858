import { Injectable } from '@angular/core';
import { ChangePasswordBody } from '@app/core/models/change-password-request-body';
import { Observable } from 'rxjs';
import { APIURL } from '@app/core/apis';
import { map } from 'rxjs/operators';
import { ApiService } from '@app/core/api-service/api.service';
import { ApiRequestService } from '@app/core/http/api-request.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: ApiRequestService) {}

  changePassword(requestBody: ChangePasswordBody): Observable<boolean> {
    return this.api.post(APIURL.changePassword, requestBody).pipe(
      map((body: any) => {
        return body != null;
      })
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.api.post(APIURL.forgotPassword, email).pipe(
      map((body: any) => {
        return body != null;
      })
    );
  }

  forgotClientPassword(email: string): Observable<boolean> {
    return this.api.post(APIURL.forgotClientPassword, email).pipe(
      map((body: any) => {
        return body != null;
      })
    );
  }

  submitNotExistClientData(body: any): Observable<boolean> {
    return this.api.post(APIURL.clientNotExist, body).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
