import { Component, OnInit, Inject, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SharedService } from '@app/shared/services/shared.service';

@Component({
  selector: 'app-img-cropper-dialog',
  templateUrl: './img-cropper-dialog.component.html',
  styleUrls: ['./img-cropper-dialog.component.scss'],
})
export class ImgCropperDialogComponent implements OnInit {
  base64: any;
  dialogRef: any;
  @Output() onSave = new EventEmitter<any>();
  blob: any;
  blobUrl: any;
  file: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private shared: SharedService) {}
  ngOnInit(): void {}

  imageCropped(event: any) {
    this.base64 = event.base64;
    // this.convertToBlob(event.base64);
    this.convertToFile(this.base64, 'logo');
  }

  convertToBlob(b64Data: any) {
    b64Data = b64Data.substring(b64Data.indexOf(',') + 1);
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    this.blob = new Blob([byteArray], { type: 'image/png' });
    this.blobUrl = URL.createObjectURL(this.blob);
    return this.blob;
  }

  convertToFile(b64Data: any, filename: any) {
    var arr = b64Data.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.file = new File([u8arr], filename, { type: mime });

    return this.file;
  }
  saveLogo() {
    this.onSave.emit({ file: this.file, base64: this.base64 });
  }
}
