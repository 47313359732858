<div class="filter-inline-container" fxFlex fxLayout>
  <form [formGroup]="form" *ngIf="column" (keydown)="keyDownFunction($event)">
    <ng-container *ngIf="column.list && column.list.searchableInline">
      <!-- same as the one in filters component -->
      <ng-container [ngSwitch]="column.list.filterFieldTypeInline">
        <ng-container *ngSwitchCase="null">
          <mat-form-field>
            <!-- <mat-icon matPrefix color="#999">search</mat-icon> -->
            <input
              matInput
              [formControlName]="column.name"
              [placeholder]="service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate"
            />
            <button
              mat-button
              *ngIf="form.controls[column.name].value !== null"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearInput(column.name)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <mat-form-field>
            <!-- <mat-icon matPrefix color="#999">search</mat-icon> -->
            <input
              matInput
              [formControlName]="column.name"
              [placeholder]="service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate"
            />
            <button
              class="filter-clear-button"
              mat-button
              *ngIf="form.controls[column.name].value !== null"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearInput(column.name)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <mat-form-field>
            <mat-select
              [formControlName]="column.name"
              [placeholder]="service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate"
            >
              <mat-option *ngFor="let item of service.lists[column.list.listPrefix]" [value]="item.id">{{
                item.name || item.displayName
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <mat-checkbox color="primary" class="d-flex clearfix pt-2 pb-2" [formControlName]="column.name"
            >{{ service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate }}
          </mat-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="'multi_select'">
          <mat-form-field>
            <mat-select
              [formControlName]="column.name"
              [placeholder]="'common.' + (column.list.name ? column.list.name : column.name) | translate"
              multiple
            >
              <mat-option *ngFor="let item of service.lists[column.list.listPrefix]" [value]="item.id">{{
                item.name || item.displayName
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'ng_select_multiple'">
          <ng-select
            class="ng-select-style1"
            [items]="service.lists[column.list.listPrefix]"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            dropdownPosition="bottom"
            [closeOnSelect]="false"
            [formControlName]="column.name"
            (change)="service.onChangeValue_checker(column, $event)"
            appendTo="body"
          >
            <ng-template ng-header-tmp>
              <div class="d-flex py-3">
                <button
                  class="mr-2"
                  (click)="ngSelect_selectAll(form.controls[column.name], column.list.listPrefix, true)"
                  mat-stroked-button
                  color="primary"
                >
                  {{ 'common.selectAll' | translate }}
                </button>
                <button
                  (click)="ngSelect_selectAll(form.controls[column.name], column.list.listPrefix, false)"
                  mat-stroked-button
                  color="warn"
                >
                  {{ 'common.unselectAll' | translate }}
                </button>
              </div>
            </ng-template>

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{ item.name || item.displayName }}</span>
              <span class="ng-value-icon right ml-2" (click)="clear(item)">
                <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
              </span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <div class="d-flex align-items-center">
                <mat-checkbox
                  class="d-inline-flex mr-3"
                  id="{{ item }}-option-{{ index }}"
                  color="primary"
                  [checked]="checkSelected(form.controls[column.name], item.id)"
                  (click)="preventDefault($event)"
                ></mat-checkbox>
                <div class="d-inline-flex" [ngOptionHighlight]="search">
                  {{ item?.name || item?.displayName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </ng-container>

        <ng-container *ngSwitchCase="'ng_select'">
          <ng-select
            class="ng-select-style1"
            [items]="service.lists[column.list.listPrefix]"
            [multiple]="false"
            bindLabel="name"
            bindValue="id"
            dropdownPosition="bottom"
            [closeOnSelect]="true"
            [formControlName]="column.name"
            (change)="service.onChangeValue_checker(column, $event)"
            placeholder="{{ service.cid + '.' + (column.list.name ? column.list.name : column.name) | translate }}"
            appendTo="body"
          >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{ item.name || item.displayName }}</span>
              <span class="ng-value-icon right ml-2" (click)="clear(item)">
                <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
              </span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
              <div class="d-flex align-items-center">
                <div class="d-inline-flex" [ngOptionHighlight]="search">
                  {{ item?.name || item?.displayName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </ng-container>
      </ng-container>
      <!-- same as the one in filters component -->
    </ng-container>
  </form>
</div>

<!-- clear button the only difference between these inputs and the ones in filter for when duplicating input types from filter -->
<!--<button-->
<!--  mat-button-->
<!--  *ngIf="form.controls[column.name].value !== null"-->
<!--  matSuffix-->
<!--  mat-icon-button-->
<!--  aria-label="Clear"-->
<!--  (click)="clearInput(column.name)"-->
<!--&gt;-->
<!--  <mat-icon>close</mat-icon>-->
<!--</button>-->
