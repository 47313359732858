import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize, takeWhile } from 'rxjs/operators';

import { Logger, untilDestroyed } from '@app/core';
import { AuthService } from '../../services/auth.service';
import { RootService } from '@app/core/root.service';

const log = new Logger('ForgotPassword');
interface Branding {
  name?: string;
  logo?: string;
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../auth.scss', './forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  error: string | undefined;
  forgotpasswordForm!: UntypedFormGroup;
  isLoading = false;
  showSuccessMgs: boolean;
  alive = true;
  branding: Branding = {
    name: '',
    logo: '',
  };
  logoDefault = '/assets/logo-text.svg';
  countDownTimer: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private service: RootService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  submit() {
    this.isLoading = true;
    this.error = null;
    const submit$ = this.authService.forgotPassword(this.forgotpasswordForm.value);
    submit$
      .pipe(
        finalize(() => {
          this.forgotpasswordForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (data) => {
          log.debug(`${data} successfully logged in`);
          if (data === true) {
            this.showSuccessMgs = data;
          }
          // this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        },
        (err) => {
          const error = err.error;
          log.debug(`reseting password error: ${error}`);
          if (error && error?.message) {
            this.error = error.message;
          } else {
            this.error = 'Email is incorrect.';
          }
        }
      );
  }

  ngOnDestroy() {}

  private createForm() {
    this.forgotpasswordForm = this.formBuilder.group({
      email: ['', Validators.email],
    });
  }

  didnotReceiveMail() {
    this.submit();
    this.countDown();
  }

  countDown() {
    this.countDownTimer = 60;

    let countDown = setInterval(() => {
      this.countDownTimer--;
      if (this.countDownTimer === 0) {
        clearInterval(countDown);
      }
    }, 1000);
  }
}
