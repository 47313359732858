<!--Custom Actions Start-->
<div class="list-custom-actions">
  <ng-container *ngIf="options?.customActions?.length">
    <ng-container *ngFor="let action of options?.customActions">
      <ng-container *ngIf="service?.permission?.all || (service?.permission)[action?.permissionKey]">
        <ng-container [ngSwitch]="action?.type">
          <ng-container *ngSwitchCase="null">
            <ng-container *ngIf="!action?.hideFn || (action?.hideFn && !action?.hideFn(row))">
              <button
                mat-icon-button
                color="primary"
                fxFlex="nogrow"
                fxLayoutAlign="center center"
                [class.disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [ngClass]="action?.cssClass"
                [matTooltip]="action?.tooltip?.translate ? (action?.tooltip?.text | translate) : action?.tooltip?.text"
                (click)="action?.fn(row)"
              >
                <ng-container *ngIf="action?.icon">
                  <mat-icon
                    *ngIf="action?.icon?.svgIcon"
                    class="mdi-icon icon-border"
                    [svgIcon]="action?.icon?.name"
                    [attr.size]="'14px'"
                  ></mat-icon>
                  <mat-icon *ngIf="!action?.icon?.svgIcon">{{ action?.icon?.name }}</mat-icon>
                </ng-container>
                <span *ngIf="action?.title?.name">
                  {{ action?.title?.translate ? (action?.title?.name | translate) : action?.title?.name }}
                </span>
              </button>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'toggle'">
            <ng-container *ngIf="!action?.hideFn || (action?.hideFn && !action?.hideFn(row))">
              <mat-slide-toggle
                fxFlex="nogrow"
                fxLayoutAlign="center center"
                color="primary"
                [class.disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [disabled]="action?.disableFn ? action?.disableFn(row) : false"
                [ngClass]="action?.cssClass"
                [matTooltip]="action?.tooltip?.translate ? (action?.tooltip?.text | translate) : action?.tooltip?.text"
                (click)="
                  action?.fn
                    ? action?.fn(row)
                    : service.toggleKey(
                        row?.id,
                        action?.toggleOptions?.key,
                        service?.getValue(row, action?.toggleOptions?.key)
                      )
                "
                [checked]="!!service?.getValue(row, action?.toggleOptions?.key)"
              >
                <span *ngIf="action?.title?.name">
                  {{ action?.title?.translate ? (action?.title?.name | translate) : action?.title?.name }}
                </span>
              </mat-slide-toggle>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'dropdown'">
            <ng-container *ngIf="!action?.hideFn || (action?.hideFn && !action?.hideFn(row))">
              <ng-container *ngIf="action?.dropdownActions?.length">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  fxFlex="nogrow"
                  fxLayoutAlign="center center"
                  [class.disabled]="action?.disableFn ? action?.disableFn(row) : false"
                  [disabled]="action?.disableFn ? action?.disableFn(row) : false"
                  [ngClass]="action?.cssClass"
                  [matTooltip]="
                    action?.tooltip?.translate ? (action?.tooltip?.text | translate) : action?.tooltip?.text
                  "
                  class="settings-dropdown-btn"
                  fxLayout="row"
                >
                  <mat-icon class="mdi-icon" [svgIcon]="'dots-vertical'"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <ng-container *ngFor="let dropdownAction of action?.dropdownActions">
                    <ng-template
                      [ngTemplateOutlet]="actionTemplateRef"
                      [ngTemplateOutletContext]="{ action: dropdownAction }"
                    >
                    </ng-template>
                  </ng-container>
                </mat-menu>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<!--Custom Actions End-->

<!-- Start Single Action Template -->
<ng-template #actionTemplateRef let-action="action">
  <button
    mat-menu-item
    [disabled]="action?.disableFn ? action?.disableFn(row) : false"
    [ngClass]="action?.cssClass"
    (click)="action?.fn(row)"
  >
    <ng-container *ngIf="action?.icon">
      <mat-icon
        *ngIf="action?.icon?.svgIcon"
        class="mdi-icon"
        [svgIcon]="action?.icon?.name"
        [attr.size]="'20px'"
      ></mat-icon>
      <mat-icon *ngIf="!action?.icon?.svgIcon">{{ action?.icon?.name }}</mat-icon>
    </ng-container>

    <span *ngIf="action?.title?.name">
      {{ action?.title?.translate ? (action?.title?.name | translate) : action?.title?.name }}
    </span>
  </button>
</ng-template>
<!-- End Single Action Template -->
