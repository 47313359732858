<div id="app-shell" [dir]="currentLang === 'ar-SA' ? 'rtl' : 'ltr'" fxFlex>
  <mat-sidenav-container fxFill hasBackdrop="false" class="menu-state {{ menuSizeState }}">
    <mat-sidenav
      #sidenav
      opened="true"
      disableClose="true"
      mode="push"
      class="main-menu menu-style {{ menuSizeState }}"
    >
      <nav>
        <mat-list>
          <div class="menu-brand" fxLayout="row" *ngIf="menuSizeState === 'large'">
            <a class="logo" [routerLink]="['/']">
              <img
                [src]="logo"
                width="120px"
                alt="Afaqy"
                (error)="logoSrc.src = 'assets/logo.svg'"
                [afaqyHeatmap]="{ page: 'menu', target: 'logo' }"
                [ngClass]="{ 'filter-none': !logo.includes('assets') }"
                #logoSrc
              />
            </a>
            <button class="menuSizeToggler" mat-icon-button (click)="toggleMenuSize()">
              <div class="rtl-view">
                <mat-icon class="text-primary" *ngIf="currentLang === 'ar-SA'">chevron_right</mat-icon>
                <mat-icon class="text-primary" *ngIf="currentLang === 'ar-SA'">chevron_right</mat-icon>
              </div>
              <div class="ltr-view">
                <mat-icon class="text-primary" *ngIf="currentLang === 'en-US'">chevron_left</mat-icon>
                <mat-icon class="text-primary" *ngIf="currentLang === 'en-US'">chevron_left</mat-icon>
              </div>
            </button>
          </div>
          <div class="position-relative scrollable-container" [perfectScrollbar]>
            <a
              *ngIf="menuSizeState === 'small' || menuSizeState === 'hidden'"
              class="menu-button"
              href="javascript:void(0);"
              (click)="toggleMenuSize()"
            >
              <mat-icon>menu</mat-icon>
            </a>
            <!-- <mat-divider></mat-divider> -->
            <div>
              <div fxLayout="row" fxLayoutAlign="space-between center" class="user-card">
                <div fxLayout="row" fxLayoutAlign=" center">
                  <a class="img menu-user-image" [routerLink]="['/profile']" style="text-decoration: none">
                    <img
                      [src]="
                        userDetailsService?.userDetails?.photo
                          ? userDetailsService?.userDetails?.photo
                          : 'assets/user.png'
                      "
                      [alt]="userDetailsService?.userDetails?.name"
                    />
                  </a>
                  <a class="details" [routerLink]="['/profile']" style="text-decoration: none">
                    <h3>
                      {{ userDetailsService?.userDetails?.name }}
                    </h3>
                    <h4>
                      {{ userDetailsService?.userDetails?.position }}
                    </h4>
                  </a>
                </div>

                <button
                  mat-icon-button
                  class="profile-menu-button text-primary"
                  [matMenuTriggerFor]="profileMenu"
                  *ngIf="menuSizeState === 'large'"
                >
                  <mat-icon class="profile-menu-button-icon">more_horiz</mat-icon>
                </button>
                <mat-menu #profileMenu="matMenu" class="profile-menu">
                  <button mat-menu-item [routerLink]="['/profile']">
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'profile.update_profile' | translate }}</span>
                  </button>
                  <button
                    class="d-flex flex-justify-center align-items-center"
                    mat-menu-item
                    (click)="refreshPermissions()"
                  >
                    <mat-icon>verified_user</mat-icon>
                    <span>{{ 'profile.update_permissions' | translate }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>

            <!-- <mat-divider></mat-divider> -->
            <mat-accordion displayMode="flat">
              <ng-container *ngFor="let item of menuItems.items; let i = index">
                <div></div>
                <ng-container *ngIf="checkPermission(item.permissionsKey)">
                  <a *ngIf="!item?.children?.length" mat-list-item [routerLink]="item.url" routerLinkActive="active">
                    <div *ngIf="item.icon && !item.mdiIcon" class="icon">
                      <mat-icon class="menuIcon">{{ item.icon }}</mat-icon>
                    </div>
                    <div *ngIf="item.icon && item.mdiIcon" class="icon">
                      <mat-icon class="menuIcon mdi-icon" svgIcon="{{ item.icon }}"></mat-icon>
                    </div>
                    <span
                      *ngIf="menuSizeState === 'large'"
                      tourAnchor="{{ tutorialService.wholeAppTourSteps[getStepIndex(item)]?.anchorId }}"
                      >{{ 'menu.' + item.title | translate }}</span
                    >
                  </a>
                  <a
                    class="text-decoration-none"
                    mat-list-item
                    *ngIf="item?.children?.length > 0 && menuSizeState === 'small'"
                    [routerLink]="item.url"
                    routerLinkActive="active"
                  >
                    <div *ngIf="item.icon && !item.mdiIcon" class="icon">
                      <mat-icon class="menuIcon">{{ item.icon }}</mat-icon>
                    </div>
                    <div *ngIf="item.icon && item.mdiIcon" class="icon">
                      <mat-icon class="menuIcon mdi-icon" svgIcon="{{ item.icon }}"></mat-icon>
                    </div>
                    <span *ngIf="menuSizeState === 'large'">{{ 'menu.' + item.title | translate }}</span>
                  </a>
                  <ng-container *ngIf="item?.children?.length > 0 && menuSizeState !== 'small'">
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header [class.active]="checkCurrentRoute(item)" fxLayout="row">
                        <div *ngIf="item.icon && !item.mdiIcon" class="icon">
                          <mat-icon class="menuIcon">{{ item.icon }}</mat-icon>
                        </div>
                        <div *ngIf="item.icon && item.mdiIcon" class="icon">
                          <mat-icon class="menuIcon mdi-icon" svgIcon="{{ item.icon }}"></mat-icon>
                        </div>
                        <span
                          *ngIf="menuSizeState === 'large'"
                          tourAnchor="{{ tutorialService.wholeAppTourSteps[getStepIndex(item)]?.anchorId }}"
                          >{{ 'menu.' + item.title | translate }}</span
                        >
                      </mat-expansion-panel-header>
                      <mat-list *ngFor="let child of item.children; let i = index">
                        <ng-container *ngIf="checkPermission(item.permissionsKey, child.permissionsKey)">
                          <ng-container *ngIf="!child.hidden">
                            <ng-container *ngIf="!child?.children?.length">
                              <a
                                class="menu-item"
                                mat-list-item
                                [routerLink]="child.url"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: !!child.exact }"
                                [afaqyHeatmap]="{ page: 'menu', target: item.title }"
                              >
                                <!-- <div *ngIf="item.icon && !item.mdiIcon" class="icon">-->
                                <!--                        <mat-icon class="menuIcon">{{ item.icon }}</mat-icon>-->
                                <!--                      </div>-->
                                <!--                      <div *ngIf="item.icon && item.mdiIcon" class="icon">-->
                                <!--                        <mat-icon class="menuIcon mdi-icon" svgIcon="{{ item.icon }}"></mat-icon>-->
                                <!--                      </div> -->
                                <span *ngIf="menuSizeState === 'large'">{{ 'menu.' + child.title | translate }}</span>
                              </a>
                            </ng-container>
                            <ng-container *ngIf="child?.children?.length > 0 && menuSizeState !== 'small'">
                              <a
                                *ngIf="child.url"
                                class="menu-item"
                                mat-list-item
                                [routerLink]="child.url"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: !!child.exact }"
                              >
                                <span *ngIf="menuSizeState === 'large'">{{ 'menu.' + child.title | translate }}</span>
                              </a>
                              <a *ngIf="!child.url" class="menu-item" mat-list-item>
                                <ng-container *ngIf="child.icon && !child.mdiIcon">
                                  <mat-icon class="child-item-icon">{{ child.icon }}</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="child.icon && child.mdiIcon">
                                  <mat-icon class="child-item-icon" svgIcon="{{ child.icon }}"></mat-icon>
                                </ng-container>

                                <span *ngIf="menuSizeState === 'large'">{{ 'menu.' + child.title | translate }}</span>
                              </a>
                              <mat-list>
                                <ng-container *ngFor="let child of child.children; let i = index">
                                  <ng-container *ngIf="!child.hidden">
                                    <a
                                      class="menu-item"
                                      mat-list-item
                                      [routerLink]="child.url"
                                      routerLinkActive="active"
                                      [routerLinkActiveOptions]="{ exact: !!child.exact }"
                                    >
                                      <!--                      <div *ngIf="item.icon && !item.mdiIcon" class="icon">-->
                                      <!--                        <mat-icon class="menuIcon">{{ item.icon }}</mat-icon>-->
                                      <!--                      </div>-->
                                      <!--                      <div *ngIf="item.icon && item.mdiIcon" class="icon">-->
                                      <!--                        <mat-icon class="menuIcon mdi-icon" svgIcon="{{ item.icon }}"></mat-icon>-->
                                      <!--                      </div>-->
                                      <span *ngIf="menuSizeState === 'large'" class="px-6">{{
                                        'menu.' + child.title | translate
                                      }}</span>
                                    </a>
                                  </ng-container>
                                </ng-container>
                                <!--                  <mat-divider *ngIf="i !== item.children.length - 1"></mat-divider>-->
                              </mat-list>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <!--                  <mat-divider *ngIf="i !== item.children.length - 1"></mat-divider>-->
                      </mat-list>
                    </mat-expansion-panel>
                  </ng-container>
                  <!-- <mat-divider></mat-divider> -->
                </ng-container>
              </ng-container>
            </mat-accordion>
          </div>
        </mat-list>
      </nav>
    </mat-sidenav>

    <app-header
      [sidenav]="sidenav"
      [menuSizeState]="menuSizeState"
      (menuSizeStateEvent)="toggleMenuSize()"
      class="menu-state {{ menuSizeState }}"
      (isModuleTour)="ModuleTour()"
    ></app-header>
    <div class="main-container menu-{{ menuSizeState }}">
      <div class="main-container-inner">
        <router-outlet></router-outlet>
      </div>
      <div class="shell-footer-container">
        <a class="shell-footer" href="https://afaqy.com/" target="_blank">
          <span>{{ 'common.powered_by' | translate }}</span>
          <img class="logo-img" src="/assets/logo.svg" width="90px" alt="Afaqy" />
        </a>
      </div>
    </div>
  </mat-sidenav-container>
</div>
