import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltersInlineService {
  public formData: {} = {};

  constructor() {}
}
