<div class="dialog-container" fxFlex fxLayout="column">
  <mat-dialog-content>
    <image-cropper
      [imageChangedEvent]="data.image"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 1"
      format="png"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close mat-flat-button color="primary" class="btn-primary" (click)="saveLogo()">
      {{ 'common.save' | translate }}
    </button>
  </mat-dialog-actions>
</div>
