import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { PermissionsGuard } from '@app/core/permissions/permissions.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full',
    },
    {
      path: 'home',
      component: HomeComponent,
      data: {
        title: extract('Home'),
        permissionsKeys: [{ module: 'dashboard' }],
        adminDashboard: true,
        breadcrumb: { skip: true },
      },
      canActivate: [PermissionsGuard],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
