import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, OnChanges, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-slider-range',
  templateUrl: './slider-range.component.html',
  styleUrls: ['./slider-range.component.scss'],
})
export class SliderRangeComponent implements OnInit {
  @Input() floor: number;
  @Input() ceil: number;
  @Input() step?: number;
  @Input() appliedFilter: boolean;
  @Input() key: string;
  @Input() prefixUnit: string;
  @Input() form: UntypedFormGroup;
  @Input() manualRefresh: EventEmitter<void>;
  options?: Options;

  constructor() {}

  ngOnInit() {
    // console.log(this.floor);
    // console.log(this.ceil);
    this.options = {
      floor: this.floor,
      ceil: this.ceil,
      step: 1,
      noSwitching: true,
      enforceStep: false,
      enforceRange: false,
    };

    this.options.step = this.step;
    this.form.controls[this.key].setValue([this.floor, this.ceil]);
  }

  ngOnChanges() {
    if (!this.appliedFilter) {
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {}

  changeMinRange(key: string, value: number) {
    const maxValue = Number(this.ceil);
    if (maxValue >= value && value >= Number(this.floor)) {
      // console.log("min ok");
      // console.log(value);
      // console.log(maxValue);

      this.form.controls[key].setValue([value, this.form.get(key).value[1]]);
    }
  }

  changeMaxRange(key: string, value: number) {
    const minValue = Number(this.floor);

    if (value >= minValue && value <= Number(this.ceil)) {
      this.form.controls[key].setValue([this.form.get(key).value[0], value]);
    }
  }
}
