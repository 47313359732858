import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { SensorsStompConfig } from './sensors-stomp.config.service';

@Injectable({
  providedIn: 'root',
})
export class SensorsStompService extends RxStomp {
  constructor(public sensorsStompConfig: SensorsStompConfig) {
    super();
  }
}
