<mat-card class="main-card">
  <div class="header-container">
    <span class="card-title primary-font">{{ 'home.charts.numberOfActiveClients' | translate }}</span>
  </div>
  <div *ngIf="item?.datasets">
    <app-charts
      [dataSets]="item.datasets"
      [labels]="item.labels"
      [options]="options"
      [plugins]="plugins"
      [legend]="true"
      [type]="'line'"
      [height]="328"
    >
    </app-charts>
  </div>
</mat-card>
