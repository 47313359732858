<div fxFlex fxLayout="row" class="table-multi-actions" *ngIf="service.selectedItems.length">
  <div class="count">{{ service.selectedItems.length }} {{ 'common.selected' | translate }}</div>
  <div class="actions">
    <button
      *ngIf="listOptions.exportList && !listOptions.archivedList"
      mat-button
      class="btn-light export-list-btn"
      (click)="service.exportMultiple(service?.selectedItems, false, 'csv', params?.queryParams)"
    >
      <mat-icon class="mdi-icon" svgIcon="file-download-outline" [attr.size]="'16px'"></mat-icon>
      {{ 'common.export' | translate }}
    </button>

    <ng-container *ngFor="let operation of operations">
      <ng-container *ngIf="service?.permission?.all || (service?.permission)[operation?.permissionKey]">
        <button
          *ngIf="operation.condition"
          mat-button
          class="btn-light export-list-btn"
          (click)="service.openDialog(service.selectedItems, operation.type)"
        >
          <mat-icon class="mdi-icon" [svgIcon]="operation.icon" [attr.size]="'16px'"></mat-icon>
          <!--        {{ 'common.archive' | translate }}-->
          {{ 'common.' + operation.name | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
