<div *ngIf="options" [formGroup]="form">
  <div class="custom-slider" *ngIf="options?.floor != null">
    <ngx-slider [options]="options" [formControlName]="key" [manualRefresh]="manualRefresh"></ngx-slider>
  </div>
  <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>
      <mat-form-field class="range-form-field">
        <input
          matInput
          type="number"
          [value]="(form.get(key)?.value)[0]"
          (input)="changeMinRange(key, $event.target.value)"
        />
        <mat-icon matSuffix *ngIf="prefixUnit" class="mat-addon">
          <span class="input-addon">{{ prefixUnit }}</span>
        </mat-icon>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <i class="material-icons dark-color"> arrow_right_alt </i>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="range-form-field">
        <input
          matInput
          type="number"
          [value]="(form.get(key)?.value)[1]"
          (input)="changeMaxRange(key, $event.target.value)"
        />
        <mat-icon matSuffix *ngIf="prefixUnit" class="mat-addon">
          <span class="input-addon">{{ prefixUnit }}</span>
        </mat-icon>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</div>
