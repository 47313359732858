<div id="pagination-infinite-scroll">
  <div class="d-flex pages-container">
    <div
      fxFlex
      fxLayout="column"
      class="d-flex flex-justify-center align-items-center"
      *ngIf="pagination.lastPage > currentPage"
    >
      <div class="pb-5">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
      <div class="pb-5">
        {{ 'common.loading_more' | translate }}
      </div>
    </div>
  </div>
</div>
